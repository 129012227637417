import {
  devConfig,
  localhostConfig,
  nonProdConfig,
  prodConfig,
} from './configs';
import {
  getCountryCode,
  isDevelopment,
  isLocalhost,
  isNonProduction,
} from './environmentsHelpers';

const isCountry = (...args) => args.includes(getCountryCode());

const getEnvironmentConfig = () => {
  let environmentConfig = prodConfig;

  if (isNonProduction()) {
    environmentConfig = nonProdConfig;
  } else if (isDevelopment()) {
    environmentConfig = devConfig;
  } else if (isLocalhost()) {
    environmentConfig = localhostConfig;
  }

  return environmentConfig;
};

export { isCountry, getEnvironmentConfig };
