import React from 'react';
import PropTypes from 'prop-types';

function IconClose({ onClick, className }) {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 40 40"
      className={className}
      onClick={() => onClick()}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M7.31,8.282.972,14.621,0,13.649,6.339,7.31,0,.971.972,0,7.31,6.339,13.649,0l.972.972L8.282,7.31l6.339,6.339-.972.972Z"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_11168"
        data-name="Grupo 11168"
        transform="translate(-18 -24)"
      >
        <circle
          id="Elipse_418"
          data-name="Elipse 418"
          cx="17"
          cy="17"
          r="17"
          transform="translate(18 24)"
          fill="#343741"
          opacity="0.3"
          // style="mix-blend-mode: multiply;isolation: isolate"
        />
        <g
          id="Color_Liberty_Blue"
          data-name="Color/Liberty Blue"
          transform="translate(28 33.967)"
        >
          <path
            id="Mask-2"
            data-name="Mask"
            d="M7.31,8.282.972,14.621,0,13.649,6.339,7.31,0,.971.972,0,7.31,6.339,13.649,0l.972.972L8.282,7.31l6.339,6.339-.972.972Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

IconClose.defaultProps = {
  onClick: undefined,
  className: undefined,
};

IconClose.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default IconClose;
