import * as React from 'react';
import { useState } from 'react';
import AddingRow from './table/AddingRow';

export default function TableInspectionItemsFrontal() {
  const [elements, setElements] = useState([
    {
      Piece: 'Capo',
      Evaluation: 'Malo',
    },
    {
      Piece: 'Foco Izquierdo',
      Evaluation: 'Regular',
    },
    {
      Piece: 'Foco Derecho',
      Evaluation: 'Regular',
    },
  ]);

  return (
    <div className="flex flex:1 flex:col border:1px|solid|#CCC r:0.5rem box-shadow:0|0|3|5|gray-90 max-h:16.5rem overflow:hidden overflow-y:auto">
      <div className="flex sticky top:0 bg:white flex:row font-size:0.8rem font-weight:bold uppercase gap:1rem border-bottom:1px|solid|#CCC">
        <span className="flex flex:1 py:0.8rem justify-content:center  border-right:1px|solid|#CCC">
          Piezas
        </span>
        <span className="flex flex:1 py:0.8rem justify-content:center  border-right:1px|solid|#CCC">
          Evaluación
        </span>
      </div>

      <div className="flex flex:col">
        {elements.map((item, index) => (
          <div
            className={`flex flex:row py:0.5rem ${
              index % 2 === 0 ? 'bg:white' : 'bg:#CCCCCC99'
            }`}
          >
            <span className="flex flex:1 justify-content:center">
              {item.Piece}
            </span>
            <span className="flex flex:1 justify-content:center">
              {item.Evaluation}
            </span>
          </div>
        ))}

        <AddingRow onAddItem={item => setElements([...elements, item])} />
      </div>
    </div>
  );
}
