import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { IconSearch } from '@lmig/lmds-react';
import { Text } from 'informed';
import localizedStrings from '../../utils/localizedStrings';

const SearchInputGeneral = ({ setWordToSearch }) => {
  const { SEARCH_FINISHED } = localizedStrings;

  const searchInputRef = useRef();

  const searchWord = () => {
    setWordToSearch(searchInputRef.current.value);
  };
  const enterPress = event => {
    if (event.key === 'Enter') {
      setWordToSearch(searchInputRef.current.value);
    }
  };
  const resetWordIsEmpty = reference => {
    if (reference === '') setWordToSearch('');
  };

  return (
    <div className="adil-search-bar-form">
      <div className="adil-search-bar-form">
        <Text
          type="text"
          field="filter"
          autoComplete="off"
          placeholder={SEARCH_FINISHED}
          forwardedRef={searchInputRef}
          onChange={() => resetWordIsEmpty(searchInputRef.current?.value)}
          onKeyDown={enterPress}
          value={searchInputRef.current?.value || ''}
          className="adil-search-bar-input"
        />
        <button
          type="button"
          data-testid="adil-searchButton"
          className="adil-search-bar-search-icon"
          onClick={searchWord}
        >
          <IconSearch size="16" />
        </button>
      </div>
    </div>
  );
};

SearchInputGeneral.propTypes = {
  setWordToSearch: PropTypes.func,
};

SearchInputGeneral.defaultProps = {
  setWordToSearch: () => {
    // Empty function
  },
};

export default SearchInputGeneral;
