import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Content, Disclaimer, Heading } from '@lmig/lmds-react';
import { useLocation } from 'react-router-dom';
import {
  AppFooter,
  AppHeader,
  PhotoHistoryModal,
  SideBar,
  Stepper,
} from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { currentInspectionActions } from '../../redux/current-inspection';
import { getPhotos } from '../../api';
import { inspectionInformActions } from '../../redux/inspection-inform';
import { getPhotoDisplayStringFromId } from '../../config/photoDisplayStringMapping';
import { GALLERY_PHOTO_TYPE } from '../../constants/inspectionInform';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const {
  INSPECTION_INFORM_TITLE,
  INSPECTION_INFORM_SUBTITLE,
} = localizedStrings;

export default function InspectionsInform() {
  const { state: { status: inspectionData } = {} } = useLocation();
  const {
    inspectionInform: { photos },
    currentInspection: {
      rejectedPhotoHistory: { displayPhotoHistoryModal },
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();

  const loadInspectionPhotos = useCallback(async () => {
    dispatch(currentInspectionActions.setResetPhotoHistory());
    const response = await getPhotos(inspectionData.id);
    const responseVideo = await getPhotos(inspectionData.id, { video: true });
    const allPhotos = [
      ...response.photos.map(photo => ({
        ...photo,
        label: getPhotoDisplayStringFromId(
          inspectionData.vehicle.vehicleClass,
          photo.name,
        ),
        type: GALLERY_PHOTO_TYPE.PHOTO,
      })),
    ];

    if (response.photoHistory)
      dispatch(
        currentInspectionActions.setRetakePhotoHistory(response.photoHistory),
      );

    if (responseVideo.photos.length > 0) {
      const [firstPhoto] = responseVideo.photos;
      allPhotos.push({
        label: 'Video 360',
        photos: responseVideo.photos,
        type: GALLERY_PHOTO_TYPE.VIDEO,
        url: firstPhoto.url,
      });
    }

    dispatch(inspectionInformActions.setAllPhotosToGallery(allPhotos));

    const { videoCaptures } = responseVideo;
    if (videoCaptures?.length > 0) {
      videoCaptures.forEach(photo => {
        dispatch(inspectionInformActions.addVideoCaptureToGallery(photo));
      });
    }
  }, [dispatch, inspectionData.id, inspectionData.vehicle.vehicleClass]);

  useEffect(() => {
    (async () => {
      await loadInspectionPhotos();
    })();
  }, [loadInspectionPhotos]);

  const onClosePhotoHistoryModal = useCallback(() => {
    dispatch(
      currentInspectionActions.onDisplayPhotoHistoryModal(false, null, null),
    );
  }, [dispatch]);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <Content className="adil-content">
          <Heading type="h3-light" className="auto-left-right-margin">
            {INSPECTION_INFORM_TITLE}
          </Heading>
          <Disclaimer>{INSPECTION_INFORM_SUBTITLE}</Disclaimer>
          <Stepper inspectionData={inspectionData} photos={photos} />
          {renderFooterSection()}
        </Content>
      </SideBar>

      {displayPhotoHistoryModal && (
        <PhotoHistoryModal
          isOpen={displayPhotoHistoryModal}
          onCloseModal={onClosePhotoHistoryModal}
        />
      )}
    </div>
  );
}
