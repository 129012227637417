import { useState } from 'react';
import { PropTypes } from 'prop-types';

export const useBrigthness = (brigthnessRef = null) => {
  const [brigthnessOpen, setBrigthnessOpen] = useState(false);
  const [valueBrigthness, setvalueBrigthness] = useState(100);

  const changeValueBrigthness = () => {
    setvalueBrigthness(Math.floor(brigthnessRef.current.value));
  };
  const resetBrigthnessValue = () => {
    setvalueBrigthness(100);
    setBrigthnessOpen(false);
  };

  const handleShowBrightnessBar = () => {
    setBrigthnessOpen(!brigthnessOpen);
  };

  return {
    changeValueBrigthness,
    valueBrigthness,
    brigthnessOpen,
    handleShowBrightnessBar,
    resetBrigthnessValue,
    setvalueBrigthness,
  };
};

useBrigthness.propTypes = {
  brigthnessRef: PropTypes.shape({ current: PropTypes.instanceOf() }),
};
