import { Caption, GridRow } from '@lmig/lmds-react';
import { IconErrorAlert } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorsList = ({ errors }) => {
  // Filter out any '', null or undefined values
  const parsedErrors = errors.filter(error => !!error);

  if (parsedErrors.length === 0) {
    return null;
  }

  return parsedErrors.map(error => (
    <GridRow
      key={`vehicle-details-error-panel-${error}`}
      className="vehicle-details-error-panel"
    >
      <Caption className="error-message">
        <IconErrorAlert size="16" />
        {error}
      </Caption>
    </GridRow>
  ));
};

ErrorsList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

ErrorsList.defaultProps = {
  errors: [],
};

export default ErrorsList;
