import { LOG_LEVELS } from './logger';

const { ERROR, INFO } = LOG_LEVELS;

export default {
  AAAPI010: {
    code: 'AAAPI010',
    level: INFO,
    message: 'Fuzzy match triggered',
  },
  AAISD010: {
    code: 'AAISD010',
    level: ERROR,
    message: 'Cannot find display string for inspection status',
  },
  AANAV010: {
    code: 'AANAV010',
    level: INFO,
    message: 'User has navigated',
  },
  CALAN110: {
    code: 'CALAN110',
    level: ERROR,
    message: 'Error selecting language',
  },
  RSREM130: {
    code: 'RSREM130',
    level: ERROR,
    message: 'Failed resending notifications',
  },
  ROTI010: {
    code: 'ROTI010',
    level: ERROR,
    message: 'Error in request to rotate image',
  },
  CAPT010: {
    code: 'CAPT010',
    level: ERROR,
    message: 'Error saving video capture',
  },
  SIE010: {
    code: 'SIE010',
    level: INFO,
    message: 'Getting external inspection',
  },
  SIE020: {
    code: 'SIE020',
    level: ERROR,
    message: 'Error getting external inspection',
  },
};
