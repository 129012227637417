import { Auth } from 'aws-amplify';
import { FIELD_NAMES } from '@lmig-latam/adil-api-common-lib/constants';
import PropTypes from 'prop-types';
import cjson from 'compressed-json';
import {
  addCustomerMessage,
  assignInspection,
  createInspection,
  deleteDocument,
  deleteInspection,
  getCustomerMessages,
  getDocuments,
  getInspection,
  getCustomerInfoFromEis,
  getInspectionCount,
  getInspections,
  getFinishedInspections,
  getPhotos,
  getPhotosZip,
  getReport,
  resendCustomerNotifications,
  updateInspection,
  updateInspectionStatus,
  updatePhotos,
  uploadDocument,
  updateInspectionPhotosOrZipAPI,
  updateInspectionZipAPI,
  sendZipToS3,
  downloadDocumentsApi,
  sendDocumentsZipToS3,
  updateInspectionDocumentZipAPI,
  getReportInspectionAsia,
  rotateImage,
  addRejectedPhotosToHistory,
  addVideoCapture,
} from '../api';
import { getCountryCode } from '../config/environments/environmentsHelpers';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';

const {
  SOURCE_APPLICATION_ID,
  SOURCE_APPLICATION_ENDPOINT,
  COUNTRY_CODE,
} = FIELD_NAMES;

export default {
  PropertyTypes: PropTypes.shape({
    addCustomerMessage: PropTypes.func.isRequired,
    assignInspection: PropTypes.func.isRequired,
    createInspection: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    deleteInspection: PropTypes.func.isRequired,
    getCustomerMessages: PropTypes.func.isRequired,
    getCustomerInfoFromEis: PropTypes.func.isRequired,
    getDocuments: PropTypes.func.isRequired,
    getReportInspectionAsian: PropTypes.func.isRequired,
    getInspection: PropTypes.func.isRequired,
    getInspectionCount: PropTypes.func.isRequired,
    getInspections: PropTypes.func.isRequired,
    getPhotos: PropTypes.func.isRequired,
    getPhotosZip: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    resendCustomerNotifications: PropTypes.func.isRequired,
    rotateImage: PropTypes.func.isRequired,
    updateInspection: PropTypes.func.isRequired,
    updateInspectionPhotosOrZip: PropTypes.func.isRequired,
    updateInspectionDocumentsZip: PropTypes.func.isRequired,
    downloadDocuments: PropTypes.func.isRequired,
    updateInspectionStatus: PropTypes.func.isRequired,
    updatePhotos: PropTypes.func.isRequired,
    uploadDocument: PropTypes.func.isRequired,
  }),

  createInspection(inspection) {
    const newInspection = {
      ...inspection,
      [SOURCE_APPLICATION_ID]: 'ADIL_AGENT',
      [SOURCE_APPLICATION_ENDPOINT]: 'N/A',
      [COUNTRY_CODE]: getCountryCode(),
    };
    return async () => createInspection(newInspection);
  },

  getInspections(searchParams = {}, enableErrorHandling = false) {
    return async () => getInspections(searchParams, enableErrorHandling);
  },

  getFinishedInspections(searchParams = {}, enableErrorHandling = false) {
    return async () =>
      getFinishedInspections(searchParams, enableErrorHandling);
  },

  getCustomerInfoFromEis(searchParams = {}) {
    return async () => getCustomerInfoFromEis(searchParams);
  },

  getReport(searchParams = {}) {
    return async () => getReport(searchParams);
  },

  getInspection(inspectionId) {
    return async () => getInspection(inspectionId);
  },

  updateInspection(inspection) {
    return async () => updateInspection(inspection);
  },

  addVideoCapture(inspectionId, photo) {
    return async () => addVideoCapture(inspectionId, photo);
  },

  updateInspectionPhotosOrZip(inspectionId, PhotosOrZip) {
    return async () => {
      let response = {};
      if (PhotosOrZip.name && PhotosOrZip.name !== null) {
        response = await updateInspectionPhotosOrZipAPI(
          inspectionId,
          PhotosOrZip,
        );
      } else {
        await sendZipToS3(inspectionId, PhotosOrZip);
        const credentials = await Auth.currentUserInfo();
        response = await updateInspectionZipAPI(inspectionId, credentials.id);
      }
      return response;
    };
  },

  updateInspectionDocumentsZip(inspectionId, documentZip) {
    return async () => {
      let response = {};
      await sendDocumentsZipToS3(inspectionId, documentZip);
      const credentials = await Auth.currentUserInfo();
      response = await updateInspectionDocumentZipAPI(
        inspectionId,
        credentials.id,
      );
      return response;
    };
  },

  downloadDocuments(inspectionId, queryObject) {
    return async () => downloadDocumentsApi(inspectionId, queryObject);
  },

  addRejectedPhotosToHistory(inspectionId, status, rejectedPhotos, comments) {
    const body = { status, rejectedPhotos, comments };
    return async () => addRejectedPhotosToHistory(inspectionId, body);
  },

  updateInspectionStatus(
    inspectionId,
    status,
    agentId,
    overrideComment,
    rejectedPhotos,
    isAdmin,
  ) {
    const body = { status, rejectedPhotos, isAdmin };

    if (overrideComment) {
      body.agentOverrideComment = { agentId, overrideComment };
    }

    return async () => updateInspectionStatus(inspectionId, body);
  },

  getPhotos(inspectionId, params) {
    return async () => getPhotos(inspectionId, params);
  },

  getPhotosZip(inspectionId, queryObject) {
    return async () => getPhotosZip(inspectionId, queryObject);
  },

  updatePhotos(inspectionId, photos) {
    let body = JSON.parse(JSON.stringify(photos)); // this line is to create an array copy without modifying the original
    body = body.map(photo => {
      const item = photo;
      item.url = item.url.replace('://', '');
      return item;
    });

    return async () => updatePhotos(inspectionId, body);
  },

  getDocuments(inspectionId, queryObject) {
    return async () => {
      const response = await getDocuments(inspectionId, queryObject);

      try {
        return response.documents.map(document => ({
          ...document,
          url: document.url,
        }));
      } catch (error) {
        navigate(ERROR_GENERIC);
        return [];
      }
    };
  },

  uploadDocument(inspectionId, document) {
    return async () => uploadDocument(inspectionId, document);
  },

  getReportInspectionAsian(searchParams = {}) {
    return async () => {
      const response = await getReportInspectionAsia(searchParams);
      try {
        return cjson.decompress(response);
      } catch (error) {
        navigate(ERROR_GENERIC);
        return [];
      }
    };
  },

  deleteDocument(inspectionId, document) {
    return async () => deleteDocument(inspectionId, document);
  },

  deleteInspection(inspectionId, agentComments) {
    return async () => deleteInspection(inspectionId, agentComments);
  },

  getCustomerMessages(inspectionId, queryObject) {
    return async () => {
      const response = await getCustomerMessages(inspectionId, queryObject);

      try {
        return response.customerMessages;
      } catch (error) {
        navigate(ERROR_GENERIC);
        return [];
      }
    };
  },

  addCustomerMessage(inspectionId, customerMessage) {
    return async () => addCustomerMessage(inspectionId, customerMessage);
  },

  getInspectionCount(queryObject) {
    return async () => getInspectionCount(queryObject);
  },

  resendCustomerNotifications(inspectionId) {
    return async () => resendCustomerNotifications(inspectionId);
  },

  assignInspection(inspectionId, name, override, enableErrorHandling) {
    return async () =>
      assignInspection(inspectionId, name, override, enableErrorHandling);
  },

  rotateImage(inspectionId, document, rotate) {
    return async () => rotateImage(inspectionId, document, rotate);
  },
};
