import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE,
  SUPPORT_INSPECTION_TROUBLES,
  SUPPORT_INSPECTION_BINNACLE_MODAL,
  SUPPORT_INSPECTION_TROUBLES_ADITIONAL,
  SUPPORT_INSPECTION_SOLUTIONS,
  SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL,
  SUPPORT_INSPECTION_SOLUTIONS_USER,
  SUPPORT_INSPECTION_SOLUTIONS_RESOLUTOR,
  SUPPORT_INSPECTION_BINNACLE_SELECT,
  INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT,
  SUPPORT_INSPECTION_BINNACLE_SELECT_OPTION,
} = localizedStrings;

const BinnacleSupportModal = ({
  isModalBinnacleOpen,
  historySupport,
  closeNotificationModal,
}) => {
  const [binnaclePerRequest, setBinnaclePerRequest] = useState([]);
  const [itemSupport, setItemSupport] = useState({});

  const selectSolutionSupport = event => {
    const solution = event.target.value;
    const itemToShow = binnaclePerRequest.find(
      supporrItem => supporrItem.id === solution,
    );
    setItemSupport(itemToShow);
  };

  useEffect(() => {
    setBinnaclePerRequest(historySupport);
    return () => {
      setBinnaclePerRequest([]);
    };
  }, [historySupport]);

  const returnSolutionParse = itemSolution => {
    const itemParsed = JSON.parse(itemSolution);
    return itemParsed.description;
  };

  return (
    <ReactModal
      isOpen={isModalBinnacleOpen}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      <div className="modal-content modalBinnacle">
        <h4>{SUPPORT_INSPECTION_BINNACLE_MODAL}</h4>
        <div className="modalBinnacle__search">
          <h4>{SUPPORT_INSPECTION_BINNACLE_SELECT}</h4>
          <select
            defaultValue={INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT}
            className="inputs_rhf_adil"
            onChange={e => selectSolutionSupport(e)}
          >
            <option value={INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT} disabled>
              {INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT}
            </option>
            {binnaclePerRequest.length > 0 &&
              binnaclePerRequest.map(supportItem => (
                <option key={supportItem.id} value={supportItem.id}>
                  {SUPPORT_INSPECTION_BINNACLE_SELECT_OPTION}
                  {supportItem.id}
                </option>
              ))}
          </select>
        </div>
        <div className="supportInspectionBox">
          <div className="supportInspectionBox__troubles">
            <h4>{SUPPORT_INSPECTION_TROUBLES}</h4>
            <ul>
              {itemSupport.troubles?.map(trouble => (
                <li key={trouble.id}>{trouble.description}</li>
              ))}
            </ul>
            <div className="supportInspectionBox__troubles__informationPlus">
              <h4>{SUPPORT_INSPECTION_TROUBLES_ADITIONAL}</h4>
              <span>{itemSupport?.additionalInformation}</span>
            </div>
          </div>
          <div className="supportInspectionBox__solutions">
            <div className="supportInspectionBox__solutions__informationPlus">
              <h4>{SUPPORT_INSPECTION_SOLUTIONS_RESOLUTOR}</h4>
              <span>{itemSupport?.resolutor}</span>
            </div>
            <div className="supportInspectionBox__solutions__informationPlus">
              <h4>{SUPPORT_INSPECTION_SOLUTIONS}</h4>
              <span>
                {itemSupport?.solutionDelivered
                  ? returnSolutionParse(itemSupport?.solutionDelivered)
                  : ''}
              </span>
            </div>
            <div className="supportInspectionBox__solutions__informationPlus">
              <h4>{SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL}</h4>
              <span>{itemSupport?.commentsSolutions}</span>
            </div>
            <div className="supportInspectionBox__solutions__informationPlus">
              <h4>{SUPPORT_INSPECTION_SOLUTIONS_USER}</h4>
              <span>{itemSupport?.commentsSolutions}</span>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="adil-search__button"
          onClick={() => {
            closeNotificationModal(!isModalBinnacleOpen);
            setItemSupport();
          }}
        >
          {INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE}
        </button>
      </div>
    </ReactModal>
  );
};

BinnacleSupportModal.propTypes = {
  historySupport: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isModalBinnacleOpen: PropTypes.bool.isRequired,
  closeNotificationModal: PropTypes.func.isRequired,
};

export default BinnacleSupportModal;
