import { UPDATE_USER_INFO } from '../actions/ActionTypes';

export const initialState = {
  name: '',
  username: '',
  isReviewer: false,
  isViewer: false,
  isCreator: false,
  isBdmDca: false,
  isBdmCa: false,
  isCa: false,
  isDca: false,
  isAdmin: false,
  isVendor: false,
  allowedCountries: [],
};

const updateUserInfoHandler = (state, { user }) => ({
  ...state,
  ...user,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return updateUserInfoHandler(state, action);
    default:
      return state;
  }
};
