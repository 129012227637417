export const newVehiclePieceAdapter = ({
  piece,
  partOfPiece,
  activeOption,
}) => ({
  name: piece,
  vehiclePartId: JSON.parse(partOfPiece),
  active: JSON.parse(activeOption),
});

export const newVehiclePartAdapter = ({ part, activeOption, pieces = [] }) => ({
  name: part,
  pieces,
  active: JSON.parse(activeOption),
});
