import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Input = ({
  type,
  value,
  onChange,
  placeholder,
  className,
  disable,
  ...props
}) => (
  <input
    type={type}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className={['adil-input', className].join(' ')}
    disabled={disable}
    {...props}
  />
);

Input.defaultProps = {
  className: undefined,
  disable: undefined,
};

Input.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Input;
