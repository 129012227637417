import React from 'react';
import PropTypes from 'prop-types';
import brigthnessIcon from '../../../assets/images/brigthness-icon.png';
import { BrigthnessBar } from './BrigthnessBar';
import './styles.scss';

export const Brigthness = ({
  handleShowBrightnessBar,
  brigthnessRef,
  brigthnessOpen,
  valueBrigthness,
  changeValueBrigthness,
  hidden,
}) => (
  <div
    className="imageGallery__brigthness"
    style={{ display: hidden && 'none' }}
  >
    <BrigthnessBar
      min="100"
      max="200"
      isOpen={brigthnessOpen}
      onChange={changeValueBrigthness}
      brigthnessRef={brigthnessRef}
      value={valueBrigthness}
    />
    <div
      role="button"
      className="imageGallery__brigthness__buttons"
      onClick={handleShowBrightnessBar}
    >
      <img
        src={brigthnessIcon}
        alt="brigthnessIcon"
        style={{ filter: 'brightness(0) invert(1)' }}
        className="viewer__bridgthness"
      />
    </div>
  </div>
);

Brigthness.defaultProps = {
  hidden: false,
};

Brigthness.propTypes = {
  hidden: PropTypes.bool,
  handleShowBrightnessBar: PropTypes.func.isRequired,
  brigthnessRef: PropTypes.node.isRequired,
  brigthnessOpen: PropTypes.bool.isRequired,
  valueBrigthness: PropTypes.number.isRequired,
  changeValueBrigthness: PropTypes.func.isRequired,
};
