import { getCountryCodesUpperCase } from '@lmig-latam/adil-api-common-lib/utils';
import constants from '@lmig-latam/adil-api-common-lib/constants/constants';
import {
  DEFAULT_ASIGNEE,
  STATUS_TYPES,
} from '@lmig-latam/adil-api-common-lib/constants';
import { utils } from '@lmig-latam/adlib-ui';
import { getCurrentLanguage } from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { validateEmailFormat } from '@lmig-latam/adil-api-common-lib/utils/emailUtils';
import {
  getCountryCode,
  isAsianDomain,
} from '../config/environments/environmentsHelpers';
import { SettingsActions } from '../actions';
import { store } from '../common/store';
import {
  customerFieldsToFilter,
  fieldsToFilter,
  SIDEBAR_COOKIE_EXPIRY_HOURS,
  SIDEBAR_COOKIE_NAME,
} from './constants';
import localizedStrings from './localizedStrings';
import { logcodes, logger } from './logger';
import { getCameraTypes } from '../constants/camera';
import { InspectionBuilder } from './builders/inspectionBuilder';

const { formatString } = utils;

const CAMERA_TYPES = getCameraTypes();

const { INSPECTION_SUBMISSION_ID } = getCurrentLanguage();

const { STATUS_CUSTOMER_IN_PROGRESS } = STATUS_TYPES;

const {
  UTILS_AGE_SINCE_YEARS_AGO,
  UTILS_AGE_SINCE_MONTHS_AGO,
  UTILS_AGE_SINCE_DAYS_AGO,
  UTILS_AGE_SINCE_HOURS_AGO,
  UTILS_AGE_SINCE_MINUTES_AGO,
  UTILS_AGE_SINCE_SECONDS_AGO,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MAX,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MIN,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SUBMISSIONID,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY,
  INSPECTION_DEFAULT_ASSIGNEE_DISPLAY_VALUE,
} = localizedStrings;

export const convertFileToDataURL = file => {
  const reader = new FileReader();
  const readDataPromise = new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
  reader.readAsDataURL(file);

  return readDataPromise;
};

export const formatDate = dateLastModified => {
  const date = new Date(dateLastModified);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = date.getMinutes();
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const flattenObjectsIntoArray = (obj = {}) =>
  Object.keys(obj || {}).reduce((acc, cur) => {
    if (typeof obj[cur] === 'object') {
      // eslint-disable-next-line no-param-reassign
      acc = { ...acc, ...flattenObjectsIntoArray(obj[cur]) };
    } else {
      acc[cur] = obj[cur];
    }
    return acc;
  }, {});

export const filterByValue = (array, string) =>
  array.filter(o =>
    Object.keys(o).some(
      k =>
        o[k]
          .toString()
          .toLowerCase()
          .indexOf(string.toLowerCase()) !== -1,
    ),
  );

export const animationCollapsable = idCollapsable => {
  const growDiv = document.getElementById(idCollapsable);
  if (growDiv.clientHeight) {
    growDiv.style.height = 0;
  } else {
    const wrapper = document.querySelector(
      `.collapasableAdil__content__grown--${idCollapsable}`,
    );
    growDiv.style.height = `${wrapper.clientHeight + 30}px`;
  }
};

export const removeAllCollapsable = () => {
  document.querySelectorAll('.collapasableAdil__content').forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.style.height = 0;
  });
};

export const getAgeSince = seconds => {
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_YEARS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_MONTHS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_DAYS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_HOURS_AGO, {
      interval,
    });
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return formatString(UTILS_AGE_SINCE_MINUTES_AGO, {
      interval,
    });
  }
  return formatString(UTILS_AGE_SINCE_SECONDS_AGO, {
    interval,
  });
};

export const arraysEqual = (a, b, ignoreOrder) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  if (ignoreOrder) {
    a.sort();
    b.sort();
  }

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

// Taken from https://www.w3schools.com/js/js_cookies.asp
export const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

// Taken from https://www.w3schools.com/js/js_cookies.asp
// Note: If no expiry is provided, the cookie will expire with the session
export const setCookie = (cookieName, cookieValue, expiryInHours = null) => {
  let expires = '';
  if (expiryInHours) {
    const now = new Date();
    now.setTime(now.getTime() + expiryInHours * 60 * 60 * 1000);
    expires = `expires=${now.toUTCString()}`;
  }
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

// Taken from https://stackoverflow.com/a/2144404
export const deleteCookie = cookieName => {
  if (getCookie(cookieName)) {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

export const doubleAuthentication = () =>
  constants.NOT_DOUBLE_AUTH.includes(getCountryCode().toUpperCase());

export const formatFullName = fullName => {
  const newFullName = fullName.split(' ');
  return {
    firstName:
      newFullName.length > 2
        ? `${newFullName[0]} ${newFullName[1] || ''}`
        : newFullName[0],
    lastName:
      newFullName.length > 2
        ? `${newFullName[2]} ${newFullName[3] || ''}`
        : newFullName[1] || '',
  };
};

export const verifyFieldValidation = (
  fieldValue,
  { labelVisual, maxLength, minLenght, fieldType, fieldTypeError, required },
) => {
  let errorMessage; // Should default to undefined if validation passes
  if (labelVisual.includes('Email') && fieldValue?.length > 0) {
    errorMessage = validateEmailFormat(fieldValue, getCountryCode());
    return errorMessage?.length > 0 ? errorMessage : undefined;
  }
  if (!fieldValue && !required) {
    return errorMessage;
  }

  if (!fieldValue) {
    errorMessage = `${labelVisual}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`;
  } else if (`${fieldValue}`.length > maxLength) {
    errorMessage = `${labelVisual}: ${
      getCountryCode() === 'vn' && labelVisual === INSPECTION_SUBMISSION_ID
        ? INSPECTION_VEHICLE_DETAILS_VALIDATION_SUBMISSIONID
        : formatString(INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MAX, {
            maxLength,
          })
    }`;
  } else if (fieldType && !fieldValue.match(fieldType)) {
    errorMessage = `${labelVisual}: ${fieldTypeError}`;
  } else if (getCountryCode() === 'vn' && `${fieldValue}`.length < minLenght) {
    errorMessage = `${labelVisual}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MIN}`;
  }

  return errorMessage;
};

export const verifySelectValidation = (
  fieldValue,
  { labelVisual, required },
) => {
  let errorMessage; // Should default to undefined if validation passes

  if (!fieldValue && required) {
    errorMessage = `${labelVisual}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY}`;
  }

  return errorMessage;
};

export const getInspectionStatusDisplayName = inspectionStatus => {
  let displayString =
    localizedStrings[`INSPECTION_STATUS_${inspectionStatus}_DISPLAY_VALUE`];

  if (!displayString) {
    logger.log(logcodes.AAISD010, { status: inspectionStatus });
    displayString = inspectionStatus;
  }

  return displayString;
};

export const getAssigneeDisplayName = assignee => {
  let displayString = assignee;

  // Needed for backwards compatibility of inspections that were not created with an assignee
  if (!assignee || assignee === DEFAULT_ASIGNEE) {
    displayString = INSPECTION_DEFAULT_ASSIGNEE_DISPLAY_VALUE;
  }

  return displayString;
};

export const saveSidebarState = () => {
  const {
    settings: { sidebarOpen },
  } = store.getState();
  setCookie(SIDEBAR_COOKIE_NAME, !sidebarOpen, SIDEBAR_COOKIE_EXPIRY_HOURS);
};

export const restoreSidebarState = () => {
  const sidebarCookieVal = getCookie(SIDEBAR_COOKIE_NAME);
  if (sidebarCookieVal === 'false') {
    store.dispatch(SettingsActions.toggleSidebar());
  }
};

export const isReviewer = user =>
  user.attributes['custom:groups'].includes('ADIL_Inspection_Review_');

export const isCreator = user =>
  user.attributes['custom:groups'].includes('ADIL_Inspection_Create_');

export const isAdmin = user =>
  user.attributes['custom:groups'].includes('ADIL_Inspection_Admin_');

export const isViewer = user =>
  user.attributes['custom:groups']
    .toUpperCase()
    .includes('ADIL_INSPECTION_VIEW_');

export const getAllowedCountries = user =>
  getCountryCodesUpperCase().filter(
    country =>
      user.attributes['custom:groups'].includes(
        `ADIL_Inspection_Review_${country}`,
      ) ||
      user.attributes['custom:groups'].includes(
        `ADIL_Inspection_Create_${country}`,
      ) ||
      user.attributes['custom:groups'].includes(
        `ADIL_Inspection_Admin_${country}`,
      ) ||
      user.attributes['custom:groups']
        .toUpperCase()
        .includes(`ADIL_INSPECTION_VIEW_${country}`) ||
      user.attributes['custom:groups'].includes('ADIL_Inspection_Admin_ALL'),
  );

const startYear = 1900;
const endYear = new Date().getFullYear();

// Fill an array with sequential values
// https://davidwalsh.name/fill-array-javascript
export const YEAR_SELECT_OPTIONS = Array(endYear - startYear + 1)
  .fill()
  .map((item, index) => `${startYear + index}`);

export const formatISOString = isoString => {
  if (!Date.parse(isoString)) {
    return isoString;
  }

  const date = new Date(isoString);

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();
  let hour = date.getHours().toString();
  let minute = date.getMinutes().toString();

  day = day.length === 1 ? `0${day}` : day;
  month = month.length === 1 ? `0${month}` : month;
  hour = hour.length === 1 ? `0${hour}` : hour;
  minute = minute.length === 1 ? `0${minute}` : minute;

  return `${day}/${month}/${year} ${hour}:${minute}`;
};

export const downloadFileFromUrl = (url, fileName) => {
  const download = document.createElement('a');
  download.href = url;
  download.setAttribute('download', fileName);
  download.click();
};

export const isIntegratedCameraSelected = camera =>
  !!(camera === CAMERA_TYPES.FRAME.id && !isAsianDomain());

export const getPage = (fullItemList = [], pageSize = 10, pageIndex = 0) =>
  fullItemList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

export const inspectionListTextFilter = (inspectionArray, textFilter) => {
  const result = [];

  inspectionArray.forEach(inspection => {
    const values = [];
    fieldsToFilter.forEach(field => values.push(inspection[field]));
    customerFieldsToFilter.forEach(
      field =>
        inspection.customer &&
        inspection.customer[field] &&
        values.push(inspection.customer[field]),
    );
    if (inspection.vehicle && inspection.vehicle.licencePlate)
      values.push(inspection.vehicle.licencePlate);

    if (inspection.broker?.brokerName)
      values.push(inspection.broker.brokerName);

    if (inspection.broker?.brokerCode)
      values.push(inspection.broker.brokerCode);
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const value of values) {
      if (
        value &&
        value
          .toString()
          .toLowerCase()
          .includes(textFilter.toLowerCase())
      ) {
        result.push(inspection);
        break;
      }
    }
  });

  return result;
};

export const inspectionLIstFilter = (
  selected,
  subStatusSelected,
  mustHavePhotos,
  fullInspectionList,
) => {
  if (mustHavePhotos) {
    return fullInspectionList.filter(
      inspection =>
        STATUS_CUSTOMER_IN_PROGRESS.includes(inspection.status) &&
        inspection.photos.length > 0,
    );
  }

  if (subStatusSelected) {
    return fullInspectionList.filter(
      inspection => subStatusSelected === inspection.status,
    );
  }

  return fullInspectionList.filter(inspection =>
    selected.statuses.includes(inspection.status),
  );
};

export const countInspectionList = list => {
  const result = {};
  // eslint-disable-next-line no-return-assign
  Object.values(STATUS_TYPES).forEach(state => (result[state] = 0));

  list.forEach(item => {
    result[item.status] += 1;
  });
  return result;
};

export const prefillDataExternalInspection = ({
  customer,
  vehicle,
  broker,
  brokerEmail,
}) => {
  const builder = new InspectionBuilder();
  const inspection = builder
    .setCountryCode(getCountryCode())
    .setBroker({ ...broker, brokerEmail })
    .setCustomer(customer)
    .setVehicle(vehicle)
    .build();
  return inspection;
};

export const prefillEisData = data => {
  const {
    submissionId,
    creatorEmail,
    fullName,
    cellPhoneNumber,
    location,
    email,
    licencePlate,
    year,
    make,
    model,
    use,
    engineNumber,
    version,
    capacity,
    chassisNumber,
    value,
  } = data;
  return {
    submissionId,
    infoAgent: {
      creatorEmail,
    },
    customer: {
      fullName,
      cellPhoneNumber,
      email,
      location,
    },
    vehicle: {
      licencePlate,
      year,
      make,
      model,
      version,
      chassisNumber,
      engineNumber,
      capacity,
      use,
      value,
    },
  };
};

export const prefillDataIntoInform = inspectionData => {
  const {
    id,
    createdBy,
    submissionId,
    customer: {
      firstName = '',
      middleName = '',
      lastName = '',
      secondLastName = '',
      email: customerEmail,
    },
    broker: { brokerName = '', brokerCode = '' } = {},
    customerCompletionDate,
    location,
    vehicle: { licencePlate, class: vehicleClass },
  } = inspectionData;
  return {
    id,
    corredorName: brokerName,
    corredorCode: brokerCode,
    userInspector: createdBy,
    inspectorName: createdBy,
    supplierInspector: '',
    nameCustomer: `${firstName} ${middleName} ${lastName} ${secondLastName}`,
    inspectionNumber: submissionId,
    emailCustomer: customerEmail,
    plateInspection: licencePlate,
    vehicleType: vehicleClass,
    inspectionModule: '',
    inspectionPlace: location,
    inspectionDate: customerCompletionDate.split('T')[0],
    inspectionCityRegion: '',
  };
};
