import * as React from 'react';
import PropTypes from 'prop-types';

export default function IconCheck({ isCheck }) {
  return (
    <div
      className={`flex justify-content:center align-items:center h:1.4rem w:1.4rem r:50% z-index:99 ${
        isCheck ? 'bg:#99E5EA' : 'bg:#C5C5C5'
      }`}
    >
      <div
        className={`flex justify-content:center align-items:center h:1.2rem w:1.2rem r:50% bg:white ${
          isCheck ? 'color:#99E5EA' : 'color:#C5C5C5'
        }`}
      >
        &#10003;
      </div>
    </div>
  );
}

IconCheck.propTypes = {
  isCheck: PropTypes.bool.isRequired,
};
