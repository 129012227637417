import React from 'react';
import { useFormContext } from 'react-hook-form';
import localizedStrings from '../../utils/localizedStrings';
import corredorImage from '../../assets/images/inspector_icon_inform.png';

export const InspectorBox = () => {
  const {
    FORM_INFORM_ANALYSIS_INSPECTOR_USER,
    FORM_INFORM_ANALYSIS_INSPECTOR_NAME,
    FORM_INFORM_ANALYSIS_INSPECTOR_SUPPLIER,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED,
  } = localizedStrings;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="formInform">
      <div className="formInform__icon">
        <img src={corredorImage} alt={corredorImage} />
      </div>
      <div className="formInform__inputs">
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTOR_USER}
            {...register('userInspector', {
              required: true,
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.userInspector ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.userInspector && errors.userInspector.type === 'pattern' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
            </p>
          )}
          {errors.userInspector && errors.userInspector.type === 'required' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
            </p>
          )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTOR_NAME}
            {...register('inspectorName', {
              required: true,
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.inspectorName ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.inspectorName && errors.inspectorName.type === 'pattern' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
            </p>
          )}
          {errors.inspectorName && errors.inspectorName.type === 'required' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
            </p>
          )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTOR_SUPPLIER}
            {...register('supplierInspector', {
              required: true,
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.supplierInspector ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.supplierInspector &&
            errors.supplierInspector.type === 'pattern' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
              </p>
            )}
          {errors.supplierInspector &&
            errors.supplierInspector.type === 'required' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
              </p>
            )}
        </div>
      </div>
    </div>
  );
};
