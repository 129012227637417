import React, { useState } from 'react';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import SteeperScreen1 from './SteeperScreen1';
import SteeperScreen2 from './SteeperScreen2';
import SteeperScreen3 from './SteeperScreen3';
import './styles.scss';

const {
  STEPPER_VALIDATE_DATA,
  STEPPER_VALIDATE_CRIMINAL_RECORD,
  STEPPER_SEND_RESULTS,
  STEPPER_LABEL_ONE,
  STEPPER_LABEL_TWO,
  STEPPER_LABEL_THREE,
} = localizedStrings;

/**
 * @typedef Photos
 * @prop {string} url
 */

export const GALLERY_OPTIONS = {
  LEFT_ROTATION: 'left-rotation',
  RIGHT_ROTATION: 'right-rotation',
  FULLSCREEN: 'fullscreen',
  PHOTO_HISTORY: 'photo-history',
};

/**
 * @param {import('../../types/InspectionsInform/InspectionData').InspectionData} inspectionData
 * @param {Photos[]} photos
 * @param {(photos: Photos[]) => void} setPhotos
 * @return {JSX.Element}
 * @constructor
 */
export default function Stepper({ inspectionData, photos }) {
  /**
   * @param {1 | 2 | 3} The possibles values of useState
   */
  const [currentStep, setCurrentStep] = useState(1);

  /**
   * @param {import('../../types/InspectionsInform/InspectionFormInput').InspectionFormInput | undefined}
   */
  const [inspectionFormInput, setInspectionFormInput] = useState(undefined);

  /**
   * @param {1 | 2 | 3} step The current step.
   * @return {boolean} True if the current step is active.
   */
  const isStepActive = step => currentStep >= step;

  /**
   * @param {1 | 2 | 3} step The current step.
   */
  const getClassForActiveStep = step => {
    if (isStepActive(step)) {
      return 'adil-stepper-step-icon-active';
    }
    return 'adil-stepper-step-icon-inactive';
  };

  /**
   * @param {1 | 2 | 3} step The current step.
   */
  const getClassForJoinActive = step => {
    if (isStepActive(step)) {
      return 'adil-stepper-join-active';
    }
    return 'adil-stepper-join-inactive';
  };

  /**
   * Is important update the state inspectionForm, the step 2 require
   * this information.
   *
   * @param {import('../../types/InspectionsInform/InspectionFormInput').InspectionFormInput} inspectionForm
   *  The inspection form used in the steppers components
   */
  const onContinueStep1 = inspectionForm => {
    setCurrentStep(2);
    setInspectionFormInput(inspectionForm);
  };

  const onPrevStep = () => {
    switch (currentStep) {
      case 1:
        break;
      case 2:
        setCurrentStep(1);
        break;
      case 3:
        setCurrentStep(2);
        break;
      default:
        break;
    }
  };

  const onNextStep = () => {
    switch (currentStep) {
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        setCurrentStep(3);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  /**
   * @param {1 | 2 | 3} currentStep The current step for call jsx of index.
   */
  const screenBuilding = currentStep => {
    switch (currentStep) {
      case 1:
        return (
          <SteeperScreen1
            onCancel={onPrevStep}
            onContinue={onContinueStep1}
            inspectionData={inspectionData}
          />
        );
      case 2:
        return (
          <SteeperScreen2
            onCancel={onPrevStep}
            onContinue={onNextStep}
            photos={photos}
            inspectionData={inspectionData}
            // Required that the step 1 fill this information for will be used
            // for the stepper 2 and stepper 3.
            inspectionFormInput={inspectionFormInput}
          />
        );
      case 3:
        return <SteeperScreen3 onCancel={onPrevStep} onContinue={onNextStep} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="adil-stepper-content-icons">
        <div className="adil-stepper-column">
          <div className={`adil-stepper-step-icon ${getClassForActiveStep(1)}`}>
            {STEPPER_LABEL_ONE}
          </div>
        </div>

        <div
          className={`adil-stepper-join-right ${getClassForJoinActive(2)}`}
        />

        <div className="adil-stepper-column">
          <div className={`adil-stepper-step-icon ${getClassForActiveStep(2)}`}>
            {STEPPER_LABEL_TWO}
          </div>
        </div>

        <div className={`adil-stepper-join-left ${getClassForJoinActive(3)}`} />

        <div className="adil-stepper-column">
          <div className={`adil-stepper-step-icon ${getClassForActiveStep(3)}`}>
            {STEPPER_LABEL_THREE}
          </div>
        </div>
      </div>
      <div className="adil-stepper-content-text">
        <div className="adil-stepper-column">
          <p>{STEPPER_VALIDATE_DATA}</p>
        </div>
        <div className="adil-stepper-column">
          <p>{STEPPER_VALIDATE_CRIMINAL_RECORD}</p>
        </div>
        <div className="adil-stepper-column">
          <p>{STEPPER_SEND_RESULTS}</p>
        </div>
      </div>
      {currentStep && screenBuilding(currentStep)}
    </>
  );
}

const photos = PropTypes.shape({
  url: PropTypes.string,
});

Stepper.propTypes = {
  inspectionData: PropTypes.shape({
    id: PropTypes.string,
    vehicle: PropTypes.shape({
      countryCarId: PropTypes.string,
      serialNumber: PropTypes.string,
      color: PropTypes.string,
      year: PropTypes.string,
      licencePlate: PropTypes.string,
      use: PropTypes.string,
      engineNumber: PropTypes.string,
      origin: PropTypes.string,
      bodyStyle: PropTypes.string,
      version: PropTypes.string,
      capacity: PropTypes.string,
      chassisNumber: PropTypes.string,
      fuelType: PropTypes.string,
      kilometers: PropTypes.string,
      model: PropTypes.string,
      vin: PropTypes.string,
      make: PropTypes.string,
      class: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  }).isRequired,
  photos: PropTypes.arrayOf(photos).isRequired,
};
