import React from 'react';
import PropTypes from 'prop-types';
import { IconLocation } from '@lmig/lmds-react/icons';
import './styles.scss';

const LocationAddress = ({ geolocation }) => (
  <div
    className="location-street-address-container"
    style={{ display: !geolocation?.streetAddress && 'none' }}
  >
    <div className="street-address">
      <IconLocation />
      <p>{geolocation?.streetAddress || geolocation?.status}</p>
    </div>
  </div>
);

LocationAddress.defaultProps = {
  geolocation: undefined,
};

LocationAddress.propTypes = {
  geolocation: PropTypes.shape({
    streetAddress: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default LocationAddress;
