/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import ArrowIcon from '../../Icons/ArrowIcon/ArrowIcon';
import './styles.scss';
import { ThumbnailPicture } from './ThumbnailPicture';

export const ThumbnailsGallery = ({
  pictures,
  arrows,
  onChangeIndex,
  currentIndex,
  className,
  vehicleClass,
  styles,
}) => {
  const scrollRef = useRef();
  const [scrollGallery, setScrollGallery] = useState({
    currentScroll: 0,
    endScroll: null,
    startScroll: 0,
  });

  const classNames = [
    'inspection-inform-gallery__thumbnail-container',
    className.container,
  ].join(' ');

  const onHandleLeftArrow = () => {
    const currentScroll = scrollRef.current.getElement().scrollLeft;
    if (scrollGallery.currentScroll === currentScroll) return;
    scrollRef.current.getElement().scrollTo(currentScroll - 150, 0);
    setScrollGallery(currentScroll - 150);
  };

  const onHandleRightArrow = () => {
    const currentScroll = scrollRef.current.getElement().scrollLeft;
    if (scrollGallery.currentScroll === currentScroll && currentScroll !== 0)
      return;

    scrollRef.current.getElement().scrollTo(currentScroll + 150, 0);
    setScrollGallery(currentScroll + 150);
  };

  const getLeftArrowComponent = () => (
    <div className="arrow-container-icon --left">
      <ArrowIcon
        circle
        color="#000"
        width={18}
        height={18}
        direction="left"
        onClick={() => onHandleLeftArrow()}
      />
    </div>
  );

  const getRightArrowComponent = () => (
    <div className="arrow-container-icon --right">
      <ArrowIcon
        circle
        color="#000"
        width={18}
        height={18}
        direction="right"
        onClick={() => onHandleRightArrow()}
      />
    </div>
  );

  const getKeyName = (name, index) => `${name}-${index}`;

  return (
    <>
      {arrows && getLeftArrowComponent()}
      <ScrollContainer
        ref={scrollRef}
        className={classNames}
        style={{ ...styles.container }}
      >
        {pictures?.map(({ url, type, name, label }, index) => (
          <ThumbnailPicture
            key={getKeyName(name, index)}
            url={url}
            name={name}
            label={label}
            vehicleClass={vehicleClass}
            photoIndex={index}
            currentIndex={currentIndex}
            onChangeIndex={onChangeIndex}
            className={className}
            styles={styles}
            type={type}
          />
        ))}
      </ScrollContainer>
      {arrows && getRightArrowComponent()}
    </>
  );
};

ThumbnailsGallery.defaultProps = {
  className: {
    container: undefined,
    picture: undefined,
  },
  styles: {
    container: undefined,
    picture: undefined,
  },
  arrows: false,
  onChangeIndex: () => {
    // Empty function
  },
  pictures: [],
};

ThumbnailsGallery.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  vehicleClass: PropTypes.string.isRequired,
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
  className: PropTypes.shape({
    container: PropTypes.string,
    picture: PropTypes.string,
  }),
  styles: PropTypes.shape({
    container: PropTypes.shape({}),
    picture: PropTypes.shape({}),
  }),
  arrows: PropTypes.bool,
  onChangeIndex: PropTypes.func,
};

export default ThumbnailsGallery;
