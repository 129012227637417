import * as React from 'react';
import PropTypes from 'prop-types';
import TableInspectionItemsFrontal from './TableInspectionItemsFrontal';
import localizedStrings from '../../utils/localizedStrings';

const {
  MODAL_PRIMARY_BUTTON_LABEL,
  INSPECTION_INFORM_PIECES_INSPECTION,
  INSPECTION_INFORM_CHARACTERS_LIMIT,
  INSPECTION_INFORM_START_DAMAGE_INSPECTIONS,
} = localizedStrings;

export default function PanelInspectionFrotal({ onValidSubmit }) {
  return (
    <div className="flex flex:1 flex:col gap:0.8rem">
      <h4 className="font-size:1.2rem text:center color:cyan font-weight:bold">
        {INSPECTION_INFORM_PIECES_INSPECTION}
      </h4>

      <TableInspectionItemsFrontal />

      <div className="flex flex:col">
        <div className="flex flex:row justify-content:space-between align-items:center my:0.7rem px:2rem">
          <span className="font-size:1.2rem">IVF</span>
          <div className="flex flex:row gap:1rem align-items:center">
            <label className="flex flex:row gap:0.2rem align-items:center">
              <input type="checkbox" className="h:1.2rem w:1.2rem r:50%" />
              Buena
            </label>

            <label className="flex flex:row gap:0.2rem align-items:center">
              <input type="checkbox" className="h:1.2rem w:1.2rem r:50%" />
              Regular
            </label>

            <label className="flex flex:row gap:0.2rem align-items:center">
              <input type="checkbox" className="h:1.2rem w:1.2rem r:50%" />
              Mala
            </label>
          </div>
        </div>

        <textarea
          cols={30}
          rows={3}
          className="border:1px|solid|#CCC color:gray py:0.3rem px:0.4rem r:0.2rem font:sans"
          onChange={() => {
            // Empty function
          }}
          value="En general se presenta daño propio de un choque"
        />

        <p className="color:#CCCCCC99 font-size:0.7rem px:1rem p:0 m:0 text-align:start">
          {INSPECTION_INFORM_CHARACTERS_LIMIT}
        </p>
      </div>

      <button
        type="button"
        className="flex justify-content:center align-items:center color:#343741 font-size:1.2rem font-weight:bold bg:#CCCCCC99 border:none py:1rem"
      >
        {INSPECTION_INFORM_START_DAMAGE_INSPECTIONS}
      </button>

      <button
        type="button"
        onClick={onValidSubmit}
        className="flex justify-content:center align-items:center color:#343741 font-size:1.2rem font-weight:bold bg:#99E5EA border:none py:1rem"
      >
        {MODAL_PRIMARY_BUTTON_LABEL}
      </button>
    </div>
  );
}

PanelInspectionFrotal.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
};
