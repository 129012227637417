import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CircleLoading = ({ className }) => (
  <div className={['sbl-circ', className].join(' ')} />
);

CircleLoading.defaultProps = {
  className: undefined,
};

CircleLoading.propTypes = {
  className: PropTypes.string,
};
export default CircleLoading;
