import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button, Heading, Table, TableCell, TableRow } from '@lmig/lmds-react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import InlineLoader from '../InlineLoader';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { newVehiclePieceAdapter } from '../../adapters/partAndPieceAdapter';
import { partsAndPiecesActions } from '../../redux/parts-and-pieces-admin';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const {
  ADMIN_PIECES_MANTEINER,
  ADMIN_SUPPORT_ADD_PIECE,
  ADMIN_SUPPORT_SELECT_PART,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION,
  ADMIN_SUPPORT_ADD,
  ADMIN_SUPPORT_MODIFY,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_2,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_3,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_4,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_5,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT,
  ADMIN_SUPPORT_TABLE_UPDATE,
  ADMIN_SUPPORT_TABLE_DELETE,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
  CREATE_INSPECTION_SELECT_VIDEO_LABEL,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
  ADMIN_SUPPORT_DELETE_YES,
  ADMIN_SUPPORT_DELETE_NO,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
  ADMIN_SUPPORT_TABLE_HEAD_1,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
  SEARCH_LOADING_LABEL,
} = localizedStrings;

const TableIndividualPartsMantainer = ({ onLoader }) => {
  const MySwal = withReactContent(Swal);

  const [idModify, setidModify] = useState(null);
  const [indexIdModify, setIndexIdModify] = useState(null);
  const [isformModifying, setIsformModifying] = useState(false);
  const dispatch = useDispatch();
  const { parts = [], pieces = [] } = useSelector(
    state => state.partsAndPiecesAdmin,
  );

  const [partsArray, setPartsArray] = useState(parts);
  const [piecesArray, setPiecesArray] = useState(pieces);

  useEffect(() => {
    setPartsArray(parts);
    return () => {
      setPartsArray([]);
    };
  }, [parts, pieces]);

  useEffect(() => {
    setPiecesArray(pieces);
    return () => {
      setPiecesArray([]);
    };
  }, [pieces, parts]);

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = data => {
    if (!isValid) return;

    const adapterPiece = newVehiclePieceAdapter(data);
    let objectToSend = {
      newVehiclePiece: adapterPiece,
    };

    if (!isformModifying) {
      dispatch(partsAndPiecesActions.createPartOrPiece(objectToSend))
        .then(() => {
          setPiecesArray(oldPieces => [
            ...oldPieces,
            {
              ...adapterPiece,
            },
          ]);
          reset();
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        });
    } else {
      objectToSend = {
        newVehiclePiece: {
          ...objectToSend.newVehiclePiece,
          id: idModify,
        },
      };
      dispatch(
        partsAndPiecesActions.updatePartOrPiece({
          countryCode: getCountryCode().toLowerCase(),
          newVehiclePiece: objectToSend.newVehiclePiece,
        }),
      )
        .then(() => {
          setPiecesArray(oldPieces =>
            oldPieces.map((element, idx) => {
              if (idx === indexIdModify) {
                return {
                  ...adapterPiece,
                  id: idModify,
                };
              }
              return element;
            }),
          );
          reset();
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        })
        .finally(() => {
          setidModify(null);
          setIndexIdModify(null);
          setIsformModifying(false);
        });
    }
  };

  const deletePieceByIndex = (idPiece, vehiclePartId, idx) => {
    MySwal.fire({
      title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
      showDenyButton: true,
      confirmButtonText: ADMIN_SUPPORT_DELETE_YES,
      denyButtonText: ADMIN_SUPPORT_DELETE_NO,
      allowOutsideClick: false,
      customClass: {
        title: 'sweetAdil__title',
        denyButton: 'sweetAdil__cancelButton',
        confirmButton: 'sweetAdil__acceptButton',
      },
    }).then(result => {
      if (result.isConfirmed) {
        const pieceArrayModified = piecesArray.filter(
          (_, index) => index !== idx,
        );
        dispatch(
          partsAndPiecesActions.deletePartOrPiece({
            vehiclePartId,
            vehiclePieceId: idPiece,
            countryCode: getCountryCode().toLocaleLowerCase(),
          }),
        )
          .then(() => {
            setPiecesArray(pieceArrayModified);
            MySwal.fire({
              title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          })
          .catch(() => {
            MySwal.fire({
              title: ADMIN_SUPPORT_TABLE_HEAD_1,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          });
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  };

  const getNamePartFromId = vehiclePartId => {
    if (vehiclePartId) {
      const { name } = partsArray.find(part => part.id === vehiclePartId);
      return name;
    }
    return '';
  };

  const setValueForm = (formValues, idxPiece) => {
    const { name, active, vehiclePartId, id } = formValues;
    setIsformModifying(true);
    setidModify(id);
    setIndexIdModify(idxPiece);
    setValue('piece', name, { shouldValidate: true });
    setValue('partOfPiece', vehiclePartId, { shouldValidate: true });
    setValue('activeOption', active.toString(), { shouldValidate: true });
  };

  return (
    <div className="supportBox">
      <h4>{ADMIN_PIECES_MANTEINER}</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="supportBox__form">
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">{ADMIN_SUPPORT_ADD_PIECE}</label>
          <input
            placeholder={ADMIN_SUPPORT_ADD_PIECE}
            {...register('piece', {
              required: true,
            })}
            className={`inputs_rhf_adil ${
              errors.piece ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">{ADMIN_SUPPORT_SELECT_PART}</label>
          <select
            name="partOfPiece"
            defaultValue="0"
            className={`inputs_rhf_adil ${
              errors.activeOption ? 'validation-input-field-error' : ''
            }`}
            {...register('partOfPiece')}
          >
            <option value="0">{CREATE_INSPECTION_SELECT_VIDEO_LABEL}</option>
            {partsArray.length > 0 &&
              partsArray
                .filter(part => part.active)
                .map(part => (
                  <option key={part.id} value={part.id}>
                    {part.name}
                  </option>
                ))}
          </select>
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">
            {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE}
          </label>
          <select
            className={`inputs_rhf_adil ${
              errors.activeOption ? 'validation-input-field-error' : ''
            }`}
            defaultValue="active"
            {...register('activeOption')}
          >
            <option value="true">
              {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION}
            </option>
            <option value="false">
              {ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION}
            </option>
          </select>
        </div>
        <div className="supportBox__buttons">
          <Button type="submit" className="supportBox__button">
            {isformModifying && ADMIN_SUPPORT_MODIFY}
            {!isformModifying && ADMIN_SUPPORT_ADD}
          </Button>
        </div>
        <div className="supportBox__errorMessages">
          {errors.part && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_PIECE}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
          {errors.piece && errors.piece.type === 'required' && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_SELECT_PART}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
        </div>
      </form>
      {!onLoader && (
        <>
          <div className="supportBox__table">
            <Table>
              <tbody>
                <TableRow className="supportBox__table__headers">
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_2}
                  </TableCell>
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_3}
                  </TableCell>
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_4}
                  </TableCell>
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_5}
                  </TableCell>
                </TableRow>
                {piecesArray.length > 0 &&
                  piecesArray.map((piece, index) => (
                    <TableRow
                      className={`supportBox__table__content ${
                        index % 2 === 0 ? 'adil-table-row-grey' : ''
                      }`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${piece.id}.${piece.vehiclePartId}`}
                    >
                      <td>{piece.name}</td>
                      <td>{getNamePartFromId(piece.vehiclePartId)}</td>
                      <td>
                        {piece.active
                          ? ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT
                          : ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT}
                      </td>
                      <td>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div
                            className="supportBox__chipButtons supportBox__chipButtons__update"
                            role="button"
                            onClick={() => setValueForm(piece, index)}
                          >
                            <span>{ADMIN_SUPPORT_TABLE_UPDATE}</span>
                          </div>
                          <div
                            className="supportBox__chipButtons supportBox__chipButtons__eliminate"
                            role="button"
                            data-testid="supportBox__chipButtons__eliminate"
                            onClick={() =>
                              deletePieceByIndex(
                                piece.id,
                                piece.vehiclePartId,
                                index,
                                // eslint-disable-next-line prettier/prettier
                              )}
                          >
                            <span>{ADMIN_SUPPORT_TABLE_DELETE}</span>
                          </div>
                        </div>
                      </td>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
      {onLoader && renderInlineLoader()}
    </div>
  );
};

TableIndividualPartsMantainer.propTypes = {
  onLoader: PropTypes.bool.isRequired,
};

export default TableIndividualPartsMantainer;
