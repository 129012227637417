import { CAMERA_TYPE } from '@lmig-latam/adil-api-common-lib/constants';
import localizedStrings from '../utils/localizedStrings';

export const getCameraTypes = () => {
  const {
    CREATE_INSPECTION_SELECT_CAMERA_TYPE_NATIVE,
    CREATE_INSPECTION_SELECT_CAMERA_TYPE_NEW,
  } = localizedStrings;

  return {
    NATIVE: {
      id: CAMERA_TYPE.NATIVE,
      value: CREATE_INSPECTION_SELECT_CAMERA_TYPE_NATIVE,
    },
    FRAME: {
      id: CAMERA_TYPE.FRAME,
      value: CREATE_INSPECTION_SELECT_CAMERA_TYPE_NEW,
    },
  };
};
