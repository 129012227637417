// SEARCH FILTER ACTIONS
export const SELECT_STEP_FILTER_SEARCH = 'SELECT_STEP_FILTER_SEARCH';
export const SET_COUNTS_INSPECTIONS = 'SET_COUNTS_INSPECTIONS';
export const ONCHANGE_CURRENT_LOCATION_PAGE = 'ONCHANGE_CURRENT_LOCATION_PAGE';
export const ONCHANGE_SEARCH_TEXT_INSPECTIONS =
  'ONCHANGE_SEARCH_TEXT_INSPECTIONS';
export const ONCHANGE_SEARCH_TEXT = 'ONCHANGE_SEARCH_TEXT';
export const MONTH_AND_YEAR_SELECTED = 'MONTH_AND_YEAR_SELECTED';
export const RESET_FILTER_STEPS = 'RESET_FILTER_STEPS';
export const SELECT_ACTIVE_INSPECTION = 'SELECT_ACTIVE_INSPECTION';
export const RESET_ALL = 'RESET_ALL';
export const ONLY_VENDOR_SELECTED = 'ONLY_VENDOR_SELECTED';
export const ON_DISPLAY_SUPPORT_REQUEST_DIALOG =
  'ON_DISPLAY_SUPPORT_REQUEST_DIALOG';
export const SET_FINISHED_INSPECTIONS_QUERY = 'SET_FINISHED_INSPECTIONS_QUERY';
