import { FIELD_NAMES } from '@lmig-latam/adil-api-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';

const {
  DRIVER_NAME_1,
  DRIVER_LASTNAME_1,
  DRIVER_LICENSE_1,
  DRIVER_NAME_2,
  DRIVER_LASTNAME_2,
  DRIVER_LICENSE_2,
  NAMED_DRIVER,
} = FIELD_NAMES;

const FIELD_CONFIG = getCountryConfig('th').getFieldConfig();

export const fieldConfig = {
  1: [
    FIELD_CONFIG[NAMED_DRIVER][DRIVER_NAME_1],
    FIELD_CONFIG[NAMED_DRIVER][DRIVER_LASTNAME_1],
    FIELD_CONFIG[NAMED_DRIVER][DRIVER_LICENSE_1],
  ],
  2: [
    FIELD_CONFIG[NAMED_DRIVER][DRIVER_NAME_2],
    FIELD_CONFIG[NAMED_DRIVER][DRIVER_LASTNAME_2],
    FIELD_CONFIG[NAMED_DRIVER][DRIVER_LICENSE_2],
  ],
};

Object.keys(fieldConfig).forEach(fieldSection => {
  fieldConfig[fieldSection] = fieldConfig[fieldSection].filter(
    field => typeof field !== 'undefined',
  );
});

export const config = fieldConfig;

export default config;
