import { FIELD_NAMES } from '@lmig-latam/adil-api-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import {
  getCountryCode,
  isProduction,
} from '../../config/environments/environmentsHelpers';

const {
  SUBMISSION_ID,
  AGENT_INFO,
  CREATOR_EMAIL,
  INSURANCE_NAME_INTERMEDIARY,
  BDM_MANAGER_NAME,

  // Customer fields
  CUSTOMER,
  FULL_NAME,
  FIRST_NAME,
  LAST_NAME,
  LOCATION,
  ID_TYPE,
  ID_NUMBER,
  CELL_PHONE_NUMBER,
  EMAIL,
  MIDDLE_NAME,
  SECOND_LAST_NAME,

  // Vehicle fields
  VEHICLE,
  LICENCE_PLATE,
  LICENCE_PLATE_TYPE,
  YEAR,
  MAKE,
  MODEL,
  COLOR,
  BODY_STYLE,
  USE,
  FASECOLDA_CODE,
  FASECOLDA_VALUE,
  COUNTRY_CAR_ID,
  ORIGIN,
  VERSION,
  CHASSIS_NUMBER,
  SERIAL_NUMBER,
  FUEL_TYPE,
  TRANSMISSION_TYPE,
  PAINT_TYPE,
  VIN,
  ENGINE_NUMBER,
  CAPACITY,
  CLASS,
  KILOMETERS,
  VALUE,
  POLICY_TYPE,

  // Named driver fields
  NAMED_DRIVER,
  DRIVER_NAME_1,
  DRIVER_LASTNAME_1,
  DRIVER_LICENSE_1,
  DRIVER_NAME_2,
  DRIVER_LASTNAME_2,
  DRIVER_LICENSE_2,

  // Notes field
  NOTES_FIELD,

  // Broker fields
  BROKER,
  BROKER_CODE,
  BROKER_NAME,
  BROKER_EMAIL,
} = FIELD_NAMES;

const FIELD_CONFIG = getCountryConfig(getCountryCode()).getFieldConfig();
const fieldConfig = {
  [SUBMISSION_ID]: FIELD_CONFIG[SUBMISSION_ID],
  [CREATOR_EMAIL]: [FIELD_CONFIG[CREATOR_EMAIL]],
  ...(getCountryCode() === 'vn' && {
    [AGENT_INFO]: [
      FIELD_CONFIG[AGENT_INFO][CREATOR_EMAIL],
      FIELD_CONFIG[AGENT_INFO][INSURANCE_NAME_INTERMEDIARY],
      FIELD_CONFIG[AGENT_INFO][BDM_MANAGER_NAME],
    ],
  }),

  [CUSTOMER]: [
    FIELD_CONFIG[CUSTOMER][FULL_NAME],
    FIELD_CONFIG[CUSTOMER][FIRST_NAME],
    FIELD_CONFIG[CUSTOMER][LAST_NAME],
    FIELD_CONFIG[CUSTOMER][LOCATION],
    FIELD_CONFIG[CUSTOMER][ID_TYPE],
    FIELD_CONFIG[CUSTOMER][ID_NUMBER],
    FIELD_CONFIG[CUSTOMER][CELL_PHONE_NUMBER],
    FIELD_CONFIG[CUSTOMER][EMAIL],
    FIELD_CONFIG[CUSTOMER][MIDDLE_NAME],
    FIELD_CONFIG[CUSTOMER][SECOND_LAST_NAME],
  ],

  [VEHICLE]: [
    FIELD_CONFIG[VEHICLE][LICENCE_PLATE],
    FIELD_CONFIG[VEHICLE][LICENCE_PLATE_TYPE],
    FIELD_CONFIG[VEHICLE][YEAR],
    FIELD_CONFIG[VEHICLE][MAKE],
    FIELD_CONFIG[VEHICLE][MODEL],
    FIELD_CONFIG[VEHICLE][COLOR],
    FIELD_CONFIG[VEHICLE][BODY_STYLE],
    FIELD_CONFIG[VEHICLE][USE],
    FIELD_CONFIG[VEHICLE][FASECOLDA_CODE],
    FIELD_CONFIG[VEHICLE][FASECOLDA_VALUE],
    FIELD_CONFIG[VEHICLE][COUNTRY_CAR_ID],
    FIELD_CONFIG[VEHICLE][ORIGIN],
    FIELD_CONFIG[VEHICLE][VERSION],
    FIELD_CONFIG[VEHICLE][CHASSIS_NUMBER],
    FIELD_CONFIG[VEHICLE][SERIAL_NUMBER],
    FIELD_CONFIG[VEHICLE][FUEL_TYPE],
    FIELD_CONFIG[VEHICLE][TRANSMISSION_TYPE],
    FIELD_CONFIG[VEHICLE][PAINT_TYPE],
    FIELD_CONFIG[VEHICLE][VIN],
    FIELD_CONFIG[VEHICLE][ENGINE_NUMBER],
    FIELD_CONFIG[VEHICLE][CAPACITY],
    FIELD_CONFIG[VEHICLE][CLASS],
    FIELD_CONFIG[VEHICLE][KILOMETERS],
    FIELD_CONFIG[VEHICLE][VALUE],
    FIELD_CONFIG[VEHICLE][POLICY_TYPE],
  ],

  [BROKER]: [
    FIELD_CONFIG[BROKER][BROKER_CODE],
    FIELD_CONFIG[BROKER][BROKER_NAME],
    ...(getCountryCode() === 'ec' ? [FIELD_CONFIG[BROKER][BROKER_EMAIL]] : []),
  ],

  ...(getCountryCode() === 'th' && {
    [NAMED_DRIVER]: [
      FIELD_CONFIG[NAMED_DRIVER][DRIVER_NAME_1],
      FIELD_CONFIG[NAMED_DRIVER][DRIVER_LASTNAME_1],
      FIELD_CONFIG[NAMED_DRIVER][DRIVER_LICENSE_1],
      FIELD_CONFIG[NAMED_DRIVER][DRIVER_NAME_2],
      FIELD_CONFIG[NAMED_DRIVER][DRIVER_LASTNAME_2],
      FIELD_CONFIG[NAMED_DRIVER][DRIVER_LICENSE_2],
    ],
  }),

  ...(getCountryCode() === 'vn' && {
    [NOTES_FIELD]: [FIELD_CONFIG[NOTES_FIELD]],
  }),
};

const deleteVietnamFields = [
  LICENCE_PLATE_TYPE.toLocaleLowerCase(),
  BODY_STYLE.toLocaleLowerCase(),
  FASECOLDA_VALUE.toLocaleLowerCase(),
  COUNTRY_CAR_ID.toLocaleLowerCase(),
  VIN.toLocaleLowerCase(),
  SERIAL_NUMBER.toLocaleLowerCase(),
];

Object.keys(fieldConfig).forEach(fieldSection => {
  if (fieldSection !== 'submissionId') {
    fieldConfig[fieldSection] = fieldConfig[fieldSection].filter(
      field => typeof field !== 'undefined',
    );
  }
});

if (getCountryCode() === 'vn') {
  let options = FIELD_CONFIG[AGENT_INFO][BDM_MANAGER_NAME].options.dev;
  if (isProduction())
    options = FIELD_CONFIG[AGENT_INFO][BDM_MANAGER_NAME].options.prod;

  fieldConfig[AGENT_INFO].forEach(field => {
    if (field.fieldName === BDM_MANAGER_NAME) {
      // eslint-disable-next-line no-param-reassign
      field.options = options;
    }
  });
  fieldConfig[VEHICLE] = fieldConfig[VEHICLE].filter(
    field =>
      !deleteVietnamFields.includes(field?.fieldName.toLocaleLowerCase()),
  );
}

export const config = fieldConfig;

export default config;
