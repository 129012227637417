/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import './styles.scss';

export const Thumbnails = ({ pictures, onChangeIndex, currentIndex }) => {
  const isActive = index => {
    if (currentIndex === index) return 'thumbnail--active';
    return '';
  };

  return (
    <div className="image-gallery__thumbnail-container">
      <ScrollContainer className="scroll-container">
        {pictures?.map(({ url }, index) => (
          <div key={url} className={`thumbnail ${isActive(index)}`}>
            <img src={url} alt="" onClick={() => onChangeIndex(index)} />
          </div>
        ))}
      </ScrollContainer>
    </div>
  );
};

Thumbnails.defaultProps = {
  pictures: [],
  onChangeIndex: () => {
    // Empty function
  },
};

Thumbnails.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
  onChangeIndex: PropTypes.func,
};

export default Thumbnails;
