import {
  BodyText,
  Caption,
  Disclaimer,
  Heading,
  IconDownload,
  IconUpload,
} from '@lmig/lmds-react';
import { IconClose } from '@lmig/lmds-react/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import { fileUtils } from '@lmig-latam/adlib-ui';
import { InspectionActions } from '../../actions';
import { Panel, CircleImage } from '..';
import { documentPropType, newDocumentPropType } from '../../prop-types';
import localizedStrings from '../../utils/localizedStrings';
import Arrow from './Arrow.svg';
import './styles.scss';
import { downloadFileFromUrl } from '../../utils';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import Collapsable from '../Collapsable';

const {
  INSPECTION_SUPPORTING_DOCUMENTS_TITLE,
  INSPECTION_SUPPORTING_DOCUMENTS_SUBTITLE,
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER,
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK,
  INSPECTION_VEHICLE_DOCUMENTS_DOWNLOAD,
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
  INSPECTION_PHOTO_UPLOAD_DOCUMENTS,
  INSPECTION_PHOTO_UPLOADING_DOCUMENTS,
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP,
} = localizedStrings;

const MySwal = withReactContent(Swal);

// Accepted File Types
// Note: This should be kept in sync with the "accept" prop of the file input
// further down in this component
const validFileTypes = /\.(pdf|jpg|jpeg|png|bmp|doc|docx)$/i;

const SupportingDocuments = ({
  addNewDocuments,
  inspectionId,
  deleteDocument,
  deleteNewDocument,
  newDocuments,
  documents,
  readOnly,
  downloadZipButton,
}) => {
  const inputRef = useRef();
  const [highlighted, setHighlighted] = useState(false);
  const [downloadWaiting, setDownloadWaiting] = useState(false);
  const [uploadingDocument, setuploadingDocument] = useState(false);
  const [idInspecion] = useState(inspectionId);

  const dispatch = useDispatch();

  const inputRefDocuments = useRef(null);

  const preventDefaultFieldBehavior = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isValidFileType = fileName => fileName.match(validFileTypes);

  const addNewDocumentsHandler = async newFiles => {
    const newDocumentsToAdd = await Promise.all(
      Array.from(newFiles)
        .filter(newFile => isValidFileType(newFile.name))
        .map(
          newFile =>
            new Promise(resolve => {
              const reader = new FileReader();
              reader.readAsDataURL(newFile);
              reader.onload = event => {
                resolve({
                  name: newFile.name,
                  data: event.target.result,
                });
              };
            }),
        ),
    );
    addNewDocuments(newDocumentsToAdd);
    setHighlighted(false);
  };

  const onChangeFilePickerHandler = event => {
    addNewDocumentsHandler(event.target.files);
  };

  const handleDocumentDragDropHandler = event => {
    preventDefaultFieldBehavior(event);
    addNewDocumentsHandler(event.dataTransfer.files);
  };

  const handleDocumentDragEnterHandler = event => {
    preventDefaultFieldBehavior(event);
    setHighlighted(true);
  };

  const handleDocumentDragOverHandler = event => {
    preventDefaultFieldBehavior(event);
    setHighlighted(true);
  };

  const handleDocumentDragLeaveHandler = event => {
    preventDefaultFieldBehavior(event);
    setHighlighted(false);
  };

  const deleteDocumentHandler = document => {
    deleteDocument(document);
  };

  const deleteNewDocumentHandler = document => {
    deleteNewDocument(document);
    inputRef.current.value = '';
  };

  const handleUploadZipDocument = async event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    if (
      fileObj &&
      !event.target.files[0].type
        .split('/')
        .pop()
        .includes('zip')
    ) {
      MySwal.fire({
        icon: 'error',
        title: INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP,
        customClass: {
          title: 'sweetAdil__title',
          container: 'sweetAdil__container',
          confirmButton: 'sweetAdil__acceptButton',
        },
      });
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
      return;
    }

    setuploadingDocument(true);

    let documentZipData = {};
    documentZipData = {
      name: fileObj.name,
      data: await fileUtils.getBase64StringFromBlobFile(fileObj),
    };

    dispatch(
      InspectionActions.updateInspectionDocumentsZip(
        idInspecion,
        documentZipData,
      ),
    )
      .then(() => {
        setuploadingDocument(false);
        window.location.reload();
      })
      .catch(() => {
        MySwal.fire({
          icon: 'error',
          title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
          text: ADMIN_SUPPORT_ERROR_GENERAL,
          customClass: {
            title: 'sweetAdil__title',
            container: 'sweetAdil__container',
            confirmButton: 'sweetAdil__acceptButton',
          },
        });
        setuploadingDocument(false);
      });
  };

  const downloadDocumentsComponent = () => {
    setDownloadWaiting(true);
    dispatch(InspectionActions.downloadDocuments(inspectionId))
      .then(resolve => {
        const { documentsZipUrl } = resolve;
        downloadFileFromUrl(documentsZipUrl, `Documents_${inspectionId}.zip`);
        setDownloadWaiting(false);
      })
      .catch(() => {
        MySwal.fire({
          icon: 'error',
          title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
          text: ADMIN_SUPPORT_ERROR_GENERAL,
          customClass: {
            title: 'sweetAdil__title',
            container: 'sweetAdil__container',
            confirmButton: 'sweetAdil__acceptButton',
          },
        });
        setDownloadWaiting(false);
      });
  };

  const documentsToShow = () => (
    <>
      <div className="supporting-documents-files">
        {documents.map(document => (
          <div className="supporting-documents-file" key={document.name}>
            {downloadZipButton ? (
              <a href={document.url}>
                <Caption>{document.name}</Caption>
              </a>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a>
                <Caption>{document.name}</Caption>
              </a>
            )}
            {!readOnly && (
              <span
                role="button"
                onClick={() => {
                  deleteDocumentHandler(document);
                }}
              >
                <IconClose color="inverse" size="16" />
              </span>
            )}
          </div>
        ))}
        {newDocuments.map(document => (
          <div className="supporting-documents-file" key={document.name}>
            <a href={document.data} download={document.name}>
              <Caption>{document.name}</Caption>
            </a>
            <span
              role="button"
              onClick={() => {
                deleteNewDocumentHandler(document);
              }}
            >
              <IconClose color="inverse" size="16" />
            </span>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <Panel className="supporting-documents">
      <div
        style={{ display: 'flex', flexDirection: 'row-reverse', gap: '15px' }}
      >
        {getCountryCode() === 'vn' &&
          downloadZipButton &&
          documents?.length !== 0 && (
            <div
              onClick={downloadWaiting ? null : downloadDocumentsComponent}
              className={classNames('download-all-link', {
                'adilLink-disabled': downloadWaiting,
              })}
              role="button"
            >
              <BodyText>
                {downloadWaiting
                  ? INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE
                  : INSPECTION_VEHICLE_DOCUMENTS_DOWNLOAD}
              </BodyText>
              <CircleImage size="small">
                <IconDownload size="16" />
              </CircleImage>
            </div>
          )}
        {!readOnly && getCountryCode() === 'vn' && (
          <>
            <div
              onClick={
                uploadingDocument
                  ? null
                  : () => inputRefDocuments.current.click()
              }
              className={classNames('download-all-link', {
                'adilLink-disabled': uploadingDocument,
              })}
              role="button"
            >
              <BodyText>
                {uploadingDocument
                  ? INSPECTION_PHOTO_UPLOADING_DOCUMENTS
                  : INSPECTION_PHOTO_UPLOAD_DOCUMENTS}
              </BodyText>
              <CircleImage size="small">
                <IconUpload size="16" />
              </CircleImage>
            </div>
            <input
              style={{ display: 'none' }}
              ref={inputRefDocuments}
              type="file"
              accept=".zip"
              onChange={handleUploadZipDocument}
            />
          </>
        )}
      </div>

      <Heading type="h3-light" className="auto-left-right-margin">
        {INSPECTION_SUPPORTING_DOCUMENTS_TITLE}
      </Heading>

      {!readOnly && (
        <Disclaimer className="supporting-documents-subtitle">
          {INSPECTION_SUPPORTING_DOCUMENTS_SUBTITLE}
        </Disclaimer>
      )}
      {!readOnly && (
        <div
          className={classNames(
            'supporting-documents-upload-box',
            'auto-left-right-margin',
            { 'supporting-documents-upload-box-highlighted': highlighted },
          )}
          onDrop={handleDocumentDragDropHandler}
          onDragEnter={handleDocumentDragEnterHandler}
          onDragOver={handleDocumentDragOverHandler}
          onDragLeave={handleDocumentDragLeaveHandler}
        >
          <span>
            <img
              src={Arrow}
              alt={
                INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER +
                INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK
              }
            />
            {INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER}
            <input
              type="file"
              id="fileElem"
              multiple
              accept=".pdf,.jpg,.jpeg,.png,.bmp,.doc,.docx"
              className="supporting-documents-upload-box-file-picker"
              onChange={onChangeFilePickerHandler}
              ref={inputRef}
            />
            <label className="button" htmlFor="fileElem">
              <span className="supporting-documents-upload-box-browse-link">
                {INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK}
              </span>
            </label>
          </span>
        </div>
      )}
      {getCountryCode() !== 'vn' && documentsToShow()}
      {getCountryCode() === 'vn' &&
        (documents.length !== 0 || newDocuments.length !== 0) && (
          <Collapsable
            idCollapsable="documentsId"
            forceOpen={newDocuments.length > 0}
          >
            {documentsToShow()}
          </Collapsable>
        )}
    </Panel>
  );
};

SupportingDocuments.propTypes = {
  documents: PropTypes.arrayOf(documentPropType).isRequired,
  inspectionId: PropTypes.string.isRequired,
  newDocuments: PropTypes.arrayOf(newDocumentPropType).isRequired,
  deleteDocument: PropTypes.func.isRequired,
  deleteNewDocument: PropTypes.func.isRequired,
  addNewDocuments: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  downloadZipButton: PropTypes.bool.isRequired,
};

SupportingDocuments.defaultProps = {
  readOnly: false,
};
export default SupportingDocuments;
