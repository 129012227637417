import { Content, Heading } from '@lmig/lmds-react';
import React from 'react';
import { AppFooter, AppHeader, SideBar } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const { PAGE_NOT_FOUND_ERROR_MESSAGE } = localizedStrings;

const renderFooterSection = () =>
  getCountryCode() === 'co' ? <AppFooter /> : null;

const PageNotFound = () => (
  <div className="adil-screen">
    <AppHeader />
    <SideBar>
      <Content className="adil-content page-not-found-content">
        <Heading type="h3-light" className="auto-left-right-margin">
          {PAGE_NOT_FOUND_ERROR_MESSAGE}
        </Heading>
        {renderFooterSection()}
      </Content>
    </SideBar>
  </div>
);

export default PageNotFound;
