import {
  GET_PHOTOS_FROM_UPLOAD_PHOTOS,
  GET_INSPECTION_DATA,
  UPDATE_STATUS_INSPECTION_AFTER_PHOTOS,
  SET_RETAKE_PHOTO_HISTORY,
  ON_DISPLAY_PHOTO_HISTORY_MODAL,
  RESET_PHOTO_HISTORY,
  SET_CURRENT_PHOTO_PREVIEW_MODAL,
} from './constants';

const changeGetPhotosVariable = getPhotos => ({
  type: GET_PHOTOS_FROM_UPLOAD_PHOTOS,
  payload: getPhotos,
});

const changeStatusInspectionAfterPhotos = statusUpdated => ({
  type: UPDATE_STATUS_INSPECTION_AFTER_PHOTOS,
  payload: statusUpdated,
});

const updateInspectionDataRedux = dataInspection => ({
  type: GET_INSPECTION_DATA,
  payload: {
    idInspection: dataInspection.id,
    arrayRequiredPhotos: dataInspection.arrayRequiredPhotos,
    arrayPhotosAtMoment: dataInspection.arrayPhotosAtMoment,
    currentStatus: dataInspection.currentStatus,
  },
});

const setRetakePhotoHistory = photos => ({
  type: SET_RETAKE_PHOTO_HISTORY,
  payload: { photos },
});

const setResetPhotoHistory = () => ({
  type: RESET_PHOTO_HISTORY,
});

const onDisplayPhotoHistoryModal = (isOpen, photoId, vehicleClass) => ({
  type: ON_DISPLAY_PHOTO_HISTORY_MODAL,
  payload: { isOpen, photoId, vehicleClass },
});

const setCurrentPhotoPreviewModal = photoPreview => ({
  type: SET_CURRENT_PHOTO_PREVIEW_MODAL,
  payload: { photoPreview },
});

export default {
  changeGetPhotosVariable,
  updateInspectionDataRedux,
  changeStatusInspectionAfterPhotos,
  setRetakePhotoHistory,
  onDisplayPhotoHistoryModal,
  setResetPhotoHistory,
  setCurrentPhotoPreviewModal,
};
