export const vehicleDataInformAdapter = vehicleOriginalData => ({
  vehicleModel: vehicleOriginalData?.model.toLowerCase(),
  vehicleType: vehicleOriginalData?.class,
  vehicleBrand: vehicleOriginalData?.make.toLowerCase(),
  useOrLiscensePlate: vehicleOriginalData?.use,
  vehicleKm: vehicleOriginalData?.kilometers,
  vehicleColor: vehicleOriginalData?.color.toLowerCase(),
  fuel: vehicleOriginalData?.fuelType,
  yearModel: vehicleOriginalData?.year,
});
