import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ArrowIcon from '../Icons/ArrowIcon/ArrowIcon';
import localizedStrings from '../../utils/localizedStrings';
import { MainLoaderContainer } from '../MainLoader/MainLoaderContainer';
import { MainLoader } from '../MainLoader/MainLoader';
import './styles.scss';

const { INSPECTION_INFORM_CLOSE_FULLSCREEN_BUTTON } = localizedStrings;

export const ImageGallery = ({
  pictures,
  brigthnessValue,
  zoomValue,
  onChangeIndex,
  currentIndex,
  handleFullscreen,
  arrows,
}) => {
  const previewPicture = useMemo(() => pictures[currentIndex], [
    pictures,
    currentIndex,
  ]);

  const {
    inspectionInform: { loadingGallery },
  } = useSelector(store => store);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const containerZoomRef = useRef(null);

  useEffect(() => {
    if (zoomValue === 1) {
      setPosition({ x: 0, y: 0 });
    }
  }, [zoomValue]);

  useEffect(() => {
    const image = imageRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseUp = () => {
      isDragging = false;
    };

    const handleMouseDown = e => {
      if (!containerZoomRef.current.parentNode.matches(':hover'))
        handleMouseUp();
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = e => {
      if (!containerZoomRef.current.parentNode.matches(':hover'))
        handleMouseUp();
      if (!isDragging) return;
      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };
      setPosition(positionValue => ({
        x: positionValue.x + deltaX,
        y: positionValue.y + deltaY,
      }));
    };

    image?.addEventListener('mousedown', handleMouseDown);
    image?.addEventListener('mousemove', handleMouseMove);
    image?.addEventListener('mouseup', handleMouseUp);

    if (zoomValue === 1) {
      image?.removeEventListener('mousedown', handleMouseDown);
      image?.removeEventListener('mousemove', handleMouseMove);
      image?.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      image?.removeEventListener('mousedown', handleMouseDown);
      image?.removeEventListener('mousemove', handleMouseMove);
      image?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [imageRef, zoomValue]);

  function onNextPicture() {
    let index;
    onChangeIndex(prev => {
      if (currentIndex < pictures.length - 1) {
        index = prev + 1;
        return index;
      }
      return 0;
    });
  }

  function onBackPicture() {
    let index;
    onChangeIndex(prev => {
      if (currentIndex === 0) {
        index = prev + pictures.length - 1;
        return index;
      }
      index = prev - 1;
      return index;
    });
  }

  const getClassContainer = () => {
    let className = 'image-gallery-container';
    if (handleFullscreen?.active)
      className += ` image-gallery-container__fullscreen`;
    if (brigthnessValue)
      className += ` viewerTridi-Brigthness${brigthnessValue}`;
    return className;
  };

  return (
    <div className={getClassContainer()}>
      {loadingGallery && (
        <MainLoaderContainer>
          <MainLoader />
        </MainLoaderContainer>
      )}

      <div ref={containerZoomRef} className="image-gallery-container__portrait">
        <img
          ref={imageRef}
          role="presentation"
          style={{
            position: 'absolute',
            transform: zoomValue > 1 ? `scale(${zoomValue})` : null,
            cursor: zoomValue > 1 ? 'move' : 'default',
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
          alt={previewPicture?.name}
          src={previewPicture?.url}
          draggable={false}
        />
      </div>

      {handleFullscreen?.active && (
        <Button level="secondary" onClick={() => handleFullscreen?.exit()}>
          {INSPECTION_INFORM_CLOSE_FULLSCREEN_BUTTON}
        </Button>
      )}

      <div
        className="image-gallery-arrows"
        style={{ display: arrows ? undefined : 'none' }}
      >
        <ArrowIcon direction="left" onClick={() => onBackPicture()} />
        <ArrowIcon direction="right" onClick={() => onNextPicture()} />
      </div>
    </div>
  );
};

ImageGallery.propTypes = {
  pictures: PropTypes.arrayOf(
    PropTypes.shape({ url: PropTypes.string.isRequired }),
  ),
  onChangeIndex: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  brigthnessValue: PropTypes.number,
  zoomValue: PropTypes.number,
  handleFullscreen: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    exit: PropTypes.func.isRequired,
  }),
  arrows: PropTypes.bool,
};

ImageGallery.defaultProps = {
  pictures: [],
  arrows: true,
  handleFullscreen: null,
  brigthnessValue: null,
  zoomValue: 1,
};
