import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tridi from 'react-tridi';
import { Button } from '@lmig/lmds-react';
import { photoPropType } from '../../../prop-types';
import brigthnessIcon from '../../../assets/images/brigthness-icon.png';
import { RotateIcon } from '../../Icons/RotateIcon';
import localizedStrings from '../../../utils/localizedStrings';
import './styles.scss';

const ViewerVideo = ({ photos, hintText, rotation, onSaveRotation }) => {
  const tridiRef = useRef(null);
  const brigthnessRef = useRef();
  const [imagesList, setImageList] = useState([]);
  const [brigthnessOpen, setBrigthnessOpen] = useState(false);
  const [flagOpen, setfFlagOpen] = useState(false);
  const [valueBrigthness, setvalueBrigthness] = useState(0);
  const [pins, setPins] = useState([]);
  const [currentRotationDB, setCurrentRotationDB] = useState(null);
  const [currentRotation, setCurrentRotation] = useState(null);

  const { INSPECTION_VEHICLE_VIDEO_BUTTON_SAVE_CHANGES } = localizedStrings;

  useEffect(() => {
    const array = photos.map(item => item.url);
    setImageList(array);
    setCurrentRotationDB(Math.round(rotation / 90) * 90);
    setCurrentRotation(Math.round(rotation / 90) * 90);
  }, [photos, rotation]);

  const handleShowBrightnessBar = () => {
    setBrigthnessOpen(!brigthnessOpen);
    setfFlagOpen(true);
  };

  const onSaveRotationVideo = () => {
    onSaveRotation(currentRotation);
    setCurrentRotationDB(currentRotation);
  };

  const changeValueBrigthness = () => {
    setvalueBrigthness(Math.floor(brigthnessRef.current.value));
  };

  const onRotateImage = () => {
    const newRotationImage = (currentRotation + 90) % 360;
    setCurrentRotation(Math.round(newRotationImage / 90) * 90);
  };

  const getClassNameRotation = deegre => {
    const classNames = {
      90: 'viewer-video__rotate-90',
      180: 'viewer-video__rotate-180',
      270: 'viewer-video__rotate-270',
    };
    return classNames[deegre] || '';
  };

  return (
    <div className="viewer-video">
      {imagesList.length > 0 && (
        <>
          <Tridi
            ref={tridiRef}
            images={imagesList}
            autoplaySpeed={200}
            showControlBar
            mousewheel
            pins={pins}
            setPins={setPins}
            hintOnStartup
            className={`viewerTridi-Brigthness${valueBrigthness} ${getClassNameRotation(
              currentRotation,
            )}`}
            hintText={hintText}
          />
          {currentRotationDB !== currentRotation && (
            <div className="viewer-video__save-button-container">
              <Button
                onClick={() => onSaveRotationVideo()}
                className="viewer-video__save-button"
              >
                {INSPECTION_VEHICLE_VIDEO_BUTTON_SAVE_CHANGES}
              </Button>
            </div>
          )}

          <RotateIcon
            className="viewer-video__rotation-icon"
            onClick={() => onRotateImage()}
          />

          <div role="button" onClick={handleShowBrightnessBar}>
            <img
              src={brigthnessIcon}
              alt="brigthnessIcon"
              className="viewer__bridgthness"
            />
          </div>

          {flagOpen && (
            <input
              type="range"
              ref={brigthnessRef}
              min="100"
              max="200"
              step="1"
              className={
                brigthnessOpen
                  ? 'viewer__bridgthnessSlider'
                  : 'viewer__bridgthnessSliderOff'
              }
              value={valueBrigthness}
              onChange={changeValueBrigthness}
            />
          )}
        </>
      )}
    </div>
  );
};

ViewerVideo.defaultProps = {
  rotation: 0,
};

ViewerVideo.propTypes = {
  photos: PropTypes.arrayOf(photoPropType).isRequired,
  hintText: PropTypes.string.isRequired,
  rotation: PropTypes.number,
  onSaveRotation: PropTypes.func.isRequired,
};

export default ViewerVideo;
