import {
  GET_ALL_PARTS,
  ADD_PARTS_TO_SELECT,
  DELETE_PIECES_BY_PART,
  UPDATE_PARTS_BY_STATUS,
} from './constants';

export const initialState = {
  parts: [],
  pieces: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARTS:
      return {
        ...state,
        parts: action.payload.parts,
        pieces: action.payload.pieces,
      };

    case ADD_PARTS_TO_SELECT:
      return {
        ...state,
        parts: [...state.parts, action.payload],
      };

    case DELETE_PIECES_BY_PART:
      return {
        ...state,
        pieces: action.payload,
      };

    case UPDATE_PARTS_BY_STATUS:
      return {
        ...state,
        parts: action.payload,
      };

    default:
      return state;
  }
};
