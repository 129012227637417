import PropTypes from 'prop-types';
import { saveSidebarState } from '../utils';
import { store } from '../common/store';
import {
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  TOGGLE_SIDEBAR,
  UPDATE_LOGGED_IN,
} from './ActionTypes';

let showAppLoaderTimeout;

const PropertyTypes = PropTypes.shape({
  showAppLoader: PropTypes.func.isRequired,
  hideAppLoader: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateLoggedIn: PropTypes.func.isRequired,
});

const hideAppLoader = () => {
  clearTimeout(showAppLoaderTimeout);
  return {
    type: HIDE_APP_LOADER,
  };
};

const showAppLoader = (translucent, message) => {
  showAppLoaderTimeout = setTimeout(() => {
    store.dispatch(hideAppLoader());
  }, 30000);

  return {
    type: SHOW_APP_LOADER,
    translucent,
    message,
  };
};

const toggleSidebar = () => {
  saveSidebarState();
  return {
    type: TOGGLE_SIDEBAR,
  };
};

const updateLoggedIn = loggedIn => ({
  type: UPDATE_LOGGED_IN,
  loggedIn,
});

export default {
  PropertyTypes,
  showAppLoader,
  hideAppLoader,
  toggleSidebar,
  updateLoggedIn,
};
