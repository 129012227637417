import constants from '@lmig-latam/adil-api-common-lib/constants';

const {
  LANGUAGES: { VN, TH, CO, CL, EC },
} = constants;

export const photoStatuses = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const identificationSystemStatuses = {
  APPROVED: { label: 'APPROVED', class: 'greenApproved' },
  DISAPPROVED: { label: 'DISAPPROVED', class: 'redDisapproved' },
};

export const OverlayEnablerStatuses = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

export const notificationStatus = {
  CONFIRMATION: 'CONFIRMATION',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const SIDEBAR_COOKIE_NAME = 'sidebar';
export const LOGIN_FAIL_COOKIE_NAME = 'login_failure_count';
export const SIDEBAR_COOKIE_EXPIRY_HOURS = 8760; // 1 year in hours

export const fieldValidation = {
  numericOnly: RegExp(/^[0-9]*$/i),
  alphaNumericOnly: RegExp(
    /^[\u0E00-\u0E7Fa-zA-ZÀ-ÿ0-9àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\-_,'.:$%&()/\\ ]+$/i,
  ),
  currency: RegExp(/^[a-zA-ZÀ-ÿ0-9-,.$]+$/i),
  decimal: RegExp(/^[0-9.]*$/i),
};

export const isValidCameraModule = [CL, CO, EC];
export const isValidVideoModule = [CL, CO, EC];
export const enableRejectButton = [VN, TH];
export const supportRequestEnabled = [VN, CO, CL, EC];
export const isValidInLatam = [CO, CL, EC];

export const fieldsToFilter = ['assignee', 'createdBy', 'submissionId', 'id'];

export const subItemRetakeDates = ['CUSTOMER_RETAKE'];

export const subItemSupportDates = [
  'SUPPORT_NEW',
  'SUPPORT_IN_PROGRESS',
  'SUPPORT_RETAKE',
  'SUPPORT_INSPECTOR',
];

export const customerFieldsToFilter = [
  'firstName',
  'lastName',
  'secondName',
  'middleName',
  'idNumber',
];

export const changePasswordUrl = {
  DEV: {
    latam: 'https://dev-eservices.lmig.com/EDA/enduser/ChangePassword.do',
    asia: 'https://requestit.lmig.com/web/viewForm.do?form=3044',
  },
  NONPROD: {
    latam: 'https://test-eservices.lmig.com/EDA/enduser/ChangePassword.do',
    asia: 'https://requestit.lmig.com/web/viewForm.do?form=3044',
  },
  PROD: {
    latam: 'https://eservices.lmig.com/EDA/enduser/ChangePassword.do',
    asia: 'https://eservices.lmig.com/EDA/enduser/ChangePassword.do',
  },
};

export const manageAccountUrl = {
  DEV: {
    latam: 'https://dev-eservices.lmig.com/EDA/enduser/Start.do',
    asia: 'https://requestit.lmig.com/web/viewForm.do?form=3013',
  },
  NONPROD: {
    latam: 'https://test-eservices.lmig.com/EDA/enduser/Start.do',
    asia: 'https://requestit.lmig.com/web/viewForm.do?form=3013',
  },
  PROD: {
    latam: 'https://eservices.lmig.com/EDA/enduser/Start.do',
    asia: 'https://requestit.lmig.com/web/viewForm.do?form=3013',
  },
};
