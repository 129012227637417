import React from 'react';
import PropTypes from 'prop-types';

function Image({ src, alt, ...props }) {
  return <img {...props} src={src} key={src} alt={alt} />;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
