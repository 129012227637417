import React from 'react';
import {
  Radio,
  RadioGroup,
  GridRow,
  GridCol,
  Select,
  SelectOption,
} from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import { OverlayEnablerStatuses } from '../../utils/constants';
import './styles.scss';
import {
  frequencyVideoArray,
  lenghtVideoArray,
} from './parametersConfig/parametersConfig';

const {
  INSPECTION_VEHICLE_DURATION_VIDEO,
  INSPECTION_VEHICLE_FREQUENCY_IMAGES,
  INSPECTION_VEHICLE_IMAGE_COMPRESS,
  INSPECTION_VEHICLE_IMAGE_COMPRESS_DISABLE,
} = localizedStrings;

const { ENABLED, DISABLED } = OverlayEnablerStatuses;
const ParametersVideo = ({ onChangeVideoParameters, initialValues }) => {
  const onChangeVideoParametersLocal = (event, option) => {
    if (event) onChangeVideoParameters(event.target.value, option);
  };
  return (
    <div className="videoProperties">
      <GridRow>
        <GridCol type="data" className="videoProperties__selector">
          <Select
            id="video-frequency"
            value={initialValues ? initialValues.frequency : ''}
            labelVisual={INSPECTION_VEHICLE_FREQUENCY_IMAGES}
            placeholderOption={INSPECTION_VEHICLE_FREQUENCY_IMAGES}
            onChange={e => onChangeVideoParametersLocal(e, 'frequency')}
          >
            {frequencyVideoArray.map(option => (
              <SelectOption value={option?.split(' ')[0]} key={`${option}`}>
                {option}
              </SelectOption>
            ))}
          </Select>
        </GridCol>
        <GridCol type="data" className="videoProperties__selector">
          <Select
            id="video-duration"
            value={initialValues ? initialValues.duration : ''}
            labelVisual={INSPECTION_VEHICLE_DURATION_VIDEO}
            placeholderOption={INSPECTION_VEHICLE_DURATION_VIDEO}
            onChange={e => onChangeVideoParametersLocal(e, 'duration')}
          >
            {lenghtVideoArray.map(option => (
              <SelectOption value={option?.split(' ')[0]} key={`${option}`}>
                {option}
              </SelectOption>
            ))}
          </Select>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol type="data" className="activeCompress-cell">
          <RadioGroup
            inline
            name="activeCompress-enabler-radio-group"
            className="activeCompress-enabler-radio-group"
            disabled={false}
            value={initialValues?.compress === false ? 'DISABLED' : 'ENABLED'}
            onChange={e => onChangeVideoParametersLocal(e, 'compress')}
          >
            <Radio
              id="disable-activeCompress"
              key="disable-activeCompress"
              labelVisual={INSPECTION_VEHICLE_IMAGE_COMPRESS_DISABLE}
              value={DISABLED}
            />
            <Radio
              id="enable-activeCompress"
              key="enable-activeCompress"
              labelVisual={INSPECTION_VEHICLE_IMAGE_COMPRESS}
              value={ENABLED}
            />
          </RadioGroup>
        </GridCol>
      </GridRow>
    </div>
  );
};

ParametersVideo.defaultProps = {
  onChangeVideoParameters: () => {
    // Empty function
  },
  initialValues: {},
};

ParametersVideo.propTypes = {
  onChangeVideoParameters: PropTypes.func,
  initialValues: PropTypes.shape({
    frequency: PropTypes.string,
    duration: PropTypes.string,
    compress: PropTypes.bool,
  }),
};
export default ParametersVideo;
