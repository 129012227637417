import { GridRow, Caption } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  INSPECTION_OVERRIDE_COMMENT_TITLE,
  INSPECTION_OVERRIDE_AGENT_NAME,
} = localizedStrings;

const InspectionAgentOverrideComment = ({ agentName, overrideComment }) => (
  <Panel className="agent-override-section">
    <GridRow className="override-title-row">
      <Caption className="override-text override-text-title">
        {INSPECTION_OVERRIDE_COMMENT_TITLE}
      </Caption>
    </GridRow>

    <GridRow>
      <Caption className="override-text override-text-title">
        {INSPECTION_OVERRIDE_AGENT_NAME}
      </Caption>
      <Caption className="override-text">{agentName}</Caption>
    </GridRow>

    <GridRow>
      <textarea
        className="override-text-area"
        disabled
        maxLength="1000"
        value={overrideComment}
      />
    </GridRow>
  </Panel>
);

InspectionAgentOverrideComment.propTypes = {
  agentName: PropTypes.string.isRequired,
  overrideComment: PropTypes.string.isRequired,
};

export default InspectionAgentOverrideComment;
