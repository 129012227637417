import React from 'react';
import PropTypes from 'prop-types';

export const CirclePlusIcon = ({ width, height, color, ...props }) => (
  <svg
    {...props}
    viewBox="0 0 128 128"
    width={`${width}px`}
    height={`${height}px`}
    style={{ cursor: 'pointer' }}
    fill={color}
  >
    <circle
      style={{ fill: color || 'rgb(103 224 236)' }}
      cx="25"
      cy="25"
      r="25"
    />
    <line
      style={{
        fill: 'none',
        stroke: '#FFFFFF',
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 10,
      }}
      x1="25"
      y1="13"
      x2="25"
      y2="38"
    />
    <line
      style={{
        fill: 'none',
        stroke: '#FFFFFF',
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 10,
      }}
      x1="37.5"
      y1="25"
      x2="12.5"
      y2="25"
    />
  </svg>
);

CirclePlusIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
export default CirclePlusIcon;
