import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import BinnacleSupportModal from '../BinnacleSupportModal/BinnacleSupportModal';
import Panel from '../Panel';
import './styles.scss';

const {
  SUPPORT_INSPECTION_TROUBLES,
  SUPPORT_INSPECTION_TROUBLES_ADITIONAL,
  SUPPORT_INSPECTION_SOLUTIONS,
  SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL,
  SUPPORT_INSPECTION_BINNACLE,
  SUPPORT_INSPECTION_SOLUTIONS_USER,
  INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT,
  SUPPORT_INSPECTION_NO_COMMENTS,
} = localizedStrings;

const SupportInspectionBox = ({
  supportTroubles,
  supportSolutions,
  formSupport,
  disabledSupportForm,
  textareaSupport = '',
  historySupport,
}) => {
  const [arrayTroubles] = useState(supportTroubles);
  const [arraySolutions] = useState(supportSolutions);
  const [isModalBinnacleOpen, setIsModalBinnacleOpen] = useState(false);
  const {
    register,
    formState: { isValid },
    watch,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    let subscribtion;
    if (isValid) {
      subscribtion = watch(data => {
        formSupport(data);
      });
    }

    // eslint-disable-next-line consistent-return
    return () => subscribtion?.unsubscribe();
  }, [formSupport, isValid, watch]);

  return (
    <Panel className="supportInspection">
      <div className="supportInspectionBox">
        <div className="supportInspectionBox__troubles">
          <h4>{SUPPORT_INSPECTION_TROUBLES}</h4>
          <ul>
            {arrayTroubles.map(trouble => (
              <li key={trouble.id}>{trouble.description}</li>
            ))}
          </ul>
          <div className="supportInspectionBox__troubles__informationPlus">
            <h4>{SUPPORT_INSPECTION_TROUBLES_ADITIONAL}</h4>
            <span>
              {textareaSupport.length > 1
                ? textareaSupport
                : SUPPORT_INSPECTION_NO_COMMENTS}
            </span>
          </div>
        </div>
        <div className="supportInspectionBox__solutions">
          <h4>{SUPPORT_INSPECTION_SOLUTIONS}</h4>
          <form>
            <select
              className="inputs_rhf_adil"
              {...register('supportSelectSolution', { required: true })}
              defaultValue={1}
              disabled={disabledSupportForm}
            >
              <option disabled value={1}>
                {INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT}
              </option>
              {arraySolutions.map(solution => (
                <option key={solution.id} value={JSON.stringify(solution)}>
                  {solution.description}
                </option>
              ))}
            </select>
            <div className="supportInspectionBox__solutions__informationPlus">
              <h4>{SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL}</h4>
              <textarea
                className="textarea_rhf_adil"
                {...register('commentsSolutions', { required: true })}
                disabled={disabledSupportForm}
              />
            </div>
            <div className="supportInspectionBox__solutions__informationPlus">
              <h4>{SUPPORT_INSPECTION_SOLUTIONS_USER}</h4>
              <textarea
                className="textarea_rhf_adil"
                {...register('commentsUser', { required: true })}
                disabled={disabledSupportForm}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="supportInspection__log">
        <button
          type="button"
          className={`adil-search__button ${
            historySupport.length === 0 ? 'disabled_adilButton' : ''
          }`}
          disabled={historySupport.length === 0}
          onClick={() => setIsModalBinnacleOpen(!isModalBinnacleOpen)}
        >
          {SUPPORT_INSPECTION_BINNACLE}
        </button>
      </div>
      <BinnacleSupportModal
        isModalBinnacleOpen={isModalBinnacleOpen}
        historySupport={historySupport || []}
        closeNotificationModal={modalOpen => {
          setIsModalBinnacleOpen(modalOpen);
        }}
      />
    </Panel>
  );
};

SupportInspectionBox.propTypes = {
  historySupport: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabledSupportForm: PropTypes.bool.isRequired,
  formSupport: PropTypes.func.isRequired,
  supportSolutions: PropTypes.arrayOf(PropTypes.shape()),
  supportTroubles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  textareaSupport: PropTypes.string.isRequired,
};

SupportInspectionBox.defaultProps = {
  supportSolutions: [],
};

export default SupportInspectionBox;
