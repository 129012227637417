import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, Caption } from '@lmig/lmds-react';
import {
  IconDownload,
  IconCamera,
  IconCalendarLrg,
  IconChat,
} from '@lmig/lmds-react/icons';
import { DescriptionArea } from '..';
// eslint-disable-next-line import/no-named-as-default
import getPhotoDisplayStringFromId from '../../config/photoDisplayStringMapping';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const getPhotoDate = timestamp => {
  const originDate = new Date(timestamp);
  const dateConfig = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return originDate.toLocaleDateString('es-ES', dateConfig);
};

function PhotoHistoryInfo({ photo, vehicleClass }) {
  const photoName = getPhotoDisplayStringFromId(vehicleClass, photo.name);
  const createdAt = getPhotoDate(photo.lastDateModified);

  const {
    INSPECTION_PHOTO_HISTORY_MODAL_FOTO,
    INSPECTION_PHOTO_HISTORY_MODAL_FOTO_DATE,
    INSPECTION_PHOTO_HISTORY_MODAL_PHOTO_COMMENTS,
    INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE,
  } = localizedStrings;

  return (
    <div className="photo-history-info">
      <div className="photo-history-info-container">
        {photo && (
          <>
            <div style={{ marginBottom: '1rem' }}>
              <BodyText>
                <b>
                  <IconCamera className="photo-history-info-icons" />
                  {`${INSPECTION_PHOTO_HISTORY_MODAL_FOTO}: `}
                </b>
                <label>{` ${photoName}`}</label>
              </BodyText>
            </div>
            <div style={{ marginBottom: '1.5rem' }}>
              <BodyText>
                <IconCalendarLrg className="photo-history-info-icons info-icon-calendar" />
                <b>{`${INSPECTION_PHOTO_HISTORY_MODAL_FOTO_DATE}: `}</b>
                <label>{` ${createdAt}`}</label>
              </BodyText>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <BodyText>
                <IconChat className="photo-history-info-icons" />
                <b style={{ marginBottom: '0.5rem' }}>
                  {`${INSPECTION_PHOTO_HISTORY_MODAL_PHOTO_COMMENTS}: `}
                </b>
                <DescriptionArea
                  width={92}
                  maxHeight={16}
                  description={photo.comments || ''}
                />
              </BodyText>
              <BodyText>
                <a
                  download
                  href={photo.url}
                  className="photo-history-download-image"
                >
                  <Caption>
                    {INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE}
                  </Caption>
                  <IconDownload
                    size="16"
                    className="photo-history-download-icon"
                  />
                </a>
              </BodyText>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

PhotoHistoryInfo.propTypes = {
  photo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    lastDateModified: PropTypes.string.isRequired,
    comments: PropTypes.string.isRequired,
    ageInSeconds: PropTypes.number.isRequired,
  }).isRequired,
  vehicleClass: PropTypes.string.isRequired,
};

export default PhotoHistoryInfo;
