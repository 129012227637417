import { Auth } from 'aws-amplify';
import { Button, Content, Heading } from '@lmig/lmds-react';
import { IconLogIn } from '@lmig/lmds-react/icons';
import React from 'react';
import { AppFooter, AppHeader, CircleImage, Panel } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const { LOGIN_TITLE, LOGIN_SUBTITLE } = localizedStrings;

const Login = () => {
  const signIn = () => {
    Auth.federatedSignIn({ provider: 'lmidp' });
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;
  return (
    <div className="adil-screen">
      <AppHeader />
      <Content className="adil-content login">
        <Heading type="h3-light" className="login-title auto-left-right-margin">
          {LOGIN_TITLE}
        </Heading>
        <Panel className="login-panel auto-left-right-margin">
          <div className="auto-left-right-margin">
            <CircleImage size="small">
              <IconLogIn size="48" />
            </CircleImage>
          </div>
          <Heading
            type="h4-light"
            className="login-subtitle auto-left-right-margin"
          >
            {LOGIN_SUBTITLE}
          </Heading>
          <Button
            className="login-submit-button auto-left-right-margin"
            type="submit"
            onClick={signIn}
          >
            {LOGIN_TITLE}
          </Button>
        </Panel>
        {renderFooterSection()}
      </Content>
    </div>
  );
};

export default Login;
