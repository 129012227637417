import { Header as LibertyMutualHeader } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppHeaderUserSection } from '..';
import { UserActions } from '../../actions';
import { userPropType } from '../../prop-types';
import logoSpanish from '../../styles/images/logo-liberty.svg';
import logoHDILibertyCo from '../../styles/images/logo-hdiLiberty-co.svg';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const { LIBERTY_LOGO_IMAGE_ALT_TEXT, APP_HEADER_TITLE } = localizedStrings;
const libertyLogo = getCountryCode() === 'co' ? logoHDILibertyCo : logoSpanish;
const getClassName =
  getCountryCode() === 'co' ? 'app-header app-header-co' : 'app-header';
const AppHeader = ({ loggedIn, user, userActions }) => (
  <LibertyMutualHeader className={getClassName}>
    <div>
      <img
        src={libertyLogo}
        className="app-header-image"
        alt={LIBERTY_LOGO_IMAGE_ALT_TEXT}
      />
      <span className="app-header-title">{APP_HEADER_TITLE}</span>
    </div>
    {loggedIn && (
      <AppHeaderUserSection user={user} onLogout={userActions.logout} />
    )}
  </LibertyMutualHeader>
);

AppHeader.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: userPropType.isRequired,
  userActions: UserActions.PropertyTypes.isRequired,
};

const mapStateToProps = ({ settings: { loggedIn }, user }) => ({
  loggedIn,
  user,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
