import React from 'react';
import './styles.scss';

const LoadingAnimation = () => (
  <div className="loading-animation">
    <div className="loading-animation-wrapper">
      <div className="circle circle-1" />
      <div className="circle circle-2" />
      <div className="circle circle-3" />
    </div>
  </div>
);

export default LoadingAnimation;
