import React from 'react';
import PropTypes from 'prop-types';

const BoxOptions = ({ onPress, route, title, description }) => (
  <>
    <div
      role="presentation"
      className="BoxOptions__information"
      onClick={() => onPress(route)}
    >
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </>
);
export default BoxOptions;

BoxOptions.propTypes = {
  onPress: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
