import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { PropTypes } from 'prop-types';

import { useForm } from 'react-hook-form';
import { Button, Heading, Table, TableCell, TableRow } from '@lmig/lmds-react';
import { useDispatch, useSelector } from 'react-redux';
import InlineLoader from '../InlineLoader';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { newVehiclePartAdapter } from '../../adapters/partAndPieceAdapter';
import { partsAndPiecesActions } from '../../redux/parts-and-pieces-admin';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const {
  ADMIN_PARTS_MANTEINER,
  ADMIN_SUPPORT_ADD_PART,
  ADMIN_SUPPORT_SELECT_PART,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION,
  ADMIN_SUPPORT_ADD,
  ADMIN_SUPPORT_MODIFY,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_1,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_3,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_4,
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_5,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT,
  ADMIN_SUPPORT_TABLE_UPDATE,
  ADMIN_SUPPORT_TABLE_DELETE,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
  SEARCH_LOADING_LABEL,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
  ADMIN_SUPPORT_DELETE_YES,
  ADMIN_SUPPORT_DELETE_NO,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
  ADMIN_SUPPORT_TABLE_HEAD_1,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
} = localizedStrings;

const TableGeneralPartsManteiner = ({ onLoader }) => {
  const MySwal = withReactContent(Swal);

  const dispatch = useDispatch();
  const { parts = [] } = useSelector(state => state.partsAndPiecesAdmin);

  const [partsArray, setPartsArray] = useState(parts);

  const [idModify, setidModify] = useState(null);
  const [isformModifying, setIsformModifying] = useState(false);

  useEffect(() => {
    setPartsArray(parts);
  }, [parts]);

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = data => {
    if (!isValid) return;

    const adapterPart = newVehiclePartAdapter(data);
    let objectToSend = {
      newVehiclePart: adapterPart,
    };

    if (!isformModifying) {
      dispatch(partsAndPiecesActions.createPartOrPiece(objectToSend))
        .then(() => {
          setPartsArray(oldParts => [
            ...oldParts,
            {
              id: oldParts.length + 1,
              ...adapterPart,
            },
          ]);
          dispatch(
            partsAndPiecesActions.addPartToSelect({
              ...adapterPart,
              id: partsArray.length + 1,
            }),
          );
          reset();
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        });
    } else {
      objectToSend = {
        newVehiclePart: {
          ...objectToSend.newVehiclePart,
          id: idModify,
        },
      };
      dispatch(
        partsAndPiecesActions.updatePartOrPiece({
          countryCode: getCountryCode().toLowerCase(),
          newVehiclePart: objectToSend.newVehiclePart,
        }),
      )
        .then(() => {
          setPartsArray(oldParts =>
            oldParts.map(element => {
              if (element.id === idModify) {
                return {
                  ...adapterPart,
                  id: idModify,
                };
              }
              return element;
            }),
          );
          reset();
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        })
        .finally(() => {
          setidModify(null);
          setIsformModifying(false);
        });
    }
  };

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  const setValueForm = formValues => {
    const { name, active, id } = formValues;
    setIsformModifying(true);
    setidModify(id);
    setValue('part', name, { shouldValidate: true });
    setValue('activeOption', active.toString(), { shouldValidate: true });
  };

  const deletePartForId = idPart => {
    MySwal.fire({
      title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
      showDenyButton: true,
      confirmButtonText: ADMIN_SUPPORT_DELETE_YES,
      denyButtonText: ADMIN_SUPPORT_DELETE_NO,
      allowOutsideClick: false,
      customClass: {
        title: 'sweetAdil__title',
        denyButton: 'sweetAdil__cancelButton',
        confirmButton: 'sweetAdil__acceptButton',
      },
    }).then(result => {
      if (result.isConfirmed) {
        const partArrayDeleteId = partsArray.filter(item => item.id !== idPart);
        dispatch(
          partsAndPiecesActions.deletePartOrPiece({
            vehiclePartId: idPart,
            countryCode: getCountryCode().toLocaleLowerCase(),
          }),
        )
          .then(() => {
            setPartsArray(partArrayDeleteId);
            MySwal.fire({
              title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          })
          .catch(() => {
            MySwal.fire({
              title: ADMIN_SUPPORT_TABLE_HEAD_1,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          });
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  };

  return (
    <div className="supportBox">
      <h4>{ADMIN_PARTS_MANTEINER}</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="supportBox__form">
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">{ADMIN_SUPPORT_ADD_PART}</label>
          <input
            placeholder={ADMIN_SUPPORT_ADD_PART}
            {...register('part', {
              required: true,
            })}
            className={`inputs_rhf_adil ${
              errors.part ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">
            {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE}
          </label>
          <select
            className={`inputs_rhf_adil ${
              errors.activeOption ? 'validation-input-field-error' : ''
            }`}
            defaultValue="active"
            {...register('activeOption')}
          >
            <option value="true">
              {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION}
            </option>
            <option value="false">
              {ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION}
            </option>
          </select>
        </div>
        <div className="supportBox__buttons">
          <Button type="submit" className="supportBox__button">
            {isformModifying && ADMIN_SUPPORT_MODIFY}
            {!isformModifying && ADMIN_SUPPORT_ADD}
          </Button>
        </div>
        <div className="supportBox__errorMessages">
          {errors.part && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_PART}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
          {errors.piece && errors.piece.type === 'required' && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_SELECT_PART}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
        </div>
      </form>
      {!onLoader && (
        <>
          <div className="supportBox__table">
            <Table>
              <tbody>
                <TableRow className="supportBox__table__headers">
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_1}
                  </TableCell>
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_3}
                  </TableCell>
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_4}
                  </TableCell>
                  <TableCell type="colHead">
                    {ADMIN_PIECES_MANTEINER_TABLE_HEAD_5}
                  </TableCell>
                </TableRow>
                {partsArray.length > 0 &&
                  partsArray.map((part, index) => (
                    <TableRow
                      className={`supportBox__table__content ${
                        index % 2 === 0 ? 'adil-table-row-grey' : ''
                      }`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={part.id}
                    >
                      <td>{part.id}</td>
                      <td>{part.name}</td>
                      <td>
                        {part.active
                          ? ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT
                          : ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT}
                      </td>
                      <td>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div
                            className="supportBox__chipButtons supportBox__chipButtons__update"
                            role="button"
                            onClick={() => setValueForm(part)}
                          >
                            <span>{ADMIN_SUPPORT_TABLE_UPDATE}</span>
                          </div>
                          <div
                            className="supportBox__chipButtons supportBox__chipButtons__eliminate"
                            role="button"
                            data-testid="supportBox__chipButtons__eliminate"
                            onClick={() => deletePartForId(part.id)}
                          >
                            <span>{ADMIN_SUPPORT_TABLE_DELETE}</span>
                          </div>
                        </div>
                      </td>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
      {onLoader && renderInlineLoader()}
    </div>
  );
};

TableGeneralPartsManteiner.propTypes = {
  onLoader: PropTypes.bool.isRequired,
};

export default TableGeneralPartsManteiner;
