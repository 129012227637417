import React from 'react';
import constants from '@lmig-latam/adil-api-common-lib/constants';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import localizedStrings from '../../utils/localizedStrings';
import { SearchActions } from '../../redux/search-inspection';
import StatusButton from './StatusButton';
import './styles.scss';

const {
  STATUS_TYPES: { STATUS_CUSTOMER_IN_PROGRESS },
} = constants;

const { SEARCH_FILTER_BAR_WITH_PHOTOS } = localizedStrings;

const StatusFilterBar = () => {
  const dispatch = useDispatch();

  const { filterSelected, countInspections, currentLocation } = useSelector(
    store => store.searchFilter,
  );

  const getStatusesOfStep = () => filterSelected.statuses;

  const getSubStatusSelected = () => currentLocation.subStatusSelected;

  const onChangeLocationPage = newCurrentLocation =>
    dispatch(SearchActions.onChangeCurrentLocationPage(newCurrentLocation));

  const hasPhotos = () =>
    currentLocation.mustHavePhotos &&
    getSubStatusSelected() === STATUS_CUSTOMER_IN_PROGRESS;

  const renderWithPhotosOption = () => {
    if (!getStatusesOfStep().includes(STATUS_CUSTOMER_IN_PROGRESS)) {
      return null;
    }

    return (
      <div
        role="button"
        onClick={() => {
          onChangeLocationPage({
            subStatusSelected: STATUS_CUSTOMER_IN_PROGRESS,
            mustHavePhotos: true,
          });
        }}
        className={classNames({
          'status-filter-bar-container-option': true,
          'status-filter-bar-selected': hasPhotos(),
        })}
      >
        {SEARCH_FILTER_BAR_WITH_PHOTOS}
      </div>
    );
  };

  return (
    <div className="status-filter-bar-container">
      {getStatusesOfStep().map(status => (
        <StatusButton
          key={status}
          status={status}
          optionsCount={countInspections}
        />
      ))}

      {renderWithPhotosOption()}
    </div>
  );
};

export default StatusFilterBar;
