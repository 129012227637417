import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import PropTypes from 'prop-types';
import { ImageGallery } from '../ImageGallery';
import { ThumbnailsGallery } from './ThumbnailsGallery/ThumbnailsGallery';
import PreviewVideo from './PreviewVideo/PreviewVideo';
import { GalleryOptions } from './GalleryOptions/GalleryOptions';
import { GALLERY_OPTIONS } from '../Stepper/Stepper';
import { rotateImage } from '../../api';
import { logcodes, logger } from '../../utils/logger';
import { currentInspectionActions } from '../../redux/current-inspection';
import { inspectionInformActions } from '../../redux/inspection-inform';
import { InspectionActions } from '../../actions';
import './styles.scss';

function InspectionInformGallery({ photos, inspectionId, vehicleClass }) {
  const dispatch = useDispatch();
  const [currentIndexImage, setCurrentIndexImage] = useState(0);
  const [brigthnessValue, setBrigthnessValue] = useState(100);
  const [zoomValue, setZoomValue] = useState(1);
  const [resetBrigthness, setResetBrigthness] = useState(false);
  const handleFullscreen = useFullScreenHandle();
  const brigthnessRef = useRef(null);

  const {
    inspectionInform: { loadingGallery },
    currentInspection: {
      rejectedPhotoHistory: { photoHistory },
    },
  } = useSelector(store => store);

  const currentPhoto = useMemo(() => photos[currentIndexImage], [
    currentIndexImage,
    photos,
  ]);

  useEffect(() => {
    if (zoomValue <= 1) setZoomValue(1);
    if (zoomValue >= 10) setZoomValue(10);
  }, [setZoomValue, zoomValue]);

  useEffect(() => {
    setZoomValue(1);
  }, [setCurrentIndexImage, currentIndexImage]);

  const isPhotoWithPhotoHistory = () =>
    photoHistory && photoHistory[currentPhoto?.name];

  const handleRotateImage = async (photoName, rotateDirection) => {
    try {
      const photo = { name: `${photoName}.jpeg` };
      dispatch(inspectionInformActions.onLoadingGallery(true));
      const response = await rotateImage(inspectionId, photo, rotateDirection);
      const updatedPhotos = photos.map(item =>
        item.name === photoName ? { ...item, url: response.url } : item,
      );
      dispatch(inspectionInformActions.setAllPhotosToGallery(updatedPhotos));
      dispatch(inspectionInformActions.onLoadingGallery(false));
    } catch (error) {
      logger.log(logcodes.ROTI010, { error, photoName });
    }
  };

  const onDisplayPhotoHistoryModal = useCallback(() => {
    dispatch(
      currentInspectionActions.onDisplayPhotoHistoryModal(
        true,
        currentPhoto.name,
        vehicleClass,
      ),
    );
  }, [currentPhoto.name, dispatch, vehicleClass]);

  const onSelectVideoCapture = async photo => {
    dispatch(inspectionInformActions.onLoadingGallery(true));
    dispatch(InspectionActions.addVideoCapture(inspectionId, photo.name))
      .then(() => {
        dispatch(inspectionInformActions.onLoadingGallery(false));
        dispatch(inspectionInformActions.addVideoCaptureToGallery(photo));
      })
      .catch(error => {
        logger.log(logcodes.CAPT010, error);
      });
  };

  const onManageGalleryOption = type => {
    switch (type) {
      case GALLERY_OPTIONS.LEFT_ROTATION:
        handleRotateImage(currentPhoto.name, 90);
        break;
      case GALLERY_OPTIONS.RIGHT_ROTATION:
        handleRotateImage(currentPhoto.name, -90);
        break;
      case GALLERY_OPTIONS.PHOTO_HISTORY:
        onDisplayPhotoHistoryModal();
        break;
      default:
    }
  };

  useEffect(() => {
    let timeOutBrigthness;
    if (brigthnessValue && brigthnessValue !== 100) {
      setBrigthnessValue(100);
      setResetBrigthness(true);
      timeOutBrigthness = setTimeout(() => {
        setResetBrigthness(false);
      }, 100);
    }
    return () => {
      clearTimeout(timeOutBrigthness);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndexImage]);

  const isVideoPreview = currentPhoto.type === 'video';

  return (
    <div className="inspection-inform-gallery">
      <TransformWrapper>
        {!isVideoPreview && !loadingGallery && (
          <GalleryOptions
            hasPhotoHistory={!!isPhotoWithPhotoHistory()}
            onManageGalleryOption={onManageGalleryOption}
            currentPhoto={currentPhoto}
            setBrigthnessValue={setBrigthnessValue}
            brigthnessRef={brigthnessRef}
            resetBrigthness={resetBrigthness}
            handleFullscreen={handleFullscreen}
            setZoomValue={setZoomValue}
            enabledOptions={[
              'rotation',
              'trash',
              'fullscreen',
              'download',
              'brigthness',
              'zoom',
            ]}
          />
        )}

        {isVideoPreview ? (
          <PreviewVideo
            allPictures={photos}
            videoPhotos={currentPhoto?.photos}
            onSelectVideoCapture={onSelectVideoCapture}
          />
        ) : (
          <FullScreen handle={handleFullscreen}>
            <TransformComponent>
              <ImageGallery
                pictures={photos}
                vehicleClass={vehicleClass}
                brigthnessValue={brigthnessValue}
                zoomValue={zoomValue}
                onChangeIndex={setCurrentIndexImage}
                currentIndex={currentIndexImage}
                handleFullscreen={handleFullscreen}
                arrows={false}
              />
            </TransformComponent>
          </FullScreen>
        )}

        <div className="inspection-inform-gallery__container">
          <ThumbnailsGallery
            arrows
            pictures={photos}
            vehicleClass={vehicleClass}
            currentIndex={currentIndexImage}
            onChangeIndex={setCurrentIndexImage}
            className={{ container: 'inspection-inform-gallery__thumbnail' }}
            styles={{
              picture: {
                borderRadius: '0px',
              },
              container: {
                flexDirection: 'row',
                background: 'none',
              },
            }}
          />
        </div>
      </TransformWrapper>
    </div>
  );
}

InspectionInformGallery.defaultProps = {
  photos: [],
};

InspectionInformGallery.propTypes = {
  vehicleClass: PropTypes.string.isRequired,
  inspectionId: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
};

export default InspectionInformGallery;
