import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import { Heading, BodyText, Button } from '@lmig/lmds-react';
import {
  IconThumbsUp,
  IconThumbsDown,
  IconQuestionMark,
  IconEmail,
} from '@lmig/lmds-react/icons';
import { CircleImage } from '..';
import { notificationStatus } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { logcodes } from '../../utils/logger';

const { CONFIRMATION, SUCCESS, FAILURE } = notificationStatus;

const {
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_GENERIC,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_GENERIC,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE,
  INSPECTION_CUSTOMER_DETAILS_BUTTON_SENDING,
  INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_EMAIL,
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_EMAIL,
} = localizedStrings;

const SendNotificationsModal = ({
  modalType,
  sendNotifications,
  isNotificationsSending,
  isModalNotificationOpen,
  errorCode,
  closeNotificationModal,
}) => {
  const getModalContent = type => {
    switch (type) {
      case SUCCESS:
        return {
          title: INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE,
          subtitle: INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE,
          icon: <IconThumbsUp size="64" />,
        };
      case FAILURE: {
        let failureTitle = INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_GENERIC;
        let failureSubtitle = INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_GENERIC;

        if (errorCode === logcodes.RSREM130.code) {
          failureTitle = INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_EMAIL;
          failureSubtitle = INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_EMAIL;
        }

        return {
          title: failureTitle,
          subtitle: failureSubtitle,
          icon: <IconThumbsDown size="64" />,
        };
      }
      case CONFIRMATION:
        return {
          title: INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE,
          subtitle: INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE,
          icon: <IconQuestionMark size="64" />,
        };
      default:
        return {
          title: INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE,
          subtitle: INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE,
          icon: <IconQuestionMark size="64" />,
        };
    }
  };

  const { title, subtitle, icon } = getModalContent(modalType);

  return (
    <ReactModal
      isOpen={isModalNotificationOpen}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      <div className="modal-content">
        <CircleImage>{icon}</CircleImage>
        <Heading type="h3-light">{title}</Heading>
        <BodyText>{subtitle}</BodyText>

        <div className="notification-modal-button-area">
          <Button
            type="button"
            level="secondary"
            onClick={closeNotificationModal}
            disabled={isNotificationsSending}
          >
            {INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE}
          </Button>
          {modalType === CONFIRMATION && (
            <Button type="button" level="primary" onClick={sendNotifications}>
              {isNotificationsSending
                ? INSPECTION_CUSTOMER_DETAILS_BUTTON_SENDING
                : INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND}
              <IconEmail
                size="24"
                className="send-notifications-resend-button"
              />
            </Button>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

SendNotificationsModal.propTypes = {
  modalType: PropTypes.oneOf([SUCCESS, FAILURE, CONFIRMATION]).isRequired,
  isModalNotificationOpen: PropTypes.bool.isRequired,
  closeNotificationModal: PropTypes.func.isRequired,
  sendNotifications: PropTypes.func.isRequired,
  errorCode: PropTypes.string,
  isNotificationsSending: PropTypes.bool.isRequired,
};

SendNotificationsModal.defaultProps = {
  errorCode: null,
};

export default SendNotificationsModal;
