import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MainLoaderContainer = ({ children, className }) => (
  <div className={['main-loader__container', className].join(' ')}>
    {children}
  </div>
);

MainLoaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
};
