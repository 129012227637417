import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MenuGalleryIcon = ({ width, height, isOpen, onPress }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16.001"
    onClick={() => onPress(!isOpen)}
    className="menu-gallery"
    style={{
      transform: isOpen ? `rotate(0deg)` : `rotate(135deg)`,
    }}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          d="M0,15.073,7.073,8,0,.928.927,0,8,7.073,15.072,0,16,.928,8.927,8,16,15.073,15.072,16,8,8.928.927,16Z"
          fill="#1a1446"
        />
      </clipPath>
    </defs>

    <g>
      <path
        d="M0,15.073,7.073,8,0,.928.927,0,8,7.073,15.072,0,16,.928,8.927,8,16,15.073,15.072,16,8,8.928.927,16Z"
        fill="#1a1446"
      />
      <g clipPath="url(#clip-path)">
        <g transform="translate(-42.318 -40.303)">
          <rect width="101.766" height="97.735" fill="#1a1446" />
        </g>
        <g transform="translate(-45.341 -8.061)">
          <rect width="101.766" height="97.735" fill="#1a1446" />
        </g>
      </g>
    </g>
  </svg>
);

MenuGalleryIcon.defaultProps = {
  width: 40,
  height: 40,
};

MenuGalleryIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};
