export const restMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  // Amplify uses 'del' instead of 'delete'
  // https://aws-amplify.github.io/amplify-js/api/classes/apiclass.html#del
  DELETE: 'del',
};

export const getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
});
