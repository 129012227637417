import React, { useCallback, useEffect, useState } from 'react';
import { IconWarning } from '@lmig/lmds-react/icons';
import constants from '@lmig-latam/adil-api-common-lib/constants';
import { BodyText, Content, Disclaimer, Heading } from '@lmig/lmds-react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import cjson from 'compressed-json';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { InspectionActions, SettingsActions } from '../../actions';
import SearchActions from '../../redux/search-inspection/actions';
import localizedStrings from '../../utils/localizedStrings';
import {
  AppFooter,
  AppHeader,
  InlineLoader,
  InspectionTable,
  SearchBar,
  SideBar,
  SupportRequestInfoModal,
} from '../../components';
import './styles.scss';
import {
  countInspectionList,
  getPage,
  inspectionLIstFilter,
  inspectionListTextFilter,
} from '../../utils';
import {
  getCountryCode,
  isAsianDomain,
} from '../../config/environments/environmentsHelpers';
import { subItemRetakeDates, subItemSupportDates } from '../../utils/constants';
import { TAB_SECTION_NUMBER_LATAM } from '../../constants/menu';

const MySwal = withReactContent(Swal);

const {
  SEARCH_TITLE,
  SEARCH_SUBTITLE,
  SEARCH_NO_RESULTS_TITLE,
  FINISHED_SEARCH_NO_RESULTS_TITLE,
  SEARCH_NO_RESULTS_SUBTITLE,
  FINISHED_SEARCH_NO_RESULTS_SUBTITLE,
  SEARCH_LOADING_LABEL,
  INSPECTIONS_TABLE_HEADER_CREATED_ON,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW,
  SUPPORT_INSPECTION_MODAL_TITLE,
} = localizedStrings;

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_SUPPORT_AGENT_IN_PROGRESS,
    STATUS_CUSTOMER_RETAKE,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_SUPPORT_RETAKE,
    STATUS_SUPPORT_AGENT_REVIEW,
    STATUS_SUPPORT_INSPECTOR,
  },
} = constants;

export const mainStatus = [
  STATUS_AGENT_REVIEW,
  STATUS_CUSTOMER_RETAKE,
  STATUS_CUSTOMER_NEW,
  STATUS_CUSTOMER_IN_PROGRESS,
];

export const supportStatus = {
  latam: [
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_SUPPORT_INSPECTOR,
    STATUS_SUPPORT_RETAKE,
  ],
  vn: [
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_SUPPORT_AGENT_IN_PROGRESS,
    STATUS_SUPPORT_AGENT_REVIEW,
    STATUS_SUPPORT_RETAKE,
  ],
};

export const getMainStatus = () => {
  let status = [];
  switch (getCountryCode()) {
    case 'vn':
      status = mainStatus.concat(supportStatus.vn);
      break;
    case 'th':
      status = mainStatus;
      break;
    default:
      status = mainStatus.concat(supportStatus.latam);
      break;
  }

  return status.join(',');
};

const sortableHeaders = {
  [INSPECTIONS_TABLE_HEADER_CREATED_ON]: 'emailTokenCreationDate',
  [INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO]: 'customerCompletionDate',
  [INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE]: 'dateSortRetake',
  [INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW]: 'dateSortSupport',
};

const Search = ({
  inspectionActions: { getInspections, getFinishedInspections },
}) => {
  const {
    filterText,
    filterSelected,
    currentLocation,
    activeInspections,
    displaySupportRequestDialog,
    supportRequest,
  } = useSelector(store => store.searchFilter);

  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(true);
  const [inspectionsInScope, setInspectionsInScope] = useState([]);
  const [inspectionFilteredCount, setInspectionFilteredCount] = useState();
  const [sortConfig, setSortConfig] = useState({
    key: INSPECTIONS_TABLE_HEADER_CREATED_ON,
    direction: true,
  });
  const [fullInspectionList, setFullInspectionList] = useState([]);
  const [originalInspectionList, setOriginalInspectionList] = useState([]);
  const [fullInspectionCount, setFullInspectionCount] = useState({});
  const [inspectionListFiltered, setInspectionListFiltered] = useState([]);

  const dispatch = useDispatch();

  const onCloseSupportRequestDialog = () => {
    dispatch(SearchActions.onDisplaySupportRequestDialog(false));
  };

  useEffect(() => {
    setInspectionsInScope(
      getPage(inspectionListFiltered, 10, currentLocation.currentPageIndex),
    );
  }, [inspectionListFiltered, currentLocation.currentPageIndex]);

  useEffect(() => {
    const fetchInspections = async () => {
      setInlineLoaderDisplayed(true);
      if (activeInspections) {
        let dataParams = {};
        dataParams = {
          status: getMainStatus(),
        };

        const response = await cjson.decompress(
          await getInspections(dataParams),
        );

        if (response) {
          const { Items: inspections, itemFilteredCount } = response;

          setFullInspectionList(inspections);
          setOriginalInspectionList(inspections);
          setInspectionFilteredCount(itemFilteredCount);
          setInspectionListFiltered(inspections);
          setInspectionsInScope(getPage(inspections, 10, 0));
          const inspectionsCount = countInspectionList(inspections);
          setFullInspectionCount(inspectionsCount);
          dispatch(SearchActions.setCountInspections(inspectionsCount));
        }
      }
      setInlineLoaderDisplayed(false);
    };
    fetchInspections();
  }, [getInspections, dispatch, activeInspections]);

  const applyFilterSelected = useCallback(
    list =>
      inspectionLIstFilter(
        filterSelected,
        currentLocation.subStatusSelected,
        currentLocation.mustHavePhotos,
        list,
      ),
    [
      currentLocation.mustHavePhotos,
      currentLocation.subStatusSelected,
      filterSelected,
    ],
  );

  const onSubmitFinishedForm = async data => {
    setInlineLoaderDisplayed(true);
    const { searchingField, searchingValue } = data;

    const response = await cjson.decompress(
      await getFinishedInspections({ [searchingField]: searchingValue }),
    );
    if (response) {
      const { Items: inspections, itemFilteredCount } = response;
      if (itemFilteredCount === 0) {
        MySwal.fire({
          title: FINISHED_SEARCH_NO_RESULTS_TITLE,
          text: FINISHED_SEARCH_NO_RESULTS_SUBTITLE,
          allowOutsideClick: true,
          showConfirmButton: false,
          customClass: {
            htmlContainer: 'sweetAdil__container',
            title: 'sweetAdil__title',
          },
        });
      }
      setFullInspectionList(inspections);
      setOriginalInspectionList(inspections);
      setInspectionFilteredCount(itemFilteredCount);
      setInspectionListFiltered(inspections);
      setInspectionsInScope(getPage(inspections, 10, 0));
      setInlineLoaderDisplayed(false);
      if (!activeInspections) {
        dispatch(
          SearchActions.setCountInspections(countInspectionList(inspections)),
        );
      }
    }
  };

  const applyFilterText = useCallback(
    list => {
      const newList = inspectionListTextFilter(list, filterText.toLowerCase());
      setFullInspectionList(newList);
      return newList;
    },
    [filterText],
  );

  useEffect(() => {
    let inspectionsList;
    if (filterSelected)
      inspectionsList = applyFilterSelected(fullInspectionList);
    if (filterText === undefined) {
      inspectionsList = originalInspectionList;
      dispatch(SearchActions.setCountInspections(fullInspectionCount));
      setFullInspectionList(inspectionsList);
    } else if (filterText.trim() !== '') {
      if (
        !isAsianDomain() &&
        filterSelected?.id === TAB_SECTION_NUMBER_LATAM.FINISHED
      )
        return;

      setInlineLoaderDisplayed(true);
      inspectionsList = applyFilterText(originalInspectionList);
      setInlineLoaderDisplayed(false);
      dispatch(
        SearchActions.setCountInspections(countInspectionList(inspectionsList)),
      );
    }

    if (inspectionsList) {
      setInspectionFilteredCount(inspectionsList.length);
      setInspectionListFiltered(inspectionsList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSelected,
    filterText,
    dispatch,
    fullInspectionCount,
    originalInspectionList,
    applyFilterSelected,
    applyFilterText,
  ]);

  const requestSort = key => {
    let direction = true;
    if (sortConfig.direction) {
      direction = !sortConfig.direction;
    }
    let sortableItems = inspectionListFiltered;

    if (
      !isAsianDomain() &&
      currentLocation?.subStatusSelected &&
      subItemRetakeDates.includes(currentLocation.subStatusSelected)
    ) {
      const sortItemsRetake = sortableItems.map(elementGeneralArray => {
        const elementFilteredByRetake = elementGeneralArray.history?.findLast(
          elements => elements.name === 'CUSTOMER_RETAKE',
        );
        if (elementFilteredByRetake) {
          return {
            ...elementGeneralArray,
            dateSortRetake: elementFilteredByRetake.timestamp || null,
          };
        }
        return elementGeneralArray;
      });
      sortableItems = sortItemsRetake;
    }
    if (
      !isAsianDomain() &&
      currentLocation?.subStatusSelected &&
      subItemSupportDates.includes(currentLocation.subStatusSelected)
    ) {
      const supportDateStatus = currentLocation.subStatusSelected;
      const sortItemsRetake = sortableItems.map(elementGeneralArray => {
        const elementFilteredBySupport = elementGeneralArray.history?.findLast(
          elements => elements.name === supportDateStatus,
        );
        if (elementFilteredBySupport) {
          return {
            ...elementGeneralArray,
            dateSortSupport: elementFilteredBySupport.timestamp || null,
          };
        }
        return elementGeneralArray;
      });
      sortableItems = sortItemsRetake;
    }
    if (sortConfig !== null && key in sortableHeaders) {
      sortableItems.sort((a, b) => {
        if (a[sortableHeaders[key]] < b[sortableHeaders[key]]) {
          return sortConfig.direction ? -1 : 1;
        }
        if (a[sortableHeaders[key]] > b[sortableHeaders[key]]) {
          return sortConfig.direction ? 1 : -1;
        }
        return 0;
      });
      setSortConfig({ key, direction });
    }
    setInspectionsInScope(
      getPage(sortableItems, 10, currentLocation.currentPageIndex),
    );
  };

  const onUpdateInspections = newInspection => {
    const inspectionUpdated = inspectionsInScope.map(inspection => {
      if (inspection.id === newInspection.id) {
        return { ...inspection, ...newInspection };
      }
      return inspection;
    });

    setInspectionsInScope(
      getPage(inspectionUpdated, 10, currentLocation.currentPageIndex),
    );
  };

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  const renderSearchResults = () => {
    if (inlineLoaderDisplayed) {
      return renderInlineLoader();
    }
    if (inspectionsInScope.length === 0) {
      return (
        <div>
          <IconWarning size="64" color="gray" />
          <Heading type="h4-light" className="auto-left-right-margin">
            {activeInspections
              ? SEARCH_NO_RESULTS_TITLE
              : FINISHED_SEARCH_NO_RESULTS_TITLE}
          </Heading>
          <BodyText className="auto-left-right-margin">
            {activeInspections
              ? SEARCH_NO_RESULTS_SUBTITLE
              : FINISHED_SEARCH_NO_RESULTS_SUBTITLE}
          </BodyText>
        </div>
      );
    }

    return (
      <InspectionTable
        onUpdateInspections={onUpdateInspections}
        inspections={inspectionsInScope}
        count={inspectionFilteredCount}
        currentPageIndex={currentLocation.currentPageIndex}
        requestSort={requestSort}
        sortConfig={sortConfig}
      />
    );
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <Content className="adil-content">
          <div className="search-title-area">
            <Heading type="h3-light" className="auto-left-right-margin">
              {SEARCH_TITLE}
            </Heading>
            <Disclaimer>{SEARCH_SUBTITLE}</Disclaimer>
          </div>

          <SearchBar
            disabled={inlineLoaderDisplayed}
            onSubmitFinishedForm={onSubmitFinishedForm}
          />

          {renderSearchResults()}
          <SupportRequestInfoModal
            title={SUPPORT_INSPECTION_MODAL_TITLE}
            description={supportRequest?.textareaSupport}
            isOpen={displaySupportRequestDialog}
            closeModal={onCloseSupportRequestDialog}
          />
          {renderFooterSection()}
        </Content>
      </SideBar>
    </div>
  );
};

Search.propTypes = {
  inspectionActions: InspectionActions.PropertyTypes.isRequired,
};

const mapStateToProps = ({ user: { isCreator } }) => ({
  isCreator,
});

const mapDispatchToProps = dispatch => ({
  inspectionActions: bindActionCreators(InspectionActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
