import { Content } from '@lmig/lmds-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppFooter, AppHeader, SideBar } from '../../components';
import TableSupportSolutions from '../../components/TableSupportSolutions/TableSupportSolutions';
import TableSupportTrobles from '../../components/TableSupportTroubles/TableSupportTrobles';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { supportRequestActions } from '../../redux/support-request';
import './styles.scss';

const AdminSupport = () => {
  const [troublesArray, setTroublesArray] = useState([]);
  const [troublesRequired, setTroublesRtroublesRequired] = useState([]);
  const [solutionsArray, setSolutionsArray] = useState([]);
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(true);
  const dispatch = useDispatch();

  const { supportTroubleAndSolutions } = useSelector(
    store => store.supportRequestAdmin,
  );

  useEffect(() => {
    setInlineLoaderDisplayed(true);
    dispatch(
      supportRequestActions.getSupportRequestDb(
        getCountryCode()
          .toLowerCase()
          .toString(),
      ),
    ).then(() => setInlineLoaderDisplayed(false));
  }, [dispatch]);

  useEffect(() => {
    setTroublesArray(supportTroubleAndSolutions.troubles || []);
    setTroublesRtroublesRequired(
      supportTroubleAndSolutions.troublesRequired || [],
    );
    setSolutionsArray(supportTroubleAndSolutions.solutions || []);
  }, [setTroublesArray, supportTroubleAndSolutions]);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <div className="adil-content">
          <Content>
            <TableSupportTrobles
              onLoader={inlineLoaderDisplayed}
              troubles={troublesArray}
              troublesRequired={troublesRequired}
            />
            <TableSupportSolutions
              onLoader={inlineLoaderDisplayed}
              solutions={solutionsArray}
            />
            {renderFooterSection()}
          </Content>
        </div>
      </SideBar>
    </div>
  );
};

export default AdminSupport;
