import { Radio, RadioGroup, TableCell, TableRow } from '@lmig/lmds-react';
import { IconAuto, IconMyAccount, IconInfo } from '@lmig/lmds-react/icons';
import { photoUtils } from '@lmig-latam/adlib-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { VIDEO_SECTION } from '@lmig-latam/adil-api-common-lib/constants';
import { REQUIRED_PHOTO_IDS } from '@lmig-latam/adil-api-common-lib/constants/constants';
import { getPhotoDisplayStringFromId } from '../../config/photoDisplayStringMapping';
import { photoPropType } from '../../prop-types';
import { photoStatuses } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import VideoModal from '../Video/VideoModal/VideoModal';
import { formatDate } from '../../utils';
import { CirclePlusIcon } from '../Icons';
import { currentInspectionActions } from '../../redux/current-inspection';
import './styles.scss';
import { isProduction } from '../../config/environments/environmentsHelpers';

const { doesImageContainPerson, doesImageContainVehicle } = photoUtils;

const { APPROVED, REJECTED } = photoStatuses;

const {
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT,
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT,
  INSPECTION_VEHICLE_PHOTOS_HEADER_2,
  INSPECTION_VEHICLE_PHOTOS_HEADER_3,
  INSPECTION_VEHICLE_PHOTOS_HEADER_4,
  INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP,
  INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP,
  INSPECTION_VEHICLE_PHOTOS_IMAGE_TEXT_TOOLTIP,
  INSPECTION_VEHICLE_VIDEO_TITLE,
  INSPECTION_VEHICLE_CHASSIS_NUMBER_DETECTED_LABEL,
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_ACCEPT,
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_REJECT,
  INSPECTION_PHOTO_DAMAGE_NAME,
} = localizedStrings;

const VehiclePhotoRow = ({
  photo,
  approveAllPhotos,
  updatePhoto,
  isGrey,
  readOnly,
  displayImageAnalysis,
  vehicleClass,
  photoModalDisplayed,
  updateVideo,
  videoData,
  drivers,
  showPhotoModal,
}) => {
  const [changeRadioButton, setChangeRadioButton] = useState(false);
  const [changeAIRadioButton, setChangeAIRadioButton] = useState(false);
  const [videoModalDisplayed, setVideoModalDisplayed] = useState(false);

  const {
    rejectedPhotoHistory: { photoHistory },
  } = useSelector(store => store.currentInspection);

  const dispatch = useDispatch();

  const showVideoModal = () => {
    setVideoModalDisplayed(true);
  };

  const hideVideoModal = () => {
    setVideoModalDisplayed(false);
  };

  const onChangeAIRadioButton = event => {
    setChangeAIRadioButton(true);
    const AINewResult = {
      isCorrect: event.target.value,
    };
    updatePhoto({ ...photo, AIResult: AINewResult });
  };

  const onChangeRadioButton = event => {
    setChangeRadioButton(true);
    updatePhoto({ ...photo, status: event.target.value });
  };

  const {
    url,
    name,
    status,
    tags,
    lastDateModified,
    photos: videoPhotos,
    AIResult,
  } = photo;

  const lastDateModifiedVideo = useMemo(
    () => (videoPhotos?.length ? videoPhotos[0].lastDateModified : undefined),
    [videoPhotos],
  );

  const doesImageContainChassisNumber = () =>
    tags?.some(tag => tag.Key === 'DETECTED_CHASSIS_NUMBER');

  const displayImageContainsVehicleIcon =
    displayImageAnalysis && doesImageContainVehicle(tags);

  const displayImageContainsPersonIcon =
    displayImageAnalysis && doesImageContainPerson(tags);

  const displayImageConstainsTextIcon =
    !isProduction() && displayImageAnalysis && doesImageContainChassisNumber();

  let photoDisplayName = getPhotoDisplayStringFromId(vehicleClass, name);

  if (name.includes('DAMAGE_PHOTO')) {
    photoDisplayName = `${INSPECTION_PHOTO_DAMAGE_NAME} ${photo?.id}`;
  }

  if (drivers.length > 0) {
    if (name === 'INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1') {
      photoDisplayName = `${photoDisplayName} ${drivers[0].firstName} ${drivers[0].lastName}`;
    }
    if (name === 'INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2') {
      photoDisplayName = `${photoDisplayName} ${drivers[1].firstName} ${drivers[1].lastName}`;
    }
  }

  const onDisplayPhotoHistoryModal = useCallback(() => {
    dispatch(
      currentInspectionActions.onDisplayPhotoHistoryModal(
        true,
        name,
        vehicleClass,
      ),
    );
  }, [dispatch, name, vehicleClass]);

  const onManagerModal = photo => {
    const { INSPECTION_VIDEO_PHOTOS_VEHICLE } = VIDEO_SECTION;
    if (INSPECTION_VIDEO_PHOTOS_VEHICLE === name) showVideoModal();
    else showPhotoModal(photo);
  };

  const getChassisValueDetected = () => {
    const chassisTag = tags.find(tag => tag.Key === 'DETECTED_CHASSIS_NUMBER');
    return chassisTag?.Value.toUpperCase() || 'N/A';
  };

  const renderPhotoNameLabel = () => (
    <div className="vehicle-photo-row-photo-name-container">
      <div className="vehicle-photo-row-photo-name-label">
        {photoDisplayName}
      </div>
      {name === REQUIRED_PHOTO_IDS.INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER &&
        displayImageConstainsTextIcon && (
          <div className="vehicle-photo-row-photo-name-label-chassis">
            {`${INSPECTION_VEHICLE_CHASSIS_NUMBER_DETECTED_LABEL} ${getChassisValueDetected()}`}
            <RadioGroup
              inline
              name={`vehicle-photo-row-${name}-ai-radio-group`}
              onChange={onChangeAIRadioButton}
              value={
                !changeAIRadioButton && approveAllPhotos
                  ? APPROVED
                  : AIResult?.isCorrect
              }
              className="vehicle-photo-row-radio-group"
              disabled={readOnly}
            >
              <Radio
                id={`vehicle-photo-row-${name}-ai-approve`}
                key={`vehicle-photo-row-${name}-ai-approve`}
                labelVisual={INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_ACCEPT}
                value={APPROVED}
              />
              <Radio
                id={`vehicle-photo-row-${name}-ai-reject`}
                key={`vehicle-photo-row-${name}-ai-reject`}
                labelVisual={INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_REJECT}
                value={REJECTED}
              />
            </RadioGroup>
          </div>
        )}
    </div>
  );

  const renderImage = () => {
    if (photoModalDisplayed) {
      return (
        <>
          <img
            key={1}
            src={url}
            alt={name}
            className="vehicle-photo-row-thumbnail-image"
          />
          <div className="vehicle-photo-row-image-popup">
            <img key={2} src={url} alt={name} />
          </div>
        </>
      );
    }
    return (
      <>
        <img
          key={3}
          src={url}
          alt={name}
          className="vehicle-photo-row-thumbnail-image"
        />
        <div className="vehicle-photo-row-image-popup">
          <img key={4} src={url} alt={name} />
        </div>
      </>
    );
  };

  return (
    <TableRow
      className={classNames({
        'adil-table-row-grey': isGrey,
      })}
    >
      <TableCell type="data" className="vehicle-photo-row-thumbnail-cell">
        <div
          className="vehicle-photo-row-thumbnail"
          onClick={() => onManagerModal(photo)}
          role="button"
        >
          {renderImage()}
        </div>
        {photoHistory && photoHistory[name] && (
          <div className="vehicle-photo-row-photo-history">
            <CirclePlusIcon
              width={80}
              height={80}
              color="rgb(103 217 236 / 77%)"
              onClick={onDisplayPhotoHistoryModal}
            />
          </div>
        )}
      </TableCell>

      <TableCell className="vehicle-photo-row-icons-cell">
        <div className="vehicle-photo-row-icons">
          <div>
            {displayImageContainsVehicleIcon && (
              <IconAuto
                color="success"
                size="16"
                data-tip={INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP}
                title=""
                className="vehicle-photo-row-icon"
              />
            )}
            {displayImageContainsPersonIcon && (
              <IconMyAccount
                color="error"
                size="16"
                data-tip={INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP}
                title=""
                className="vehicle-photo-row-icon"
              />
            )}
            {displayImageConstainsTextIcon && (
              <IconInfo
                color="teal"
                size="16"
                data-tip={INSPECTION_VEHICLE_PHOTOS_IMAGE_TEXT_TOOLTIP}
                title=""
                className="vehicle-photo-row-icon"
              />
            )}
          </div>
          <ReactTooltip type="light" effect="solid" border />
        </div>

        {photo.photos && (
          <VideoModal
            title={INSPECTION_VEHICLE_VIDEO_TITLE}
            isOpen={videoModalDisplayed}
            onCloseModal={hideVideoModal}
            updateVideo={updateVideo}
            videoData={videoData}
            photos={photo.photos}
          />
        )}
      </TableCell>
      <TableCell type="data" dataHeader={INSPECTION_VEHICLE_PHOTOS_HEADER_2}>
        {renderPhotoNameLabel()}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTION_VEHICLE_PHOTOS_HEADER_3}>
        {formatDate(lastDateModified || lastDateModifiedVideo)}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTION_VEHICLE_PHOTOS_HEADER_4}>
        <RadioGroup
          inline
          name={`vehicle-photo-row-${name}-radio-group`}
          onChange={onChangeRadioButton}
          value={!changeRadioButton && approveAllPhotos ? APPROVED : status}
          className="vehicle-photo-row-radio-group"
          disabled={readOnly}
        >
          <Radio
            id={`vehicle-photo-row-${name}-approve`}
            key={`vehicle-photo-row-${name}-approve`}
            labelVisual={INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT}
            value={APPROVED}
          />
          <Radio
            id={`vehicle-photo-row-${name}-reject`}
            key={`vehicle-photo-row-${name}-reject`}
            labelVisual={INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT}
            value={REJECTED}
          />
        </RadioGroup>
      </TableCell>
    </TableRow>
  );
};

VehiclePhotoRow.propTypes = {
  isGrey: PropTypes.bool,
  approveAllPhotos: PropTypes.bool.isRequired,
  photo: photoPropType.isRequired,
  updateVideo: PropTypes.func.isRequired,
  updatePhoto: PropTypes.func.isRequired,
  showPhotoModal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  displayImageAnalysis: PropTypes.bool.isRequired,
  vehicleClass: PropTypes.string.isRequired,
  photoModalDisplayed: PropTypes.bool.isRequired,
  videoData: PropTypes.shape({
    rotation: PropTypes.number,
  }),
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      licenseNumber: PropTypes.string,
    }),
  ).isRequired,
};

VehiclePhotoRow.defaultProps = {
  isGrey: false,
  readOnly: false,
  videoData: {
    rotation: 0,
  },
};

export default VehiclePhotoRow;
