import { Content, Disclaimer, Heading } from '@lmig/lmds-react';
import React from 'react';
import { AppFooter, AppHeader, SideBar } from '../../components';
import BoxOptions from '../../components/BoxOptions/index';
import localizedStrings from '../../utils/localizedStrings';
import { navigate } from '../../utils/NavigationUtils';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const { SUPPORT_TITTLE_PAGE, SUPPORT_SUBTITTLE_PAGE } = localizedStrings;

const optionsSupportArray = [
  {
    id: 1,
    iconName: 'IconHome',
    title: 'Support administrator',
    route: 'ADMIN_SUPPORT',
    description: 'Here, you can modify all of support',
  },
  {
    id: 2,
    iconName: 'IconMyAccount',
    title: 'Parts maintainer',
    route: 'ADMIN_PARTS_MANTEINER',
    description: 'Here, you can modify Parts',
  },
];

const AdminOptions = () => {
  const navigateToPage = route => {
    navigate(route.toLowerCase());
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <div className="adil-content">
          <Content>
            <Heading type="h3-light" className="auto-left-right-margin">
              {SUPPORT_TITTLE_PAGE}
            </Heading>
            <Disclaimer>{SUPPORT_SUBTITTLE_PAGE}</Disclaimer>
            <ul className="BoxOptions">
              {optionsSupportArray.map(({ id, route, title, description }) => (
                <li className="BoxOptions__item" key={id}>
                  <BoxOptions
                    title={title}
                    description={description}
                    route={route}
                    onPress={route => navigateToPage(route)}
                  />
                </li>
              ))}
            </ul>
            {renderFooterSection()}
          </Content>
        </div>
      </SideBar>
    </div>
  );
};

export default AdminOptions;
