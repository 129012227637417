import React from 'react';
import { Button } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { navigate } from '../../utils/NavigationUtils';
import { INSPECTION_INFORM } from '../../utils/navigationConstants';
import { BackIcon, SaveAndExitIcon, SaveIcon } from '.';
import {
  getCountryCode,
  isProduction,
} from '../../config/environments/environmentsHelpers';
import { isValidInLatam, enableRejectButton } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  INSPECTION_APPROVE_BUTTON,
  INSPECTION_REJECT_BUTTON,
  INSPECTION_RETURN_BUTTON,
  INSPECTION_CUSTOMER_RETAKE_BUTTON,
  INSPECTION_SAVE_BUTTON,
  INSPECTION_SAVE_AND_EXIT_BUTTON,
  INSPECTION_DELETE_BUTTON,
  INSPECTION_CONTINUE_ADIL_BUTTON,
  INSPECTION_GET_INFORM_ANALYTICS,
  INSPECTION_CHANGE_PRESENTIAL_BUTTON,
  INSPECTION_CANCEL_BUTTON,
} = localizedStrings;

const InspectionButtons = ({
  buttonsSupportShow,
  disabledButtonsSupportRequest,
  handleContinueADIL,
  handlePassPresential,
  showRetakeButton,
  handleReturn,
  handleSave,
  handleSaveAndExit,
  handleInformAndAnalytics,
  disableSave,
  disableApproveRejectDelete,
  handleDelete,
  handleCancel,
  currentInspection,
  disableCancelButton,
  showDeleteButton,
  showCancelButton,
  handleReject,
  showCustomerRetakeModal,
  handleApprove,
}) => {
  const renderTextButtons = () => (
    <>
      <Button
        type="button"
        level="secondary"
        onClick={handleReturn}
        className="inspection-text-button"
      >
        <BackIcon />
        {INSPECTION_RETURN_BUTTON}
      </Button>
      <Button
        type="button"
        level="secondary"
        onClick={handleSave}
        className="inspection-text-button"
        disabled={
          disableSave ||
          (buttonsSupportShow &&
            isValidInLatam.indexOf(getCountryCode()) !== -1)
        }
      >
        <SaveIcon />
        {INSPECTION_SAVE_BUTTON}
      </Button>
      <Button
        type="button"
        level="secondary"
        onClick={handleSaveAndExit}
        className="inspection-text-button"
        disabled={
          disableSave ||
          (buttonsSupportShow &&
            isValidInLatam.indexOf(getCountryCode()) !== -1)
        }
      >
        <SaveAndExitIcon />
        {INSPECTION_SAVE_AND_EXIT_BUTTON}
      </Button>
    </>
  );

  const renderApproveRejectButtons = () => (
    <>
      {enableRejectButton.includes(getCountryCode()) && (
        <Button
          type="button"
          level="secondary"
          onClick={handleReject}
          disabled={disableApproveRejectDelete}
        >
          {INSPECTION_REJECT_BUTTON}
        </Button>
      )}
      <Button
        type="button"
        onClick={handleApprove}
        disabled={disableApproveRejectDelete}
      >
        {INSPECTION_APPROVE_BUTTON}
      </Button>
    </>
  );

  const renderSupportRequestButtons = () => (
    <>
      <Button
        type="button"
        level="secondary"
        onClick={handleContinueADIL}
        disabled={!disabledButtonsSupportRequest}
      >
        {INSPECTION_CONTINUE_ADIL_BUTTON}
      </Button>
      <Button
        type="button"
        onClick={handlePassPresential}
        disabled={!disabledButtonsSupportRequest}
      >
        {INSPECTION_CHANGE_PRESENTIAL_BUTTON}
      </Button>
    </>
  );

  const renderHandleInformAndAnalytics = () => (
    <Button
      type="button"
      disabled={disableApproveRejectDelete}
      onClick={() => navigate(INSPECTION_INFORM, { status: currentInspection })}
    >
      {INSPECTION_GET_INFORM_ANALYTICS}
    </Button>
  );

  const renderRetakeButton = () => (
    <Button
      type="button"
      onClick={showCustomerRetakeModal}
      disabled={!showRetakeButton}
    >
      {INSPECTION_CUSTOMER_RETAKE_BUTTON}
    </Button>
  );

  const renderDeleteButton = () =>
    showDeleteButton && (
      <Button
        type="button"
        className={disableApproveRejectDelete ? '' : 'inspection-delete-button'}
        onClick={handleDelete}
        disabled={disableApproveRejectDelete}
      >
        {INSPECTION_DELETE_BUTTON}
      </Button>
    );

  const renderCancelButton = () =>
    showCancelButton && (
      <Button
        type="button"
        level="secondary"
        className={disableApproveRejectDelete ? '' : 'inspection-delete-button'}
        onClick={handleCancel}
        disabled={disableCancelButton}
      >
        {INSPECTION_CANCEL_BUTTON}
      </Button>
    );

  const conditionalRenderButtons = () => {
    if (showRetakeButton) {
      return renderRetakeButton();
    }
    if (buttonsSupportShow && isValidInLatam.indexOf(getCountryCode()) !== -1) {
      return renderSupportRequestButtons();
    }
    return renderApproveRejectButtons();
  };

  return (
    <div className="inspection-action-buttons">
      {renderTextButtons()}
      {conditionalRenderButtons()}
      {!buttonsSupportShow && showCancelButton
        ? renderCancelButton()
        : renderDeleteButton()}
      {handleInformAndAnalytics &&
        getCountryCode() === 'ec' &&
        !isProduction() &&
        renderHandleInformAndAnalytics()}
    </div>
  );
};

InspectionButtons.propTypes = {
  disabledButtonsSupportRequest: PropTypes.bool.isRequired,
  buttonsSupportShow: PropTypes.bool.isRequired,
  handleContinueADIL: PropTypes.func.isRequired,
  handlePassPresential: PropTypes.func.isRequired,
  disableApproveRejectDelete: PropTypes.bool.isRequired,
  handleReturn: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
  disableCancelButton: PropTypes.bool.isRequired,
  handleSaveAndExit: PropTypes.func.isRequired,
  handleInformAndAnalytics: PropTypes.bool,
  handleReject: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  currentInspection: PropTypes.shape({}),
  showRetakeButton: PropTypes.bool.isRequired,
  showCustomerRetakeModal: PropTypes.func.isRequired,
  disableSave: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

InspectionButtons.defaultProps = {
  currentInspection: {},
  handleInformAndAnalytics: false,
  disableSave: false,
  showDeleteButton: false,
  handleDelete: () => {
    // Empty function
  },
};

export default InspectionButtons;
