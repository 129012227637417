import React from 'react';
import footerLine from '../../styles/images/footer-line.svg';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { LIBERTY_LOGO_IMAGE_ALT_TEXT, APP_FOOTER_TITLE } = localizedStrings;

const AppFooter = () => (
  <div className="app-footer-container">
    <div className="app-footer-element">
      <img
        src={footerLine}
        className="app-footer-line"
        alt={LIBERTY_LOGO_IMAGE_ALT_TEXT}
      />
    </div>

    <div className="app-footer-element">
      <span className="app-footer-title">{APP_FOOTER_TITLE}</span>
    </div>
  </div>
);

export default AppFooter;
