import { GridRow, GridCol, Button } from '@lmig/lmds-react';
import { IconEmail } from '@lmig/lmds-react/icons';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';
import { customerPropType } from '../../prop-types';
import { InformedAlphanumericInput, Panel, ErrorsList, EmailStatus } from '..';
import { config } from './config';
import { formatFullName, verifyFieldValidation } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import {
  getCountryCode,
  isAsianDomain,
} from '../../config/environments/environmentsHelpers';

const { INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND } = localizedStrings;

const CustomerDetails = ({
  showNotificationModal,
  customer,
  updateFieldValidation,
  updateCustomer,
  emailStatusInfo,
  isLoadingEmailStatus,
  readOnly,
}) => {
  const formatValue = fieldName => {
    if (fieldName === 'fullName') {
      return [
        customer.firstName,
        customer.middleName,
        customer.lastName,
        customer.secondLastName,
      ].join(' ');
    }
    return customer[fieldName];
  };

  const formatUpdatedValue = (value, fieldName) => {
    if (fieldName === 'fullName') {
      const { firstName, lastName } = formatFullName(value);

      return { ...customer, firstName, lastName };
    }
    return { ...customer, [fieldName]: value };
  };

  /**
   * @brief The code defines a function called isNullableOrUndefined that takes
   * a parameter fieldName. The function first gets the value of the fieldName
   * property of the customer object and assigns it to a variable called value.
   *
   * This function is useful when you want to check if a given property of an
   * object has a value or not. In this case, the function returns a boolean
   * value indicating whether the property is nullable or not.
   *
   * @param fieldName The name of the property to verify in the customer object.
   *
   * @returns {boolean} Next, the function checks if value is null or undefined.
   * If it is, the function returns true as this means that the property doesn't
   * have a value. Otherwise, it returns false.
   */
  const isNullableOrUndefined = fieldName => {
    const value = customer[fieldName];
    return value === null || value === undefined;
  };

  const getInformedInput = field => {
    const { fieldName, labelVisual } = field;

    const fieldProps = {
      field: fieldName,
      id: fieldName,
      className: 'customer-details-input',
      labelVisual,
      onChange: event => {
        updateCustomer(formatUpdatedValue(event.target.value, fieldName));
      },
      initialValue: formatValue(fieldName),
      value: formatValue(fieldName),
      validate: value => verifyFieldValidation(value, field),
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      disabled: readOnly,
    };

    if (isNullableOrUndefined(fieldName) && !isAsianDomain()) {
      return null;
    }

    return (
      <GridCol key={`vehicle-details-${fieldName}`}>
        <InformedAlphanumericInput {...fieldProps} />
      </GridCol>
    );
  };

  const handleFieldValidation = formState => {
    updateFieldValidation(Object.values(formState.errors));
  };

  return (
    <Form autoComplete="off" onChange={handleFieldValidation}>
      {({ formState }) => {
        const errors = Object.values(formState.errors);

        return (
          <Panel className="customer-details-section">
            <GridRow justify="start">
              {getCountryCode() === 'vn' && (
                <GridCol base={10}>
                  <GridRow>
                    {config.extra.map(field => getInformedInput(field))}
                  </GridRow>
                </GridCol>
              )}

              <GridCol base={isAsianDomain() ? 10 : 8}>
                <GridRow gutterSize="small">
                  {config.customer.map(field => getInformedInput(field))}
                </GridRow>
              </GridCol>

              {!isAsianDomain() && (
                <GridCol base={2}>
                  <GridRow>
                    <EmailStatus
                      info={emailStatusInfo}
                      isLoading={isLoadingEmailStatus}
                    />
                  </GridRow>
                </GridCol>
              )}
              <GridCol base={2}>
                <Button
                  type="button"
                  level="primary"
                  onClick={showNotificationModal}
                  className="customer-details-resend-button"
                  disabled={errors.length > 0 || readOnly}
                >
                  <span>{INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND}</span>

                  <IconEmail
                    size="24"
                    className="customer-details-resend-button-icon"
                  />
                </Button>
              </GridCol>
            </GridRow>

            <ErrorsList errors={errors} />
          </Panel>
        );
      }}
    </Form>
  );
};

CustomerDetails.propTypes = {
  showNotificationModal: PropTypes.func.isRequired,
  isLoadingEmailStatus: PropTypes.bool.isRequired,
  customer: customerPropType.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  updateFieldValidation: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  emailStatusInfo: PropTypes.shape({
    currentEmail: PropTypes.string.isRequired,
    currentStatus: PropTypes.string.isRequired,
  }),
};

CustomerDetails.defaultProps = {
  readOnly: false,
  emailStatusInfo: null,
};

export default CustomerDetails;
