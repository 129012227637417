import localizedStrings from '../../../utils/localizedStrings';

const {
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_1,
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_2,
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_1,
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_2,
} = localizedStrings;

const frequencyVideoArray = [
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_1,
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_2,
];
const lenghtVideoArray = [
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_1,
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_2,
];

export { frequencyVideoArray, lenghtVideoArray };
