import localizedStrings from '../utils/localizedStrings';

export const getVideoOptions = () => {
  const {
    CREATE_INSPECTION_SELECT_VIDEO_OPTION_1,
    CREATE_INSPECTION_SELECT_VIDEO_OPTION_2,
  } = localizedStrings;

  return [
    CREATE_INSPECTION_SELECT_VIDEO_OPTION_1,
    CREATE_INSPECTION_SELECT_VIDEO_OPTION_2,
  ];
};
