import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { buildQueryStringFromObject } from './build-request';
import { sendServiceCall } from './call-request';
import { restMethods } from './http-methods';

const { GET, POST, PUT, DELETE } = restMethods;

const postRequest = (path, body, enableErrorHandling) =>
  sendServiceCall(path, POST, body, enableErrorHandling);

const getRequest = (path, enableErrorHandling, queryObject = {}) => {
  const pathWithQueryParams = `${path}${buildQueryStringFromObject({
    ...queryObject,
    countryCode: getCountryCode(),
  })}`;

  return sendServiceCall(pathWithQueryParams, GET, null, enableErrorHandling);
};

const putRequest = (path, body, enableErrorHandling) =>
  sendServiceCall(path, PUT, body, enableErrorHandling);

const deleteRequest = (path, queryObject = {}, enableErrorHandling = false) => {
  const pathWithQueryParams = `${path}${buildQueryStringFromObject({
    ...queryObject,
    countryCode: getCountryCode(),
  })}`;

  return sendServiceCall(
    pathWithQueryParams,
    DELETE,
    null,
    enableErrorHandling,
  );
};

export {
  getRequest as get,
  postRequest as post,
  putRequest as put,
  deleteRequest as del,
};
