/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { PlayIcon, SignFineIcon } from '../../Icons';
import './styles.scss';

export const ThumbnailPicture = ({
  url,
  currentIndex,
  photoIndex,
  onChangeIndex,
  styles,
  className,
  label,
  type,
}) => {
  const classNames = ['thumbnail', className.picture].join(' ');
  const isActive = () => currentIndex === photoIndex;

  const isActiveClassName = () => {
    if (isActive()) return 'thumbnail--active';
    return '';
  };

  const getPlayIconComponent = () => (
    <PlayIcon
      height={36}
      width={36}
      className="thumbnail-center-icon"
      onClick={() => onChangeIndex(photoIndex)}
    />
  );

  const isVideo = () => type === 'video';

  return (
    <div
      key={url}
      className={`${classNames} ${isActiveClassName()}`}
      style={{ ...styles.picture }}
    >
      {isVideo() && !isActive() && getPlayIconComponent()}
      {isActive() && <SignFineIcon className="thumbnail-center-icon" />}
      <div className="thumbnail-photo-preview">
        <div>
          <img
            src={url}
            title={label}
            alt={label}
            onClick={() => onChangeIndex(photoIndex)}
          />
        </div>
        <label>{label}</label>
      </div>
    </div>
  );
};

ThumbnailPicture.defaultProps = {
  className: {
    container: undefined,
    picture: undefined,
  },
  styles: {
    container: undefined,
    picture: undefined,
  },
  onChangeIndex: () => {
    // Empty function
  },
};

ThumbnailPicture.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  photoIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.shape({
    container: PropTypes.string,
    picture: PropTypes.string,
  }),
  styles: PropTypes.shape({
    container: PropTypes.shape({}),
    picture: PropTypes.shape({}),
  }),
  onChangeIndex: PropTypes.func,
};
