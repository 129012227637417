import * as React from 'react';
import PropTypes from 'prop-types';
import TableInspectionItems from './TableInspectionItems';
import localizedStrings from '../../utils/localizedStrings';

const {
  MODAL_PRIMARY_BUTTON_LABEL,
  INSPECTION_INFORM_PIECES_INSPECTION,
  INSPECTION_INFORM_CHARACTERS_LIMIT,
  INSPECTION_INFORM_START_DAMAGE_INSPECTIONS,
} = localizedStrings;

export default function PanelInspectionItems({ onValidSubmit }) {
  return (
    <div className="flex flex:1 flex:col gap:0.8rem">
      <h4 className="font-size:1.2rem text:center color:cyan font-weight:bold">
        {INSPECTION_INFORM_PIECES_INSPECTION}
      </h4>

      <TableInspectionItems />

      <div className="flex flex:col">
        <textarea
          onChange={() => {
            // Empty function
          }}
          cols={30}
          rows={3}
          className="border:1px|solid|#CCC color:gray py:0.3rem px:0.4rem r:0.2rem font:sans"
          value="En general se presenta daño propio de un choque"
        />

        <p className="color:#CCCCCC99 font-size:0.7rem px:1rem p:0 m:0 text-align:start">
          {INSPECTION_INFORM_CHARACTERS_LIMIT}
        </p>
      </div>

      <button
        type="button"
        className="flex justify-content:center align-items:center color:#343741 font-size:1.2rem font-weight:bold bg:#CCCCCC99 border:none py:1rem"
      >
        {INSPECTION_INFORM_START_DAMAGE_INSPECTIONS}
      </button>

      <button
        type="button"
        onClick={onValidSubmit}
        className="flex justify-content:center align-items:center color:#343741 font-size:1.2rem font-weight:bold bg:#99E5EA border:none py:1rem"
      >
        {MODAL_PRIMARY_BUTTON_LABEL}
      </button>
    </div>
  );
}

PanelInspectionItems.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
};
