import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tridi from 'react-tridi';
import { IconCamera } from '@lmig/lmds-react';
import { useSelector } from 'react-redux';
import PlayButton from '../PlayButton/PlayButton';
import IconButton from '../../IconButton';
import { MainLoaderContainer } from '../../MainLoader/MainLoaderContainer';
import { MainLoader } from '../../MainLoader/MainLoader';
import './styles.scss';

const PreviewVideo = ({ videoPhotos, onSelectVideoCapture }) => {
  const tridiRef = useRef(null);
  const [imagesList, setImageList] = useState([]);
  const [indexPhoto, setIndexPhoto] = useState(1);

  const {
    inspectionInform: { loadingGallery },
  } = useSelector(store => store);

  const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);
  const onPlay = () => {
    tridiRef.current.toggleAutoplay(!isAutoPlayRunning);
  };
  const currentPhoto = useMemo(() => videoPhotos[indexPhoto], [
    indexPhoto,
    videoPhotos,
  ]);

  useEffect(() => {
    const array = videoPhotos.map(item => item.url);
    setImageList(array);
  }, [videoPhotos]);

  return (
    <div className="preview-video">
      {loadingGallery && (
        <MainLoaderContainer>
          <MainLoader />
        </MainLoaderContainer>
      )}
      <PlayButton isPlayRunning={isAutoPlayRunning} onClick={onPlay} />

      {!currentPhoto.isAdded && (
        <IconButton
          simple
          color="inverse"
          background="round"
          className="camera-icon circle-icon"
          onClick={() => onSelectVideoCapture(currentPhoto)}
          IconComponent={IconCamera}
        />
      )}
      <Tridi
        ref={tridiRef}
        images={imagesList}
        autoplaySpeed={300}
        onFrameChange={index => setIndexPhoto(index)}
        onAutoplayStart={() => setIsAutoPlayRunning(true)}
        onAutoplayStop={() => setIsAutoPlayRunning(false)}
      />
    </div>
  );
};

PreviewVideo.propTypes = {
  onSelectVideoCapture: PropTypes.func.isRequired,
  videoPhotos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      isAdded: PropTypes.bool,
    }).isRequired,
  ).isRequired,
};

export default PreviewVideo;
