import {
  FIELD_NAMES,
  FIELD_REGEX,
} from '@lmig-latam/adil-api-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import { getCurrentLanguage } from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import localizedStrings from '../../utils/localizedStrings';

const { CUSTOMER, CELL_PHONE_NUMBER, EMAIL, CAMERA, SMS_PING } = FIELD_NAMES;
const { alphaNumericSymbolOnly } = FIELD_REGEX;

const {
  INSPECTION_RESEND_USERNAME,
  INSPECTION_SUMMARY_LOCATION,
} = localizedStrings;
const {
  INSPECTION_VEHICLE_DETAILS_VALIDATION_ALPHANUMERICSYMBOL_ONLY,
} = getCurrentLanguage();

const FIELD_CONFIG = getCountryConfig(getCountryCode()).getFieldConfig();

export const fieldConfig = {
  [CUSTOMER]: [
    FIELD_CONFIG[CUSTOMER][CELL_PHONE_NUMBER],
    FIELD_CONFIG[CUSTOMER][EMAIL],
    FIELD_CONFIG[CUSTOMER][CAMERA],
    FIELD_CONFIG[CUSTOMER][SMS_PING],
  ],
};

if (getCountryCode() === 'vn') {
  fieldConfig.extra = [
    {
      fieldName: 'fullName',
      fieldType: alphaNumericSymbolOnly,
      fieldTypeError: INSPECTION_VEHICLE_DETAILS_VALIDATION_ALPHANUMERICSYMBOL_ONLY,
      labelVisual: INSPECTION_RESEND_USERNAME,
      maxLength: 100,
      required: true,
      updatable: true,
    },
    {
      fieldName: 'location',
      fieldType: '',
      fieldTypeError: INSPECTION_VEHICLE_DETAILS_VALIDATION_ALPHANUMERICSYMBOL_ONLY,
      labelVisual: INSPECTION_SUMMARY_LOCATION,
      maxLength: 200,
      updatable: true,
    },
  ];
}

Object.keys(fieldConfig).forEach(fieldSection => {
  fieldConfig[fieldSection] = fieldConfig[fieldSection].filter(
    field => typeof field !== 'undefined',
  );
});

export const config = fieldConfig;

export default config;
