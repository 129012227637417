export const ROOT = '/';
export const DEFAULT = '/app';
export const INSPECTION = '/app/inspection';
export const CREATE_INSPECTION = '/app/create_inspection';
export const ADMIN_OPTIONS = '/app/admin_options';
export const ADMIN_SUPPORT = '/app/admin_support';
export const ADMIN_PARTS_MANTEINER = '/app/admin_parts_manteiner';
export const ERROR_GENERIC = '/app/error';
export const INSPECTION_COMPLETE = '/app/complete';
export const INSPECTION_INFORM = '/app/inspections_inform';
export const SEARCH = '/app/search';
export const REPORT = '/app/report';
