import history from './history';
import { logcodes, logger } from './logger';

export const navigate = (newPath, state) => {
  const {
    location: { pathname },
    push,
  } = history;

  logger.log(logcodes.AANAV010, { from: pathname, to: newPath });
  window.scrollTo(0, 0);
  push(newPath, state);
};

export default {
  navigate,
};
