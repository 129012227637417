import React from 'react';
import PropTypes from 'prop-types';
import localizedStrings from '../../utils/localizedStrings';
import CircleLoading from '../CircleLoading/CircleLoading';
import { EmailIcon } from '../Icons';
import './styles.scss';

const {
  INSPECTION_CUSTOMER_DETAILS_EMAIL_PRECESSED_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DELIVERED_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BOUNCED_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DROPPED_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DIFERRED_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BLOCKED_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_UNKNOWN_STATUS,
  INSPECTION_CUSTOMER_DETAILS_EMAIL_STATUS_LABEL,
} = localizedStrings;

const getStatusEmailInfo = key => {
  const result = {
    processed: INSPECTION_CUSTOMER_DETAILS_EMAIL_PRECESSED_STATUS,
    delivered: INSPECTION_CUSTOMER_DETAILS_EMAIL_DELIVERED_STATUS,
    bounce: INSPECTION_CUSTOMER_DETAILS_EMAIL_BOUNCED_STATUS,
    dropped: INSPECTION_CUSTOMER_DETAILS_EMAIL_DROPPED_STATUS,
    deferred: INSPECTION_CUSTOMER_DETAILS_EMAIL_DIFERRED_STATUS,
    blocked: INSPECTION_CUSTOMER_DETAILS_EMAIL_BLOCKED_STATUS,
  };
  return result[key];
};

const getColor = key => {
  const result = {
    processed: 'blue',
    delivered: 'green',
    bounce: 'red',
    dropped: 'red',
    deferred: 'blue',
    blocked: 'red',
  };
  if (!result[key]) return null;

  return {
    color: `email-status-color-${result[key]}`,
    border: `email-status-border-${result[key]}`,
    icon: `email-status-icon-${result[key]}`,
  };
};

const EmailStatus = ({ info, isLoading }) => {
  const className = getColor(info?.currentStatus);
  return (
    <div className={['email-status', className?.border].join(' ')}>
      <div className="email-status-container">
        <div className={['email-status-text', className?.color].join(' ')}>
          <span>{INSPECTION_CUSTOMER_DETAILS_EMAIL_STATUS_LABEL}</span>
        </div>

        <div className="email-status-icon">
          {isLoading ? (
            <CircleLoading
              className={[className?.color, 'email-status-loading'].join(' ')}
            />
          ) : (
            <EmailIcon className={className?.icon} />
          )}
        </div>
        <div className="email-status-label">
          <label className={className?.color}>
            {getStatusEmailInfo(info?.currentStatus) ||
              INSPECTION_CUSTOMER_DETAILS_EMAIL_UNKNOWN_STATUS}
          </label>
        </div>
      </div>
    </div>
  );
};

EmailStatus.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  info: PropTypes.shape({
    currentEmail: PropTypes.string.isRequired,
    currentStatus: PropTypes.string.isRequired,
  }),
};

EmailStatus.defaultProps = {
  info: null,
};

export default EmailStatus;
