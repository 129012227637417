import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getLanguageForCountryCode } from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import localizedStrings from '../../utils/localizedStrings';
import inspectionImage from '../../assets/images/inspection_icon_inform.png';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

export const InspectionBox = () => {
  const {
    FORM_INFORM_ANALYSIS_INSPECTION_NAME_CUSTOMER,
    FORM_INFORM_ANALYSIS_INSPECTION_NUMBER,
    FORM_INFORM_ANALYSIS_INSPECTION_EMAIL_CUSTOMER,
    FORM_INFORM_ANALYSIS_INSPECTION_PLATE,
    FORM_INFORM_ANALYSIS_INSPECTION_VEHICULE_TYPE,
    FORM_INFORM_ANALYSIS_INSPECTION_MODULE,
    FORM_INFORM_ANALYSIS_INSPECTION_PLACE,
    FORM_INFORM_ANALYSIS_INSPECTION_DATE,
    FORM_INFORM_ANALYSIS_INSPECTION_CITY_OR_REGION,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY,
  } = localizedStrings;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const strings = getLanguageForCountryCode(getCountryCode().toLowerCase());

  const [optionClassVehicles] = useState(
    strings.INSPECTION_DROPDOWN_DEFAULT_VALUES?.class || [],
  );
  return (
    <div className="formInform">
      <div className="formInform__icon">
        <img src={inspectionImage} alt={inspectionImage} />
      </div>
      <div className="formInform__inputs">
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_NAME_CUSTOMER}
            {...register('nameCustomer', {
              required: true,
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.nameCustomer ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.nameCustomer && errors.nameCustomer.type === 'pattern' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
            </p>
          )}
          {errors.nameCustomer && errors.nameCustomer.type === 'required' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
            </p>
          )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_NUMBER}
            disabled
            {...register('inspectionNumber', {
              required: true,
              pattern: {
                value: /^[\u0E00-\u0E7Fa-zA-ZÀ-ÿ0-9àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\-_,'.:$%#&()/\\ ]*$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.inspectionNumber ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.inspectionNumber &&
            errors.inspectionNumber.type === 'pattern' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY}
              </p>
            )}
          {errors.inspectionNumber &&
            errors.inspectionNumber.type === 'required' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
              </p>
            )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_EMAIL_CUSTOMER}
            disabled
            {...register('emailCustomer', {
              required: true,
            })}
            className={`inputs_rhf_adil ${
              errors.emailCustomer ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_PLATE}
            disabled
            {...register('plateInspection', {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9 -]*$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.plateInspection ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.plateInspection &&
            errors.plateInspection.type === 'pattern' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
              </p>
            )}
          {errors.plateInspection &&
            errors.plateInspection.type === 'required' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
              </p>
            )}
        </div>
        <div className="formInform__inputs__individual">
          <select
            name="vehicleType"
            defaultValue="default"
            {...register('vehicleType')}
            className={`inputs_rhf_adil ${
              errors.vehicleType ? 'validation-input-field-error' : ''
            }`}
          >
            <option value="default" disabled="disabled">
              {FORM_INFORM_ANALYSIS_INSPECTION_VEHICULE_TYPE}
            </option>
            {optionClassVehicles.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_MODULE}
            {...register('inspectionModule', {
              required: true,
              pattern: {
                value: /^[a-zA-ZáéíóúÁÉÍÓÚüÜ\- 0-9]*$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.inspectionModule ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.inspectionModule &&
            errors.inspectionModule.type === 'pattern' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
              </p>
            )}
          {errors.inspectionModule &&
            errors.inspectionModule.type === 'required' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
              </p>
            )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_PLACE}
            {...register('inspectionPlace', {
              required: true,
              pattern: {
                value: /^[a-zA-ZáéíóúÁÉÍÓÚüÜ.\-#, 0-9 ]+$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.inspectionPlace ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.inspectionPlace &&
            errors.inspectionPlace.type === 'pattern' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
              </p>
            )}
          {errors.inspectionPlace &&
            errors.inspectionPlace.type === 'required' && (
              <p className="error-message--forms">
                {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
              </p>
            )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            {...register('inspectionDate')}
            className={`inputs_rhf_adil ${
              errors.inspectionDate ? 'validation-input-field-error' : ''
            }`}
            type="text"
            onFocus={e => {
              e.currentTarget.type = 'date';
              e.currentTarget.focus();
            }}
            onBlur={e => {
              e.currentTarget.type = 'text';
              e.currentTarget.blur();
            }}
            placeholder={FORM_INFORM_ANALYSIS_INSPECTION_DATE}
          />
        </div>
        <div className="formInform__inputs__individual">
          <select
            defaultValue="default"
            name="inspectionCityRegion"
            {...register('inspectionCityRegion')}
            className={`inputs_rhf_adil ${
              errors.inspectionCityRegion ? 'validation-input-field-error' : ''
            }`}
          >
            <option value="default" disabled="disabled">
              {FORM_INFORM_ANALYSIS_INSPECTION_CITY_OR_REGION}
            </option>
            {optionClassVehicles.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
