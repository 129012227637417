import {
  ONCHANGE_CURRENT_LOCATION_PAGE,
  ONCHANGE_SEARCH_TEXT_INSPECTIONS,
  ONCHANGE_SEARCH_TEXT,
  MONTH_AND_YEAR_SELECTED,
  SELECT_STEP_FILTER_SEARCH,
  SET_COUNTS_INSPECTIONS,
  RESET_FILTER_STEPS,
  RESET_ALL,
  ONLY_VENDOR_SELECTED,
  ON_DISPLAY_SUPPORT_REQUEST_DIALOG,
  SET_FINISHED_INSPECTIONS_QUERY,
} from './constants';

const setStepFilter = filter => ({
  type: SELECT_STEP_FILTER_SEARCH,
  payload: { filter },
});

const resetFilterSteps = () => ({
  type: RESET_FILTER_STEPS,
});

const setCountInspections = count => ({
  type: SET_COUNTS_INSPECTIONS,
  payload: { count },
});

const setMonthAndYearSelected = date => ({
  type: MONTH_AND_YEAR_SELECTED,
  payload: date,
});

const onChangeSearchTextInspections = text => ({
  type: ONCHANGE_SEARCH_TEXT_INSPECTIONS,
  payload: { text },
});

const onChangeSearchText = text => ({
  type: ONCHANGE_SEARCH_TEXT,
  payload: { text },
});

const onChangeCurrentLocationPage = location => ({
  type: ONCHANGE_CURRENT_LOCATION_PAGE,
  payload: { location },
});

const resetAll = () => ({
  type: RESET_ALL,
});

const setOnlyVendorSelected = onlyVendor => ({
  type: ONLY_VENDOR_SELECTED,
  payload: { onlyVendor },
});

const onDisplaySupportRequestDialog = (isOpen, supportRequest) => ({
  type: ON_DISPLAY_SUPPORT_REQUEST_DIALOG,
  payload: { displaySupportRequestDialog: isOpen, supportRequest },
});

const setFinishedInspectionsQuery = query => ({
  type: SET_FINISHED_INSPECTIONS_QUERY,
  payload: { query },
});

export default {
  setStepFilter,
  resetFilterSteps,
  setCountInspections,
  onChangeSearchTextInspections,
  onChangeSearchText,
  setMonthAndYearSelected,
  onChangeCurrentLocationPage,
  resetAll,
  setOnlyVendorSelected,
  onDisplaySupportRequestDialog,
  setFinishedInspectionsQuery,
};
