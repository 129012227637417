import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Panel = props => {
  const { children, className } = props;

  return (
    <div
      {...props}
      className={classNames('adil-panel', 'adil-box-shadow', className)}
    >
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Panel.defaultProps = {
  className: '',
};

export default Panel;
