import { Caption, Link, IconButton } from '@lmig/lmds-react';
import {
  IconAdd,
  IconCaretRight,
  IconCaretLeft,
  IconLogIn,
  IconMyAccount,
  IconSearch,
  IconDocument,
} from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SettingsActions } from '../../actions';
import {
  getCountryCode,
  PropertyTypes as EnvironmentPropertyTypes,
} from '../../config/environments/environmentsHelpers';
import { SearchActions } from '../../redux/search-inspection';
import localizedStrings from '../../utils/localizedStrings';
import {
  CREATE_INSPECTION,
  REPORT,
  SEARCH,
  ADMIN_OPTIONS,
} from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import './styles.scss';
import Vigilado from './vigilado.svg';

const {
  SIDEBAR_ITEM_1,
  SIDEBAR_ITEM_2,
  SIDEBAR_ITEM_3,
  SIDEBAR_ITEM_4,
  SIDEBAR_ITEM_5,
  SIDEBAR_ITEM_6,

  VIGILADO_IMAGE_ALT_TEXT,
} = localizedStrings;

const SideBar = ({
  environment,
  settingsActions: { toggleSidebar },
  isCreator,
  children,
  sidebarOpen,
  isAdmin,
  isViewer,
}) => {
  const dispatch = useDispatch();

  const navigateToSearchScreen = () => {
    dispatch(SearchActions.resetFilterSteps());
    navigate(SEARCH);
  };

  const navigateToManageAccount = () => {
    const { manageAccountUrl } = environment;
    window.open(manageAccountUrl, '_blank');
  };

  const navigateToChangePassword = () => {
    const { changePasswordUrl } = environment;
    window.open(changePasswordUrl, '_blank');
  };

  const navigateToCreateInspection = () => {
    navigate(CREATE_INSPECTION);
  };

  const navigateToAdminOptions = () => {
    navigate(ADMIN_OPTIONS);
  };

  const navigateToReport = () => {
    navigate(REPORT);
  };

  const renderSidebarClosed = () => (
    <div className="sidebar sidebar-closed">
      <IconButton
        className="sidebar-button"
        variant="brand"
        onClick={toggleSidebar}
      >
        <IconCaretRight size="16" />
      </IconButton>
    </div>
  );

  const renderSidebarOpen = () => (
    <div className="sidebar sidebar-open">
      <IconButton
        className="sidebar-button"
        variant="brand"
        onClick={toggleSidebar}
      >
        <IconCaretLeft size="16" />
      </IconButton>
      <div className="sidebar-contents">
        {!isCreator && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            caretPosition="none"
            onClick={navigateToSearchScreen}
            variant="standalone"
            onBackground="blue"
          >
            <IconSearch
              size="16"
              style={{
                marginRight: '0.5rem',
              }}
            />
            <Caption>{SIDEBAR_ITEM_1}</Caption>
          </Link>
        )}
        {(isCreator || isAdmin || (isViewer && getCountryCode() === 'cl')) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            caretPosition="none"
            onClick={navigateToCreateInspection}
            variant="standalone"
            onBackground="blue"
          >
            <IconAdd
              size="16"
              style={{
                marginRight: '0.5rem',
              }}
            />
            <Caption>{SIDEBAR_ITEM_4}</Caption>
          </Link>
        )}
        {(isAdmin || (isViewer && getCountryCode() !== 'cl')) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            caretPosition="none"
            onClick={navigateToReport}
            variant="standalone"
            onBackground="blue"
          >
            <IconDocument
              size="16"
              style={{
                marginRight: '0.5rem',
              }}
            />
            <Caption>{SIDEBAR_ITEM_5}</Caption>
          </Link>
        )}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          caretPosition="none"
          onClick={navigateToManageAccount}
          variant="standalone"
          onBackground="blue"
        >
          <IconMyAccount
            size="16"
            style={{
              marginRight: '0.5rem',
            }}
          />
          <Caption>{SIDEBAR_ITEM_2}</Caption>
        </Link>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          caretPosition="none"
          onClick={navigateToChangePassword}
          variant="standalone"
          onBackground="blue"
        >
          <IconLogIn
            size="16"
            style={{
              marginRight: '0.5rem',
            }}
          />
          <Caption>{SIDEBAR_ITEM_3}</Caption>
        </Link>
        {isAdmin && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            caretPosition="none"
            onClick={navigateToAdminOptions}
            variant="standalone"
            onBackground="blue"
          >
            <IconMyAccount
              size="16"
              style={{
                marginRight: '0.5rem',
              }}
            />
            <Caption>{SIDEBAR_ITEM_6}</Caption>
          </Link>
        )}
      </div>
    </div>
  );

  const { vigiladoLogoDisplayed } = environment[getCountryCode()];

  return (
    <div className="sidebar-wrapper">
      <div>
        {sidebarOpen ? renderSidebarOpen() : renderSidebarClosed()}
        {vigiladoLogoDisplayed && (
          <img
            src={Vigilado}
            alt={VIGILADO_IMAGE_ALT_TEXT}
            className="sidebar-vigilado-logo"
          />
        )}
      </div>
      {children}
    </div>
  );
};

SideBar.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  environment: EnvironmentPropertyTypes.isRequired,
  children: PropTypes.node.isRequired,
  isCreator: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isViewer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  settings: { environment, sidebarOpen },
  user: { isCreator, isReviewer, isAdmin, isViewer },
}) => ({
  isCreator,
  isReviewer,
  environment,
  sidebarOpen,
  isAdmin,
  isViewer,
});

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
