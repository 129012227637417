import constants from '@lmig-latam/adil-api-common-lib/constants';

const {
  LANGUAGES: { VN },
} = constants;

const code = VN;

const strings = {
  BROWSER_TAB_TITLE: 'Inspections  – Liberty Insurance',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Liberty Insurance',
  VIGILADO_IMAGE_ALT_TEXT: 'VIGILADO SUPERINTENDENCIA BANCARIA DE COLOMBIA',

  APP_HEADER_TITLE: 'Integrated Inspections Module',
  APP_HEADER_USER_SECTION_LOGOUT: 'Logout',
  OPERATING_COUNTRY_IMAGE_ALT_TEXT: 'English Flag',

  APP_FOOTER_TITLE: 'Liberty 2024. All rights reserved.',

  SIDEBAR_ITEM_1: 'Inspections',
  SIDEBAR_ITEM_2: 'Manage Account',
  SIDEBAR_ITEM_3: 'Change Password',
  SIDEBAR_ITEM_4: 'Create Inspection',
  SIDEBAR_ITEM_5: 'Inspection report',
  SIDEBAR_ITEM_6: 'Administrators',

  FORM_INFORM_ANALYSIS_CORREDOR_NAME: 'Name of the broker',
  FORM_INFORM_ANALYSIS_CORREDOR_CODE: 'Corridor code',
  FORM_INFORM_ANALYSIS_INSPECTOR_USER: 'Inspector user',
  FORM_INFORM_ANALYSIS_INSPECTOR_NAME: 'Inspector name',
  FORM_INFORM_ANALYSIS_INSPECTOR_SUPPLIER: 'Supplier',
  FORM_INFORM_ANALYSIS_INSPECTION_NAME_CUSTOMER: 'Customer name',
  FORM_INFORM_ANALYSIS_INSPECTION_NUMBER: 'Inspection number',
  FORM_INFORM_ANALYSIS_INSPECTION_EMAIL_CUSTOMER: 'Customer email',
  FORM_INFORM_ANALYSIS_INSPECTION_PLATE: 'Patent',
  FORM_INFORM_ANALYSIS_INSPECTION_VEHICULE_TYPE: 'Vehicle type',
  FORM_INFORM_ANALYSIS_INSPECTION_MODULE: 'Inspection model',
  FORM_INFORM_ANALYSIS_INSPECTION_PLACE: 'Inspection place',
  FORM_INFORM_ANALYSIS_INSPECTION_DATE: 'Inspection Date',
  FORM_INFORM_ANALYSIS_INSPECTION_CITY_OR_REGION: 'City or region',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS: 'No special characters or numbers are displayed',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY: 'No special characters are displayed',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY: 'Only numeric characters are accepted',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED: 'This field is required',

  SUPPORT_TITTLE_PAGE: 'Administrators',
  SUPPORT_SUBTITTLE_PAGE: 'Choose the option you want to manage.',
  ADMIN_SUPPORT_PAGE_TROUBLES: 'Support Request Manager - Issues',
  ADMIN_SUPPORT_PAGE_SOLUTIONS: 'Support Request Manager - Solutions',
  ADMIN_PIECES_MANTEINER: '',
  ADMIN_PARTS_MANTEINER: '',
  ADMIN_SUPPORT_ADD: 'ADD',
  ADMIN_SUPPORT_MODIFY: 'MODIFY',
  ADMIN_SUPPORT_BUTTON_VISUALIZE: 'VISUALIZE',
  ADMIN_SUPPORT_ADD_TROUBLE: 'Add problem',
  ADMIN_SUPPORT_ADD_PIECE: '',
  ADMIN_SUPPORT_ADD_PART: '',
  ADMIN_SUPPORT_SELECT_PART: '',
  ADMIN_SUPPORT_ADD_TROUBLE_SORT: 'Add order',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE: 'Status',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION: 'Enable',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION: 'Disable',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT: 'Active',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT: 'Inactive',
  ADMIN_SUPPORT_ADD_SOLUTION: 'Add solution',
  ADMIN_SUPPORT_TABLE_HEAD_1: 'Issues',
  ADMIN_SUPPORT_TABLE_HEAD_1_SOLUTIONS: 'Solutions',
  ADMIN_SUPPORT_TABLE_HEAD_2: 'Order',
  ADMIN_SUPPORT_TABLE_HEAD_3: 'Status',
  ADMIN_SUPPORT_TABLE_HEAD_4: 'Administrator',
  ADMIN_SUPPORT_TABLE_HEAD_5: 'Creation date',
  ADMIN_SUPPORT_TABLE_HEAD_6: 'Last modified date',
  ADMIN_SUPPORT_TABLE_DELETE: 'Delete',
  ADMIN_SUPPORT_TABLE_UPDATE: 'Update',
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: 'Something has happened!',
  ADMIN_SUPPORT_ERROR_GENERAL: 'It was not possible to complete this action',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION: 'Are you sure you want to delete it?',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT: 'Deleted option',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE: 'You have successfully removed the option',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE: 'It was not possible to remove the option',
  ADMIN_SUPPORT_DELETE_YES: 'Accept',
  ADMIN_SUPPORT_DELETE_NO: 'Cancel',
  COUNTER_PAGES: 'Pages:',
  ADMIN_SUPPORT_TROUBLE_PREVISUALIZATE: 'Preview problems:',
  ADMIN_SUPPORT_TROUBLE_5_PROBLEMS: 'It is only possible to add 5 records related to problems.',

  ADMIN_PIECES_MANTEINER_TABLE_HEAD_1: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_2: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_3: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_4: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_5: '',

  LOADING_TEXT: 'Loading',

  ERROR_MESSAGE_NUMBER_ONLY: 'The field must be numeric',
  SUPPORT_INSPECTION_TROUBLES: 'Reported problems:',
  SUPPORT_INSPECTION_TROUBLES_ADITIONAL: 'Additional information',
  SUPPORT_INSPECTION_SOLUTIONS: 'Solution:',
  SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL: 'Additional Solution Comments',
  SUPPORT_INSPECTION_SOLUTIONS_USER: 'Additional User Comments',
  SUPPORT_INSPECTION_SOLUTIONS_RESOLUTOR: 'Resolver',
  SUPPORT_INSPECTION_BINNACLE: 'View log',
  SUPPORT_INSPECTION_BINNACLE_MODAL: 'Support log',
  SUPPORT_INSPECTION_BINNACLE_SELECT: 'Select a request:',
  SUPPORT_INSPECTION_BINNACLE_SELECT_OPTION: 'Support request - ',
  SUPPORT_INSPECTION_MODAL_TITLE: 'Support request',

  ERROR_MESSAGE_TITLE: 'Sorry, the process has already started or does not work correctly',
  ERROR_MESSAGE_SUBTITLE: 'If you need help, you can contact us through our service channels.',
  ERROR_MESSAGE_ACCESS_DENIED_TITLE: 'Access denied',
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE: "You do not have access to this application's inspections. Have you logged in with the correct account?",

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404: Page not found!',

  GPS_PERMISSION_DENIED: 'Permission denied',
  GPS_PERMISSION_ENABLED: 'Permission enabled',

  MODAL_PRIMARY_BUTTON_LABEL: 'Continue',

  UTILS_AGE_SINCE_YEARS_AGO: '{interval} years ago',
  UTILS_AGE_SINCE_MONTHS_AGO: '{interval} months ago',
  UTILS_AGE_SINCE_DAYS_AGO: '{interval} days ago',
  UTILS_AGE_SINCE_HOURS_AGO: '{interval} hours ago',
  UTILS_AGE_SINCE_MINUTES_AGO: '{interval} minutes ago',
  UTILS_AGE_SINCE_SECONDS_AGO: '{interval} seconds ago',

  LOGIN_TITLE: 'Log In',
  LOGIN_SUBTITLE: 'Click the button below to sign in to ADIL',

  INSPECTIONS_TABLE_HEADER_NUMBER: 'POLICY N°.',
  INSPECTIONS_TABLE_HEADER_PLATE: 'NUMBER PLATE',
  INSPECTIONS_TABLE_HEADER_CHASSIS_NUMBER: 'CHASSIS N°.',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION_TYPE: 'TYPE OF IDENFITICATION',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION: 'IDENFITICATION',
  INSPECTIONS_TABLE_HEADER_NAME: 'NAME',
  INSPECTIONS_TABLE_HEADER_ACTION: 'ACTION',
  INSPECTIONS_TABLE_HEADER_STATUS: 'Status',
  INSPECTIONS_TABLE_ACTION_BUTTON: 'REVIEW',
  INSPECTIONS_TABLE_HEADER_CREATED_BY: 'CREATED BY',
  INSPECTIONS_TABLE_HEADER_CREATED_ON: 'CREATED ON',
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE: 'APPROVED DATE',
  INSPECTIONS_TABLE_HEADER_ASSIGNEE: 'ASSIGNEE',
  INSPECTIONS_TABLE_HEADER_BROKER_NAME: 'BROKER NAME',
  INSPECTIONS_TABLE_HEADER_BROKER_CODE: 'BROKER CODE',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO: 'PHOTO DATE',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE: 'RETURN DATE',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW: 'SUPPORT REQUEST DATE',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_TOOLTIP: 'Inspection with attached photographs',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT: 'PHOTO ALERT',
  INSPECTIONS_TABLE_ROW_SUPPORT_REQUEST_ALERT_TOOLTIP: 'Inspection with support request',

  INSPECTIONS_TABLE_REPORT_ADIL_TICKET: 'ADIL ticket',
  INSPECTIONS_TABLE_REPORT_STATUS: 'Status',
  INSPECTIONS_TABLE_REPORT_PLATE_NUMBER: 'Plate number',
  INSPECTIONS_TABLE_REPORT_CREATOR_NAME: 'Creator name',
  INSPECTIONS_TABLE_REPORT_CREATED_DATE: 'Creator date',
  INSPECTIONS_TABLE_REPORT_CUSTOMER_ADDRESS: 'Customer address',
  INSPECTIONS_TABLE_REPORT_INSPECTION_USER: 'Inspetion user',
  INSPECTIONS_TABLE_REPORT_INSPECTION_DATE: 'Inspection date',
  INSPECTIONS_TABLE_REPORT_APPROVED_USER: 'Approved user',
  INSPECTIONS_TABLE_REPORT_APPROVED_DATE: 'Approved date',
  REPORT_TABLE_ASIAN_NO_RESULTS: 'No results found',

  INSPECTION_SUMMARY_SUBMISSION_ID: 'Policy No.',
  INSPECTION_SUMMARY_LICENCE_PLATE: 'License Plate',
  INSPECTION_SUMMARY_ID_TYPE: 'ID type',
  INSPECTION_SUMMARY_ID_NUMBER: 'ID Number',
  INSPECTION_SUMMARY_USER_NAME: 'Name of taker',
  INSPECTION_SUMMARY_CREATED_BY: 'Created by',
  INSPECTION_SUMMARY_CREATION_DATE: 'Created on',
  INSPECTION_SUMMARY_STATUS: 'Status',
  INSPECTION_SUMMARY_CUSTOMER_COMPLETION_DATE: 'Photo date',
  INSPECTION_SUMMARY_COMPLETION_DATE: 'Approved date',
  INSPECTION_SUMMARY_POLICY_TYPE: 'Policy type',
  INSPECTION_SUMMARY_LOCATION: 'Location',
  INSPECTION_RESEND_USERNAME: '',

  FINISHED_SELECT_FIELD: 'Searching by:',
  FINISHED_SELECT_INPUT_VALUE: 'Enter the value to search:',
  SEARCH_FINISHED: 'Search',
  SEARCH_TITLE: 'Inspections',
  SEARCH_SUBTITLE: 'Manages, updates and performs vehicle inspections photographic waiting for your policy',
  SEARCH_LOADING_LABEL: 'Please wait...',
  SEARCH_DUPLICATED_SUBMISSIONID_LABEL: 'Verifying duplicate policy number. Please wait...',
  SEARCH_CUSTOMER_INFO_EIS_LABEL: 'Searching information in EIS. Please wait...',

  SEARCH_BAR_TABS_PENDING_REVIEW: 'Pending for review',
  SEARCH_BAR_TABS_PENDING_APPROVAL: 'Pending completion',
  SEARCH_BAR_TABS_FINISHED: 'Completed',
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER: 'Pending customer action',
  SEARCH_BAR_TABS_PENDING_PER_SUPPORT: 'Pending by seller',
  SEARCH_BAR_TABS_SUPPORT_REQUEST: 'Support Request',
  SEARCH_BAR_INPUT_PLACEHOLDER: 'Search by number plate or identification...',
  SEARCH_BAR_SEARCH_ACTIVE_NOTIFICATION: 'The search bar will filter only statuses other than "Completed". To search for completed inspections, please select the respective tab.',
  SEARCH_BAR_SEARCH_COMPLETED_NOTIFICATION: 'The search bar will filter only statuses equal to "Completed". To search for inspections with pending status, please select a tab other than "Completed".',

  SEARCH_NO_RESULTS_TITLE: "Oops, sorry we have not found what you're looking for",
  SEARCH_NO_RESULTS_SUBTITLE: 'Verify and try again',
  FINISHED_SEARCH_NO_RESULTS_TITLE: 'No records found with these values',
  FINISHED_SEARCH_NO_RESULTS_SUBTITLE: 'Please, try again.',

  INSPECTION_TITLE: 'Inspection',
  INSPECTION_SWITCH_ENABLE_ENGLISH_EMAILS: 'Announcement in English',
  INSPECTION_SUBTITLE: 'Make the inspection of the vehicle by means of the photographs sent by the client, do not forget the comments and attach the necessary supports',
  INSPECTION_LOADING_LABEL: 'Please wait...',
  INSPECTION_APPROVE_BUTTON: 'Approve Photos',
  INSPECTION_CONTINUE_ADIL_BUTTON: 'Continue in adil',
  INSPECTION_GET_INFORM_ANALYTICS: 'Report and analysis',
  INSPECTION_CHANGE_PRESENTIAL_BUTTON: 'Go to face-to-face',
  INSPECTION_REJECT_BUTTON: 'Reject',
  INSPECTION_RETURN_BUTTON: 'Return',
  INSPECTION_CUSTOMER_RETAKE_BUTTON: 'Request a new photo shoot',
  INSPECTION_SAVE_BUTTON: 'Save',
  INSPECTION_SAVE_AND_EXIT_BUTTON: 'Save and exit',
  INSPECTION_DELETE_BUTTON: 'Delete',
  INSPECTION_CANCEL_BUTTON: 'Cancel',
  INSPECTION_PHOTO_UPLOAD_IMAGES: 'Upload Images',
  INSPECTION_PHOTO_UPLOAD_DOCUMENTS: 'Upload compressed file',
  INSPECTION_PHOTO_UPLOADING_DOCUMENTS: 'Uploading compressed file...',
  INSPECTION_PHOTO_UPLOAD_IMAGES_PER_ITEM: 'Upload image',
  INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP: 'Upload Zip File',
  INSPECTION_PHOTO_UPLOAD_IMAGES_FILE: 'Select image',
  INSPECTION_PHOTO_UPLOAD_IMAGES_SAVE_BUTTON: 'Upload',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG: 'The file does not contain the correct format. use: (.jpg,.jpeg,.png)',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP: 'The file does not contain the correct format. use: (.zip)',

  INSPECTION_DELETE_MODAL_TITLE: 'Are you sure?',
  INSPECTION_DELETE_MODAL_SUBTITLE: 'This action cannot be undone.',
  INSPECTION_DELETE_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  INSPECTION_DELETE_MODAL_CONFIRM_BUTTON_LABEL: 'Delete',
  INSPECTION_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Why is this inspection being deleted? (Maximum 1000 characters)',
  INSPECTION_DELETE_MODAL_TEXTAREA_TITLE: 'Please write your comments regarding this case',

  INSPECTION_CANCEL_MODAL_TITLE: 'Are you sure?',
  INSPECTION_CANCEL_MODAL_SUBTITLE: 'This action cannot be undone.  ',
  INSPECTION_CANCEL_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  INSPECTION_CANCEL_MODAL_CONFIRM_BUTTON_LABEL: 'Confirm',
  INSPECTION_CANCEL_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Why is this inspection being cancelled? (Maximum 1000 characters)',
  INSPECTION_CANCEL_MODAL_TEXTAREA_TITLE: 'Please write your comments regarding this case:',

  INSPECTION_OVERRIDE_MODAL_TITLE: 'Inspection Completion Override',
  INSPECTION_OVERRIDE_MODAL_SUBTITLE: 'This action cannot be undone.',
  INSPECTION_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  INSPECTION_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Why is this inspection being overriden? Please provide as much detail as possible I.E. If photos have been aquired from other sources where are they stored ETC. (Maximum 1000 characters)',
  INSPECTION_OVERRIDE_MODAL_TEXTAREA_TITLE: 'Please write your comments regarding this case',

  INSPECTION_PHOTO_HISTORY_MODAL_FOTO: 'Photo',
  INSPECTION_PHOTO_HISTORY_MODAL_FOTO_DATE: 'Load date',
  INSPECTION_PHOTO_HISTORY_MODAL_PHOTO_COMMENTS: 'Comments',
  INSPECTION_PHOTO_HISTORY_MODAL_HEADER: 'History of repeated photos',

  INSPECTION_OVERRIDE_COMMENT_TITLE: 'Inspection was Completed via Override',
  INSPECTION_OVERRIDE_AGENT_NAME: 'Overriding Agent Name:',

  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_TITLE: 'Inspection not found.',
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_SUBTITLE: 'The inspection is not created as a request.',
  INSPECTION_DUPLICATE_MODAL_ERROR_TITLE: 'Service error',
  INSPECTION_DUPLICATE_MODAL_ERROR_SUBTITLE: 'Service unavailable. Please add the information.',

  INSPECTION_SUMMARY_HEADER_1: 'Policy No.',
  INSPECTION_SUMMARY_HEADER_2: 'License Plate',
  INSPECTION_SUMMARY_HEADER_3: 'Type of ID',
  INSPECTION_SUMMARY_HEADER_4: 'ID Number',
  INSPECTION_SUMMARY_HEADER_5: 'Name of taker',
  INSPECTION_SUMMARY_HEADER_6: 'Created By',
  INSPECTION_SUMMARY_HEADER_7: 'Created On',
  INSPECTION_SUMMARY_HEADER_8: 'Status',

  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_SUCCESS: 'Camera has been changed',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_FAILED: 'There was a problem when changing the camera',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_BUTTON: 'Change camera',

  INSPECTION_MESSAGE_IS_REQUIRED_VIDEO: '360 video is required',
  INSPECTION_MESSAGE_IS_OPTIONAL_VIDEO: '360 video is optional',

  INSPECTION_VEHICLE_DETAILS_IMAGE_ALT_TEXT: 'Vehicle',
  INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT: '- Please Select -',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY: 'field must not be empty',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MAX: 'field must not be more than {maxLength} characters',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SUBMISSIONID: 'Quotation number/Invalid contract number. Please check again.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MIN: 'Quotation number/Invalid contract number. Please check again.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY: 'please select a valid option from the dropdown list',
  INSPECTION_VEHICLE_SUBMISSIONID_EXIST: 'Policy No. already exists in the system.',
  INSPECTION_VEHICLE_LICENCEPLATE_EXIST: 'licence plate of vehicle already exists in the system.',

  INSPECTION_VEHICLE_VIDEO_TITLE: '360° video ',
  INSPECTION_VEHICLE_VIDEO_HINT_VIEW: 'Drag to see',
  INSPECTION_VEHICLE_VIDEO_BUTTON_CLOSE: 'Close',
  INSPECTION_VEHICLE_VIDEO_BUTTON_SAVE_CHANGES: 'Save Changes',

  INSPECTION_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE: 'No photos availabe',
  INSPECTION_VEHICLE_PHOTOS_TITLE: 'Photo Approval',
  INSPECTION_VEHICLE_PHOTOS_SUBTITLE: 'Begin the inspection process, verify that the photographs comply with the permitted standards to continue with the process',
  INSPECTION_VEHICLE_PHOTOS_HEADER_2: 'Image description',
  INSPECTION_VEHICLE_PHOTOS_HEADER_3: 'Upload date',
  INSPECTION_VEHICLE_PHOTOS_HEADER_4: 'Actions',
  INSPECTION_VEHICLE_PHOTOS_AI_BANNER: 'Note: You may see icons beside each image, we are testing automatic image detection to help you with the review process. This information is for guidance only and images must still be manually reviewed.',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP: 'We have detected a vehicle present in this image',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP: 'We have detected that a person may appear in this image',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_TEXT_TOOLTIP: 'We have detected that the chassis number may appear in this image',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT: 'Accept',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT: 'Retake',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_ACCEPT: 'Enable Photo Guide',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_REJECT: 'Disable Photo Guide',
  INSPECTION_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY: 'Photographs were returned, comments were successfully sente',
  INSPECTION_VEHICLE_PHOTOS_VIEW_COMMENTS: 'See comments',
  INSPECTION_VEHICLE_PHOTOS_INCORRECT_PHOTOS: 'Oops, apparently there are some incorrect photographs',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE: 'Download image',
  INSPECTION_VEHICLE_DOCUMENTS_DOWNLOAD: 'Download',
  INSPECTION_VEHICLE_PHOTOS_APPROVE_ALL_PHOTOS: 'Approve Photos',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE: 'Download all images',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE: 'Downloading... Please Wait',
  INSPECTION_VEHICLE_DURATION_VIDEO: 'Video length',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES: 'Images frequency (Seconds)',
  INSPECTION_VEHICLE_DURATION_VIDEO_FIELD: 'Video length',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES_FIELD: 'Images frequency',
  INSPECTION_VEHICLE_IMAGE_COMPRESS: 'Compress images',
  INSPECTION_VEHICLE_IMAGE_COMPRESS_DISABLE: 'NO Compress images',

  INSPECTION_VEHICLE_CHASSIS_NUMBER_DETECTED_LABEL: 'Chassis number detected:',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_ACCEPT: 'Correct',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_REJECT: 'Incorrect',

  INSPECTION_CUSTOMER_MESSAGES_TITLE: 'My comments',
  INSPECTION_CUSTOMER_MESSAGES_BUTTON_LABEL: 'Close',

  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE: 'Success',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_GENERIC: 'Fail',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE: 'Attention',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE: 'Email have been resent to the customer',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_GENERIC: 'Notifications failed to send',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE: 'Are you sure you want to send? Accepting will also save the inspection information',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE: 'Close',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_EMAIL: 'Error sending reminder mail',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_EMAIL: 'Successful SMS sent, but the contact email is correct',

  INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND: 'Resend',
  INSPECTION_CUSTOMER_DETAILS_BUTTON_SENDING: 'Sending...',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_PRECESSED_STATUS: 'Processed',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DELIVERED_STATUS: 'Delivered',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BOUNCED_STATUS: 'Bounced',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DROPPED_STATUS: 'Dropped',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DIFERRED_STATUS: 'Differed',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BLOCKED_STATUS: 'Blocked',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_UNKNOWN_STATUS: 'Unknown',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_STATUS_LABEL: 'Email status',

  INSPECTION_NEW_CUSTOMER_MESSAGE_TITLE: 'Photo comments',
  INSPECTION_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER: 'Write your observations about the photographs (maximum 1000 characters)',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS: 'Send message to the client',
  INSPECTION_NEW_CUSTOMER_MESSAGE_LOADING: 'Please wait...',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE: 'Message sent to the customer',
  INSPECTION_NEW_CUSTOMER_MESSAGE_CLOSE: 'Close',

  INSPECTION_SUPPORTING_DOCUMENTS_TITLE: 'Supports',
  INSPECTION_SUPPORTING_DOCUMENTS_SUBTITLE: 'Attach and send the supporting items that you consider necessary for the inspection, they can be screenshots, documents, among others.\nSupported formats (PDF, jpg, jpeg, png, bmp, doc, docx).',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER: 'Drag files to attach, or ',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK: 'browse',

  INSPECTION_COMMENTS_TITLE: 'Your comments',
  INSPECTION_COMMENTS_SUBTITLE: 'Do not forget to enter the comments you have about the inspection',
  INSPECTION_COMMENTS_INPUT_PLACEHOLDER: 'Write your observations (maximum 1000 characters)',

  INSPECTION_STATUS_AGENT_REVIEW_DISPLAY_VALUE: 'Needs agent review',
  INSPECTION_STATUS_SUPPORT_AGENT_REVIEW_DISPLAY_VALUE: 'Needs agent review',
  INSPECTION_STATUS_AGENT_IN_PROGRESS_DISPLAY_VALUE: 'Agent review in progress',
  INSPECTION_STATUS_AGENT_APPROVED_DISPLAY_VALUE: 'Approved',
  INSPECTION_STATUS_AGENT_REJECTED_DISPLAY_VALUE: 'Rejected',
  INSPECTION_STATUS_CUSTOMER_RETAKE_DISPLAY_VALUE: 'Customer needs to retake',
  INSPECTION_STATUS_CUSTOMER_NEW_DISPLAY_VALUE: 'New inspection',
  INSPECTION_STATUS_CUSTOMER_IN_PROGRESS_DISPLAY_VALUE: 'Customer in progress',
  INSPECTION_STATUS_SUPPORT_INSPECTOR_DISPLAY_VALUE: 'Inspector requested',
  INSPECTION_STATUS_SUPPORT_RETAKE_DISPLAY_VALUE: 'Supplier pending response',
  INSPECTION_STATUS_SUPPORT_NEW_DISPLAY_VALUE: 'New inspection',
  INSPECTION_STATUS_SUPPORT_IN_PROGRESS_DISPLAY_VALUE: 'Supplier in progress',
  INSPECTION_STATUS_EXPIRED_DISPLAY_VALUE: 'Expired',
  INSPECTION_STATUS_DELETED_DISPLAY_VALUE: 'Deleted',
  INSPECTION_STATUS_CANCELLED_DISPLAY_VALUE: 'Cancelled',
  INSPECTION_STATUS_CHANGED_TO_PRESENTIAL_DISPLAY_VALUE: 'switch to in-person',

  SUPPORT_INSPECTION_NO_COMMENTS: 'No comments.',

  ENABLE_VENDOR_LABEL: 'Only vendor inspections',

  INSPECTION_ASSIGNEE_TITLE: 'Assigned:',
  INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON: 'Assign to me',
  INSPECTION_DEFAULT_ASSIGNEE_DISPLAY_VALUE: 'UNASSIGNED',

  INSPECTION_COMPLETE_ACCEPTED_TITLE: 'Photographic Inspection Accepted',
  INSPECTION_COMPLETE_ACCEPTED_SUBTITLE: 'Photos meet inspection requirements',
  INSPECTION_COMPLETE_REJECTED_TITLE: 'Rejected inspection',
  INSPECTION_COMPLETE_REJECTED_SUBTITLE: 'The vehicle does not meet the basic requirements to obtain a policy',
  INSPECTION_COMPLETE_BUTTON_LABEL: 'Return',

  INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING: 'Front property card',
  INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING: 'Back property card',
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING: 'Number plate',
  INSPECTION_PHOTO_VEHICLE_FRONT_DISPLAY_STRING: 'Vehicle front',
  INSPECTION_PHOTO_VEHICLE_REAR_DISPLAY_STRING: 'Rear of the vehicle',
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING: 'Right side',
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING: 'Left side',
  INSPECTION_PHOTO_VEHICLE_ENGINE_DISPLAY_STRING: 'Engine compartment',
  INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING: 'Milometer or dashboard',
  INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING: 'Mileage',
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING: 'Sticker or serial plate',
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING: 'Chassis number',
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE_DISPLAY_STRING: 'Panoramic plate',
  INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTIONE_DISPLAY_STRING: 'Certificate of Inspection',
  INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD_DISPLAY_STRING: 'Registrarion Card',
  INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE_DISPLAY_STRING: 'Left Front Angle',
  INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE_DISPLAY_STRING: 'Right Front Angle',
  INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE_DISPLAY_STRING: 'Right Back Angle',
  INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE_DISPLAY_STRING: 'Left Back Angle',
  INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP_DISPLAY_STRING: 'Left Headlamp',
  INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP_DISPLAY_STRING: 'Right Headlamp',
  INSPECTION_PHOTO_VEHICLE_VN_ODOMETER_DISPLAY_STRING: 'Odometer',
  INSPECTION_PHOTO_VEHICLE_VIDEO_DISPLAY_STRING: '360° video of the vehicle',
  INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT_DISPLAY_STRING: 'ID Card or Passport',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1_DISPLAY_STRING: 'Driver License/ IDP for 1st named driver',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2_DISPLAY_STRING: 'Driver License/ IDP for 2st named driver',

  INSPECTION_PHOTO_ACCESSORY_RADIO_DISPLAY_STRING: 'Radio',
  INSPECTION_PHOTO_ACCESSORY_DVD_DISPLAY_STRING: 'DVD Player',
  INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS_DISPLAY_STRING: 'Amplifiers',
  INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS_DISPLAY_STRING: 'Fixed Speakers',
  INSPECTION_PHOTO_ACCESSORY_EQUALIZERS_DISPLAY_STRING: 'Equalizers',
  INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA_DISPLAY_STRING: 'Rear Camera',
  INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS_DISPLAY_STRING: 'Proximity Sensors',
  INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK_DISPLAY_STRING: 'Bloqueo central',
  INSPECTION_PHOTO_ACCESSORY_ALARM_DISPLAY_STRING: 'Alarm',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM_DISPLAY_STRING: 'Safety Film',
  INSPECTION_PHOTO_ACCESSORY_SUN_ROOF_DISPLAY_STRING: 'Sun roof',
  INSPECTION_PHOTO_ACCESSORY_SPOILER_EXTRA: 'Spoiler', // Extra spoiler
  INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS_DISPLAY_STRING: 'Electric Windows',
  INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING_DISPLAY_STRING: 'Air Conditioning',
  INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY_DISPLAY_STRING: 'Turn -Off Electricity',
  INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING_DISPLAY_STRING: 'Patent engraving on glass',
  INSPECTION_PHOTO_ACCESSORY_FOOTRESTS_DISPLAY_STRING: 'Footrests',
  INSPECTION_PHOTO_ACCESSORY_ROLL_BAR_DISPLAY_STRING: 'Inner / outer roll bar',
  INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL_DISPLAY_STRING: 'Hitch wheel',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL_DISPLAY_STRING: 'Trailer hitch ball',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG_DISPLAY_STRING: 'Trailer plug',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS_DISPLAY_STRING: 'Luxury Wheels',
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS_DISPLAY_STRING: 'Explorers',
  INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION_DISPLAY_STRING: 'Gas Conversion Equipment',
  INSPECTION_PHOTO_ACCESSORY_DURALINER_DISPLAY_STRING: 'Duraliner',
  INSPECTION_PHOTO_ACCESSORY_HELMET_DISPLAY_STRING: 'Helmet',

  INSPECTION_PHOTO_ACCESSORY_ARMOR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CD_BOX_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FIXED_DVD_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WOOFER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_GLASSES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BULLBAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUNROOF_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_ACCESSORY_SPOILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TOW_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SKIRTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TIRES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROOF_RACK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRUCK_BED_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TWEETER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SLIDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WINCH_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_DRAWER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUSPENSION_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUMPER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BODY_KIT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MIRRORS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_SPARE_WHEEL_DISPLAY_STRING: 'Spare Wheel',

  INSPECTION_PHOTO_MOTORBIKE_FRONT_DISPLAY_STRING: 'Motorbiker front',
  INSPECTION_PHOTO_MOTORBIKE_REAR__DISPLAY_STRING: 'Pear of the vehicle',
  INSPECTION_PHOTO_MOTORBIKE_ENGINE_DISPLAY_STRING: 'Enginne',

  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_TRUNK_DISPLAY_STRING: 'Trunk',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PORTER_DISPLAY_STRING: 'Porter',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PROTECTOR_KIT_DISPLAY_STRING: 'Protectors kit',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_ALARM_DISPLAY_STRING: 'Alarm',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_EXPLORERS_DISPLAY_STRING: 'Explorers',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_HELMET_DISPLAY_STRING: 'Helmet',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_JACKET_DISPLAY_STRING: 'Jacket',

  INSPECTION_PHOTO_DAMAGE_NAME: 'Damage',

  CREATE_INSPECTION_NOTES_TITLE: '',
  CREATE_INSPECTION_CUSTOMER_TITLE: 'Customer',
  CREATE_INSPECTION_CUSTOMER_SUBTITLE: 'Enter customer information',
  CREATE_INSPECTION_AGENT_TITLE: 'Agent',
  CREATE_INSPECTION_AGENT_SUBTITLE: 'Enter agent information',
  CREATE_INSPECTION_VEHICLE_TITLE: 'Vehicle',
  CREATE_INSPECTION_VEHICLE_SUBTITLE: 'Enter vehicle information',
  CREATE_INSPECTION_BROKER_TITLE: 'Broker',
  CREATE_INSPECTION_BROKER_SUBTITLE: 'Enter broker information',
  CREATE_INSPECTION_NAMED_DRIVER_TITLE: 'Named driver',
  CREATE_INSPECTION_NAMED_DRIVER_SUBTITLE: 'Enter named driver information',
  CREATE_INSPECTION_SUBMIT: 'Submit',
  CREATE_INSPECTION_PREFILL: 'Prefill',
  CREATE_INSPECTION_CLEAN_FIELDS: 'Clean Fields',
  CREATE_INSPECTION_CREATION_ERROR: 'Error when creating inspection',
  CREATE_INSPECTION_CREATION_SUCCESS: 'Inspection created successfully',
  CREATE_INSPECTION_CREATION_CLOSE: 'Close',
  CREATE_INSPECTION_CREATION_CONTINUE: 'Continue',
  CREATE_INSPECTION_CREATION_COPY: 'Copy link',
  CREATE_INSPECTION_INVALID_PHONE_NUMBER: 'Phone number invalid',
  CREATE_INSPECTION_CAMERA_TITLE: 'Camera',
  CREATE_INSPECTION_CAMERA_SUBTITLE: 'Select the camera to be used by the client',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL: 'Camera type',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NATIVE: 'Native camera',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NEW: 'Camera Module integrated',
  CREATE_INSPECTION_VIDEO_TITLE: '360° Video',
  CREATE_INSPECTION_VIDEO_SUBTITLE: 'In this section you can enable 360° video for the client',
  CREATE_INSPECTION_SELECT_VIDEO_LABEL: 'Select an option',
  CREATE_INSPECTION_POLICY_TYPE_LABEL: 'Policy type',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_1: 'Implement 360° video',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_2: 'Do not implement 360° video',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_1: 'New Business',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_2: 'Renewal',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_1: '1 Image',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_2: '2 Images',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_1: '1 Image',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_2: '2 Images',
  CREATE_INSPECTION_INSPECTION_NOT_FOUND: '',
  CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR: '',
  CREATE_INSPECTION_LOADING_INSPECTION_MESSAGE: '',

  REPORT_SUCCESS_MODAL_TITLE: 'Your report has been downloaded successfully',
  REPORT_SUCCESS_MODAL_BUTTON: 'Close',
  REPORT_TITLE: 'Reporte de inspecciones',
  REPORT_SUBTITLE: 'Generar un reporte detallado con toda información de tus inspecciones',
  REPORT_SUBMIT_BUTTON: 'Generate report',
  REPORT_FORM_SUBTITLE: 'Select the range of dates you want to generate your report',
  REPORT_FORM_START_DATE: 'Start date',
  REPORT_FORM_END_DATE: 'End date',
  REPORT_FORM_ERROR_MAX_RANGE: 'The requested timeframe must be 90 days or less',
  REPORT_OPTION_ONE: 'Adil Workflow',
  REPORT_OPTION_TWO: 'Retake photos',
  REPORT_OPTION_THREE: 'Dates',
  REPORT_OPTION_FOR: 'Errors',
  REPORT_OPTION_FIVE: 'Support Request',
  REPORT_OPTION_SIX: 'Features',
  REPORT_OPTION_EIGHT: 'Mailing Status',
  REPORT_OPTION_NINE: 'Inspections deleted',
  REPORT_OPTION_TEN: 'Geolocation photos',
  REPORT_MESSAGE_TIMEOUT: 'Limit of records to consult exceeded, please reduce the selected time range',

  SEARCH_FILTER_BAR_WITH_PHOTOS: 'With photos',

  INSPECTION_INFORM_TITLE: 'Inform Inspection',
  INSPECTION_INFORM_SUBTITLE: 'Complete the follow steps for see the inform inspections result.',
  INSPECTION_INFORM_DATA_OPERATOR: 'Datos del corredor',
  INSPECTION_INFORM_DATA_INSPECTOR: 'Datos del inspector',
  INSPECTION_INFORM_DATA_INSPECTION: 'Datos de la inspección',
  INSPECTION_INFORM_DATA_VEHICLE: 'Datos del vehículo',
  INSPECTION_INFORM_SYSTEM_IDENTIFICATION: 'Sistemas de identificación',
  INSPECTION_INFORM_REVISION_FRONTAL: 'Revisión estructural: Frontal',
  INSPECTION_INFORM_LATERAL_LEFT: 'Revisión estructural: Lateral izquierdo',
  INSPECTION_INFORM_MAP_DAMAGE: 'Mapa de daños',
  INSPECTION_INFORM_PIECES_INSPECTION: 'Piezas a inspeccionar',
  INSPECTION_INFORM_CHARACTERS_LIMIT: '50 de 1000 caracteres',
  INSPECTION_INFORM_START_DAMAGE_INSPECTIONS: 'Ingresar inspección con daños',

  INSPECTION_INFORM_MODEL_YEAR: 'Año Modelo',
  INSPECTION_INFORM_CLASS: 'Clase',
  INSPECTION_INFORM_MANUFACTURER: 'Marca',
  INSPECTION_INFORM_TYPE_ID: 'Uso/Tipo de placa',
  INSPECTION_INFORM_KM_OR_AUTONOMIC: 'Km o autonomía',
  INSPECTION_INFORM_COLOR: 'Color',
  INSPECTION_INFORM_TYPE_ENGINE: 'Combustible',
  INSPECTION_INFORM_MODEL_VERSION: 'Modelo/Version',

  INSPECTION_INFORM_NUMBER_PATENT: 'Placa/Patente',
  INSPECTION_INFORM_STATE_PATENT: 'Estado Placa/Patente',
  INSPECTION_INFORM_NUMBER_CHASSIS: 'Número de chasis',
  INSPECTION_INFORM_STATE_CHASSIS: 'Estado Número de chasis',
  INSPECTION_INFORM_NUMBER_MOTOR: 'Serie motor',
  INSPECTION_INFORM_STATE_MOTOR: 'Estado Serie motor',
  INSPECTION_INFORM_CLOSE_FULLSCREEN_BUTTON: 'Exit full screen',
  INSPECTION_INFORM_APPROVED_OPTION: 'Approved',
  INSPECTION_INFORM_DISAPPROVED_OPTION: 'Disapproved',

  STEPPER_VALIDATE_DATA: 'Validation data',
  STEPPER_VALIDATE_CRIMINAL_RECORD: 'Validation Criminal Record',
  STEPPER_SEND_RESULTS: 'Send Results',
  STEPPER_LABEL_ONE: '1',
  STEPPER_LABEL_TWO: '2',
  STEPPER_LABEL_THREE: '3',
};

export default { code, strings };
