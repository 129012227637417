import constants from '@lmig-latam/adil-api-common-lib/constants';
import PropTypes from 'prop-types';

const {
  STATUS_TYPES: {
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_CANCELLED,
  },
} = constants;

export const userPropType = PropTypes.shape({
  name: PropTypes.string,
  username: PropTypes.string,
});

export const customerPropType = PropTypes.shape({
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  secondLastName: PropTypes.string,
  idType: PropTypes.string,
  idNumber: PropTypes.string,
  cellPhoneNumber: PropTypes.string,
  email: PropTypes.string,
});

export const vehiclePropType = PropTypes.shape({
  licencePlate: PropTypes.string,
  licencePlateType: PropTypes.string,
  countryCarId: PropTypes.string,
  origin: PropTypes.string,
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  color: PropTypes.string,
  version: PropTypes.string,
  bodyStyle: PropTypes.string,
  chassisNumber: PropTypes.string,
  fuelType: PropTypes.string,
  vin: PropTypes.string,
  engineNumber: PropTypes.string,
  capacity: PropTypes.string,
  use: PropTypes.string,
  kilometers: PropTypes.string,
  value: PropTypes.string,
});

export const photoPropType = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  status: PropTypes.string,
  ageInSeconds: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string,
      Value: PropTypes.string,
    }),
  ),
});

export const photoServicePropType = PropTypes.shape({
  photos: PropTypes.arrayOf(photoPropType),
  photoZipUrl: PropTypes.string,
});

export const documentPropType = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});

export const newDocumentPropType = PropTypes.shape({
  name: PropTypes.string,
  data: PropTypes.string,
});

export const customerMessagePropType = PropTypes.shape({
  customerMessage: PropTypes.string,
  agentId: PropTypes.string,
});

export const inspectionPropType = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  comments: PropTypes.string,
  customer: customerPropType,
  vehicle: vehiclePropType,
});

export const driversPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  comments: PropTypes.string,
  licenseNumber: PropTypes.string,
});

export const allInspectionsPropType = PropTypes.shape({
  [STATUS_CUSTOMER_NEW]: PropTypes.arrayOf(inspectionPropType),
  [STATUS_CUSTOMER_IN_PROGRESS]: PropTypes.arrayOf(inspectionPropType),
  [STATUS_AGENT_REVIEW]: PropTypes.arrayOf(inspectionPropType),
  [STATUS_AGENT_APPROVED]: PropTypes.arrayOf(inspectionPropType),
  [STATUS_AGENT_REJECTED]: PropTypes.arrayOf(inspectionPropType),
  [STATUS_CUSTOMER_RETAKE]: PropTypes.arrayOf(inspectionPropType),
  [STATUS_CANCELLED]: PropTypes.arrayOf(inspectionPropType),
});
