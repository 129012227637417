import {
  Button,
  Content,
  Disclaimer,
  GridCol,
  GridRow,
  Heading,
  BodyText,
} from '@lmig/lmds-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IconDocument } from '@lmig/lmds-react/icons';
import { Form, Text, Scope } from 'informed';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { InspectionActions, SettingsActions } from '../../actions';
import {
  AppHeader,
  Panel,
  SideBar,
  CircleImage,
  ErrorsList,
  InformedSelect,
  AppFooter,
} from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { downloadFileFromUrl } from '../../utils';
import {
  getCountryCode,
  isAsianDomain,
} from '../../config/environments/environmentsHelpers';
import ReportAsian from '../../components/ReportAsian';
import './styles.scss';

const {
  REPORT_SUCCESS_MODAL_TITLE,
  REPORT_SUCCESS_MODAL_BUTTON,
  REPORT_TITLE,
  REPORT_SUBTITLE,
  REPORT_SUBMIT_BUTTON,
  REPORT_FORM_SUBTITLE,
  REPORT_FORM_START_DATE,
  REPORT_FORM_END_DATE,
  REPORT_OPTION_ONE,
  REPORT_OPTION_TWO,
  REPORT_OPTION_THREE,
  REPORT_OPTION_FOR,
  REPORT_OPTION_FIVE,
  REPORT_OPTION_SIX,
  REPORT_OPTION_EIGHT,
  REPORT_OPTION_NINE,
  REPORT_OPTION_TEN,
  REPORT_MESSAGE_TIMEOUT,
} = localizedStrings;

const Report = ({
  inspectionActions: { getReport },
  settingsActions: { hideAppLoader, showAppLoader },
}) => {
  const [formApi, setFormApi] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const MySwal = withReactContent(Swal);

  const SHEET_REPORT_OPTION = {
    [REPORT_OPTION_ONE]: 1,
    [REPORT_OPTION_TWO]: 2,
    [REPORT_OPTION_THREE]: 3,
    [REPORT_OPTION_FOR]: 4,
    [REPORT_OPTION_FIVE]: 5,
    [REPORT_OPTION_SIX]: 6,
    [REPORT_OPTION_EIGHT]: 8,
    [REPORT_OPTION_NINE]: 9,
    [REPORT_OPTION_TEN]: 10,
  };

  const getReportOptionsArray = () => Object.keys(SHEET_REPORT_OPTION);

  const showAlertTimeout = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Error',
      html: `<p>${REPORT_MESSAGE_TIMEOUT}</p>`,
      customClass: {
        title: 'sweetAdil__title',
        container: 'sweetAdil__container',
        confirmButton: 'sweetAdil__acceptButton',
      },
    });
  };

  const submitReportRequest = async () => {
    showAppLoader();
    const d = formApi.getState();
    const {
      values: { startDate, endDate },
    } = d;

    try {
      const { reportUrl } = await getReport({
        startDate,
        endDate,
        reportOption: selectedReport,
      });
      const fileNamePrefix = 'Reports/';
      const fileNameSuffix = '.xlsx';

      const fileName = reportUrl.substring(
        reportUrl.indexOf(fileNamePrefix) + fileNamePrefix.length,
        reportUrl.indexOf(fileNameSuffix) + fileNameSuffix.length,
      );
      downloadFileFromUrl(reportUrl, fileName);
    } catch (err) {
      if (err.name === 'AbortError') {
        showAlertTimeout();
      } else {
        // eslint-disable-next-line no-console
        console.log('Request fail by other reason');
      }
    }
    setIsModalOpen(true);
    formApi.reset();
    hideAppLoader();
  };

  const setInformedApi = informedApi => {
    setFormApi(informedApi);
  };

  const renderReportSuccessModal = () => (
    <ReactModal
      isOpen={isModalOpen}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      <div className="modal-content">
        <CircleImage>
          <IconDocument size="64" />
        </CircleImage>
        <Heading type="h3-light">{REPORT_SUCCESS_MODAL_TITLE}</Heading>
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          {REPORT_SUCCESS_MODAL_BUTTON}
        </Button>
      </div>
    </ReactModal>
  );

  const validateReportSelected = report => {
    if (!report) {
      return 'no value';
    }
    return undefined;
  };

  const validateStartDate = (startDate, { endDate }) => {
    if (!startDate) {
      return 'no value';
    }

    if (startDate && !endDate) {
      formApi.setValue('endDate', startDate);
    }

    if (startDate > endDate) {
      formApi.setValue('endDate', startDate);
    }

    return undefined;
  };

  const validateEndDate = (endDate, { startDate }) => {
    if (!endDate) {
      return 'no value';
    }

    if (endDate < startDate) {
      formApi.setValue('startDate', endDate);
    }

    return undefined;
  };

  const onChangeReport = e => {
    const { value } = e.target;
    const id = SHEET_REPORT_OPTION[value];
    setSelectedReport(id);
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      {renderReportSuccessModal()}
      <AppHeader />
      <SideBar>
        <Content className="adil-content">
          <Heading type="h3-light" className="auto-left-right-margin">
            {REPORT_TITLE}
          </Heading>
          <Disclaimer>{REPORT_SUBTITLE}</Disclaimer>
          {getCountryCode() !== 'vn' ? (
            <Panel className="adil-report-panel">
              <GridRow gutters justify="center">
                <GridCol base={12}>
                  <CircleImage size="small">
                    <IconDocument size="48" />
                  </CircleImage>
                </GridCol>
                <GridCol base={6}>
                  <BodyText type="lead" className="auto-left-right-margin">
                    {REPORT_FORM_SUBTITLE}
                  </BodyText>
                </GridCol>

                <Form getApi={setInformedApi} onSubmit={submitReportRequest}>
                  {({
                    formState: {
                      errors: {
                        startDate: startDateErrors,
                        endDate: endDateErrors,
                      },
                      error,
                    },
                  }) => (
                    <GridRow gutters justify="center">
                      <GridCol base={4}>
                        <label>
                          {REPORT_FORM_START_DATE}
                          <Text
                            field="startDate"
                            type="date"
                            className={classNames([
                              'adil-report-input',
                              {
                                'validation-input-field-error':
                                  startDateErrors || error,
                              },
                            ])}
                            validate={validateStartDate}
                            validateOnChange
                          />
                        </label>
                      </GridCol>
                      <GridCol base={4}>
                        <label>
                          {REPORT_FORM_END_DATE}
                          <Text
                            field="endDate"
                            type="date"
                            className={classNames([
                              'adil-report-input',
                              {
                                'validation-input-field-error':
                                  endDateErrors || error,
                              },
                            ])}
                            validate={validateEndDate}
                            validateOnChange
                          />
                        </label>
                      </GridCol>

                      {!isAsianDomain() && (
                        <GridCol base={4}>
                          <div className="selectReport">
                            <Scope scope="report">
                              <InformedSelect
                                id="report"
                                labelVisual="Reporte"
                                onChange={onChangeReport}
                                selectValues={getReportOptionsArray()}
                                validate={validateReportSelected}
                                validateOnChange
                              />
                            </Scope>
                          </div>
                        </GridCol>
                      )}
                      {error && (
                        <GridCol base={12}>
                          <ErrorsList errors={[error]} />
                        </GridCol>
                      )}
                      <GridCol base={12}>
                        <Button type="submit">{REPORT_SUBMIT_BUTTON}</Button>
                      </GridCol>
                    </GridRow>
                  )}
                </Form>
              </GridRow>
            </Panel>
          ) : (
            <ReportAsian />
          )}
          {renderFooterSection()}
        </Content>
      </SideBar>
    </div>
  );
};

Report.propTypes = {
  inspectionActions: InspectionActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  inspectionActions: bindActionCreators(InspectionActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Report);
