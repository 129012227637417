import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import {
  getAllowedCountries,
  isAdmin,
  isCreator,
  isReviewer,
  isViewer,
} from '../utils';
import { UPDATE_USER_INFO } from './ActionTypes';
import { navigate } from '../utils/NavigationUtils';
import { DEFAULT } from '../utils/navigationConstants';

export default {
  PropertyTypes: PropTypes.shape({
    updateUserInfo: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }),

  updateUserInfo() {
    return async dispatch =>
      Auth.currentAuthenticatedUser().then(cognitoUser => {
        const user = {
          name: cognitoUser.attributes.name,
          username: cognitoUser.username,
          isReviewer: isReviewer(cognitoUser),
          isCreator: isCreator(cognitoUser),
          isAdmin: isAdmin(cognitoUser),
          isViewer: isViewer(cognitoUser),
          allowedCountries: getAllowedCountries(cognitoUser),
        };
        dispatch({
          type: UPDATE_USER_INFO,
          user,
        });
      });
  },

  logout() {
    return async () => {
      await Auth.signOut();
      navigate(DEFAULT);
    };
  },
};
