import React from 'react';
import PropTypes from 'prop-types';

function ZoomResetIcon({ hidden, onClick, ...props }) {
  return (
    <div
      role="button"
      className="circle-icon"
      style={{ display: hidden && 'none' }}
      onClick={onClick}
    >
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 32 32"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        onClick={onClick}
        {...props}
      >
        <circle cx="14" cy="14" r="12" stroke="#fff" />
        <path d="M23 23 L30 30" stroke="#fff" />
        <path
          d="M9 12 L9 9 12 9 M16 9 L19 9 19 12 M9 16 L9 19 12 19 M19 16 L19 19 16 19"
          stroke="#fff"
        />
      </svg>
    </div>
  );
}

ZoomResetIcon.defaultProps = {
  hidden: false,
};

ZoomResetIcon.propTypes = {
  hidden: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ZoomResetIcon;
