import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { Heading, IconButton } from '@lmig/lmds-react';
import { IconAuto, IconClose } from '@lmig/lmds-react/icons';
import CircleImage from '../CircleImage';
import { Button, DescriptionArea } from '..';
import './styles.scss';

const SupportRequestInfoModal = ({
  isOpen,
  closeModal,
  title,
  description,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={closeModal}
    className="confirmation-modal"
    overlayClassName="confirmation-modal-overlay"
  >
    <div className="confirmation-modal-content">
      <div className="confirmation-modal-image-close-button">
        <IconButton onClick={closeModal}>
          <IconClose size="16" />
        </IconButton>
      </div>

      <CircleImage size="small">
        <IconAuto size="64" />
      </CircleImage>

      <Heading type="h4-bold" className="auto-left-right-margin">
        {title}
      </Heading>

      <DescriptionArea description={description} />

      <div>
        <Button onClick={closeModal} className="">
          Cerrar
        </Button>
      </div>
    </div>
  </ReactModal>
);

SupportRequestInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

SupportRequestInfoModal.defaultProps = {
  description: '',
};

export default SupportRequestInfoModal;
