import { Caption, Table, TableCell, TableRow } from '@lmig/lmds-react';
import React from 'react';
import { Panel } from '..';
import { getSummaryTableFields } from '../../constants/menu';
import { inspectionPropType } from '../../prop-types';
import { formatISOString, getInspectionStatusDisplayName } from '../../utils';
import './styles.scss';

const fieldsArray = getSummaryTableFields();

const NOT_APPLICABLE = 'N/A';

const InspectionSummary = ({ inspection }) => {
  const renderTableHeader = () => (
    <TableRow className="inspection-summary-headers">
      {fieldsArray.map(field => (
        <TableCell type="colHead" key={field.label}>
          {field.label}
        </TableCell>
      ))}
    </TableRow>
  );

  const renderTableContent = value => {
    let valueDisplayed;
    switch (value) {
      case 'submissionId':
        valueDisplayed =
          inspection[value] === NOT_APPLICABLE
            ? inspection.id.toUpperCase()
            : inspection[value].toUpperCase();
        break;
      case 'creationDate':
      case 'customerCompletionDate':
      case 'completionDate':
        valueDisplayed = formatISOString(inspection[value]);
        break;
      case 'licencePlate':
        valueDisplayed = inspection.vehicle.licencePlate
          ? inspection.vehicle.licencePlate.toUpperCase()
          : NOT_APPLICABLE;
        break;
      case 'userName':
        valueDisplayed = inspection.vendorSupport
          ? 'Vendors'
          : [
              inspection.customer.firstName,
              inspection.customer.middleName,
              inspection.customer.lastName,
              inspection.customer.secondLastName,
            ].join(' ');
        break;
      case 'status':
        valueDisplayed = getInspectionStatusDisplayName(inspection[value]);
        break;
      case 'idType':
      case 'idNumber':
        valueDisplayed = inspection.customer[value];
        break;
      case 'policyType':
        valueDisplayed = inspection[value] || 'New Business';
        break;
      default:
        valueDisplayed = inspection[value];
    }

    return (
      <TableCell key={value}>
        <Caption>{valueDisplayed}</Caption>
      </TableCell>
    );
  };

  return (
    <Panel className="inspection-summary">
      <Table>
        <tbody>
          {renderTableHeader()}
          <TableRow className="inspection-summary-content">
            {fieldsArray.map(field => renderTableContent(field.value))}
          </TableRow>
        </tbody>
      </Table>
    </Panel>
  );
};

InspectionSummary.propTypes = {
  inspection: inspectionPropType.isRequired,
};

export default InspectionSummary;
