/* eslint-disable react/prop-types */

import React from 'react';
import {
  CreateInspection,
  GenericError,
  Inspection,
  InspectionComplete,
  PageNotFound,
  AdminSupport,
  AdminOptions,
  Search,
  AdminPartsManteiner,
} from '..';
import {
  CREATE_INSPECTION,
  ADMIN_SUPPORT,
  ADMIN_OPTIONS,
  ADMIN_PARTS_MANTEINER,
  ERROR_GENERIC,
  INSPECTION,
  INSPECTION_COMPLETE,
  SEARCH,
  REPORT,
  INSPECTION_INFORM,
} from '../../utils/navigationConstants';
import Report from '../Report';
import InspectionInform from '../InspectionInform';

export default [
  {
    exact: true,
    path: CREATE_INSPECTION,
    render: props => <CreateInspection {...props} />,
    isCreatorRoute: true,
    isReviewerRoute: false,
    isViewerRoute: true,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: ADMIN_SUPPORT,
    render: props => <AdminSupport {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: false,
    isViewerRoute: false,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: ADMIN_OPTIONS,
    render: props => <AdminOptions {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: false,
    isViewerRoute: false,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: ADMIN_PARTS_MANTEINER,
    render: props => <AdminPartsManteiner {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: false,
    isViewerRoute: false,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: SEARCH,
    render: props => <Search {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
    isViewerRoute: true,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: `${INSPECTION}/:inspectionId`,
    render: props => <Inspection {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
    isViewerRoute: true,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: INSPECTION_INFORM,
    render: props => <InspectionInform {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
    isViewerRoute: true,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: INSPECTION_COMPLETE,
    render: props => <InspectionComplete {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: true,
    isViewerRoute: false,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: REPORT,
    render: props => <Report {...props} />,
    isCreatorRoute: false,
    isReviewerRoute: false,
    isViewerRoute: true,
    isAdminRoute: true,
  },
  {
    exact: true,
    path: ERROR_GENERIC,
    render: props => <GenericError {...props} />,
    isCreatorRoute: true,
    isReviewerRoute: true,
    isViewerRoute: true,
    isAdminRoute: true,
  },
  {
    render: props => <PageNotFound {...props} />,
    isCreatorRoute: true,
    isReviewerRoute: true,
    isViewerRoute: true,
    isAdminRoute: true,
  },
];
