import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import { CameraIcon, SupportRequestIcon } from '../Icons';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { supportRequestEnabled } from '../../utils/constants';
import { SearchActions } from '../../redux/search-inspection';
import './styles.scss';

const {
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_TOOLTIP,
  INSPECTIONS_TABLE_ROW_SUPPORT_REQUEST_ALERT_TOOLTIP,
} = localizedStrings;

function NoticeIcon({ isCustomerInProgress, supportRequest }) {
  const dispatch = useDispatch();

  const onDisplaySupportRequest = () => {
    dispatch(SearchActions.onDisplaySupportRequestDialog(true, supportRequest));
  };

  return (
    <div className="inspection-row-color-band">
      {supportRequestEnabled.includes(getCountryCode()) && supportRequest && (
        <div data-tip={INSPECTIONS_TABLE_ROW_SUPPORT_REQUEST_ALERT_TOOLTIP}>
          <SupportRequestIcon
            count={supportRequest?.requestCounter}
            onClick={onDisplaySupportRequest}
          />
          <ReactTooltip type="light" effect="solid" border />
        </div>
      )}

      {isCustomerInProgress && (
        <div data-tip={INSPECTIONS_TABLE_ROW_PHOTO_ALERT_TOOLTIP}>
          <CameraIcon />
          <ReactTooltip type="light" effect="solid" border />
        </div>
      )}
    </div>
  );
}

NoticeIcon.defaultProps = {
  supportRequest: null,
};

NoticeIcon.propTypes = {
  isCustomerInProgress: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  supportRequest: PropTypes.any,
};

export default NoticeIcon;
