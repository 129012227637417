import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ZoomOutIcon = ({ width, height, onClick, hidden, ...props }) => (
  <div
    role="button"
    className="circle-icon"
    onClick={onClick}
    style={{ display: hidden && 'none' }}
  >
    <svg
      width={width}
      height={height}
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-150 -150 850 850"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      onClick={onClick}
      {...props}
    >
      <g>
        <g>
          <path
            style={{
              fill: '#FFFFFF',
            }}
            d="m495,466.1l-109.5-109.4c31.2-37.8 48.2-84.7 48.2-134.3 0-56.5-22-109.5-61.9-149.5-39.9-39.9-93-61.9-149.5-61.9-56.5,0-109.5,22-149.5,61.9-82.4,82.4-82.4,216.5 0,298.9 39.9,39.9 93,61.9 149.5,61.9 49.6,0 96.6-17 134.3-48.2l109.5,109.5c11.5,10.5 23.8,5.1 28.9,0 7.9-8 7.9-20.9 0-28.9zm-152.1-123.2c-32.2,32.2-75,50-120.6,50-45.6,0-88.4-17.7-120.6-50-66.5-66.5-66.5-174.7 0-241.2 32.2-32.2 75-49.9 120.6-49.9 45.6,0 88.4,17.7 120.6,49.9 32.2,32.2 50,75 50,120.6 0,45.6-17.8,88.4-50,120.6z"
          />
          <path
            style={{
              fill: '#FFFFFF',
            }}
            d="m319.6,201.9h-194.6c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h194.6c11.3,0 20.4-9.1 20.4-20.4 0.1-11.2-9.1-20.4-20.4-20.4z"
          />
        </g>
      </g>
    </svg>
  </div>
);

ZoomOutIcon.defaultProps = {
  width: 40,
  height: 40,
  hidden: false,
};

ZoomOutIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};
