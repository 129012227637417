import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CardFilter from '../CardFilter/CardFilter';
import './styles.scss';

const MenuFilter = ({ disabled }) => {
  const menuFilter = useSelector(store => store.searchFilter.menuFilter);

  return (
    <div className="menu-filter">
      {menuFilter.map(item => (
        <CardFilter key={item.id} itemFilter={item} disabled={disabled} />
      ))}
    </div>
  );
};

MenuFilter.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default MenuFilter;
