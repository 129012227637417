// SETTINGS ACTIONS
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const UPDATE_LOGGED_IN = 'UPDATE_LOGGED_IN';

// USER ACTIONS
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

// SEARCH FILTER ACTIONS
export const SELECT_STEP_FILTER_SEARCH = 'SELECT_STEP_FILTER_SEARCH';
export const SET_COUNTS_INSPECTIONS = 'SET_COUNTS_INSPECTIONS';
export const ONCHANGE_SEARCH_TEXT_INSPECTIONS =
  'ONCHANGE_SEARCH_TEXT_INSPECTIONS';
export const QUARTER_FILTER = 'QUARTER_FILTER';
export const ONCHANGE_CURRENT_LOCATION_PAGE = 'ONCHANGE_CURRENT_LOCATION_PAGE';
