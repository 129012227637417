import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export default function ArrowIcon({
  className,
  width,
  height,
  direction,
  color,
  circle,
  ...props
}) {
  const customStyles = useMemo(() => {
    if (direction === 'left')
      return { transform: 'rotate(0deg)', cursor: 'pointer' };
    if (direction === 'right')
      return { transform: 'rotate(180deg)', cursor: 'pointer' };
    if (direction === 'top')
      return { transform: 'rotate(90deg)', cursor: 'pointer' };
    if (direction === 'bottom')
      return { transform: 'rotate(270deg)', cursor: 'pointer' };
    return null;
  }, [direction]);

  const customCircleStyles = {
    background: '#fff',
    borderRadius: '24px',
    width: `${width + 10}px`,
    height: `${height + 10}px`,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    border: '1px solid #e6e6e6',
  };

  return (
    <div style={circle ? customCircleStyles : {}}>
      <svg
        data-testid={direction}
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 1024 1024"
        fill={color}
        className="arrows"
        style={customStyles}
        {...props}
      >
        <path
          d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z"
          fill={color}
        />
      </svg>
    </div>
  );
}

ArrowIcon.defaultProps = {
  className: '',
  color: '#FFFFFF',
  width: 80,
  height: 80,
  direction: 'left',
  circle: false,
};

ArrowIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  circle: PropTypes.bool,
  direction: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
};
