import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionStatusDisplayName } from '../../utils';
import { SearchActions } from '../../redux/search-inspection';

function StatusButton({ status, optionsCount }) {
  const dispatch = useDispatch();
  const { currentLocation } = useSelector(store => store.searchFilter);

  const getTabCount = () => (!optionsCount ? '?' : optionsCount[status]);

  const displayName = () => (
    <div>
      {getInspectionStatusDisplayName(status)}
      <span className="number">{getTabCount()}</span>
    </div>
  );

  const onChangeLocationPage = newCurrentLocation =>
    dispatch(SearchActions.onChangeCurrentLocationPage(newCurrentLocation));

  return (
    <div
      key={status}
      role="button"
      onClick={() => {
        onChangeLocationPage({
          subStatusSelected: status,
          mustHavePhotos: false,
        });
      }}
      className={classNames({
        'status-filter-bar-container-option': true,
        'status-filter-bar-selected':
          !currentLocation.mustHavePhotos &&
          status === currentLocation.subStatusSelected,
      })}
    >
      {displayName()}
    </div>
  );
}

StatusButton.propTypes = {
  status: PropTypes.string.isRequired,
  optionsCount: PropTypes.shape({
    AGENT_APPROVED: PropTypes.number,
    AGENT_REJECTED: PropTypes.number,
    AGENT_REVIEW: PropTypes.number,
    COMPLETED: PropTypes.number,
    CUSTOMER_IN_PROGRESS: PropTypes.number,
    CUSTOMER_NEW: PropTypes.number,
    CUSTOMER_RETAKE: PropTypes.number,
    DELETED: PropTypes.number,
    EXPIRED: PropTypes.number,
  }),
};

StatusButton.defaultProps = {
  optionsCount: undefined,
};

export default StatusButton;
