import constants, {
  APPROVAL_TYPES,
  CAMERA_TYPE,
  REQUIRED_PHOTO_IDS,
  VIDEO_SECTION,
} from '@lmig-latam/adil-api-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import { IconErrorAlert, IconTrash } from '@lmig/lmds-react/icons';
import {
  Content,
  Disclaimer,
  GridCol,
  GridRow,
  Heading,
} from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InspectionActions, SettingsActions } from '../../actions';
import VideoImg from '../../styles/images/svg/video.svg';
import {
  isValidCameraModule,
  photoStatuses,
  notificationStatus,
  OverlayEnablerStatuses,
  isValidInLatam,
} from '../../utils/constants';

import {
  AppHeader,
  Comments,
  ConfirmationModal,
  CustomerRetakeModal,
  InlineLoader,
  InspectionButtons,
  InspectionSummary,
  SideBar,
  SupportingDocuments,
  VehicleDetails,
  VehiclePhotos,
  CustomerDetails,
  SendNotificationsModal,
  InspectionAssignee,
  InspectionAgentOverrideComment,
  NamedDriverDetails,
  PhotoHistoryModal,
  AppFooter,
} from '../../components';
import ChangeParametersCamera from '../../components/ChangeParametersCamera';
import {
  getCountryCode,
  isAsianDomain,
} from '../../config/environments/environmentsHelpers';
import {
  customerMessagePropType,
  documentPropType,
  inspectionPropType,
  photoServicePropType,
} from '../../prop-types';
import { downloadFileFromUrl, isIntegratedCameraSelected } from '../../utils';

import localizedStrings from '../../utils/localizedStrings';
import {
  DEFAULT,
  ERROR_GENERIC,
  INSPECTION_COMPLETE,
} from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import { getCameraTypes } from '../../constants/camera';
import OverlayEnabler from '../../components/OverlayEnabler/OverlayEnabler';
import { SearchActions } from '../../redux/search-inspection';
import SupportInspectionBox from '../../components/SupportInspectionBox';
import { supportRequestWayConstant } from '../../constants/supportRequest';
import { currentInspectionActions } from '../../redux/current-inspection';
import { supportRequestActions } from '../../redux/support-request';
import { collapsableActions } from '../../redux/collapsables';
import { logcodes, logger } from '../../utils/logger';
import './styles.scss';

const localizedStringsCommonlib = require('@lmig-latam/adil-api-common-lib/utils/localizedStrings');

const CAMERA_TYPES = getCameraTypes();

const { ENABLED } = OverlayEnablerStatuses;

const {
  VEHICLE_CLASS_MOTORBIKE,
  INSPECTION_VEHICLE_CLASS_DEFAULT,
} = localizedStringsCommonlib.getCurrentLanguage();

const { CONFIRMATION, SUCCESS, FAILURE } = notificationStatus;

const {
  STATUS_TYPES: {
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_CUSTOMER_NEW,
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_RETAKE,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_CHANGED_TO_PRESENTIAL,
    STATUS_SUPPORT_INSPECTOR,
    STATUS_EXPIRED,
    STATUS_CANCELLED,
  },
  DEFAULT_ASIGNEE,
} = constants;

const countryConfig = getCountryConfig(getCountryCode());

const { APPROVED, REJECTED } = photoStatuses;

const {
  INSPECTION_TITLE,
  INSPECTION_SUBTITLE,
  INSPECTION_LOADING_LABEL,
  INSPECTION_DELETE_MODAL_TITLE,
  INSPECTION_DELETE_MODAL_SUBTITLE,
  INSPECTION_DELETE_MODAL_CANCEL_BUTTON_LABEL,
  INSPECTION_DELETE_MODAL_CONFIRM_BUTTON_LABEL,
  INSPECTION_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER,
  INSPECTION_DELETE_MODAL_TEXTAREA_TITLE,
  INSPECTION_CANCEL_MODAL_TITLE,
  INSPECTION_CANCEL_MODAL_SUBTITLE,
  INSPECTION_CANCEL_MODAL_CANCEL_BUTTON_LABEL,
  INSPECTION_CANCEL_MODAL_CONFIRM_BUTTON_LABEL,
  INSPECTION_CANCEL_MODAL_MESSAGE_INPUT_PLACEHOLDER,
  INSPECTION_CANCEL_MODAL_TEXTAREA_TITLE,
  INSPECTION_OVERRIDE_MODAL_TITLE,
  INSPECTION_OVERRIDE_MODAL_SUBTITLE,
  INSPECTION_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL,
  INSPECTION_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER,
  INSPECTION_OVERRIDE_MODAL_TEXTAREA_TITLE,
  INSPECTION_APPROVE_BUTTON,
  INSPECTION_REJECT_BUTTON,
  CREATE_INSPECTION_NOTES_TITLE,
} = localizedStrings;

const GET_DOCUMENTS_INDEX = 0;
const GET_CUSTOMER_MESSAGES_INDEX = 1;
const UPDATED_ASSIGNEE_INDEX = 2;

const DEFAULT_PARAMETERS_VIDEO = {
  frequency: 1,
  duration: 1,
  compress: false,
  retryCounter: 0,
  required: false,
  isFinished: false,
  status: APPROVAL_TYPES.PENDING,
  retryList: [{ id: 1, photos: [] }],
};

const Inspection = ({
  inspection: inspectionProp,
  photos: photosProp,
  documents: documentsProp,
  customerMessages: customerMessagesProp,
  username,
  isAdmin,
  isCreator,
  isViewer,
  isReviewer,
  isVendor,
  name,
  displayImageAnalysis,
  inspectionActions: {
    updateInspectionStatus,
    addRejectedPhotosToHistory,
    addCustomerMessage,
    deleteInspection,
    updateInspection,
    updatePhotos,
    getDocuments,
    uploadDocument,
    deleteDocument,
    getCustomerMessages,
    getInspection,
    getPhotos,
    getPhotosZip,
    resendCustomerNotifications,
    assignInspection,
    rotateImage,
  },
  settingsActions: { hideAppLoader, showAppLoader },
  match: {
    params: { inspectionId },
  },
}) => {
  const inlineLoadDefault =
    !inspectionProp || !photosProp || !documentsProp || !customerMessagesProp;
  const [camera, setCamera] = useState(CAMERA_TYPES.NATIVE.value);
  const [waitResponse, setWaitResponse] = useState(false);
  const [approveAllPhotos, setApproveAllPhotos] = useState(false);
  const [cameraId, setCameraId] = useState();
  const [supportForm, setSupportForm] = useState();
  const [updateData, setUpdateData] = useState('');
  const [photosRequiredToFilter, setPhotosRequiredToFilter] = useState([]);
  const [afterInspectionChange, setAfterInspectionChange] = useState(false);
  const [isChangeCameraSuccess, setChangeCameraSuccess] = useState(null);
  const [inspection, setInspection] = useState(inspectionProp);
  const [photos, setPhotos] = useState(
    photosProp ? photosProp.photos : undefined,
  );
  const [documents, setDocuments] = useState(documentsProp);
  const [customerMessages, setCustomerMessages] = useState(
    customerMessagesProp,
  );
  const [isLoadingEmailStatus, setLoadingEmailStatus] = useState(false);
  const [emailStatusInfo, setEmailStatus] = useState(null);
  const [documentsToDelete, setDocumentsToDelete] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [newCustomerMessages, setNewCustomerMessages] = useState([]);
  const [photoModalDisplayed, setPhotoModalDisplayed] = useState(true);

  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(
    inlineLoadDefault,
  );
  const dispatch = useDispatch();
  const {
    supportRequestAdmin: { supportTroubleAndSolutions },
    currentInspection: {
      rejectedPhotoHistory: { displayPhotoHistoryModal },
    },
  } = useSelector(store => store);

  const {
    getPhotosAgain,
    arrayRequiredPhotos,
    arrayPhotosAtMoment,
    currentStatus,
  } = useSelector(store => store.currentInspection);
  const [handleInformAndAnalytics, setHandleInformAndAnalytics] = useState(
    false,
  );
  const [inspectionDirty, setInspectionDirty] = useState(false);
  const [photosDirty, setPhotosDirty] = useState(false);
  const [documentsDirty, setDocumentsDirty] = useState(false);
  const [newDocumentsDirty, setNewDocumentsDirty] = useState(false);
  const [newCustomerMessagesDirty, setNewCustomerMessagesDirty] = useState(
    false,
  );
  const [
    vehicleFieldValidationErrors,
    setVehicleFieldValidationErrors,
  ] = useState({});
  const [
    customerFieldValidationErrors,
    setCustomerFieldValidationErrors,
  ] = useState({});
  const [
    retakeInlineLoaderDisplayed,
    setRetakeInlineLoaderDisplayed,
  ] = useState(false);
  const [
    sendNotificationsLoaderDisplayed,
    setSendNotificationsLoaderDisplayed,
  ] = useState(false);
  const [sendNotificationsModalType, setSendNotificationsModalType] = useState(
    CONFIRMATION,
  );
  const [sendNotificationsErrorCode, setSendNotificationsErrorCode] = useState(
    null,
  );
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [
    customerRetakeModalDisplayed,
    setCustomerRetakeModalDisplayed,
  ] = useState(false);
  const [
    deleteConfirmationModalDisplayed,
    setDeleteConfirmationModalDisplayed,
  ] = useState(false);
  const [
    cancelConfirmationModalDisplayed,
    setCancelConfirmationModalDisplayed,
  ] = useState(false);
  const [isModalNotificationOpen, setIsNotificationModalOpen] = useState(false);
  const [photosZipDownloading, setPhotosZipDownloading] = useState(false);
  const [
    overrideConfirmationModalConfig,
    setOverrideConfirmationModalConfig,
  ] = useState({
    displayed: false,
    status: '',
  });
  const [isFinishedInspection, setIsFinishedInspection] = useState(false);
  const [overlayEnabler, setOverlayEnabler] = useState(false);

  const onClosePhotoHistoryModal = useCallback(() => {
    dispatch(
      currentInspectionActions.onDisplayPhotoHistoryModal(false, null, null),
    );
  }, [dispatch]);

  const navigateToSearchScreen = () => {
    navigate(DEFAULT);
  };

  const navigateToErrorScreen = () => {
    navigate(ERROR_GENERIC);
  };

  const navigateToInspectionAcceptedScreen = () => {
    navigate(INSPECTION_COMPLETE, {
      status: STATUS_AGENT_APPROVED,
    });
  };

  const navigateToInspectionRejectedScreen = () => {
    navigate(INSPECTION_COMPLETE, {
      status: STATUS_AGENT_REJECTED,
    });
  };

  const updateVehicleFieldValidation = errors => {
    setVehicleFieldValidationErrors(errors);
  };

  const updateCustomerFieldValidation = errors => {
    setCustomerFieldValidationErrors(errors);
  };

  const updateCustomer = customer => {
    setInspection({ ...inspection, customer });
    setInspectionDirty(true);
  };

  const updateVehicle = vehicle => {
    setInspection({ ...inspection, vehicle });
    setInspectionDirty(true);
  };

  const formSupport = solutions => {
    setSupportForm(solutions);
  };

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? (
      <div className="inspection-footer-container">
        <AppFooter />
      </div>
    ) : null;

  const deleteExistingDocument = document => {
    const allDocuments = documentsToDelete.concat(document);
    const filteredDocuments = documents.filter(item => item !== document);
    setDocumentsToDelete(allDocuments);
    setDocuments(filteredDocuments);
    setDocumentsDirty(allDocuments.length > 0);
  };

  const deleteNewDocument = document => {
    const filteredDocuments = newDocuments.filter(item => item !== document);
    setNewDocuments(filteredDocuments);
    setNewDocumentsDirty(filteredDocuments.length > 0);
  };

  const filterExistingDocuments = documentsToAdd => {
    const allDocs = [...documents, ...newDocuments];
    return documentsToAdd.filter(doc =>
      allDocs.every(item => doc.name !== item.name),
    );
  };

  const addNewDocuments = documentsToAdd => {
    const filteredDocumentsToAdd = filterExistingDocuments(documentsToAdd);
    if (filteredDocumentsToAdd.length > 0) {
      setNewDocuments(newDocuments.concat(filteredDocumentsToAdd));
      setNewDocumentsDirty(true);
    }
  };

  const updateExistingPhotos = existingPhotos => {
    setPhotos(existingPhotos);
    setPhotosDirty(true);
  };

  const updateComments = comments => {
    setInspection({ ...inspection, comments });
    setInspectionDirty(true);
  };

  const addNewCustomerMessage = newCustomerMessage => {
    const newCustomerMessageItem = {
      customerMessage: newCustomerMessage,
      agentId: username,
    };
    const allCustomerMessages = newCustomerMessages.concat(
      newCustomerMessageItem,
    );
    setNewCustomerMessages(allCustomerMessages);
    setNewCustomerMessagesDirty(allCustomerMessages.length > 0);
  };

  const showCustomerRetakeModal = () => {
    setCustomerRetakeModalDisplayed(true);
  };

  const hideCustomerRetakeModal = () => {
    setRetakeInlineLoaderDisplayed(false);
    setShowMessageSuccess(false);
    setCustomerRetakeModalDisplayed(false);
  };

  const showDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDisplayed(true);
  };

  const hideDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDisplayed(false);
  };

  const showCancelConfirmationModal = () => {
    setCancelConfirmationModalDisplayed(true);
  };

  const hideCancelConfirmationModal = () => {
    setCancelConfirmationModalDisplayed(false);
  };

  const showOverrideConfirmationModal = status => {
    setOverrideConfirmationModalConfig({ displayed: true, status });
  };

  const hideOverrideConfirmationModal = () => {
    setOverrideConfirmationModalConfig({ displayed: false, status: '' });
  };

  const overrideValidator = () => {
    if (isAsianDomain()) {
      return (
        [
          STATUS_AGENT_APPROVED,
          STATUS_AGENT_REJECTED,
          STATUS_CANCELLED,
        ].includes(inspection.status) && !isAdmin
      );
    }
    return [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_CANCELLED,
    ].includes(inspection.status);
  };

  const getRequiredPhotos = () => {
    const { vehicle, namedDriver } = inspection;
    const country = getCountryCode();
    let countryRequiredPhotos = countryConfig.countryRequiredPhotoIds;
    if (country === 'th') {
      const photoToDelete = [];
      if (!namedDriver) {
        photoToDelete.push(
          REQUIRED_PHOTO_IDS.INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1,
          REQUIRED_PHOTO_IDS.INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2,
        );
      } else if (namedDriver.drivers.length === 1) {
        photoToDelete.push(
          REQUIRED_PHOTO_IDS.INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2,
        );
      }

      countryRequiredPhotos = countryConfig.countryRequiredPhotoIds.filter(
        photo => !photoToDelete.includes(photo),
      );
    }
    if (vehicle.class === VEHICLE_CLASS_MOTORBIKE) {
      countryRequiredPhotos = countryConfig.countryMotorBikeRequiredPhotosIds;
    }
    return countryRequiredPhotos;
  };

  const updateInspectionState = inspectionStatus => {
    setInspection({
      ...inspection,
      status: inspectionStatus,
    });
    setInspectionDirty(true);
    if (getCountryCode() === 'vn')
      dispatch(collapsableActions.closeAllCollapsables(false));
  };

  const isUnassigned = () =>
    inspection.assignee === DEFAULT_ASIGNEE || !inspection.assignee;

  const isAnythingDirty = () =>
    newCustomerMessagesDirty ||
    documentsDirty ||
    newDocumentsDirty ||
    photosDirty ||
    inspectionDirty;

  const updateInspectionAttributes = async () => {
    const promises = [];

    // Update Photos
    if (photosDirty) {
      promises.push(updatePhotos(inspection.id, photos));
    }

    // Upload New Documents
    if (newDocumentsDirty) {
      newDocuments.forEach(document => {
        promises.push(uploadDocument(inspection.id, document));
      });
    }

    // Delete Documents
    if (documentsDirty) {
      documentsToDelete.forEach(document => {
        promises.push(deleteDocument(inspection.id, document));
      });
    }

    await Promise.all(promises);

    // Now we have uploaded all the updated data, refresh certain data from the server
    // Including:
    // * Documents
    // * Customer Messages
    const updatedDataPromises = new Array(2);

    // Get the updated list of existing and newly uploaded documents
    if (newDocumentsDirty || documentsDirty) {
      updatedDataPromises[GET_DOCUMENTS_INDEX] = getDocuments(inspection.id);
    }

    // Get the updated list of Customer Messages
    if (newCustomerMessagesDirty) {
      updatedDataPromises[GET_CUSTOMER_MESSAGES_INDEX] = getCustomerMessages(
        inspection.id,
      );
    }

    // Auto assign inspection on first update if its unassigned
    if (isUnassigned() && isAnythingDirty()) {
      const { id } = inspection;
      updatedDataPromises[UPDATED_ASSIGNEE_INDEX] = assignInspection(id, name);
    }

    // Wait for the updated data
    const updatedData = await Promise.all(updatedDataPromises);
    return updatedData;
  };

  const resetAllInspection = updatedData => {
    // Mark all items as no longer dirty
    setInspectionDirty(false);
    setPhotosDirty(false);
    setDocumentsDirty(false);
    setNewDocumentsDirty(false);
    setNewCustomerMessagesDirty(false);

    // If we have updated documents data, update the state
    if (updatedData[GET_DOCUMENTS_INDEX]) {
      setDocuments(updatedData[GET_DOCUMENTS_INDEX]);
      setDocumentsToDelete([]);
      setNewDocuments([]);
    }

    // If we have updated customer messages data, update the state
    if (updatedData[GET_CUSTOMER_MESSAGES_INDEX]) {
      setCustomerMessages(updatedData[GET_CUSTOMER_MESSAGES_INDEX]);
      setNewCustomerMessages([]);
    }

    // If we have assignee, update the state
    if (updatedData[UPDATED_ASSIGNEE_INDEX]) {
      const { assignee } = updatedData[UPDATED_ASSIGNEE_INDEX];
      setInspection({ ...inspection, assignee });
    }
  };

  const setSupportBySwith = supportRequestWay => {
    if (inspection) {
      switch (supportRequestWay) {
        case 'CONTINUE':
          // setinspection with history in support request and satatus change.
          setInspection(prevState => ({
            ...prevState,
            status: prevState.supportRequest.lastCustomerStatus,
            supportRequest: {
              ...prevState.supportRequest,
              history: [
                {
                  textareaSupport: prevState.supportRequest.textareaSupport,
                  troubles: prevState.supportRequest.troubles,
                  supportForm,
                },
              ],
            },
          }));
          setAfterInspectionChange(true);
          break;

        case 'PRESENTIAL':
          // setinspection with history in support request and satatus change.
          setInspection(prevState => ({
            ...prevState,
            status: STATUS_CHANGED_TO_PRESENTIAL,
            supportRequest: {
              ...prevState.supportRequest,
              history: [
                {
                  textareaSupport: prevState.supportRequest.textareaSupport,
                  troubles: prevState.supportRequest.troubles,
                  supportForm,
                },
              ],
            },
          }));
          setAfterInspectionChange(true);
          break;

        default:
          break;
      }
    }
  };

  const saveInspection = async () => {
    try {
      const updatedData = await updateInspectionAttributes();
      // Update Inspection
      if (inspectionDirty) {
        await updateInspection(inspection);
      }
      if (updatedData) resetAllInspection(updatedData);
    } catch (error) {
      // Hide App Loader
      navigateToErrorScreen();
    }
  };

  const handlePassPresential = async () => {
    try {
      showAppLoader();
      setSupportBySwith(supportRequestWayConstant.presential);
      const updatedData = await updateInspectionAttributes();
      setUpdateData(updatedData);
    } catch (error) {
      // Hide App Loader
      navigateToErrorScreen();
    } finally {
      hideAppLoader();
    }
  };

  const handleContinueADIL = async () => {
    // get the last status before supportRequest
    try {
      showAppLoader();
      setSupportBySwith(supportRequestWayConstant.continue);
      const updatedData = await updateInspectionAttributes();
      setUpdateData(updatedData);
    } catch (error) {
      // Hide App Loader
      navigateToErrorScreen();
    } finally {
      hideAppLoader();
    }
  };

  useEffect(() => {
    if (inspection?.supportRequest?.history && afterInspectionChange) {
      updateInspection(inspection);
      setAfterInspectionChange(false);

      if (updateData) {
        resetAllInspection(updateData);
        setUpdateData('');
        setSupportForm({});
        navigateToSearchScreen();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection, afterInspectionChange]);

  useEffect(() => {
    if (arrayPhotosAtMoment.length !== 0 && arrayRequiredPhotos.length !== 0) {
      const filterRequiredFromMoment = arrayPhotosAtMoment.filter(photo =>
        arrayRequiredPhotos.includes(photo.name),
      );
      setPhotosRequiredToFilter(filterRequiredFromMoment);
    }
  }, [arrayPhotosAtMoment, arrayRequiredPhotos]);

  const isInspectionReadyForApproveOrReject = statusInspection => {
    const { INSPECTION_VIDEO_PHOTOS_VEHICLE } = VIDEO_SECTION;
    const photosFiltered = photos.filter(
      i =>
        i.name !== INSPECTION_VIDEO_PHOTOS_VEHICLE &&
        !String(i.name).includes('DAMAGE'),
    );

    const countryRequiredPhotos = getRequiredPhotos();
    // Count the number of required photos present by comparing against the countryRequiredPhotoIds object
    const numberOfRequiredPhotos = photosFiltered.filter(photo =>
      countryRequiredPhotos.includes(photo.name),
    ).length;
    // Inspection can be approved/rejected when ALL of the following conditions are met:

    // All required photos are present
    const allRequiredPhotosPresent =
      numberOfRequiredPhotos === countryRequiredPhotos.length;

    // All photos are in approved state
    const allPhotosApproved = photosFiltered.every(
      photo => photo.status === APPROVED,
    );

    // The comments field will be empty if is approved.
    let commentsArePresent = inspection.comments.length > 0;
    if (statusInspection === STATUS_AGENT_APPROVED) {
      commentsArePresent = true;
    }

    // All fields pass the validation check
    const noValidationErrors =
      customerFieldValidationErrors.length === 0 &&
      vehicleFieldValidationErrors.length === 0;

    // The inspection has NOT been previously accepted, cancelled or rejected
    const inspectionNotCompleted =
      [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED, STATUS_CANCELLED].indexOf(
        inspection.status,
      ) === -1;

    return (
      allPhotosApproved &&
      allRequiredPhotosPresent &&
      commentsArePresent &&
      noValidationErrors &&
      inspectionNotCompleted
    );
  };

  const handleApprove = async reason => {
    try {
      updateInspectionState(STATUS_AGENT_APPROVED);
      hideOverrideConfirmationModal();

      showAppLoader();

      await saveInspection();

      await updateInspectionStatus(
        inspection.id,
        STATUS_AGENT_APPROVED,
        name,
        reason,
        null,
        isAdmin,
      );

      navigateToInspectionAcceptedScreen();
    } catch (error) {
      navigateToErrorScreen();
    } finally {
      hideAppLoader();
    }
  };

  const handleReject = async comment => {
    try {
      updateInspectionState(STATUS_AGENT_REJECTED);

      hideOverrideConfirmationModal();

      showAppLoader();

      await saveInspection();

      await updateInspectionStatus(
        inspection.id,
        STATUS_AGENT_REJECTED,
        name,
        comment,
        null,
        isAdmin,
      );

      navigateToInspectionRejectedScreen();
    } catch (error) {
      navigateToErrorScreen();
    } finally {
      hideAppLoader();
    }
  };

  const handleCancel = async cancellationReason => {
    try {
      const promises = [];
      const { id } = inspection;
      showAppLoader();
      hideCancelConfirmationModal();
      promises.push(
        updateInspectionStatus(
          id,
          STATUS_CANCELLED,
          name,
          cancellationReason,
          null,
          isAdmin,
        ),
      );
      await Promise.all(promises);
    } catch (error) {
      navigateToErrorScreen();
    } finally {
      navigateToSearchScreen();
      hideAppLoader();
    }
  };

  const approveOrRejectInspection = (status, comment) => {
    if (status === STATUS_AGENT_APPROVED) {
      handleApprove(comment);
    }

    if (status === STATUS_AGENT_REJECTED) {
      handleReject(comment);
    }
  };

  const handleInspectionCompletionFlow = status => {
    if (isInspectionReadyForApproveOrReject(status)) {
      approveOrRejectInspection(status);
    } else {
      showOverrideConfirmationModal(status);
    }
  };

  const handleSendComments = async message => {
    try {
      const newCustomerMessage = {
        customerMessage: message,
        agentId: username,
      };

      const rejectedPhotos = photos
        .filter(item => item.status === REJECTED)
        .map(item => item.name);

      const retakeStatus = inspection.vendorSupport
        ? STATUS_SUPPORT_RETAKE
        : STATUS_CUSTOMER_RETAKE;
      setRetakeInlineLoaderDisplayed(true);

      updateInspectionState(retakeStatus);

      await saveInspection();
      await addCustomerMessage(inspection.id, newCustomerMessage);

      const updateInspectionPromise = updateInspectionStatus(
        inspection.id,
        retakeStatus,
        null,
        null,
        rejectedPhotos,
        isAdmin,
      );

      if (isAsianDomain()) {
        await updateInspectionPromise;
        setRetakeInlineLoaderDisplayed(false);
        setShowMessageSuccess(true);
        return;
      }

      const photoHistoryPromise = addRejectedPhotosToHistory(
        inspection.id,
        STATUS_CUSTOMER_RETAKE,
        rejectedPhotos,
        newCustomerMessage.customerMessage,
      );

      await Promise.all([updateInspectionPromise, photoHistoryPromise]);

      const response = await getPhotos(inspectionId);

      if (response.photoHistory) {
        dispatch(
          currentInspectionActions.setRetakePhotoHistory(response.photoHistory),
        );
      }
      setRetakeInlineLoaderDisplayed(false);
      setShowMessageSuccess(true);
    } catch (error) {
      navigateToErrorScreen();
    }
  };

  const handleReturn = () => {
    dispatch(SearchActions.resetAll());
    navigateToSearchScreen();
    if (getCountryCode() === 'vn')
      dispatch(collapsableActions.closeAllCollapsables(false));
  };

  const handleSave = async () => {
    showAppLoader();
    await saveInspection();
    hideAppLoader();
    if (getCountryCode() === 'vn')
      dispatch(collapsableActions.closeAllCollapsables(false));
  };

  const handleSaveAndExit = async () => {
    showAppLoader();
    await saveInspection();
    navigateToSearchScreen();
    hideAppLoader();
    if (getCountryCode() === 'vn')
      dispatch(collapsableActions.closeAllCollapsables(false));
  };

  const handleDelete = async agentComments => {
    try {
      const deleteComment = {
        comments: agentComments,
      };

      const { id } = inspection;

      showAppLoader();
      hideDeleteConfirmationModal();
      await deleteInspection(id, deleteComment);
      navigateToSearchScreen();
      hideAppLoader();
    } catch (error) {
      navigateToErrorScreen();
    }
  };

  const anyRejectedPhotos = () =>
    photos.some(photo => photo.status === REJECTED);

  const openNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };

  const closeNotificationModal = () => {
    setSendNotificationsModalType(CONFIRMATION);
    setSendNotificationsErrorCode(null);
    setIsNotificationModalOpen(false);
  };
  const overlayFieldValidator = () => {
    if (!isAsianDomain()) {
      return cameraId === CAMERA_TYPES.FRAME.id ? overlayEnabler : false;
    }
    return true;
  };

  const sendNotifications = async () => {
    setSendNotificationsLoaderDisplayed(true);
    try {
      await saveInspection();
      await resendCustomerNotifications(inspectionId);
      setSendNotificationsModalType(SUCCESS);

      if (!isAsianDomain()) {
        setLoadingEmailStatus(true);
        setTimeout(async () => {
          const { responseInspection } = await getInspection(inspectionId);
          setInspection(responseInspection);
          if (responseInspection?.emailStatusInfo) {
            const { emailStatusInfo } = responseInspection;
            setEmailStatus(emailStatusInfo);
            setLoadingEmailStatus(false);
          }
        }, 10000);
      }
    } catch (error) {
      setSendNotificationsModalType(FAILURE);
      setSendNotificationsErrorCode(error.code);
    }
    setSendNotificationsLoaderDisplayed(false);
  };

  const handleAssignInspection = async () => {
    const { id } = inspection;
    const overrideStatus = [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_CANCELLED,
    ].includes(inspection.status);
    showAppLoader();
    const { assignee, status } = await assignInspection(
      id,
      name,
      overrideStatus,
    );
    setInspection({
      ...inspection,
      assignee,
      ...(overrideStatus && { status }),
    });

    if (getCountryCode() === 'vn' && !isVendor) setApproveAllPhotos(true);
    setPhotosDirty(true);
    hideAppLoader();
  };

  const handleRotateImage = async (url, photoName, rotateDirection) => {
    try {
      const { id } = inspection;
      const photo = { name: `${photoName}.jpeg` };
      const response = await rotateImage(id, photo, rotateDirection);
      const updatedPhotos = photos.map(item =>
        item.name === photoName ? { ...item, url: response.url } : item,
      );
      setPhotos(updatedPhotos);
      return response;
    } catch (error) {
      logger.log(logcodes.ROTI010, { error, photoName });
      return null;
    }
  };

  const isAssignedToYou = () => inspection.assignee === name;

  const isAssignedToSomeoneElse = () => !isAssignedToYou() && !isUnassigned();

  const enableForCreator = () => {
    if (isCreator) {
      return [
        STATUS_AGENT_APPROVED,
        STATUS_AGENT_REJECTED,
        STATUS_EXPIRED,
        STATUS_CANCELLED,
      ].includes(inspection.status);
    }
    return true;
  };

  const isAssignButtonDisabled = () =>
    isCreator ||
    overrideValidator() ||
    isAssignedToYou() ||
    isViewer ||
    [STATUS_EXPIRED].includes(inspection.status);

  const isReadOnly = (
    statusArray = [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_CHANGED_TO_PRESENTIAL,
      STATUS_CANCELLED,
      STATUS_CUSTOMER_NEW,
      STATUS_CUSTOMER_RETAKE,
      STATUS_SUPPORT_NEW,
      STATUS_SUPPORT_RETAKE,
      STATUS_EXPIRED,
    ],
  ) =>
    isCreator ||
    statusArray.includes(inspection.status) ||
    isAssignedToSomeoneElse() ||
    isViewer;

  const isDisabledFeature = () => {
    if (getCountryCode() === 'vn') {
      return isReadOnly([STATUS_EXPIRED, STATUS_CANCELLED]);
    }
    return isReadOnly();
  };

  const enableDownloadButton = () => !isVendor && enableForCreator();

  const renderInlineLoader = () => (
    <div className="inspection-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {INSPECTION_LOADING_LABEL}
      </Heading>
    </div>
  );

  const downloadPhotosZip = async () => {
    setPhotosZipDownloading(true);

    const { photoZipUrl } = await getPhotosZip(inspectionId, {
      isFinished: isFinishedInspection,
    });

    downloadFileFromUrl(photoZipUrl, `${inspectionId}.zip`);

    setPhotosZipDownloading(false);
  };

  const disableUploadPhotos = () =>
    isReadOnly([STATUS_EXPIRED]) || getCountryCode() !== 'vn';

  const isDisabledSupportForm = () =>
    ![
      STATUS_SUPPORT_NEW,
      STATUS_SUPPORT_RETAKE,
      STATUS_SUPPORT_IN_PROGRESS,
      STATUS_SUPPORT_INSPECTOR,
    ].includes(inspection.status) || !isAssignedToYou();

  const disableChangeCamera = () =>
    isCreator ||
    [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_EXPIRED,
      STATUS_CANCELLED,
      STATUS_CHANGED_TO_PRESENTIAL,
    ].includes(inspection.status) ||
    !isAssignedToYou();

  const agentOverrideCompletion = () => {
    if (
      !isValidInLatam.includes(getCountryCode()) ||
      !inspection.agentOverrideComment
    ) {
      return false;
    }
    if (
      ![
        STATUS_AGENT_APPROVED,
        STATUS_AGENT_REJECTED,
        STATUS_CANCELLED,
      ].includes(inspection.status)
    ) {
      return false;
    }

    return true;
  };

  const agentOverrideAsia = () => {
    if (!inspection.agentOverrideComment) {
      return false;
    }

    return true;
  };

  const renderInspection = () => {
    const readOnly = isReadOnly();

    const isCustomerDetailsReadOnly =
      [
        STATUS_AGENT_APPROVED,
        STATUS_AGENT_REJECTED,
        STATUS_CHANGED_TO_PRESENTIAL,
        STATUS_EXPIRED,
        STATUS_CANCELLED,
      ].includes(inspection.status) ||
      isViewer ||
      isVendor ||
      !isAssignedToYou();

    const updateRequiredCameraStatus = async video => {
      try {
        await updateInspection({ ...inspection, video });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    const updateCameraType = async () => {
      const overlay = overlayFieldValidator();
      const cameraType = cameraId;
      switch (cameraId) {
        case CAMERA_TYPE.NATIVE:
          inspection.enableVideoSection = false;
          delete inspection.video;
          break;
        case CAMERA_TYPE.FRAME:
          inspection.enableVideoSection = true;
          inspection.video = DEFAULT_PARAMETERS_VIDEO;
          break;
        default:
      }

      try {
        setWaitResponse(true);
        await updateInspection({ ...inspection, cameraType, overlay });
        setInspection(prev => ({ ...prev, cameraType: cameraId }));

        setChangeCameraSuccess(true);
        setTimeout(() => {
          setChangeCameraSuccess(null);
        }, 5000);
        setWaitResponse(false);
      } catch (err) {
        setChangeCameraSuccess(false);
        setWaitResponse(false);
      }
    };

    const onChangeRequiredVideo = async checked => {
      const video = {
        ...DEFAULT_PARAMETERS_VIDEO,
        required: checked,
      };

      try {
        await updateRequiredCameraStatus(video);
        setInspection(prev => ({ ...prev, video }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    const handlerOverlayEnabler = overlayStatus => {
      const { status } = overlayStatus;
      if (status === ENABLED) setOverlayEnabler(true);
      else setOverlayEnabler(false);
    };

    const disablePhotos = () => {
      if (approveAllPhotos) {
        return false;
      }
      const statusToValidate =
        getCountryCode() === 'vn'
          ? [
              STATUS_AGENT_APPROVED,
              STATUS_AGENT_REJECTED,
              STATUS_CANCELLED,
              STATUS_EXPIRED,
            ]
          : [
              STATUS_AGENT_APPROVED,
              STATUS_AGENT_REJECTED,
              STATUS_CANCELLED,
              STATUS_CUSTOMER_NEW,
              STATUS_CUSTOMER_RETAKE,
              STATUS_SUPPORT_NEW,
              STATUS_SUPPORT_RETAKE,
              STATUS_EXPIRED,
              STATUS_SUPPORT_NEW,
              STATUS_SUPPORT_IN_PROGRESS,
              STATUS_SUPPORT_INSPECTOR,
              STATUS_CHANGED_TO_PRESENTIAL,
            ];
      return isReadOnly(statusToValidate) || isUnassigned() || isVendor;
    };

    const onUpdateVideo = async rotation => {
      const inspectionUpdated = {
        ...inspection,
        video: { ...inspection.video, rotation },
      };
      await updateInspection(inspectionUpdated);
      setInspection(inspectionUpdated);
    };
    const enableNamedDriverComponent = () =>
      getCountryCode() === 'th' && inspection.namedDriver;

    return (
      <div>
        <Heading type="h3-light" className="auto-left-right-margin">
          {INSPECTION_TITLE}
        </Heading>

        <Disclaimer>{INSPECTION_SUBTITLE}</Disclaimer>

        {agentOverrideCompletion() && (
          <InspectionAgentOverrideComment
            agentName={
              inspection.agentOverrideComment[
                inspection.agentOverrideComment.length - 1
              ].agentId
            }
            overrideComment={
              inspection.agentOverrideComment[
                inspection.agentOverrideComment.length - 1
              ].overrideComment
            }
          />
        )}

        <InspectionAssignee
          assignee={inspection.assignee}
          assignInspection={handleAssignInspection}
          assignDisabled={isAssignButtonDisabled()}
        />

        <InspectionSummary inspection={inspection} />

        <CustomerDetails
          showNotificationModal={openNotificationModal}
          customer={{ ...inspection.customer, smsPin: inspection.smsPin }}
          updateFieldValidation={updateCustomerFieldValidation}
          updateCustomer={updateCustomer}
          emailStatusInfo={emailStatusInfo}
          readOnly={isCustomerDetailsReadOnly}
          isLoadingEmailStatus={isLoadingEmailStatus}
        />

        {enableNamedDriverComponent() && (
          <NamedDriverDetails
            namedDrivers={inspection.namedDriver}
            updateFieldValidation={updateCustomerFieldValidation}
            readOnly
          />
        )}

        {isValidCameraModule.includes(getCountryCode()) && (
          <>
            <ChangeParametersCamera
              cameraType={inspection.cameraType}
              success={isChangeCameraSuccess}
              initialValue={camera}
              onChange={cameraType => setCameraId(cameraType)}
              readOnly={disableChangeCamera() || waitResponse}
              updateCamera={updateCameraType}
              isRequiredVideo={inspection.video?.required}
              onChangeRequiredVideo={onChangeRequiredVideo}
            />

            {isIntegratedCameraSelected(cameraId) && (
              <OverlayEnabler
                onChangeOverlayEnabler={handlerOverlayEnabler}
                disabled={readOnly}
              />
            )}
          </>
        )}

        <VehicleDetails
          vehicle={inspection.vehicle}
          updateVehicle={updateVehicle}
          updateFieldValidation={updateVehicleFieldValidation}
          readOnly={readOnly || isVendor}
        />

        {getCountryCode() === 'vn' && inspection.creatorNote && (
          <GridRow className="adil_noteField">
            <GridCol>
              <p>{CREATE_INSPECTION_NOTES_TITLE}</p>
              <div className="adilDisabledInformation">
                <span>{inspection.creatorNote}</span>
              </div>
            </GridCol>
          </GridRow>
        )}

        {inspection.supportRequest &&
          isValidInLatam.indexOf(getCountryCode()) !== -1 && (
            <SupportInspectionBox
              supportTroubles={inspection.supportRequest.troubles}
              supportSolutions={supportTroubleAndSolutions?.solutions || []}
              formSupport={formSupport}
              disabledSupportForm={isDisabledSupportForm()}
              textareaSupport={inspection.supportRequest.textareaSupport}
              historySupport={inspection.supportRequest.history || []}
            />
          )}

        <VehiclePhotos
          photos={photos}
          approveAllPhotos={approveAllPhotos}
          inspectionId={inspection?.id}
          hideButtonUploadPhotos={disableUploadPhotos()}
          downloadPhotosZip={downloadPhotosZip}
          downloadDisabled={!enableForCreator() || isVendor}
          photosZipDownloading={photosZipDownloading}
          updatePhotos={updateExistingPhotos}
          customerMessages={customerMessages}
          newCustomerMessages={newCustomerMessages}
          readOnly={disablePhotos()}
          displayImageAnalysis={displayImageAnalysis}
          vehicleClass={inspection.vehicle.class || ''}
          rotateImage={handleRotateImage}
          updateVideo={onUpdateVideo}
          videoData={inspection?.video}
          drivers={inspection.namedDriver?.drivers}
          setPhotos={setPhotos}
          photoModalDisplayed={photoModalDisplayed}
          setPhotoModalDisplayed={setPhotoModalDisplayed}
        />

        <SupportingDocuments
          documents={documents}
          inspectionId={inspection?.id}
          newDocuments={newDocuments}
          deleteDocument={deleteExistingDocument}
          deleteNewDocument={deleteNewDocument}
          addNewDocuments={addNewDocuments}
          readOnly={isDisabledFeature()}
          downloadZipButton={enableDownloadButton()}
        />

        <Comments
          inspection={inspection}
          updateComments={updateComments}
          readOnly={readOnly}
        />

        {agentOverrideAsia() && (
          <InspectionAgentOverrideComment
            agentName={
              inspection.agentOverrideComment[
                inspection.agentOverrideComment.length - 1
              ].agentId
            }
            overrideComment={
              inspection.agentOverrideComment[
                inspection.agentOverrideComment.length - 1
              ].overrideComment
            }
          />
        )}

        {renderFooterSection()}

        <CustomerRetakeModal
          showModalInlineLoader={retakeInlineLoaderDisplayed}
          showMessageSuccess={showMessageSuccess}
          isOpen={customerRetakeModalDisplayed}
          closeButtonHandler={hideCustomerRetakeModal}
          addNewCustomerMessage={addNewCustomerMessage}
          handleSendComments={handleSendComments}
        />
      </div>
    );
  };

  const doesInspectionHaveAnyRejectedPhotos = () => {
    const { status } = inspection;
    const requiredPhotosIds = getRequiredPhotos();
    const numberOfRequiredPhotos = photos.filter(photo =>
      requiredPhotosIds.includes(photo.name),
    ).length;

    const isInpectionInValidStatus = ![
      STATUS_SUPPORT_RETAKE,
      STATUS_CUSTOMER_RETAKE,
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_CANCELLED,
      STATUS_SUPPORT_INSPECTOR,
      STATUS_SUPPORT_NEW,
      STATUS_SUPPORT_IN_PROGRESS,
      STATUS_CHANGED_TO_PRESENTIAL,
    ].includes(status);

    const allRequiredPhotosPresent =
      numberOfRequiredPhotos === requiredPhotosIds.length;

    const notAllPhotosApproved = anyRejectedPhotos();
    const { INSPECTION_VIDEO_PHOTOS_VEHICLE: videoId } = VIDEO_SECTION;

    const allPhotosApprovedOrRejectedArray = photos.filter(
      photo => photo.name !== videoId && !photo.name.includes('DAMAGE_PHOTO'),
    );

    const allPhotosApprovedOrRejected = allPhotosApprovedOrRejectedArray.every(
      photo =>
        (photo.status === APPROVED || photo.status === REJECTED) &&
        photo.name !== videoId,
    );

    return (
      allRequiredPhotosPresent &&
      notAllPhotosApproved &&
      allPhotosApprovedOrRejected &&
      isInpectionInValidStatus
    );
  };

  const disableSaveButton = () =>
    customerFieldValidationErrors.length !== 0 ||
    vehicleFieldValidationErrors.length !== 0 ||
    isDisabledFeature();

  const disableApproveRejectDelete = () =>
    isVendor ||
    isViewer ||
    isCreator ||
    [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_CHANGED_TO_PRESENTIAL,
      STATUS_EXPIRED,
      STATUS_CANCELLED,
    ].includes(inspection.status) ||
    (isReviewer && !isAssignedToYou());

  const showDeleteButton = () =>
    isValidInLatam.includes(getCountryCode()) && isAdmin;

  const showCancelButton = () => getCountryCode() === 'vn';

  const disableCancelButton = () =>
    isViewer ||
    (isReviewer && !isAssignedToYou()) ||
    [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_CHANGED_TO_PRESENTIAL,
      STATUS_EXPIRED,
      STATUS_CANCELLED,
    ].includes(inspection.status);

  const validateOldInspection = inspectionRaw => {
    const inspectionData = inspectionRaw;
    const { vehicle } = inspectionData;

    if (!vehicle.class) {
      vehicle.class = INSPECTION_VEHICLE_CLASS_DEFAULT;
    }
    inspectionData.vehicle = vehicle;
    return inspectionData;
  };

  const preloadDataVideo = (videoPhotos = []) => {
    let itemRowVideo;
    if (videoPhotos.length > 0) {
      const { ageInSeconds } = videoPhotos[0];
      const { INSPECTION_VIDEO_PHOTOS_VEHICLE: videoId } = VIDEO_SECTION;
      const { PENDING } = APPROVAL_TYPES;
      itemRowVideo = {
        tags: [],
        photos: videoPhotos,
        name: videoId,
        url: VideoImg,
        status: PENDING,
        ageInSeconds,
        readOnly: true,
      };
    }
    return itemRowVideo;
  };

  useEffect(() => {
    const preloadData = async () => {
      const promises = [];
      let currentInspection = inspectionProp;
      let finished = false;

      if (!currentInspection) {
        const { responseInspection, isFinished } = await getInspection(
          inspectionId,
        );
        currentInspection = responseInspection;
        finished = isFinished;
      }
      setIsFinishedInspection(finished);
      const queryObject = {
        isFinished: finished,
      };

      // Fetch photos if we don't already have them
      promises.push(
        photosProp ||
          getPhotos(inspectionId, {
            assignee: name,
            isFinished: finished,
          }),
      );

      // Fetch documents if we don't already have them
      promises.push(documentsProp || getDocuments(inspectionId, queryObject));

      // Fetch customer messages if we don't already have them
      promises.push(
        customerMessagesProp || getCustomerMessages(inspectionId, queryObject),
      );

      dispatch(currentInspectionActions.setResetPhotoHistory());
      const results = await Promise.all(promises);

      const [
        responsePhotos,
        responseDocuments,
        responseCustomerMessages,
      ] = results;

      if (currentInspection.emailStatusInfo) {
        const { emailStatusInfo } = currentInspection;
        setEmailStatus(emailStatusInfo);
      }

      if (currentInspection.supportRequest) {
        dispatch(
          supportRequestActions.getSupportRequestDb(
            getCountryCode()
              .toLowerCase()
              .toString(),
          ),
        );
      }

      if (!currentInspection.cameraType) setCamera(CAMERA_TYPES.NATIVE.value);
      else if (CAMERA_TYPES.FRAME.id === currentInspection.cameraType)
        setCamera(CAMERA_TYPES.FRAME.value);
      else setCamera(CAMERA_TYPES.NATIVE.value);

      let damagePhotos = responsePhotos?.damagePhotos || [];

      if (damagePhotos) {
        damagePhotos.sort((a, b) => a.name - b.name);
        damagePhotos = damagePhotos.map((photo, index) => ({
          ...photo,
          id: index + 1,
          readOnly: true,
        }));
      }

      let videoPhotos;
      if (currentInspection.enableVideoSection) {
        videoPhotos = preloadDataVideo(responsePhotos.videoPhotos);
      }

      // Hide the loader & update the state
      setInspection(validateOldInspection(currentInspection));
      setPhotos(prevPhotos => {
        const mergedPhotos = [
          ...(prevPhotos || []),
          ...(responsePhotos.photos || []),
        ];
        const conditionalyPhotos = mergedPhotos.map(item => ({
          ...item,
          readOnly: false,
        }));

        const result = [...conditionalyPhotos, ...damagePhotos];
        if (videoPhotos) result.push(videoPhotos);

        return result;
      });

      if (responsePhotos.photoHistory) {
        dispatch(
          currentInspectionActions.setRetakePhotoHistory(
            responsePhotos.photoHistory,
          ),
        );
      }

      setDocuments(responseDocuments);
      setCustomerMessages(responseCustomerMessages);
      setInlineLoaderDisplayed(false);
      setCameraId(currentInspection.cameraType);
    };
    preloadData();
  }, [
    customerMessagesProp,
    documentsProp,
    getCustomerMessages,
    getDocuments,
    getInspection,
    getPhotos,
    inspectionId,
    inspectionProp,
    name,
    photosProp,
    dispatch,
  ]);

  useEffect(() => {
    if (inspection) {
      const { countryRequiredPhotoIds } = getCountryConfig(getCountryCode());
      dispatch(
        currentInspectionActions.updateInspectionDataRedux({
          id: inspection.id,
          arrayRequiredPhotos: countryRequiredPhotoIds,
          arrayPhotosAtMoment: photos,
          currentStatus: inspection.status,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection]);

  useEffect(() => {
    if (
      currentStatus !== null &&
      inspection &&
      inspection.status !== currentStatus
    ) {
      updateInspectionState(currentStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  const reviewAllPhotosApproved = () =>
    photosRequiredToFilter.every(
      photo => photo.status === photoStatuses.APPROVED,
    );

  useEffect(() => {
    if (photos) {
      dispatch(
        currentInspectionActions.updateInspectionDataRedux({
          arrayPhotosAtMoment: photos,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  useEffect(() => {
    if (
      inspection &&
      isAssignedToYou() &&
      photosRequiredToFilter.length !== 0
    ) {
      setHandleInformAndAnalytics(reviewAllPhotosApproved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateExistingPhotos, inspection]);

  useEffect(() => {
    if (arrayPhotosAtMoment?.length > 0 && arrayRequiredPhotos?.length > 0) {
      const arrayPhotosAtMomentTemp = [];
      arrayPhotosAtMoment.forEach(element => {
        arrayPhotosAtMomentTemp.push(element.name);
      });
      arrayPhotosAtMomentTemp.sort();
      arrayRequiredPhotos.sort();
    }
  }, [arrayPhotosAtMoment, arrayRequiredPhotos]);

  useEffect(() => {
    if (getPhotosAgain) {
      const getPhotosFromModal = async () => {
        const photosInspection = await getPhotos(inspectionId, {
          assignee: name,
          isFinished: isFinishedInspection,
        });
        if (approveAllPhotos) {
          photosInspection.photos.forEach(photo => {
            // eslint-disable-next-line no-param-reassign
            photo.status = APPROVED;
          });
          setPhotosDirty(true);
        }
        setPhotos(
          photosInspection.photos.map(item => ({ ...item, readOnly: false })),
        );
        dispatch(
          currentInspectionActions.updateInspectionDataRedux({
            arrayPhotosAtMoment: photosInspection.photos.map(item => ({
              ...item,
              readOnly: false,
            })),
          }),
        );
      };
      getPhotosFromModal();
      dispatch(currentInspectionActions.changeGetPhotosVariable(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPhotos, getPhotosAgain === true]);

  const locationChassis = photos => {
    const photoInformation = photos.find(
      photo => photo.name === 'INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER',
    );

    return photoInformation?.location?.streetAddress;
  };

  return (
    <div className="adil-screen">
      <AppHeader />

      <SideBar>
        <div className="adil-content">
          <Content>
            {inlineLoaderDisplayed ? renderInlineLoader() : renderInspection()}
          </Content>
          {!inlineLoaderDisplayed && (
            <InspectionButtons
              inspectionIsReadyForApproveOrReject={isInspectionReadyForApproveOrReject()}
              buttonsSupportShow={
                [
                  STATUS_SUPPORT_NEW,
                  STATUS_SUPPORT_RETAKE,
                  STATUS_SUPPORT_INSPECTOR,
                  STATUS_SUPPORT_IN_PROGRESS,
                ].indexOf(inspection?.status) > -1
              }
              disabledButtonsSupportRequest={supportForm !== undefined}
              showRetakeButton={doesInspectionHaveAnyRejectedPhotos()}
              showCustomerRetakeModal={showCustomerRetakeModal}
              disableSave={
                disableSaveButton() ||
                inspection.status === STATUS_CHANGED_TO_PRESENTIAL
              }
              disableApproveRejectDelete={disableApproveRejectDelete()}
              handleReturn={handleReturn}
              handleSave={handleSave}
              currentInspection={{
                ...inspection,
                location: locationChassis(photos),
              }}
              handleContinueADIL={handleContinueADIL}
              handlePassPresential={handlePassPresential}
              handleSaveAndExit={handleSaveAndExit}
              handleInformAndAnalytics={handleInformAndAnalytics}
              handleReject={() => {
                handleInspectionCompletionFlow(STATUS_AGENT_REJECTED);
              }}
              handleApprove={() => {
                handleInspectionCompletionFlow(STATUS_AGENT_APPROVED);
              }}
              handleDelete={showDeleteConfirmationModal}
              handleCancel={showCancelConfirmationModal}
              showDeleteButton={showDeleteButton()}
              showCancelButton={showCancelButton()}
              disableCancelButton={disableCancelButton()}
            />
          )}

          <ConfirmationModal
            isOpen={deleteConfirmationModalDisplayed}
            title={INSPECTION_DELETE_MODAL_TITLE}
            subtitle={INSPECTION_DELETE_MODAL_SUBTITLE}
            cancelButtonLabel={INSPECTION_DELETE_MODAL_CANCEL_BUTTON_LABEL}
            confirmButtonLabel={INSPECTION_DELETE_MODAL_CONFIRM_BUTTON_LABEL}
            cancelButtonHandler={hideDeleteConfirmationModal}
            confirmButtonHandler={handleDelete}
            textareaDisabled={false}
            textareaPlaceholderLabel={
              INSPECTION_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER
            }
            textareaTitleLabel={INSPECTION_DELETE_MODAL_TEXTAREA_TITLE}
            Icon={IconTrash}
          />

          <ConfirmationModal
            isOpen={cancelConfirmationModalDisplayed}
            title={INSPECTION_CANCEL_MODAL_TITLE}
            subtitle={INSPECTION_CANCEL_MODAL_SUBTITLE}
            cancelButtonLabel={INSPECTION_CANCEL_MODAL_CANCEL_BUTTON_LABEL}
            confirmButtonLabel={INSPECTION_CANCEL_MODAL_CONFIRM_BUTTON_LABEL}
            cancelButtonHandler={hideCancelConfirmationModal}
            confirmButtonHandler={handleCancel}
            textareaDisabled={false}
            textareaPlaceholderLabel={
              INSPECTION_CANCEL_MODAL_MESSAGE_INPUT_PLACEHOLDER
            }
            textareaTitleLabel={INSPECTION_CANCEL_MODAL_TEXTAREA_TITLE}
            Icon={IconErrorAlert}
          />

          <ConfirmationModal
            isOpen={overrideConfirmationModalConfig.displayed}
            title={INSPECTION_OVERRIDE_MODAL_TITLE}
            subtitle={INSPECTION_OVERRIDE_MODAL_SUBTITLE}
            cancelButtonLabel={INSPECTION_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL}
            confirmButtonLabel={
              overrideConfirmationModalConfig.status === STATUS_AGENT_APPROVED
                ? INSPECTION_APPROVE_BUTTON
                : INSPECTION_REJECT_BUTTON
            }
            cancelButtonHandler={hideOverrideConfirmationModal}
            confirmButtonHandler={reason => {
              approveOrRejectInspection(
                overrideConfirmationModalConfig.status,
                reason,
              );
            }}
            textareaDisabled={false}
            textareaPlaceholderLabel={
              INSPECTION_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER
            }
            textareaTitleLabel={INSPECTION_OVERRIDE_MODAL_TEXTAREA_TITLE}
            Icon={IconErrorAlert}
          />
        </div>
      </SideBar>

      <SendNotificationsModal
        modalType={sendNotificationsModalType}
        sendNotifications={sendNotifications}
        errorCode={sendNotificationsErrorCode}
        isNotificationsSending={sendNotificationsLoaderDisplayed}
        isModalNotificationOpen={isModalNotificationOpen}
        closeNotificationModal={closeNotificationModal}
      />
      {displayPhotoHistoryModal && (
        <PhotoHistoryModal
          isOpen={displayPhotoHistoryModal}
          onCloseModal={onClosePhotoHistoryModal}
        />
      )}
    </div>
  );
};

Inspection.propTypes = {
  inspection: inspectionPropType,
  photos: photoServicePropType,
  documents: PropTypes.arrayOf(documentPropType),
  customerMessages: PropTypes.arrayOf(customerMessagePropType),
  username: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  isCreator: PropTypes.bool.isRequired,
  isReviewer: PropTypes.bool.isRequired,
  isVendor: PropTypes.bool.isRequired,
  isViewer: PropTypes.bool.isRequired,
  displayImageAnalysis: PropTypes.bool.isRequired,
  inspectionActions: InspectionActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  match: PropTypes.shape({
    params: PropTypes.oneOfType([PropTypes.object]).isRequired,
  }).isRequired,
};

Inspection.defaultProps = {
  inspection: undefined,
  photos: undefined,
  documents: undefined,
  customerMessages: undefined,
};

const mapStateToProps = ({
  user: { username, isAdmin, isCreator, isViewer, isReviewer, name, isVendor },
  settings: {
    environment: { displayImageAnalysis },
  },
}) => ({
  username,
  isAdmin,
  isCreator,
  isViewer,
  isVendor,
  isReviewer,
  name,
  displayImageAnalysis,
});

const mapDispatchToProps = dispatch => ({
  inspectionActions: bindActionCreators(InspectionActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
