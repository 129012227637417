import React, { useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IconClose } from '@lmig/lmds-react/icons';
import { Heading, IconButton } from '@lmig/lmds-react';
import PhotoHistoryInfo from './PhotoHistoryInfo';
import { ImageGallery, Thumbnails } from '../ImageGallery';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { INSPECTION_PHOTO_HISTORY_MODAL_HEADER } = localizedStrings;

const PhotoHistoryModal = ({ isOpen, onCloseModal }) => {
  const {
    rejectedPhotoHistory: { photoHistory, photoId, vehicleClass },
  } = useSelector(store => store.currentInspection);

  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [currentIndexImage, setCurrentIndexImage] = useState(0);

  const photoHistoryList = useMemo(
    () => (photoId && photoHistory[photoId] ? photoHistory[photoId] : []),
    [photoHistory, photoId],
  );

  useEffect(() => {
    setCurrentPhoto(photoHistoryList[currentIndexImage]);
  }, [currentIndexImage, photoHistoryList]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="photo-history-modal"
      overlayClassName="photo-history-modal-overlay"
    >
      <div className="photo-history-modal-content">
        <div className="photo-history-modal-image-close-button">
          <IconButton onClick={onCloseModal}>
            <IconClose size="16" />
          </IconButton>
        </div>
        <div className="photo-history-header">
          <Heading type="h3-light">
            {INSPECTION_PHOTO_HISTORY_MODAL_HEADER}
          </Heading>
        </div>

        <div className="full-container-slider">
          <div className="slide-images">
            <Thumbnails
              pictures={photoHistoryList}
              onChangeIndex={setCurrentIndexImage}
              currentIndex={currentIndexImage}
            />
            <ImageGallery
              pictures={photoHistoryList}
              onChangeIndex={setCurrentIndexImage}
              currentIndex={currentIndexImage}
            />
            <br />
            <hr />
          </div>

          <div className="vertical-line" />
          {currentPhoto && (
            <PhotoHistoryInfo
              photo={currentPhoto}
              vehicleClass={vehicleClass}
            />
          )}
        </div>
        <br />
      </div>
    </ReactModal>
  );
};

PhotoHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default PhotoHistoryModal;
