import { CLOSE_ALL_COLLAPSABLES } from './constants';

const closeAllCollapsables = actionPayload => ({
  type: CLOSE_ALL_COLLAPSABLES,
  payload: actionPayload,
});

export default {
  closeAllCollapsables,
};
