import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { supportRequestEnabled } from '../../utils/constants';

export const setMenuArrayFilter = (menu, idSelected) =>
  menu.map(item =>
    item.id === idSelected
      ? { ...item, active: true }
      : { ...item, active: false },
  );

export const setActiveInspections = idFilter => {
  const languageId = supportRequestEnabled.includes(getCountryCode()) ? 5 : 4;
  if (idFilter === languageId) return false;
  return true;
};

export const getCountsInpections = (menuFilter, count) =>
  menuFilter.map(item => {
    const countNew = item.statuses.reduce(
      (total, status) => total + count[status],
      0,
    );
    return { ...item, count: countNew };
  });

export const resetMenuFilter = menuFilter =>
  menuFilter.map(item => ({ ...item, active: false }));

export const resetPageInChangeSubStatus = (currentLocation, newLocation) => {
  if (
    (newLocation.subStatusSelected !== undefined &&
      currentLocation.subStatusSelected !== newLocation.subStatusSelected) ||
    (newLocation.mustHavePhotos !== undefined &&
      currentLocation.mustHavePhotos !== newLocation.mustHavePhotos)
  ) {
    return { ...currentLocation, ...newLocation, currentPageIndex: 0 };
  }
  return { ...currentLocation, ...newLocation };
};
