import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const RequiredVideoSwitch = ({ required, onChange }) => {
  const {
    INSPECTION_MESSAGE_IS_REQUIRED_VIDEO,
    INSPECTION_MESSAGE_IS_OPTIONAL_VIDEO,
  } = localizedStrings;

  return (
    <div className="video-required-container">
      {required ? (
        <p className="video-label video-label__required">
          {INSPECTION_MESSAGE_IS_REQUIRED_VIDEO}
        </p>
      ) : (
        <p className="video-label video-label__optional">
          {INSPECTION_MESSAGE_IS_OPTIONAL_VIDEO}
        </p>
      )}
      <Switch
        onColor="#28a3af"
        onChange={checked => onChange(checked, 'required')}
        checked={required}
      />
    </div>
  );
};

RequiredVideoSwitch.propTypes = {
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequiredVideoSwitch;
