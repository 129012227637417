import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DescriptionArea = ({ description, width, maxHeight }) => (
  <div
    className="description-modal-text"
    style={{ width: `${width}%`, maxHeight: `${maxHeight}rem` }}
  >
    {description}
  </div>
);

DescriptionArea.defaultProps = {
  width: 80,
  maxHeight: 12,
};

DescriptionArea.propTypes = {
  description: PropTypes.string.isRequired,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
};

export default DescriptionArea;
