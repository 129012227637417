import { getCountryCode } from '../../config/environments/environmentsHelpers';

const country = getCountryCode().toUpperCase();

export const prefillData = {
  submissionId: '123456',
  sourceApplicationId: 'UNQUORK',
  creatorEmail: {
    creatorEmail: 'test@test.com',
  },
  ...(country === 'VN' && {
    infoAgent: {
      creatorEmail: 'test@email.com',
      nameIntermediaryInsurance: 'Anh Duong',
    },
  }),
  sourceApplicationEndpoint: 'www.unqork.lm.lmig.com/appraisalReturn',
  customer: {
    fullName: 'Sebastián Mateo Sánchez Rodríguez',
    firstName: 'Sebastián',
    middleName: 'Mateo',
    lastName: 'Sánchez',
    secondLastName: 'Rodríguez',
    location: 'tokio',
    idType: 'License',
    idNumber: '12345',
    cellPhoneNumber: country === 'VN' ? '0123456789' : '55-123456789',
    email: 'test@test.com',
  },
  vehicle: {
    fasecoldaCode: 'ABC123',
    fasecoldaValue: '$15,000',
    licencePlate: country === 'VN' ? '51H-12345' : 'A12345',
    licensePlateType: 'Placa tipo Colombia (C)',
    countryCarId: 'CO',
    origin: 'Imported',
    year: '2018',
    make: 'BMW',
    model: 'M4',
    color: 'Cobre',
    version: 'CS',
    bodyStyle: 'Coupé',
    chassisNumber: '1234',
    serialNumber: '1234',
    fuelType: 'Diesel',
    transmissionType: 'Mecánica',
    paintType: 'Metalizada',
    vin: 'WBS3R9C57FK334661',
    engineNumber: 'S55B30O0',
    capacity: '2.0',
    use: 'Público',
    kilometers: '152134',
    value: '$17,000',
  },
  broker: {
    brokerCode: '12345',
    brokerName: 'LM Brokers',
    brokerEmail: 'brokers@domain.com',
  },
};
