import React from 'react';
import PropTypes from 'prop-types';
import { PlayIcon, PauseIcon } from '../../Icons';
import './styles.scss';

const PlayButton = ({ onClick, isPlayRunning }) => (
  <button type="button" className="play-button" onClick={onClick}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {!isPlayRunning ? (
        <PlayIcon height={90} width={90} />
      ) : (
        <PauseIcon width={70} height={70} />
      )}
    </div>
  </button>
);

PlayButton.propTypes = {
  isPlayRunning: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PlayButton;
