import React from 'react';
import {
  Table,
  TableCell,
  TableRow,
  Radio,
  RadioGroup,
} from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { OverlayEnablerStatuses } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { ENABLED, DISABLED } = OverlayEnablerStatuses;

const {
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_ACCEPT,
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_REJECT,
} = localizedStrings;

const OverlayEnabler = ({ onChangeOverlayEnabler, disabled }) => {
  const onChangeRadioButton = event => {
    onChangeOverlayEnabler({ status: event.target.value });
  };
  return (
    <Table>
      <tbody>
        <TableRow>
          <TableCell type="data" className="overlay-cell">
            <RadioGroup
              inline
              name="overlay-enabler-radio-group"
              onChange={onChangeRadioButton}
              className="overlay-enabler-radio-group"
              disabled={disabled}
            >
              <Radio
                id="disable-overlay"
                key="disable-overlay"
                labelVisual={
                  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_REJECT
                }
                value={DISABLED}
                defaultChecked
              />
              <Radio
                id="enable-overlay"
                key="enable-overlay"
                labelVisual={
                  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_ACCEPT
                }
                value={ENABLED}
              />
            </RadioGroup>
          </TableCell>
        </TableRow>
      </tbody>
    </Table>
  );
};

OverlayEnabler.defaultProps = {
  disabled: false,
};

OverlayEnabler.propTypes = {
  onChangeOverlayEnabler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default OverlayEnabler;
