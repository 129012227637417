import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lmig/lmds-react';
import './styles.scss';

export const CustomButton = ({ className, children, ...props }) => {
  const classNames = ['adil-custom-button', className].join(' ');
  return (
    <Button {...props} className={classNames}>
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomButton;
