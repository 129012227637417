import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageForCountryCode } from '@lmig-latam/adil-api-common-lib/utils/localizedStrings';
import localizedStrings from '../../utils/localizedStrings';
import { stringsByLanguage } from '../../utils/languages/stringsByLanguage';
import { vehicleDataInformAdapter } from '../../adapters/vehicleDataInform';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { inspectionInformActions } from '../../redux/inspection-inform';

const {
  INSPECTION_INFORM_MODEL_YEAR,
  INSPECTION_INFORM_CLASS,
  INSPECTION_INFORM_MANUFACTURER,
  INSPECTION_INFORM_TYPE_ID,
  INSPECTION_INFORM_KM_OR_AUTONOMIC,
  INSPECTION_INFORM_COLOR,
  INSPECTION_INFORM_TYPE_ENGINE,
  INSPECTION_INFORM_MODEL_VERSION,
} = localizedStrings;

const vehicleBrands = [
  { label: 'bmw', value: 'bmw' },
  { label: 'chevrolet', value: 'chevrolet' },
  { label: 'mazda', value: 'mazda' },
];
const vehicleModel = [
  { label: 'CX-30', value: 'cx-30' },
  { label: 'OPTRA', value: 'optra' },
  { label: 'GTR', value: 'gtr' },
  { label: 'm4', value: 'm4' },
];

export default function PanelInspectionInfoVehicle() {
  const {
    register,
    formState: { errors },
    control,
    reset,
  } = useFormContext();

  const { FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY } = localizedStrings;

  const strings = getLanguageForCountryCode(getCountryCode().toLowerCase());

  const {
    inspectionOriginal: { vehicle },
  } = useSelector(store => store.inspectionInform);

  const dispatch = useDispatch();

  const stringsLanguage = stringsByLanguage;
  const [optionClassVehicles] = useState(
    stringsLanguage.INSPECTION_DROPDOWN_DEFAULT_VALUES?.class || [],
  );

  const [optionUseVehicles] = useState(
    stringsLanguage.INSPECTION_DROPDOWN_DEFAULT_VALUES?.use || [],
  );

  const [optionColorVehicle] = useState(
    strings.INSPECTION_DROPDOWN_DEFAULT_VALUES?.color.map(element => ({
      label: element.toLowerCase(),
      value: element.toLowerCase(),
    })) || [],
  );

  const [optionVehicleType] = useState(
    strings.INSPECTION_DROPDOWN_DEFAULT_VALUES?.bodyStyle || [],
  );

  useEffect(() => {
    const vehicleData = vehicleDataInformAdapter(vehicle);
    reset(vehicleData);
    dispatch(inspectionInformActions.addStep2VehicleRedux(vehicleData));
  }, [vehicle, reset, dispatch]);

  return (
    <div className="flex flex:1 flex:col gap:0.8rem">
      <div>
        <input
          placeholder={INSPECTION_INFORM_MODEL_YEAR}
          {...register('yearModel', {
            pattern: {
              value: /^[0-9]*$/,
              message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY,
            },
          })}
          className={`inputs_rhf_adil ${
            errors.yearModel && errors.yearModel.type === 'pattern'
              ? 'validation-input-field-error'
              : ''
          }`}
        />

        {errors.yearModel && errors.yearModel.type === 'pattern' && (
          <p className="error-message--forms">
            {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY}
          </p>
        )}
      </div>

      <div>
        <select
          name="vehicleType"
          defaultValue="default"
          {...register('vehicleType')}
          className={`inputs_rhf_adil ${
            errors.vehicleType ? 'validation-input-field-error' : ''
          }`}
        >
          <option value="default" disabled="disabled">
            {INSPECTION_INFORM_CLASS}
          </option>
          {optionVehicleType.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div>
        <Controller
          name="vehicleBrand"
          control={control}
          render={({ field }) => (
            <Select
              options={vehicleBrands}
              onChange={field.onChange}
              value={vehicleBrands.find(c => c.value === field.value)}
              placeholder={INSPECTION_INFORM_MANUFACTURER}
              className="reactSelect__align"
            />
          )}
        />
      </div>

      <div>
        <select
          name="useOrLiscensePlate"
          defaultValue="default"
          {...register('useOrLiscensePlate')}
          className={`inputs_rhf_adil ${
            errors.useOrLiscensePlate ? 'validation-input-field-error' : ''
          }`}
        >
          <option value="default" disabled="disabled">
            {INSPECTION_INFORM_TYPE_ID}
          </option>
          {optionUseVehicles.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div>
        <input
          placeholder={INSPECTION_INFORM_KM_OR_AUTONOMIC}
          {...register('vehicleKm', {
            pattern: {
              value: /^[0-9]*$/,
              message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY,
            },
          })}
          className={`inputs_rhf_adil ${
            errors.vehicleKm ? 'validation-input-field-error' : ''
          }`}
        />

        {errors.vehicleKm && errors.vehicleKm.type === 'pattern' && (
          <p className="error-message--forms">
            {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY}
          </p>
        )}
      </div>

      <div>
        <Controller
          name="vehicleColor"
          control={control}
          render={({ field }) => (
            <Select
              options={optionColorVehicle}
              onChange={field.onChange}
              placeholder={INSPECTION_INFORM_COLOR}
              value={optionColorVehicle.find(c => c.value === field.value)}
              className="reactSelect__align"
            />
          )}
        />
      </div>

      <div>
        <select
          name="fuel"
          defaultValue="default"
          {...register('fuel')}
          className={`inputs_rhf_adil ${
            errors.fuel ? 'validation-input-field-error' : ''
          }`}
        >
          <option value="default" disabled="disabled">
            {INSPECTION_INFORM_TYPE_ENGINE}
          </option>
          {optionClassVehicles.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div>
        <Controller
          name="vehicleModel"
          control={control}
          render={({ field }) => (
            <Select
              options={vehicleModel}
              onChange={field.onChange}
              value={vehicleModel.find(c => c.value === field.value)}
              placeholder={INSPECTION_INFORM_MODEL_VERSION}
              className="reactSelect__align"
            />
          )}
        />
      </div>
    </div>
  );
}
