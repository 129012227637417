import {
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  TOGGLE_SIDEBAR,
  UPDATE_LOGGED_IN,
} from '../actions/ActionTypes';
import { getEnvironmentConfig } from '../config/environments';

export const initialState = {
  loggedIn: false,
  environment: getEnvironmentConfig(),
  sidebarOpen: true,
  appLoader: {
    displayed: true,
    translucent: undefined,
    message: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_APP_LOADER:
      return {
        ...state,
        appLoader: {
          displayed: true,
          translucent: action.translucent ? action.translucent : undefined,
          message: action.message ? action.message : undefined,
        },
      };
    case HIDE_APP_LOADER:
      return {
        ...state,
        appLoader: {
          displayed: false,
          translucent: undefined,
          message: undefined,
        },
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    case UPDATE_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    default:
      return state;
  }
};
