import { Button, Heading, IconButton } from '@lmig/lmds-react';
import { IconQuestionMark, IconClose } from '@lmig/lmds-react/icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { CircleImage } from '..';
import './styles.scss';

const ConfirmationModal = ({
  isOpen,
  textareaDisabled,
  confirmButtonHandler,
  cancelButtonHandler,
  cancelButtonLabel,
  confirmButtonLabel,
  textareaTitleLabel,
  textareaPlaceholderLabel,
  title,
  subtitle,
  Icon,
}) => {
  const [comment, setComment] = useState('');

  const updateComment = event => {
    setComment(event.target.value);
  };

  const addComment = () => {
    confirmButtonHandler(comment);
    setComment('');
  };

  const textAreaElements = () => (
    <>
      <div className="confirmation-modal-text-area-title">
        {textareaTitleLabel}
      </div>

      <textarea
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        className="confirmation-modal-text-area"
        onChange={updateComment}
        maxLength="1000"
        placeholder={textareaPlaceholderLabel}
      />
    </>
  );

  const confirmButton = () => {
    if (textareaDisabled) {
      return (
        <Button onClick={confirmButtonHandler}>{confirmButtonLabel}</Button>
      );
    }

    return (
      <Button onClick={addComment} disabled={comment.length < 1}>
        {confirmButtonLabel}
      </Button>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={cancelButtonHandler}
      className="confirmation-modal"
      overlayClassName="confirmation-modal-overlay"
    >
      <div className="confirmation-modal-content">
        <div className="confirmation-modal-image-close-button">
          <IconButton onClick={cancelButtonHandler}>
            <IconClose size="16" />
          </IconButton>
        </div>
        <CircleImage>
          {Icon ? <Icon size="64" /> : <IconQuestionMark size="64" />}
        </CircleImage>
        <Heading type="h3-light" className="auto-left-right-margin">
          {title}
        </Heading>

        {!textareaDisabled && textAreaElements()}

        <Heading type="h4-light" className="auto-left-right-margin">
          {subtitle}
        </Heading>
        <div>
          <Button onClick={cancelButtonHandler}>{cancelButtonLabel}</Button>

          {confirmButton()}
        </div>
      </div>
    </ReactModal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  textareaDisabled: PropTypes.bool,

  Icon: PropTypes.func.isRequired,

  confirmButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  textareaTitleLabel: PropTypes.string,
  textareaPlaceholderLabel: PropTypes.string,

  confirmButtonHandler: PropTypes.func.isRequired,
  cancelButtonHandler: PropTypes.func.isRequired,

  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

ConfirmationModal.defaultProps = {
  textareaDisabled: true,
  textareaTitleLabel: 'MISSING TEXT TEXTAREA TITLE',
  textareaPlaceholderLabel: 'MISSING TEXT TEXTAREA PLACEHOLDER',
};

export default ConfirmationModal;
