import * as React from 'react';

export default function TableInspectionItems() {
  return (
    <div className="flex flex:1 flex:col border:1px|solid|#CCC r:0.5rem box-shadow:0|0|3|5|gray-90 max-h:16.5rem overflow:hidden overflow-y:auto">
      <div className="flex sticky top:0 bg:white flex:row font-size:0.8rem font-weight:bold uppercase gap:1rem border-bottom:1px|solid|#CCC">
        <span className="flex flex:1 py:0.8rem justify-content:center  border-right:1px|solid|#CCC">
          Piezas
        </span>
        <span className="flex flex:1 py:0.8rem justify-content:center  border-right:1px|solid|#CCC">
          Daño
        </span>
        <span className="flex flex:1 py:0.8rem justify-content:center  border-right:1px|solid|#CCC">
          Intensidad
        </span>
        <span className="flex flex:1 py:0.8rem justify-content:center">
          Deducible (UF)
        </span>
      </div>

      <div className="flex flex:col">
        <div className="flex flex:row bg:white py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:#CCCCCC99 py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:white py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:#CCCCCC99 py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:white py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:#CCCCCC99 py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:white py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:#CCCCCC99 py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:white py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>

        <div className="flex flex:row bg:#CCCCCC99 py:0.5rem">
          <span className="flex flex:1 justify-content:center">Retrovisor</span>
          <span className="flex flex:1 justify-content:center">Rotura</span>
          <span className="flex flex:1 justify-content:center">Level</span>
          <span className="flex flex:1 justify-content:center">S/C</span>
        </div>
      </div>
    </div>
  );
}
