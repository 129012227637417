import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Chip({ text, color, size }) {
  const getSizeClass = () => {
    if (size) return size;
    const { length } = text;

    if (length <= 12) return 'medium';
    if (length > 12 && length <= 19) return 'medium';
    if (length > 19) return 'small';
    return 'small';
  };
  return <div className={`chip ${color} ${getSizeClass()}`}>{text}</div>;
}

Chip.defaultProps = {
  color: 'gray',
  size: undefined,
};

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Chip;
