import {
  createPieceOrPart,
  deletePieceOrPart,
  getPiecesAndParts,
  updatePieceOrPart,
} from '../../api';
import { store } from '../../common/store';
import {
  ADD_PARTS_TO_SELECT,
  GET_ALL_PARTS,
  DELETE_PIECES_BY_PART,
  UPDATE_PARTS_BY_STATUS,
} from './constants';

const createPartOrPiece = partOrPiece => async () =>
  createPieceOrPart(partOrPiece);

const updatePartRedux = parts => ({
  type: UPDATE_PARTS_BY_STATUS,
  payload: parts,
});

// eslint-disable-next-line consistent-return
const updatePartOrPiece = partOrPiece => async dispatch => {
  const { parts = [] } = store.getState().partsAndPiecesAdmin;
  const {
    newVehiclePart: partToUpdate = [],
    newVehiclePiece = [],
  } = partOrPiece;
  let updateParts = [];
  const isPartsArray =
    Array.isArray(newVehiclePiece) && newVehiclePiece.length === 0;

  if (isPartsArray) {
    updateParts = parts.map(part => {
      if (part.id === partToUpdate.id) {
        return {
          ...partToUpdate,
        };
      }
      return part;
    });
  }

  try {
    await updatePieceOrPart(partOrPiece);
    if (isPartsArray) dispatch(updatePartRedux(updateParts));
  } catch (error) {
    return error;
  }
};

const deletePiecesByPart = part => {
  const { pieces = [] } = store.getState().partsAndPiecesAdmin;

  if (Array.isArray(pieces) && pieces.length === 0) {
    return {};
  }

  const partsUpdated = pieces.filter(
    piece => piece.vehiclePartId !== part.vehiclePartId,
  );

  return {
    type: DELETE_PIECES_BY_PART,
    payload: partsUpdated,
  };
};

// eslint-disable-next-line consistent-return
const deletePartOrPiece = partOrPiece => async dispatch => {
  try {
    await deletePieceOrPart(partOrPiece);
    dispatch(deletePiecesByPart(partOrPiece));
  } catch (error) {
    return error;
  }
};

const addPartToSelect = parts => ({
  type: ADD_PARTS_TO_SELECT,
  payload: parts,
});

const allDataPiecesAndParts = vehicleParts => {
  const piecesArray = vehicleParts.map(vehiclePart => [
    ...vehiclePart.pieces.map(piece => ({
      ...piece,
      vehiclePartId: vehiclePart.id,
    })),
  ]);

  return {
    type: GET_ALL_PARTS,
    payload: { parts: vehicleParts, pieces: piecesArray.flat() },
  };
};

// eslint-disable-next-line consistent-return
const getPiecesAndPartsDB = countryCode => async dispatch => {
  try {
    const { vehicleParts } = await getPiecesAndParts(countryCode);
    dispatch(allDataPiecesAndParts(vehicleParts));
  } catch (error) {
    return error;
  }
};

export default {
  createPartOrPiece,
  updatePartOrPiece,
  deletePartOrPiece,
  addPartToSelect,
  getPiecesAndPartsDB,
};
