import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SearchActions } from '../../redux/search-inspection';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { isValidInLatam, supportRequestEnabled } from '../../utils/constants';
import './styles.scss';

const CardTitle = ({ title }) => (
  <div className="card-filter__title">
    <p>{title}</p>
  </div>
);

const CardContent = ({ count, icon }) => (
  <div className="card-filter__content">
    <p className="card-filter__content__count">{count}</p>
    <img className="card-filter__content__icon" src={icon} alt="" />
  </div>
);

function CardFilter({ itemFilter, disabled }) {
  const { activeInspections } = useSelector(store => store.searchFilter);

  const { id, title, count, icon, active } = itemFilter;

  const isActive = `${active ? 'card-filter--active' : ''} `;
  const isDisable = `${disabled ? 'card-filter--disable' : ''} `;

  const dispatch = useDispatch();

  const darkCardValidator = () => {
    const languageId = supportRequestEnabled.includes(getCountryCode()) ? 5 : 4;
    if (activeInspections) {
      if (id === languageId) return true;
    } else if (id !== languageId) return true;
    return false;
  };
  const useDarkCard = `${darkCardValidator() ? 'card-filter--dark' : ''} `;

  const onSetStepFilter = () => {
    dispatch(SearchActions.setStepFilter(itemFilter));
    dispatch(SearchActions.setMonthAndYearSelected(''));
  };

  const validationCount = () => {
    const country = getCountryCode();
    if (id === 5 && isValidInLatam.includes(country)) return null;
    return count;
  };

  return (
    <div
      key={id}
      role="button"
      className={`card-filter ${isDisable} ${isActive} ${useDarkCard}`}
      onClick={() => onSetStepFilter()}
    >
      <div className="card-filter__container">
        <CardTitle title={title} />
        <CardContent count={validationCount()} icon={icon} />
      </div>
    </div>
  );
}

CardFilter.propTypes = {
  itemFilter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

CardContent.propTypes = {
  count: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CardFilter;
