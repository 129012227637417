import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Button, Heading, IconButton } from '@lmig/lmds-react';
import { IconClose } from '@lmig/lmds-react/icons';
import ViewerVideo from '../ViewerVideo/ViewerVideo';
import localizedStrings from '../../../utils/localizedStrings';
import { photoPropType } from '../../../prop-types';
import './styles.scss';

const VideoModal = ({
  isOpen,
  onCloseModal,
  title,
  photos,
  updateVideo,
  videoData,
}) => {
  const {
    INSPECTION_VEHICLE_VIDEO_BUTTON_CLOSE: closeButtonlabel,
    INSPECTION_VEHICLE_VIDEO_HINT_VIEW: hintTextView,
  } = localizedStrings;

  const onSaveRotation = rotation => {
    updateVideo(rotation);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="video-modal"
      overlayClassName="video-modal-overlay"
    >
      <div className="video-modal-content videoModal-scroll">
        <div className="video-modal-image-close-button">
          <IconButton onClick={onCloseModal}>
            <IconClose size="16" />
          </IconButton>
        </div>

        <Heading type="h4-bold" className="auto-left-right-margin">
          {title}
        </Heading>

        <ViewerVideo
          photos={photos}
          rotation={videoData.rotation}
          hintText={hintTextView}
          onSaveRotation={onSaveRotation}
        />

        <div>
          <Button onClick={onCloseModal}>{closeButtonlabel}</Button>
        </div>
      </div>
    </ReactModal>
  );
};

VideoModal.defaultProps = {
  videoData: {
    rotation: 0,
  },
};

VideoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(photoPropType).isRequired,
  updateVideo: PropTypes.func.isRequired,
  videoData: PropTypes.shape({
    rotation: PropTypes.number,
  }),
};

export default VideoModal;
