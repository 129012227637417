/* eslint-disable no-console */

import { logMessage } from '../../api';
import { isProduction } from '../../config/environments/environmentsHelpers';

export const LOG_LEVELS = {
  ERROR: 'ERROR',
  WARN: 'WARN',
  INFO: 'INFO',
};

const { ERROR, WARN } = LOG_LEVELS;

const handleDevMessage = (level, message) => {
  switch (level) {
    case ERROR:
      console.error(message);
      break;
    case WARN:
      console.warn(message);
      break;
    default:
      console.info(message);
      break;
  }
};

const log = (message, data = {}) => {
  const logMessageBody = { ...message, data };

  logMessage(logMessageBody);

  if (!isProduction()) {
    handleDevMessage(message.level, logMessageBody);
  }
};

export default { log, LOG_LEVELS };
