import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@lmig/lmds-react';
import { CardGlobalHeader } from '../CardGlobal';
import CollapsableItem from '../CollapsableItem';
import PanelInspectionItems from '../../widgets/InspecionInform/PanelInspectionItems';
import PanelInspectionInfoVehicle from '../../widgets/InspecionInform/PanelInspectionInfoVehicle';
import PanelInspectionSystem from '../../widgets/InspecionInform/PanelInspectionSystem';
import PanelInspectionFrotal from '../../widgets/InspecionInform/PanelInspectionFrotal';
import localizedStrings from '../../utils/localizedStrings';
import InspectionInformGallery from '../InspectionInformGallery';
import PanelMark from '../../widgets/InspecionInform/PanelMark';
import { Panel } from '..';
import './styles.scss';

const {
  MODAL_PRIMARY_BUTTON_LABEL,
  INSPECTION_RETURN_BUTTON,
  INSPECTION_INFORM_DATA_VEHICLE,
  INSPECTION_INFORM_SYSTEM_IDENTIFICATION,
  INSPECTION_INFORM_REVISION_FRONTAL,
  INSPECTION_INFORM_LATERAL_LEFT,
} = localizedStrings;

/**
 * @param {import('../../types/InspectionsInform/InspectionFormInput').InspectionFormInput} inspectionFormInput
 * @param {(step: number) => void} onContinue
 * @param {() => void} onCancel
 * @return {JSX.Element}
 * @constructor
 */
export default function SteeperScreen2({
  onContinue,
  onCancel,
  photos,
  inspectionData,
}) {
  const [stepValidate, setStepValidate] = useState({
    One: true,
    Two: false,
    Three: false,
    Four: false,
    Five: false,
  });

  const methods = useForm({
    mode: 'all',
  });

  const isValidForm = () =>
    methods.formState.isValid ||
    Object.keys(methods.formState.errors).length === 0;

  const isAllStepsValid = () =>
    Object.values(stepValidate).every(column => column === true);

  const submit = () => {
    if (isValidForm() && isAllStepsValid()) {
      onContinue();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <div className="content flex flex:1 justify-content:center align-items:center">
          <div className="flex flex:col max-w:100%">
            <div className="flex flex:col">
              <CardGlobalHeader indexCard={1} titleCard="Titulo por definir" />

              <div className="flex flex:1 justify-content:center align-items:center gap:1rem">
                <PanelMark className="position:relative flex gap:0.8rem flex:col font-weight:normal bg:white r:0.5rem p:1rem min-h:45rem max-h:45rem max-w:30rem@2xl overflow:hidden overflow-y:auto">
                  <CollapsableItem
                    title={INSPECTION_INFORM_DATA_VEHICLE}
                    isValid={stepValidate.One}
                    number={1}
                  >
                    <PanelInspectionInfoVehicle
                      vehicle={inspectionData.vehicle}
                    />
                  </CollapsableItem>

                  <CollapsableItem
                    title={INSPECTION_INFORM_SYSTEM_IDENTIFICATION}
                    isValid={stepValidate.Two}
                    number={2}
                  >
                    <PanelInspectionSystem
                      onValidSubmit={() => {
                        setStepValidate({
                          ...stepValidate,
                          Two: true,
                        });
                      }}
                      vehicle={inspectionData.vehicle}
                    />
                  </CollapsableItem>

                  <CollapsableItem
                    title={INSPECTION_INFORM_REVISION_FRONTAL}
                    isValid={stepValidate.Three}
                    number={3}
                  >
                    <PanelInspectionFrotal
                      onValidSubmit={() => {
                        setStepValidate({
                          ...stepValidate,
                          Three: true,
                        });
                      }}
                    />
                  </CollapsableItem>

                  <CollapsableItem
                    title={INSPECTION_INFORM_LATERAL_LEFT}
                    isValid={stepValidate.Four}
                    number={4}
                  >
                    <PanelInspectionItems
                      onValidSubmit={() => {
                        setStepValidate({
                          ...stepValidate,
                          Four: true,
                        });
                      }}
                    />
                  </CollapsableItem>

                  <CollapsableItem
                    title={INSPECTION_INFORM_DATA_VEHICLE}
                    isValid={stepValidate.Five}
                    number={5}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setStepValidate({
                          ...stepValidate,
                          Five: true,
                        });
                      }}
                      className="flex flex:1 justify-content:center align-items:center color:#343741 font-size:1.2rem font-weight:bold bg:#99E5EA border:none py:1rem"
                    >
                      {MODAL_PRIMARY_BUTTON_LABEL}
                    </button>
                  </CollapsableItem>
                </PanelMark>

                <div className="flex align-items:center  bg:white r:0.5rem p:1rem min-h:45rem max-h:45rem  w:50rem max-w:50rem ">
                  {photos.length > 0 && (
                    <InspectionInformGallery
                      photos={photos}
                      inspectionId={inspectionData.id}
                      vehicleClass={inspectionData?.vehicle.class}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Panel className="adil-report-panel">
          <Button onClick={onCancel} level="secondary">
            {INSPECTION_RETURN_BUTTON}
          </Button>
          <Button type="submit" level="secondary">
            {MODAL_PRIMARY_BUTTON_LABEL}
          </Button>
        </Panel>
      </form>
    </FormProvider>
  );
}

SteeperScreen2.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  inspectionData: PropTypes.shape({
    id: PropTypes.string,
    vehicle: PropTypes.shape({
      countryCarId: PropTypes.string,
      serialNumber: PropTypes.string,
      color: PropTypes.string,
      year: PropTypes.string,
      licencePlate: PropTypes.string,
      use: PropTypes.string,
      engineNumber: PropTypes.string,
      origin: PropTypes.string,
      bodyStyle: PropTypes.string,
      version: PropTypes.string,
      capacity: PropTypes.string,
      chassisNumber: PropTypes.string,
      fuelType: PropTypes.string,
      kilometers: PropTypes.string,
      model: PropTypes.string,
      vin: PropTypes.string,
      make: PropTypes.string,
      class: PropTypes.string,
      value: PropTypes.string,
    }),
  }).isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ).isRequired,
};

SteeperScreen2.defaultProps = {
  onCancel: () => {
    // Empty function
  },
  onContinue: () => {
    // Empty function
  },
};
