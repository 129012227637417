import React from 'react';
import PropTypes from 'prop-types';

export const BrigthnessBar = ({
  isOpen,
  brigthnessRef,
  value,
  onChange,
  min,
  max,
}) => (
  <input
    type="range"
    ref={brigthnessRef}
    min={min}
    max={max}
    step="1"
    value={value}
    onChange={onChange}
    className={
      isOpen ? 'viewer__bridgthnessSlider' : 'viewer__bridgthnessSliderOff'
    }
  />
);

BrigthnessBar.propTypes = {
  brigthnessRef: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
