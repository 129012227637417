import React from 'react';
import PropTypes from 'prop-types';

export const PlayIcon = ({ width, height, ...props }) => (
  <svg width={`${width}`} height={`${height}`} viewBox="0 0 36 36" {...props}>
    <g transform="translate(14097 17908)">
      <path
        id="Icon_Video"
        d="M21.251,26.442a1.4,1.4,0,0,1-1.4-1.4V9.825a1.4,1.4,0,0,1,1.4-1.4,1.385,1.385,0,0,1,.767.23l11.566,7.608a1.4,1.4,0,0,1,0,2.338l-11.566,7.61A1.393,1.393,0,0,1,21.251,26.442Zm1.4-14.022V22.449l7.621-5.014Z"
        transform="translate(-14103.854 -17907.428)"
        fill="#fff"
      />
      <g
        id="Elipse_6"
        data-name="Elipse 6"
        transform="translate(-14097 -17908)"
        fill="none"
        stroke="#fff"
        strokeWidth="3"
      >
        <circle cx="18" cy="18" r="18" stroke="none" />
        <circle cx="18" cy="18" r="16.5" fill="none" />
      </g>
    </g>
  </svg>
);

PlayIcon.defaultProps = {
  width: 80,
  height: 80,
};

PlayIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
