import { Heading } from '@lmig/lmds-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { LoadingAnimation } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { LOADING_TEXT } = localizedStrings;

const AppLoader = ({ displayed, translucent, message }) =>
  displayed && (
    <div
      className={classNames('app-loader', {
        translucent,
      })}
    >
      <div className="app-loader-animation auto-left-right-margin">
        <LoadingAnimation />
      </div>
      <Heading
        type="h4-light"
        className="app-loader-heading auto-left-right-margin"
      >
        {message}
      </Heading>
    </div>
  );

AppLoader.propTypes = {
  displayed: PropTypes.bool.isRequired,
  translucent: PropTypes.bool,
  message: PropTypes.string,
};

AppLoader.defaultProps = {
  translucent: false,
  message: LOADING_TEXT,
};

const mapStateToProps = ({
  settings: {
    appLoader: { displayed, translucent, message },
  },
}) => ({
  displayed,
  translucent,
  message,
});

export default connect(mapStateToProps, null)(AppLoader);
