import React from 'react';

export const SignFineIcon = ({ ...props }) => (
  <svg
    fill="#FFF"
    id="Capa_1"
    width="30px"
    height="30px"
    viewBox="0 0 31.963 31.963"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M31.453,9.17c0.372-0.439,0.554-1.011,0.501-1.585c-0.055-0.574-0.338-1.102-0.785-1.466l-3.811-3.084
		c-0.92-0.744-2.27-0.602-3.014,0.317L12.675,17.773l-5.536-4.667c-1.054-0.889-2.61-0.822-3.585,0.151l-2.927,2.926
		c-0.421,0.421-0.647,0.999-0.626,1.594c0.022,0.596,0.291,1.152,0.742,1.542l11.108,9.565c0.432,0.373,0.994,0.558,1.562,0.513
		c0.568-0.044,1.096-0.312,1.465-0.747L31.453,9.17z"
      />
    </g>
  </svg>
);
