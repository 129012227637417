import React from 'react';
import PropTypes from 'prop-types';

export default function MainLoaderCircle({ className, ...props }) {
  return (
    <svg
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={className}
      style={{ background: 'none', position: 'absolute' }}
      {...props}
    >
      <defs>
        <filter
          id="tail-e6f9c25d73633"
          x="-100%"
          y="-100%"
          width="300%"
          height="300%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
          <feComponentTransfer result="cutoff">
            <feFuncA type="linear" slope="60" intercept="-40" />
          </feComponentTransfer>
        </filter>
      </defs>
      <g
        ng-attr-filter="url(#{{config.filterid}})"
        filter="url(#tail-e6f9c25d73633)"
        transform="rotate(266.459 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="7.5s"
          begin="0s"
          repeatCount="indefinite"
        />
        <g transform="rotate(359.819 50.0066 50.0066)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="19"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.13333333333333333 0 0.03333333333333333 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.804 50.0085 50.0084)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="18"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.16666666666666669 0 0.06666666666666667 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.787 50.0093 50.0093)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="17"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.2 0 0.1 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.768 50.0084 50.0083)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="16"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.23333333333333334 0 0.13333333333333333 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.747 49.9979 49.998)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="15"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.26666666666666666 0 0.16666666666666666 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.723 49.9986 49.9986)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="14"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.30000000000000004 0 0.2 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.696 49.9974 49.9975)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="13"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.33333333333333337 0 0.23333333333333334 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.664 50.001 50.001)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="12"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.3666666666666667 0 0.26666666666666666 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.628 49.9975 49.9975)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="11"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.4 0 0.3 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.587 50.0013 50.0013)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="10"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.43333333333333335 0 0.3333333333333333 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.538 50.0004 50.0004)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="9"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.4666666666666667 0 0.36666666666666664 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.481 50.0003 50.0003)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="8"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.5 0 0.4 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.412 50.0001 50.0001)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="7"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.5333333333333333 0 0.43333333333333335 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.331 50.0022 50.0022)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="6"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.5666666666666667 0 0.4666666666666667 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(359.232 50.0007 50.0007)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="5"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.6 0 0.5 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(359.11 50.0002 50.0002)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="4"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.6333333333333333 0 0.5333333333333333 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(358.959 50.0018 50.0018)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="3"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.6666666666666666 0 0.5666666666666667 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(358.767 50.0002 50.0002)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="2"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.7 0 0.6 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>

        <g transform="rotate(358.372 49.9996 49.9996)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="1"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.7333333333333333 0 0.6333333333333333 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
        <g transform="rotate(358.372 49.9996 49.9996)">
          <g
            ng-attr-transform="translate(50 {{config.cy}})"
            transform="translate(50 16)"
          >
            <circle
              cx="0"
              cy="0"
              r="0"
              ng-attr-fill="{{config.fill}}"
              ng-attr-transform="scale({{config.scale}})"
              fill="#ffd000"
              transform="scale(0.30000000000000004)"
            />
          </g>
          <animateTransform
            attributeName="transform"
            calcMode="spline"
            type="rotate"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            ng-attr-dur="{{config.speed2}}"
            keySplines="0.7666666666666666 0 0.6666666666666666 1"
            repeatCount="indefinite"
            dur="1.875"
          />
        </g>
      </g>
    </svg>
  );
}

MainLoaderCircle.defaultProps = {
  className: '',
};

MainLoaderCircle.propTypes = {
  className: PropTypes.string,
};
