import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconAuto, IconMyAccount } from '@lmig/lmds-react/icons';
import ReactTooltip from 'react-tooltip';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import localizedStrings from '../../utils/localizedStrings';
import ArrowIcon from '../Icons/ArrowIcon/ArrowIcon';
import { GALLERY_OPTIONS } from '../Stepper/Stepper';
import IconClose from './IconClose';
import ImagePreviewModal from './ImagePreviewModal';
import { logcodes, logger } from '../../utils/logger';
import { MainLoaderContainer } from '../MainLoader/MainLoaderContainer';
import { MainLoader } from '../MainLoader/MainLoader';
import { inspectionInformActions } from '../../redux/inspection-inform';
import { currentInspectionActions } from '../../redux/current-inspection';
import './styles.scss';
import LocationAddress from './LocationAddress';

const {
  INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP,
  INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP,
} = localizedStrings;

const PhotoModal = ({
  isOpen,
  inspectionId,
  photos = [],
  currentPhotoPreview,
  closeButtonHandler,
  rotation,
  geolocation,
  rotateImage,
  displayImageContainsVehicleIcon,
  displayImageContainsPersonIcon,
  downloadDisabled,
}) => {
  const [brigthnessValue, setBrigthnessValue] = useState(100);
  const [currentIndexImage, setCurrentIndexImage] = useState();
  const [resetBrigthness, setResetBrigthness] = useState(false);
  const handleFullscreen = useFullScreenHandle();
  const brigthnessRef = useRef(null);

  const {
    inspectionInform: { loadingGallery },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  useEffect(() => {
    let timeOutBrigthness;
    if (brigthnessValue && brigthnessValue !== 100) {
      setBrigthnessValue(100);
      setResetBrigthness(true);
      timeOutBrigthness = setTimeout(() => {
        setResetBrigthness(false);
      }, 100);
    }
    return () => {
      clearTimeout(timeOutBrigthness);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndexImage]);

  useEffect(() => {
    const index = photos.findIndex(
      photo => photo.name === currentPhotoPreview.name,
    );
    setCurrentIndexImage(index);
  }, [currentPhotoPreview, photos]);

  const onChangeCurrentPhotoPreview = useCallback(
    nextPhoto => {
      dispatch(currentInspectionActions.setCurrentPhotoPreviewModal(nextPhoto));
    },
    [dispatch],
  );

  function onNextPicture() {
    let index;
    setCurrentIndexImage(prev => {
      if (currentIndexImage < photos?.length - 1) {
        index = prev + 1;
      } else index = 0;

      onChangeCurrentPhotoPreview(photos[index || 0]);
      return index;
    });
  }

  function onBackPicture() {
    let index;
    setCurrentIndexImage(prev => {
      if (currentIndexImage === 0) {
        index = prev + photos?.length - 1;
      } else {
        index = prev - 1;
      }

      onChangeCurrentPhotoPreview(photos[index]);
      return index;
    });
  }

  const handleRotateImage = async (photoName, rotateDirection) => {
    try {
      dispatch(inspectionInformActions.onLoadingGallery(true));

      const response = await rotateImage(
        inspectionId,
        photoName,
        rotateDirection,
      );

      const photoUpdated = {
        ...currentPhotoPreview,
        url: response.url,
      };

      dispatch(
        currentInspectionActions.setCurrentPhotoPreviewModal(photoUpdated),
      );

      dispatch(inspectionInformActions.onLoadingGallery(false));
    } catch (error) {
      logger.log(logcodes.ROTI010, { error, photoName });
    }
  };

  const onManageGalleryOption = async type => {
    switch (type) {
      case GALLERY_OPTIONS.LEFT_ROTATION:
        await handleRotateImage(currentPhotoPreview.name, 90);
        break;
      case GALLERY_OPTIONS.RIGHT_ROTATION:
        await handleRotateImage(currentPhotoPreview.name, -90);
        break;
      default:
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeButtonHandler}
      className="photo-modal"
      overlayClassName="photo-modal-overlay"
    >
      <FullScreen handle={handleFullscreen} className="photo-modal-content">
        {loadingGallery && (
          <MainLoaderContainer>
            <MainLoader />
          </MainLoaderContainer>
        )}

        <div className="preview-photo-arrows">
          <ArrowIcon direction="left" onClick={() => onBackPicture()} />
          <ArrowIcon direction="right" onClick={() => onNextPicture()} />
        </div>

        <IconClose
          className="photo-modal-image-close-left-button"
          onClick={closeButtonHandler}
        />

        <ImagePreviewModal
          downloadDisabled={downloadDisabled}
          geolocation={geolocation}
          currentPhoto={currentPhotoPreview}
          handleFullscreen={handleFullscreen}
          rotation={rotation}
          galleryOptions={{
            onManageGalleryOption,
            setBrigthnessValue,
            brigthnessRef,
            resetBrigthness,
          }}
        />

        <div className="photo-modal-image-footer">
          <div className="photo-modal-icons">
            <LocationAddress geolocation={geolocation} />

            {displayImageContainsVehicleIcon && (
              <IconAuto
                color="success"
                size="16"
                data-tip={INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP}
                title=""
                className="photo-modal-icon"
              />
            )}

            {displayImageContainsPersonIcon && (
              <IconMyAccount
                color="error"
                size="16"
                data-tip={INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP}
                title=""
                className="photo-modal-icon"
              />
            )}
            <ReactTooltip type="light" effect="solid" border />
          </div>
        </div>
      </FullScreen>
    </ReactModal>
  );
};

PhotoModal.defaultProps = {
  geolocation: null,
  photos: [],
};

PhotoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeButtonHandler: PropTypes.func.isRequired,
  displayImageContainsVehicleIcon: PropTypes.bool.isRequired,
  displayImageContainsPersonIcon: PropTypes.bool.isRequired,
  inspectionId: PropTypes.string.isRequired,

  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),

  currentPhotoPreview: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  downloadDisabled: PropTypes.bool.isRequired,
  rotateImage: PropTypes.func.isRequired,
  rotation: PropTypes.number.isRequired,
  geolocation: PropTypes.shape({
    streetAddress: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
};

export default PhotoModal;
