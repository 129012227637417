import * as React from 'react';
import PropTypes from 'prop-types';

function PanelMark({ className, children }) {
  return (
    <div
      className={`${className} content:'':before position:absolute:before top:0:before left:0:before h:100%:before w:6px:before bg:#99e5ea:before`}
    >
      {children}
    </div>
  );
}

PanelMark.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PanelMark.defaultProps = {
  className: '',
};

export default React.memo(PanelMark);
