import React from 'react';
import PropTypes from 'prop-types';

export const FullscreenIcon = ({
  width,
  height,
  background,
  hidden,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    cursor="pointer"
    style={{ display: hidden && 'none' }}
    {...props}
  >
    <g>
      <circle cx="16" cy="16" r="16" fill={background} />
      <g id="Grupo_19264" data-name="Grupo 19264" transform="translate(8 8)">
        <path
          d="M29.046,29.121l-4.093-.008.008.928h5.005V25.087l-.928-.008Z"
          transform="translate(-13.966 -14.041)"
          fill="#fff"
        />
        <path
          d="M0,25.087v4.955H4.955l.008-.928L.92,29.121l.008-4.042Z"
          transform="translate(0 -14.041)"
          fill="#fff"
        />
        <path
          d="M25.089,0l-.008.928,4.035,0-.008,4.042.928-.008V.006Z"
          transform="translate(-14.036)"
          fill="#fff"
        />
        <path d="M4.954,0,0,.006V4.961l.928.008L.92.927l4.042,0Z" fill="#fff" />
      </g>
    </g>
  </svg>
);

FullscreenIcon.defaultProps = {
  width: 40,
  height: 40,
  hidden: false,
  background: '#2a2a2a3d',
};

FullscreenIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};
