import { Button, Heading, Table, TableCell, TableRow } from '@lmig/lmds-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import withReactContent from 'sweetalert2-react-content';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { supportRequestActions } from '../../redux/support-request';
import { formatISOString } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { InlineLoader } from '..';

const {
  ADMIN_SUPPORT_PAGE_SOLUTIONS,
  ADMIN_SUPPORT_ADD,
  ADMIN_SUPPORT_MODIFY,
  ADMIN_SUPPORT_ADD_SOLUTION,
  ADMIN_SUPPORT_ADD_TROUBLE_SORT,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT,
  ADMIN_SUPPORT_TABLE_HEAD_1_SOLUTIONS,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
  ADMIN_SUPPORT_TABLE_HEAD_1,
  ADMIN_SUPPORT_TABLE_HEAD_2,
  ADMIN_SUPPORT_TABLE_HEAD_3,
  ADMIN_SUPPORT_TABLE_HEAD_4,
  ADMIN_SUPPORT_TABLE_HEAD_5,
  ADMIN_SUPPORT_TABLE_HEAD_6,
  ADMIN_SUPPORT_TABLE_DELETE,
  ADMIN_SUPPORT_TABLE_UPDATE,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY,
  ERROR_MESSAGE_NUMBER_ONLY,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
  ADMIN_SUPPORT_DELETE_YES,
  ADMIN_SUPPORT_DELETE_NO,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
  COUNTER_PAGES,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
  SEARCH_LOADING_LABEL,
} = localizedStrings;

const MySwal = withReactContent(Swal);

const TableSupportSolutions = ({ solutions, onLoader }) => {
  const [solutionsArray, setSolutionsArray] = useState(solutions || []);
  const [isformModifying, setIsformModifying] = useState(false);
  const [idModify, setidModify] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const dataPerPage = 5;
  const lastPost = page * dataPerPage;
  const firstPost = lastPost - dataPerPage;
  const [currentSolution, setCurrentSolution] = useState(
    solutions.slice(firstPost, lastPost) || [],
  );

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setSolutionsArray(solutions);
    return () => {
      setSolutionsArray({});
    };
  }, [solutions]);

  useEffect(() => {
    solutions.sort((a, b) => a.sort - b.sort);
    solutionsArray.sort((a, b) => a.sort - b.sort);
  }, [solutions, solutionsArray]);

  useEffect(() => {
    setCurrentSolution(solutionsArray.slice(firstPost, lastPost));
    return () => {
      setCurrentSolution([]);
    };
  }, [firstPost, lastPost, setCurrentSolution, solutionsArray]);

  useEffect(() => {
    const indexPagination = [];
    for (
      let index = 1;
      index <= Math.ceil(solutionsArray.length / dataPerPage);
      // eslint-disable-next-line no-plusplus
      index++
    ) {
      indexPagination.push(index);
    }
    setPagination(indexPagination);
  }, [setCurrentSolution, solutionsArray, currentSolution]);

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = data => {
    if (isValid && !isformModifying) {
      const solutionArrayTemp = [...solutionsArray];
      const createDate = formatISOString(new Date()).split(' ')[0];
      const solutionToSend = {
        ...data,
        id: uuidv4(),
        createDate,
        countryCode: getCountryCode().toLocaleLowerCase(),
      };
      dispatch(
        supportRequestActions.createAndUpdatesupportRequest(solutionToSend),
      )
        .then(() => {
          solutionArrayTemp.push(solutionToSend);
          setSolutionsArray(solutionArrayTemp);
          setPage(1);
          reset();
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        });
    }

    if (isValid && isformModifying) {
      const dateModify = formatISOString(new Date()).split(' ')[0];
      const solutionArrayTemp = [...solutionsArray];
      const indexSolution = solutionsArray.findIndex(
        solution => solution.id === idModify,
      );
      solutionArrayTemp[indexSolution] = {
        id: idModify,
        ...data,
        createDate: solutionArrayTemp[indexSolution].createDate,
        updateDate: dateModify,
      };
      dispatch(
        supportRequestActions.createAndUpdatesupportRequest(
          solutionArrayTemp[indexSolution],
        ),
      )
        .then(() => {
          setSolutionsArray(solutionArrayTemp);
          reset();
          setIsformModifying(false);
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        });
      reset();
      setIsformModifying(false);
    }
  };

  const deleteSolutionForId = idSolution => {
    MySwal.fire({
      title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
      showDenyButton: true,
      confirmButtonText: ADMIN_SUPPORT_DELETE_YES,
      denyButtonText: ADMIN_SUPPORT_DELETE_NO,
      allowOutsideClick: false,
      customClass: {
        title: 'sweetAdil__title',
        denyButton: 'sweetAdil__cancelButton',
        confirmButton: 'sweetAdil__acceptButton',
      },
    }).then(result => {
      if (result.isConfirmed) {
        const troubleArrayDeleteId = solutionsArray.filter(
          item => item.id !== idSolution,
        );
        dispatch(
          supportRequestActions.deletesSupportRequest({
            idItem: idSolution,
            isTrouble: false,
            countryCode: getCountryCode().toLocaleLowerCase(),
          }),
        )
          .then(() => {
            setSolutionsArray(troubleArrayDeleteId);
            MySwal.fire({
              title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          })
          .catch(() => {
            MySwal.fire({
              title: ADMIN_SUPPORT_TABLE_HEAD_1,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          });
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  };

  const setValueForm = formValues => {
    const { solution, description, sort, activeOption, id } = formValues;
    setIsformModifying(true);
    setidModify(id);
    setValue('solution', solution || description, { shouldValidate: true });
    setValue('sort', sort, { shouldValidate: true });
    setValue('activeOption', activeOption, { shouldValidate: true });
  };

  const changePagination = paginationNumber => {
    setPage(paginationNumber);
  };

  useEffect(() => {
    if (solutionsArray.slice(firstPost, lastPost).length === 0) {
      setPage(1);
      changePagination(1);
    }
  }, [firstPost, lastPost, solutionsArray]);

  return (
    <div className="supportBox">
      <h4>{ADMIN_SUPPORT_PAGE_SOLUTIONS}</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="supportBox__form">
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">{ADMIN_SUPPORT_ADD_SOLUTION}</label>
          <input
            placeholder={ADMIN_SUPPORT_ADD_SOLUTION}
            {...register('solution', { required: true })}
            className={`inputs_rhf_adil ${
              errors.solution ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">
            {ADMIN_SUPPORT_ADD_TROUBLE_SORT}
          </label>
          <input
            placeholder={ADMIN_SUPPORT_ADD_TROUBLE_SORT}
            {...register('sort', {
              required: true,
              pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.sort ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">
            {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE}
          </label>
          <select
            className={`inputs_rhf_adil ${
              errors.activeOption ? 'validation-input-field-error' : ''
            }`}
            defaultValue="active"
            {...register('activeOption')}
          >
            <option value="active">
              {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION}
            </option>
            <option value="inactive">
              {ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION}
            </option>
          </select>
        </div>
        <div className="supportBox__buttons">
          <Button type="submit" className="supportBox__button">
            {isformModifying && ADMIN_SUPPORT_MODIFY}
            {!isformModifying && ADMIN_SUPPORT_ADD}
          </Button>
        </div>
        <div className="supportBox__errorMessages">
          {errors.solution && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_SOLUTION}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
          {errors.sort && errors.sort.type === 'required' && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_TROUBLE_SORT}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
          {errors.sort && errors.sort.type === 'pattern' && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_TROUBLE_SORT}: ${ERROR_MESSAGE_NUMBER_ONLY}`}
            </span>
          )}
        </div>
      </form>
      <div className="supportBox__table">
        <Table>
          <tbody>
            <TableRow className="supportBox__table__headers">
              <TableCell type="colHead">
                {ADMIN_SUPPORT_TABLE_HEAD_1_SOLUTIONS}
              </TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_2}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_3}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_4}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_5}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_6}</TableCell>
            </TableRow>
            {!onLoader &&
              currentSolution.length > 0 &&
              currentSolution.map((solutionSupport, index) => (
                <TableRow
                  className={`supportBox__table__content ${
                    index % 2 === 0 ? 'adil-table-row-grey' : ''
                  }`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={solutionSupport.id}
                >
                  <td>
                    {solutionSupport.description || solutionSupport.solution}
                  </td>
                  <td>{solutionSupport.sort}</td>
                  <td>
                    {solutionSupport.activeOption === 'active'
                      ? ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT
                      : ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT}
                  </td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        className="supportBox__chipButtons supportBox__chipButtons__update"
                        role="button"
                        onClick={() => setValueForm(solutionSupport)}
                      >
                        <span>{ADMIN_SUPPORT_TABLE_UPDATE}</span>
                      </div>
                      <div
                        className="supportBox__chipButtons supportBox__chipButtons__eliminate"
                        role="button"
                        onClick={() => deleteSolutionForId(solutionSupport.id)}
                      >
                        <span>{ADMIN_SUPPORT_TABLE_DELETE}</span>
                      </div>
                    </div>
                  </td>
                  <td>{solutionSupport.createDate}</td>
                  <td>{solutionSupport.updateDate}</td>
                </TableRow>
              ))}
          </tbody>
        </Table>
        {onLoader && renderInlineLoader()}
      </div>
      <div className="supportBox__pagination">
        <label>{COUNTER_PAGES}</label>
        <select
          onChange={e => changePagination(e.target.value)}
          className="inputs_rhf_adil"
        >
          {pagination.length > 0 &&
            pagination.map(pageData => (
              <option key={pageData} value={pageData}>
                {pageData}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

TableSupportSolutions.propTypes = {
  solutions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onLoader: PropTypes.bool.isRequired,
};

export default TableSupportSolutions;
