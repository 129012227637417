import { arrayFiltersConfig } from '../../constants/menu';
import {
  ONCHANGE_CURRENT_LOCATION_PAGE,
  ONCHANGE_SEARCH_TEXT_INSPECTIONS,
  ONCHANGE_SEARCH_TEXT,
  MONTH_AND_YEAR_SELECTED,
  RESET_FILTER_STEPS,
  SELECT_STEP_FILTER_SEARCH,
  SET_COUNTS_INSPECTIONS,
  SELECT_ACTIVE_INSPECTION,
  RESET_ALL,
  ONLY_VENDOR_SELECTED,
  ON_DISPLAY_SUPPORT_REQUEST_DIALOG,
  SET_FINISHED_INSPECTIONS_QUERY,
} from './constants';

import {
  getCountsInpections,
  resetMenuFilter,
  resetPageInChangeSubStatus,
  setMenuArrayFilter,
  setActiveInspections,
} from './helpers';

export const initialState = {
  filterText: '',
  menuFilter: arrayFiltersConfig(),
  activeInspections: true,
  filterSelected: undefined,
  onlyVendor: false,
  monthAndYearSelected: '',
  countInspections: [],
  currentLocation: {
    currentPageIndex: 0,
    mustHavePhotos: false,
    subStatusSelected: undefined,
  },
  displaySupportRequestDialog: false,
  supportRequestSelected: null,
  finishedInspectionsQuery: '',
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SELECT_STEP_FILTER_SEARCH:
      return {
        ...state,
        filterText: '',
        activeInspections: setActiveInspections(payload.filter.id),
        filterSelected: payload.filter,
        menuFilter: setMenuArrayFilter(state.menuFilter, payload.filter.id),
        currentLocation: {
          ...state.currentLocation,
          currentPageIndex: 0,
          mustHavePhotos: false,
          subStatusSelected: undefined,
        },
        finishedInspectionsQuery: '',
      };

    case SELECT_ACTIVE_INSPECTION:
      return {
        ...state,
        activeInspections: setActiveInspections(payload.filter.id),
        filterSelected: undefined,
        subStatusSelected: undefined,
        onlyVendor: false,
      };

    case SET_COUNTS_INSPECTIONS:
      return {
        ...state,
        menuFilter: getCountsInpections(state.menuFilter, payload.count),
        countInspections: payload.count,
      };

    case ONCHANGE_SEARCH_TEXT_INSPECTIONS:
      return {
        ...state,
        filterText: payload.text,
        currentLocation: {
          ...state.currentLocation,
          mustHavePhotos: false,
          subStatusSelected: undefined,
        },
      };

    case ONCHANGE_SEARCH_TEXT:
      return {
        ...state,
        filterText: payload.text,
      };

    case MONTH_AND_YEAR_SELECTED:
      return {
        ...state,
        monthAndYearSelected: payload,
      };

    case ONCHANGE_CURRENT_LOCATION_PAGE:
      return {
        ...state,
        currentLocation: resetPageInChangeSubStatus(
          state.currentLocation,
          payload.location,
        ),
      };

    case RESET_FILTER_STEPS:
      return {
        ...state,
        menuFilter: resetMenuFilter(state.menuFilter),
        activeInspections: true,
        filterSelected: undefined,
        subStatusSelected: undefined,
      };

    case RESET_ALL:
      return {
        ...state,
        ...initialState,
      };
    case ONLY_VENDOR_SELECTED:
      return {
        ...state,
        onlyVendor: payload.onlyVendor,
      };
    case ON_DISPLAY_SUPPORT_REQUEST_DIALOG: {
      const { displaySupportRequestDialog, supportRequest } = payload;
      return {
        ...state,
        displaySupportRequestDialog,
        supportRequest: displaySupportRequestDialog ? supportRequest : null,
      };
    }
    case SET_FINISHED_INSPECTIONS_QUERY: {
      return {
        ...state,
        finishedInspectionsQuery: payload.query,
      };
    }

    default:
      return state;
  }
};
