import React from 'react';
import { useFormContext } from 'react-hook-form';
import localizedStrings from '../../utils/localizedStrings';
import corredorImage from '../../assets/images/inspector_icon_inform.png';

export const CorredorBox = () => {
  const {
    FORM_INFORM_ANALYSIS_CORREDOR_NAME,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
    FORM_INFORM_ANALYSIS_CORREDOR_CODE,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY,
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED,
  } = localizedStrings;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="formInform">
      <div className="formInform__icon">
        <img src={corredorImage} alt={corredorImage} />
      </div>
      <div className="formInform__inputs">
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_CORREDOR_NAME}
            {...register('corredorName', {
              required: true,
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.corredorName ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.corredorName && errors.corredorName.type === 'pattern' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
            </p>
          )}
          {errors.corredorName && errors.corredorName.type === 'required' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
            </p>
          )}
        </div>
        <div className="formInform__inputs__individual">
          <input
            placeholder={FORM_INFORM_ANALYSIS_CORREDOR_CODE}
            {...register('corredorCode', {
              required: true,
              pattern: {
                value: /^[0-9]*$/,
                message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.corredorCode ? 'validation-input-field-error' : ''
            }`}
          />
          {errors.corredorCode && errors.corredorCode.type === 'pattern' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY}
            </p>
          )}
          {errors.corredorCode && errors.corredorCode.type === 'required' && (
            <p className="error-message--forms">
              {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
