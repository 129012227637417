import {
  FIELD_NAMES,
  LANGUAGES,
  STATUS_TYPES,
} from '@lmig-latam/adil-api-common-lib/constants';
import {
  BodyText,
  Button,
  Content,
  Disclaimer,
  GridCol,
  GridRow,
  Heading,
} from '@lmig/lmds-react';
import { IconThumbsDown, IconThumbsUp } from '@lmig/lmds-react/icons';
import { Form, Scope } from 'informed';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cjson from 'compressed-json';
import {
  formatFullName,
  isIntegratedCameraSelected,
  prefillDataExternalInspection,
  prefillEisData,
  verifyFieldValidation,
  verifySelectValidation,
  YEAR_SELECT_OPTIONS,
} from '../../utils/index';
import { InspectionActions, SettingsActions } from '../../actions';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  ErrorsList,
  InformedAlphanumericInput,
  InformedSelect,
  InlineLoader,
  RequiredVideoSwitch,
  SideBar,
} from '../../components';

import localizedStrings from '../../utils/localizedStrings';
import { DEFAULT } from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import { prefillData } from '../../utils/test-data/createInspectionPrefill';
import { config } from './config';
import {
  getCountryCode,
  isAsianDomain,
  isDevelopment,
} from '../../config/environments/environmentsHelpers';
import { getCameraTypes } from '../../constants/camera';
import {
  isValidCameraModule,
  isValidVideoModule,
  OverlayEnablerStatuses,
} from '../../utils/constants';
import { getVideoOptions } from '../../constants/video';
import OverlayEnabler from '../../components/OverlayEnabler/OverlayEnabler';
import ParametersVideo from '../../components/ParametersVideo360/ParametersVideo';
import {
  frequencyVideoArray,
  lenghtVideoArray,
} from '../../components/ParametersVideo360/parametersConfig/parametersConfig';
import { useDebounce } from '../../hooks/useDebounce';
import { logcodes, logger } from '../../utils/logger';
import './styles.scss';

const { YEAR } = FIELD_NAMES;

const MySwal = withReactContent(Swal);

const { ENABLED } = OverlayEnablerStatuses;

const {
  STATUS_AGENT_REVIEW,
  STATUS_AGENT_APPROVED,
  STATUS_CUSTOMER_RETAKE,
  STATUS_AGENT_REJECTED,
  STATUS_CUSTOMER_NEW,
  STATUS_CUSTOMER_IN_PROGRESS,
  STATUS_EXPIRED,
  STATUS_SUPPORT_AGENT_REVIEW,
  STATUS_SUPPORT_IN_PROGRESS,
  STATUS_SUPPORT_NEW,
  STATUS_SUPPORT_RETAKE,
} = STATUS_TYPES;

const getAllStatus = () =>
  [
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_EXPIRED,
    STATUS_SUPPORT_AGENT_REVIEW,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_RETAKE,
  ].join(',');

const getActiveStatus = () =>
  [
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_AGENT_REVIEW,
    STATUS_CUSTOMER_RETAKE,
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_SUPPORT_RETAKE,
    STATUS_SUPPORT_AGENT_REVIEW,
  ].join(',');

const {
  CREATE_INSPECTION_CUSTOMER_TITLE,
  CREATE_INSPECTION_NOTES_TITLE,
  CREATE_INSPECTION_CUSTOMER_SUBTITLE,
  CREATE_INSPECTION_AGENT_TITLE,
  CREATE_INSPECTION_AGENT_SUBTITLE,
  CREATE_INSPECTION_VEHICLE_TITLE,
  CREATE_INSPECTION_VEHICLE_SUBTITLE,
  CREATE_INSPECTION_BROKER_TITLE,
  CREATE_INSPECTION_BROKER_SUBTITLE,
  CREATE_INSPECTION_NAMED_DRIVER_TITLE,
  CREATE_INSPECTION_NAMED_DRIVER_SUBTITLE,
  CREATE_INSPECTION_SUBMIT,
  CREATE_INSPECTION_PREFILL,
  CREATE_INSPECTION_CLEAN_FIELDS,
  CREATE_INSPECTION_CREATION_ERROR,
  CREATE_INSPECTION_CREATION_SUCCESS,
  CREATE_INSPECTION_CREATION_CLOSE,
  CREATE_INSPECTION_CREATION_CONTINUE,
  CREATE_INSPECTION_CREATION_COPY,
  CREATE_INSPECTION_INVALID_PHONE_NUMBER,
  INSPECTION_SUMMARY_HEADER_1,
  INSPECTION_VEHICLE_SUBMISSIONID_EXIST,
  CREATE_INSPECTION_CAMERA_TITLE,
  CREATE_INSPECTION_CAMERA_SUBTITLE,
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL,
  CREATE_INSPECTION_VIDEO_TITLE,
  CREATE_INSPECTION_VIDEO_SUBTITLE,
  CREATE_INSPECTION_SELECT_VIDEO_LABEL,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  SEARCH_DUPLICATED_SUBMISSIONID_LABEL,
  SEARCH_CUSTOMER_INFO_EIS_LABEL,
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_TITLE,
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_SUBTITLE,
  INSPECTION_DUPLICATE_MODAL_ERROR_TITLE,
  INSPECTION_DUPLICATE_MODAL_ERROR_SUBTITLE,
  CREATE_INSPECTION_INSPECTION_NOT_FOUND,
  CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR,
  CREATE_INSPECTION_LOADING_INSPECTION_MESSAGE,
} = localizedStrings;

const CAMERA_TYPES = getCameraTypes();
const VIDEO_OPTIONS = getVideoOptions();

const parametersVideoInitial = {
  frequency: frequencyVideoArray[0]?.split(' ')[0],
  duration: lenghtVideoArray[0]?.split(' ')[0],
  compress: false,
  required: true,
};
const messageErrorSubmissionId = `${INSPECTION_SUMMARY_HEADER_1}: ${INSPECTION_VEHICLE_SUBMISSIONID_EXIST}`;

const isVNCountry = () => getCountryCode() === 'vn';

const setCameraByDefault = () =>
  getCountryCode() === 'th' ? CAMERA_TYPES.FRAME.id : CAMERA_TYPES.NATIVE.id;

const getFormattedLabelVisual = ({ labelVisual, required, labelExample }) => {
  const labelParts = [labelVisual];

  if (labelExample) {
    labelParts.push(labelExample);
  }

  if (required) {
    labelParts.push('*');
  }

  return labelParts.join(' ');
};

const renderPrefillButton = (onClick, enableCreateInspectionPrefill) =>
  enableCreateInspectionPrefill && (
    <Button type="button" level="secondary" onClick={onClick}>
      {CREATE_INSPECTION_PREFILL}
    </Button>
  );

const cleanFields = onClick => (
  <Button type="button" level="secondary" onClick={onClick}>
    {CREATE_INSPECTION_CLEAN_FIELDS}
  </Button>
);

const CreateInspection = ({
  inspectionActions: {
    createInspection,
    getInspections,
    getCustomerInfoFromEis,
  },
  settingsActions: { hideAppLoader, showAppLoader },
  enableCreateInspectionPrefill,
  isReviewer,
  name,
  isBdmCa,
  isCa,
}) => {
  const [formApi, setFormApi] = useState({});

  const [videoParamaters, setVideoParamaters] = useState(
    parametersVideoInitial,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inspectionLink, setInspectionLink] = useState('');
  const [camera, setCamera] = useState(setCameraByDefault());
  const [enableVideo, setEnableVideo] = useState(false);
  const [checkedSwitchEmails, setCheckedSwitchEmails] = useState(false);
  const [copyButtonSupported] = useState(
    !!(navigator && navigator.clipboard && navigator.clipboard.writeText),
  );
  const [clientErrorCode, setClientErrorCode] = useState(false);
  const [isExistSubmissionId, setIsExistSubmissionId] = useState(false);
  const [overlayEnabler, setOverlayEnabler] = useState(false);
  const [onLoader, setOnLoader] = useState(false);
  const [requestEis, setRequestEis] = useState(false);
  const [submissionIdValue, setSubmissionIdValue] = useState('');
  const [eisInfo, setEisInfo] = useState(undefined);

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {requestEis
          ? SEARCH_CUSTOMER_INFO_EIS_LABEL
          : SEARCH_DUPLICATED_SUBMISSIONID_LABEL}
      </Heading>
    </div>
  );

  useEffect(() => {
    hideAppLoader();
  }, [hideAppLoader]);

  useEffect(() => {
    if (!isAsianDomain() && camera !== CAMERA_TYPES.NATIVE.id) {
      setEnableVideo(true);
      setOverlayEnabler(false);
    } else {
      setEnableVideo(false);
    }
  }, [camera, setCamera]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmisionIdChange = submissionId => {
    if (submissionIdValue.length === 0) {
      setSubmissionIdValue(submissionId);
      return true;
    }

    const isValidChange =
      submissionId.toLowerCase() !== submissionIdValue.toLowerCase();

    if (isValidChange) {
      setSubmissionIdValue(submissionId);
      return true;
    }
    return false;
  };

  const isValidateInspection = async submissionId => {
    setIsExistSubmissionId(false);
    const submissionIdChanged = handleSubmisionIdChange(submissionId);
    if (submissionIdChanged) {
      const dataParams = {};

      const country = getCountryCode();
      if (submissionId && submissionId.trim() !== '') {
        dataParams.status =
          country === 'vn' ? getActiveStatus() : getAllStatus();
        if (country === 'ec') {
          dataParams.policyNumber = submissionId;
        } else {
          dataParams.filterString = submissionId;
        }
      }
      setOnLoader(true);
      setEisInfo(undefined);
      let response;
      try {
        response = await cjson.decompress(
          await getInspections(dataParams, true),
        );
      } catch (error) {
        MySwal.fire({
          icon: 'error',
          title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
          text: INSPECTION_DUPLICATE_MODAL_ERROR_SUBTITLE,
          allowOutsideClick: false,
          customClass: {
            htmlContainer: 'sweetAdil__container',
            title: 'sweetAdil__title',
            confirmButton: 'sweetAdil__acceptButton',
          },
        });
      }

      if (response) {
        const { Items } = response;
        if (Items.length !== 0) {
          if (getCountryCode() === 'ec') {
            setOnLoader(false);
            formApi.setValues(Items[0]);
          } else {
            setIsExistSubmissionId(true);
            // eslint-disable-next-line no-unused-expressions
            getCountryCode() === 'vn' &&
              MySwal.fire({
                title: INSPECTION_DUPLICATE_MODAL_ERROR_TITLE,
                text: messageErrorSubmissionId,
                allowOutsideClick: false,
                customClass: {
                  htmlContainer: 'sweetAdil__container',
                  title: 'sweetAdil__title',
                  confirmButton: 'sweetAdil__acceptButton',
                },
              });
          }
        } else if (country === 'vn') {
          const eisParams = { submissionId };
          setRequestEis(true);
          const customerInfoEis = await getCustomerInfoFromEis(eisParams);
          setEisInfo(customerInfoEis);
          formApi.reset();
          setOnLoader(false);
          formApi.setValues(prefillEisData(customerInfoEis));
        } else if (country === 'ec') {
          MySwal.fire({
            title: INSPECTION_DUPLICATE_MODAL_NOT_FOUND_TITLE,
            text: INSPECTION_DUPLICATE_MODAL_NOT_FOUND_SUBTITLE,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        }
      }
      setOnLoader(false);
      setRequestEis(false);
    }
  };

  const selectDefaultValue = field => {
    if (field.required) {
      // eslint-disable-next-line no-nested-ternary
      return field.options
        ? getCountryCode() === 'co' && field.fieldName === 'class'
          ? ''
          : field.options[0]
        : '';
    }
    return '';
  };

  const validateEisInfo = fieldName => {
    if (eisInfo) {
      if (
        !['submissionId', 'location', 'cellPhoneNumber', 'email'].includes(
          fieldName,
        ) &&
        eisInfo[fieldName]
      )
        return true;
    }
    return false;
  };

  const showAlert = (title, type) => {
    MySwal.fire({
      icon: type,
      title,
      customClass: {
        title: 'sweetAdil__title',
        container: 'sweetAdil__container',
        confirmButton: 'sweetAdil__acceptButton',
      },
    });
  };

  const getExternalInspectionHandler = useDebounce(async event => {
    const { value } = event.target;
    if (value.trim() === '') return;

    const countryCode = getCountryCode();
    const params = {
      submissionId: value,
      externalInspection: true,
    };

    try {
      logger.log(logcodes.SIE010, { countryCode, submissionId: value });
      showAppLoader(true, CREATE_INSPECTION_LOADING_INSPECTION_MESSAGE);
      const response = await getInspections(params, false);

      if (response?.code === '404') {
        formApi.reset();
        formApi.setValue('submissionId', value);
        showAlert(CREATE_INSPECTION_INSPECTION_NOT_FOUND, 'warning');
      } else if (response.submissionId) {
        const prefill = prefillDataExternalInspection(response);
        formApi.setValues(prefill);
      } else {
        showAlert(CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR, 'error');
        formApi.reset();
        formApi.setValue('submissionId', value);
      }
      hideAppLoader();
    } catch (error) {
      logger.log(logcodes.SIE020, error);
      hideAppLoader();
      showAlert(CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR, 'error');
    }
  }, 1000);

  const getInformedInput = (field, scope) => {
    const { fieldName } = field;
    let inputComponent;

    // Try to find content for an InformedSelect field
    let fieldContent = field.options;

    if (fieldName === YEAR) {
      fieldContent = YEAR_SELECT_OPTIONS.sort((a, b) => b - a);
    }

    const useAlphanumericInput = !fieldContent;

    if (eisInfo) validateEisInfo(fieldName);

    const validateFunction = useAlphanumericInput
      ? verifyFieldValidation
      : verifySelectValidation;

    const fieldProps = {
      field: fieldName,
      id: fieldName,
      labelVisual: getFormattedLabelVisual(field),
      validate: value => {
        // If a field is required OR if that field has been given a value then do a full validation check
        if (field.required || (value && value.length > 0)) {
          return validateFunction(value, field);
        }
        // Else do not validate that field and allow form submission
        return undefined;
      },
      initialValue: selectDefaultValue(field),
      ...(fieldName === 'submissionId' && {
        onBlur: e => {
          if (getCountryCode() !== LANGUAGES.EC) isValidateInspection(e);
        },
        onChange: e => {
          if (getCountryCode() === LANGUAGES.EC) {
            e.persist();
            getExternalInspectionHandler(e);
          }
        },
      }),
      className: 'create-inspection-input-padding',
      validateOnChange: true,
      validateOnBlur: true,
      disabled: validateEisInfo(fieldName) || onLoader,
    };
    if (useAlphanumericInput) {
      inputComponent = <InformedAlphanumericInput {...fieldProps} />;
    } else {
      inputComponent = (
        <InformedSelect {...fieldProps} selectValues={fieldContent} />
      );
    }

    return <GridCol key={`${scope}-${fieldName}`}>{inputComponent}</GridCol>;
  };

  const renderScopedInputs = (
    scope,
    fieldConfig,
    title,
    subtitle,
    numberOfColumns = 2,
  ) => {
    // Copy the fieldConfig so we don't mutate it
    const formFields = [...fieldConfig];
    // If there's an extra field, pop it from the array so we can give it its own row later
    let extraField;
    if (formFields.length % numberOfColumns !== 0) {
      extraField = formFields.pop();
    }

    let fieldGridRows = [];

    // Pair the fields together into an array to be mapped later
    let counter = 0;
    while (counter < formFields.length) {
      const columns = [];
      let field = 0;
      while (field < numberOfColumns) {
        // eslint-disable-next-line no-plusplus
        columns.push(formFields[counter++]);
        // eslint-disable-next-line no-plusplus
        field++;
      }

      fieldGridRows.push(columns);
    }

    // Convert the array of field configs into React components
    fieldGridRows = fieldGridRows.map(fieldGridRow => (
      <GridRow
        key={`${fieldGridRow[0].fieldName}-${fieldGridRow[1].fieldName}`}
      >
        {fieldGridRow.map(field => getInformedInput(field, scope))}
      </GridRow>
    ));

    // Give the extra field its own row
    if (extraField) {
      fieldGridRows.push(
        <GridRow key={`${extraField.fieldName}}`}>
          {getInformedInput(extraField, scope)}
        </GridRow>,
      );
    }

    return (
      <Scope scope={scope}>
        <div className="search-title-area">
          <Heading type="h3-light" className="auto-left-right-margin">
            {title}
          </Heading>
          <Disclaimer>{subtitle}</Disclaimer>
        </div>
        {fieldGridRows.map(fieldGridRow => fieldGridRow)}
      </Scope>
    );
  };

  const overlayFieldValidator = () => {
    if (!isAsianDomain()) {
      return camera === CAMERA_TYPES.FRAME.id ? overlayEnabler : false;
    }
    return true;
  };

  const creatorTeamValidator = () => {
    if (isBdmCa || isCa) return 'CA_VN';
    return 'DCA_VN';
  };

  const namedDriverValidator = ({
    driverLicense1,
    driverName1,
    driverLastName1,
    driverLicense2,
    driverName2,
    driverLastName2,
  }) => {
    const drivers = [];
    if (driverLicense1.length !== 0) {
      drivers.push({
        licenseNumber: driverLicense1,
        firstName: driverName1,
        lastName: driverLastName1,
      });
    }
    if (driverLicense2.length !== 0) {
      drivers.push({
        licenseNumber: driverLicense2,
        firstName: driverName2,
        lastName: driverLastName2,
      });
    }
    return drivers;
  };

  const createInspectionHandler = async inspection => {
    let inspectionData;
    try {
      showAppLoader();
      inspectionData = inspection;
      if (isAsianDomain()) {
        const { firstName, lastName } = formatFullName(
          inspection.customer.fullName,
        );
        inspectionData.customer.firstName = firstName;
        inspectionData.customer.lastName = lastName;
        delete inspectionData.customer.fullName;
        inspectionData.customer.idType = 'N/A';
      }

      if (getCountryCode() === 'th') {
        const driversList = namedDriverValidator(inspectionData.namedDriver);
        if (driversList.length > 0) {
          inspectionData.namedDriver = { drivers: driversList };
        } else delete inspectionData.namedDriver;
      }

      delete inspectionData.creatorEmail;
      delete inspectionData.camera;
      delete inspectionData.video;
      delete inspectionData.overlay;
      inspectionData.cameraType = camera;
      inspectionData.enableVideoSection = enableVideo;
      inspectionData.overlay = overlayFieldValidator();
      if (inspectionData.infoAgent?.creatorEmail)
        inspectionData.creatorEmail = inspectionData.infoAgent.creatorEmail;

      if (inspectionData.infoAgent?.bdmManagerName) {
        inspectionData.infoAgent.bdmManagerName = inspectionData.infoAgent.bdmManagerName.toLowerCase();
      }

      inspectionData.createdBy = name;
      if (getCountryCode() === 'vn') {
        inspectionData.creatorTeam = creatorTeamValidator();
        inspectionData = {
          ...inspectionData,
          creatorNote: inspectionData.noteField?.noteField,
        };
        delete inspectionData.noteField;
      }
      if (
        isValidVideoModule.includes(getCountryCode().toLowerCase()) &&
        enableVideo
      ) {
        inspectionData.videoParamaters = {
          ...videoParamaters,
          frequency: parseFloat(videoParamaters.frequency),
          duration: parseFloat(videoParamaters.duration),
        };
      } else {
        delete inspectionData.videoParamaters;
      }
      if (checkedSwitchEmails) {
        inspectionData.languageCode = 'en';
      }
      if (inspectionData.vehicle?.policyType === 'Renewal') {
        inspectionData.policyType = 'RENEWAL';
      }
      delete inspectionData.vehicle.policyType;
      const { link } = await createInspection(inspectionData);
      formApi.reset();
      setCheckedSwitchEmails(false);
      setInspectionLink(link);
      setEnableVideo(false);
      setCamera(setCameraByDefault());
      openModal();
      hideAppLoader();
    } catch (error) {
      setClientErrorCode(error.code);
      hideAppLoader();
      openModal();
    }
  };

  const setInformedApi = informedApi => {
    setFormApi(informedApi);
  };

  const resetModalState = () => {
    setIsModalOpen(false);
    setInspectionLink('');
  };

  const closeModal = () => {
    if (isReviewer) {
      resetModalState();
      navigate(DEFAULT);
    } else {
      resetModalState();
    }
  };

  const copyInspectionLink = () => {
    navigator.clipboard.writeText(inspectionLink);
  };

  const getErrorMessage = errorCode =>
    errorCode === 'SPI140' && CREATE_INSPECTION_INVALID_PHONE_NUMBER;

  const renderCreateInspectionResultsModal = () => {
    // If we have an inspection link, then the creation process was a success
    const isSuccess = !!inspectionLink;

    const messageText = isSuccess
      ? CREATE_INSPECTION_CREATION_SUCCESS
      : CREATE_INSPECTION_CREATION_ERROR;

    const messageSubText = isSuccess
      ? inspectionLink
      : getErrorMessage(clientErrorCode);

    const messageIcon = isSuccess ? (
      <IconThumbsUp size="64" />
    ) : (
      <IconThumbsDown size="64" />
    );

    const displayCopyButton = isSuccess && copyButtonSupported;

    return (
      <ReactModal
        isOpen={isModalOpen}
        className="modal-container"
        overlayClassName="modal-container-overlay"
      >
        <div className="modal-content">
          <CircleImage>{messageIcon}</CircleImage>
          <Heading type="h3-light">{messageText}</Heading>
          {(isDevelopment() && getCountryCode() === 'vn') ||
            (getCountryCode() !== 'vn' && (
              <BodyText>{messageSubText}</BodyText>
            ))}

          <div className="create-inspection-buttons-area">
            {(displayCopyButton &&
              isDevelopment() &&
              getCountryCode() === 'vn') ||
              (getCountryCode() !== 'vn' && displayCopyButton && (
                <Button type="button" onClick={copyInspectionLink}>
                  {CREATE_INSPECTION_CREATION_COPY}
                </Button>
              ))}
            <Button type="button" level="secondary" onClick={closeModal}>
              {isReviewer
                ? CREATE_INSPECTION_CREATION_CONTINUE
                : CREATE_INSPECTION_CREATION_CLOSE}
            </Button>
          </div>
        </div>
      </ReactModal>
    );
  };

  const handlerSelectCamera = e => {
    const { value } = e.target;
    if (CAMERA_TYPES.NATIVE.value.trim() === value)
      setCamera(CAMERA_TYPES.NATIVE.id);
    else setCamera(CAMERA_TYPES.FRAME.id);
  };

  const handlerSelectVideoSection = e => {
    const { value } = e.target;
    const [ON_VIDEO] = VIDEO_OPTIONS;
    if (ON_VIDEO.trim() === value.trim()) setEnableVideo(true);
    else setEnableVideo(false);
  };

  const handlerOverlayEnabler = overlayStatus => {
    const { status } = overlayStatus;
    if (status === ENABLED) setOverlayEnabler(true);
    else setOverlayEnabler(false);
  };

  const handlerVideoParameters = (value, option) => {
    switch (option) {
      case 'required':
        setVideoParamaters(prevState => ({
          ...prevState,
          required: value,
        }));
        break;
      case 'frequency':
        setVideoParamaters(prevState => ({
          ...prevState,
          frequency: value,
        }));
        break;
      case 'compress':
        setVideoParamaters(prevState => ({
          ...prevState,
          compress: value === ENABLED,
        }));
        break;
      case 'duration':
        setVideoParamaters(prevState => ({
          ...prevState,
          duration: value,
        }));
        break;
      default:
        break;
    }
  };

  const renderNamedDriver = () =>
    renderScopedInputs(
      FIELD_NAMES.NAMED_DRIVER,
      config.namedDriver,
      CREATE_INSPECTION_NAMED_DRIVER_TITLE,
      CREATE_INSPECTION_NAMED_DRIVER_SUBTITLE,
      3,
    );

  const renderNotesField = () => (
    <>
      <GridRow className="adil_noteField">
        <GridCol>
          <p>{CREATE_INSPECTION_NOTES_TITLE}</p>
          <div className="adilTextArea">
            {renderScopedInputs(FIELD_NAMES.NOTES_FIELD, config.noteField)}
          </div>
        </GridCol>
      </GridRow>
    </>
  );

  const isSelectedMotorcicle = useCallback(formApiValues => {
    const vehicleClassList = config.vehicle.find(
      item => item.fieldName === 'class',
    );

    const MOTO = vehicleClassList.options[1];

    return formApiValues?.getValues()?.vehicle?.class === MOTO;
  }, []);

  const cameraSelectValues = useCallback(
    formApiValues => [
      CAMERA_TYPES.NATIVE.value,
      !isSelectedMotorcicle(formApiValues) && CAMERA_TYPES.FRAME.value,
    ],
    [isSelectedMotorcicle],
  );

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      {renderCreateInspectionResultsModal()}
      <AppHeader />
      <SideBar>
        <div className="adil-content">
          <Content>
            <Form
              autoComplete="off"
              getApi={setInformedApi}
              onSubmit={createInspectionHandler}
            >
              {({
                formApi: formApiValues,
                formState: {
                  errors: {
                    customer: customerErrors = {},
                    vehicle: vehicleErrors = {},
                    broker: brokerErrors = {},
                    submissionId: submissionIdError = '',
                    infoAgent: agentInfoError = {},
                    noteField: noteFieldError = {},
                  },
                },
              }) => (
                <>
                  {getInformedInput(config.submissionId, null)}
                  <ErrorsList errors={[submissionIdError]} />
                  {isExistSubmissionId && (
                    <ErrorsList errors={[messageErrorSubmissionId]} />
                  )}
                  {onLoader ? (
                    renderInlineLoader()
                  ) : (
                    <>
                      {isVNCountry() &&
                        renderScopedInputs(
                          FIELD_NAMES.AGENT_INFO,
                          config.infoAgent,
                          CREATE_INSPECTION_AGENT_TITLE,
                          isVNCountry()
                            ? null
                            : CREATE_INSPECTION_AGENT_SUBTITLE,
                        )}
                      <ErrorsList errors={Object.values(agentInfoError)} />
                      {renderScopedInputs(
                        FIELD_NAMES.CUSTOMER,
                        config.customer,
                        CREATE_INSPECTION_CUSTOMER_TITLE,
                        isVNCountry()
                          ? null
                          : CREATE_INSPECTION_CUSTOMER_SUBTITLE,
                      )}
                      <ErrorsList errors={Object.values(customerErrors)} />
                      {renderScopedInputs(
                        FIELD_NAMES.VEHICLE,
                        config.vehicle,
                        CREATE_INSPECTION_VEHICLE_TITLE,
                        isVNCountry()
                          ? null
                          : CREATE_INSPECTION_VEHICLE_SUBTITLE,
                      )}
                      <ErrorsList errors={Object.values(vehicleErrors)} />

                      {getCountryCode() === 'th' && renderNamedDriver()}
                      {getCountryCode() === 'vn' && (
                        <>
                          {renderNotesField()}
                          <ErrorsList errors={Object.values(noteFieldError)} />
                        </>
                      )}

                      {!isVNCountry() &&
                        renderScopedInputs(
                          FIELD_NAMES.BROKER,
                          config.broker,
                          CREATE_INSPECTION_BROKER_TITLE,
                          CREATE_INSPECTION_BROKER_SUBTITLE,
                        )}

                      <ErrorsList errors={Object.values(brokerErrors)} />

                      {isValidCameraModule.includes(
                        getCountryCode().toLowerCase(),
                      ) && (
                        <Scope scope="camera">
                          <div className="search-title-area">
                            <Heading
                              type="h3-light"
                              className="auto-left-right-margin"
                            >
                              {CREATE_INSPECTION_CAMERA_TITLE}
                            </Heading>
                            <Disclaimer>
                              {CREATE_INSPECTION_CAMERA_SUBTITLE}
                            </Disclaimer>
                          </div>
                          <InformedSelect
                            id="camera"
                            labelVisual={
                              CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL
                            }
                            initialValue={CAMERA_TYPES.NATIVE.value}
                            onChange={e => handlerSelectCamera(e)}
                            selectValues={cameraSelectValues(formApiValues)}
                          />
                          {isIntegratedCameraSelected(camera) && (
                            <OverlayEnabler
                              onChangeOverlayEnabler={handlerOverlayEnabler}
                            />
                          )}
                        </Scope>
                      )}

                      {isValidVideoModule.includes(
                        getCountryCode().toLowerCase(),
                      ) &&
                        !isSelectedMotorcicle(formApiValues) &&
                        camera !== CAMERA_TYPES.NATIVE.id && (
                          <Scope scope="video">
                            <div className="search-title-area">
                              <Heading
                                type="h3-light"
                                className="auto-left-right-margin"
                              >
                                {CREATE_INSPECTION_VIDEO_TITLE}
                              </Heading>
                              <Disclaimer>
                                {CREATE_INSPECTION_VIDEO_SUBTITLE}
                              </Disclaimer>
                            </div>
                            {enableVideo && (
                              <RequiredVideoSwitch
                                required={videoParamaters.required}
                                onChange={handlerVideoParameters}
                              />
                            )}
                            <InformedSelect
                              id="video"
                              labelVisual={CREATE_INSPECTION_SELECT_VIDEO_LABEL}
                              onChange={e => handlerSelectVideoSection(e)}
                              initialValue={VIDEO_OPTIONS[0]}
                              selectValues={VIDEO_OPTIONS}
                            />
                            {enableVideo && (
                              <ParametersVideo
                                initialValues={{
                                  ...videoParamaters,
                                  frequency: videoParamaters.frequency.toString(),
                                  duration: videoParamaters.duration.toString(),
                                }}
                                onChangeVideoParameters={handlerVideoParameters}
                              />
                            )}
                          </Scope>
                        )}
                      <div className="create-inspection-buttons-area">
                        {renderPrefillButton(() => {
                          formApi.setValues(prefillData);
                        }, enableCreateInspectionPrefill)}
                        {cleanFields(() => {
                          formApi.reset();
                          formApi.setValue('submissionId', submissionIdValue);
                        })}
                        <Button
                          disabled={
                            getCountryCode() === 'vn' && isExistSubmissionId
                          }
                          type="submit"
                        >
                          {CREATE_INSPECTION_SUBMIT}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </Form>
            {renderFooterSection()}
          </Content>
        </div>
      </SideBar>
    </div>
  );
};

CreateInspection.propTypes = {
  inspectionActions: InspectionActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  enableCreateInspectionPrefill: PropTypes.bool.isRequired,
  isReviewer: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  isBdmCa: PropTypes.bool.isRequired,
  isCa: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  settings: {
    environment: { enableCreateInspectionPrefill },
  },
  user: { isReviewer, name, isBdmCa, isCa },
}) => ({
  enableCreateInspectionPrefill,
  isReviewer,
  name,
  isBdmCa,
  isCa,
});

const mapDispatchToProps = dispatch => ({
  inspectionActions: bindActionCreators(InspectionActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInspection);
