import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ZoomInIcon = ({ width, height, onClick, hidden, ...props }) => (
  <div
    role="button"
    className="circle-icon"
    style={{ display: hidden && 'none' }}
    onClick={onClick}
  >
    <svg
      width={width}
      height={height}
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="-50 -50 350 350"
      xmlSpace="preserve"
      onClick={onClick}
      {...props}
    >
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M217.576,214.708l-65.188-67.794c16.139-15.55,26.209-37.355,26.209-61.484 c0-47.106-38.323-85.43-85.43-85.43C46.06,0,7.737,38.323,7.737,85.43c0,47.106,38.323,85.43,85.43,85.43 c17.574,0,33.924-5.339,47.52-14.474l66.077,68.719c1.473,1.531,3.439,2.302,5.407,2.302c1.87,0,3.743-0.695,5.197-2.094 C220.354,222.441,220.447,217.693,217.576,214.708z M22.737,85.43c0-38.835,31.595-70.43,70.43-70.43 c38.835,0,70.43,31.595,70.43,70.43s-31.595,70.43-70.43,70.43C54.332,155.859,22.737,124.265,22.737,85.43z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M131.415,77.93h-30.748V47.182c0-4.143-3.357-7.5-7.5-7.5c-4.143,0-7.5,3.357-7.5,7.5V77.93H54.918 c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h30.748v30.749c0,4.143,3.357,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V92.93h30.748 c4.143,0,7.5-3.357,7.5-7.5S135.557,77.93,131.415,77.93z"
        />
      </g>
    </svg>
  </div>
);

ZoomInIcon.defaultProps = {
  width: 40,
  height: 40,
  hidden: false,
};

ZoomInIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  hidden: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
