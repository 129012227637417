import 'whatwg-fetch';
import { Storage } from 'aws-amplify';
import { fileUtils } from '@lmig-latam/adlib-ui';
import { get, post, put, del } from '../common/request/api';
import { getCountryCode } from '../config/environments/environmentsHelpers';

export const logMessage = body => post('log', body, true);

export const getInspections = (queryObject, enableErrorHandling) =>
  get('inspections', enableErrorHandling, queryObject);

export const getFinishedInspections = (queryObject, enableErrorHandling) =>
  get('inspections/finished', enableErrorHandling, queryObject);

export const getCustomerInfoFromEis = queryObject =>
  get('inspections/customerInfoEis', false, queryObject);

export const getReport = queryObject =>
  get('inspections/report', false, queryObject);

export const getInspection = inspectionId => get(`inspections/${inspectionId}`);

export const getInspectionCount = queryObject =>
  get(`inspections/count`, false, queryObject);

export const updateInspection = inspection =>
  put(`inspections/${inspection.id}`, inspection);

export const updateInspectionPhotosOrZipAPI = (inspectionId, PhotosOrZip) =>
  post(
    `inspections/${inspectionId}/uploadPhotos?countryCode=${getCountryCode()}`,
    PhotosOrZip,
    true,
  );

export const addVideoCapture = async (inspectionId, photoName) =>
  post(
    `inspections/${inspectionId}/uploadPhotos?countryCode=${getCountryCode()}`,
    { name: photoName, videoCapture: true },
    true,
  );

export const updateInspectionZipAPI = (inspectionId, userID) =>
  post(
    `inspections/${inspectionId}/photosZip?countryCode=${getCountryCode()}&userID=${userID}`,
    userID,
    true,
  );

export const updateInspectionDocumentZipAPI = (inspectionId, userID) =>
  post(
    `inspections/${inspectionId}/documentsZip?countryCode=${getCountryCode()}`,
    { userID },
    true,
  );

export const sendZipToS3 = async (id, file) => {
  const blob = await fetch(file.data).then(r => r.blob());
  Storage.configure({
    customPrefix: {
      private: `${id}/photos/`,
    },
  });

  await Storage.put(`${id}.zip`, blob, {
    contentType: 'application/zip',
    resumable: true,
    tagging: 'av-status=CLEAN',
  });

  fileUtils.deleteBlobUrl(file.data);
};

export const sendDocumentsZipToS3 = async (id, file) => {
  const blob = await fetch(file.data).then(r => r.blob());
  Storage.configure({
    customPrefix: {
      private: `${id}/documents/`,
    },
  });

  await Storage.put(`${id}.zip`, blob, {
    contentType: 'application/zip',
    resumable: true,
    tagging: 'av-status=CLEAN',
  });

  fileUtils.deleteBlobUrl(file.data);
};

export const downloadDocumentsApi = (inspectionId, queryObject) =>
  get(`inspections/${inspectionId}/documentsZip`, false, queryObject);

export const updateInspectionStatus = (inspectionId, body) =>
  put(`inspections/${inspectionId}/status`, body, true);

export const addRejectedPhotosToHistory = (inspectionId, body) =>
  put(`inspections/${inspectionId}/photoHistory`, body, true);

export const getPhotos = (inspectionId, queryObject) =>
  get(`inspections/${inspectionId}/photos`, false, queryObject);

export const rotateImage = (inspectionId, document, rotate) =>
  post(`inspections/${inspectionId}/photos`, { document, rotate }, true);

export const getPhotosZip = (inspectionId, queryObject) =>
  get(`inspections/${inspectionId}/photosZip`, false, queryObject);

export const updatePhotos = (inspectionId, photos) =>
  put(`inspections/${inspectionId}/photos`, { photos });

export const getDocuments = (inspectionId, queryObject) =>
  get(`inspections/${inspectionId}/documents`, false, queryObject);

export const uploadDocument = (inspectionId, document) =>
  put(`inspections/${inspectionId}/documents`, { document });

export const deleteDocument = (inspectionId, document) =>
  del(`inspections/${inspectionId}/documents`, {
    name: document.name,
  });

export const deleteInspection = (inspectionId, agentComments) =>
  del(`inspections/${inspectionId}`, agentComments);

export const getCustomerMessages = (inspectionId, queryObject) =>
  get(`inspections/${inspectionId}/customerMessages`, false, queryObject);

export const addCustomerMessage = (inspectionId, customerMessage) =>
  put(`inspections/${inspectionId}/customerMessages`, customerMessage);

export const createInspection = inspection =>
  post('inspection', inspection, true);

export const resendCustomerNotifications = inspectionId =>
  post(`inspections/${inspectionId}/customerEmails/resend`, {}, true);

export const createSupportRequest = supportRequest =>
  post(`support`, supportRequest, true);

export const getSupportRequest = () => get(`support`, true);

export const getReportInspectionAsia = queryObject =>
  get(`inspections/reportTable`, true, queryObject);

export const deleteSupportRequest = objectDelete =>
  post(`support/delete`, objectDelete, true);

export const createPieceOrPart = objectToAdd =>
  post(`vehicleParts`, objectToAdd, true);

export const updatePieceOrPart = objectToUpdate =>
  put(`vehicleParts`, objectToUpdate, true);

export const deletePieceOrPart = objectToDelete =>
  del(`vehicleParts`, objectToDelete, true);

export const getPiecesAndParts = () => get(`vehicleParts`, true);

export const assignInspection = (
  inspectionId,
  name,
  override,
  enableErrorHandling = true,
) =>
  put(
    `inspections/${inspectionId}/assignee`,
    { assignee: name, override },
    enableErrorHandling,
  );

// If you're adding functions here, be sure to mock them in config/jest/setup.js
export default {
  addCustomerMessage,
  createInspection,
  deleteDocument,
  getCustomerMessages,
  getDocuments,
  getInspection,
  getInspections,
  getPhotos,
  logMessage,
  downloadDocumentsApi,
  resendCustomerNotifications,
  updateInspection,
  updateInspectionStatus,
  updatePhotos,
  createSupportRequest,
  getSupportRequest,
  deleteSupportRequest,
  uploadDocument,
  assignInspection,
  sendZipToS3,
  rotateImage,
  addRejectedPhotosToHistory,
};
