import { Button, Heading, TableCell, TableRow } from '@lmig/lmds-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import constants from '@lmig-latam/adil-api-common-lib/constants';
import { bindActionCreators } from 'redux';
import { inspectionPropType } from '../../prop-types';
import {
  formatISOString,
  getAssigneeDisplayName,
  getInspectionStatusDisplayName,
  formatDate,
} from '../../utils';

import { Chip, Image, InlineLoader } from '..';
import localizedStrings from '../../utils/localizedStrings';
import Review from '../../styles/images/svg/steps/peding-review.svg';
import { getStatusColor } from '../../utils/status-color';
import {
  getCountryCode,
  isAsianDomain,
} from '../../config/environments/environmentsHelpers';
import { InspectionActions } from '../../actions';
import NoticeIcon from './NoticeIcon';
import './styles.scss';
import { subItemRetakeDates, subItemSupportDates } from '../../utils/constants';

const {
  STATUS_TYPES: {
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_CANCELLED,
    STATUS_EXPIRED,
    STATUS_CUSTOMER_IN_PROGRESS,
  },
} = constants;

const {
  INSPECTIONS_TABLE_ACTION_BUTTON,
  INSPECTIONS_TABLE_HEADER_NUMBER,
  INSPECTIONS_TABLE_HEADER_PLATE,
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION,
  INSPECTIONS_TABLE_HEADER_NAME,
  INSPECTIONS_TABLE_HEADER_ASSIGNEE,
  INSPECTIONS_TABLE_HEADER_BROKER_NAME,
  INSPECTIONS_TABLE_HEADER_ACTION,
  INSPECTIONS_TABLE_HEADER_BUTTON,
  INSPECTIONS_TABLE_HEADER_CHASSIS_NUMBER,
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE,
  INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON,
  INSPECTION_LOADING_LABEL,
} = localizedStrings;

const NOT_APPLICABLE = 'N/A';

const InspectionTableRow = ({
  inspection: currentInspection,
  inspectionActions: { assignInspection },
  isGrey,
  onClick,
  name,
  isCreator,
  isViewer,
  onUpdateInspections,
}) => {
  const {
    id,
    assignee,
    completionDate,
    customer: { firstName, middleName, lastName, idNumber },
    vehicle,
    status,
    photos,
    submissionId,
    createdBy = NOT_APPLICABLE,
    creationDate,
    history,
    broker,
    emailTokenCreationDate,
    customerCompletionDate,
  } = currentInspection;

  const { currentLocation, activeInspections } = useSelector(
    store => store.searchFilter,
  );

  const licensePlateDisplay =
    vehicle && vehicle.licencePlate ? vehicle.licencePlate : NOT_APPLICABLE;

  const isCustomerInProgress = status === STATUS_CUSTOMER_IN_PROGRESS;

  const [showInliner, setShowInliner] = useState(false);

  const customerDisplayName = [firstName, middleName, lastName].join(' ');

  const createdOn = emailTokenCreationDate
    ? formatISOString(emailTokenCreationDate)
    : formatISOString(creationDate);

  const dateLastPhoto = customerCompletionDate
    ? formatISOString(customerCompletionDate)
    : null;

  const completionDateValue = completionDate
    ? formatISOString(completionDate)
    : null;

  const renderPhotoAlertIcon = () => (
    <NoticeIcon
      inspection={currentInspection}
      isCustomerInProgress={isCustomerInProgress && photos?.length > 0}
      supportRequest={currentInspection.supportRequest}
    />
  );

  const handleAssignInspection = async () => {
    setShowInliner(true);

    const { assignee: newAssignee, newStatus } = await assignInspection(
      id,
      name,
      [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED, STATUS_CANCELLED].includes(
        status,
      ),
      false,
    );

    onUpdateInspections({
      ...currentInspection,
      newAssignee,
      ...(newStatus && { status: newStatus }),
    });

    setShowInliner(false);
  };

  const isAssignedToYou = () => assignee === name;

  const overrideValidator = () =>
    [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED, STATUS_CANCELLED].includes(
      status,
    );

  const isAssignButtonDisabled = () =>
    isCreator ||
    overrideValidator() ||
    isAssignedToYou() ||
    isViewer ||
    [STATUS_EXPIRED].includes(status);

  const Assignee = ({ assignInspection, assignDisabled }) => (
    <Button
      style={{
        width: '50%',
      }}
      type="button"
      level="primary"
      className={`assignee-button ${assignDisabled ? 'button-disabled' : ''}`}
      onClick={assignInspection}
      disabled={assignDisabled}
    >
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: 'smaller',
        }}
      >
        {INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON}
      </span>
    </Button>
  );

  Assignee.propTypes = {
    assignDisabled: PropTypes.bool.isRequired,
    assignInspection: PropTypes.func.isRequired,
  };

  const dateDependsSubtitle = () => {
    if (
      !isAsianDomain() &&
      currentLocation &&
      currentLocation.subStatusSelected &&
      subItemRetakeDates.includes(currentLocation.subStatusSelected)
    ) {
      const elementFilteredByRetake = history?.findLast(
        elements => elements.name === 'CUSTOMER_RETAKE',
      );
      return elementFilteredByRetake
        ? formatDate(elementFilteredByRetake.timestamp)
        : null;
    }
    if (
      !isAsianDomain() &&
      currentLocation &&
      currentLocation.subStatusSelected &&
      subItemSupportDates.includes(currentLocation.subStatusSelected)
    ) {
      const elementFilteredBysupport = history?.findLast(
        elements => elements.name === 'SUPPORT_NEW',
      );
      const elementFilteredBysupportProgress = history?.findLast(
        elements => elements.name === 'SUPPORT_IN_PROGRESS',
      );
      const elementFilteredBysupportInspector = history?.findLast(
        elements => elements.name === 'SUPPORT_INSPECTOR',
      );
      const elementFilteredBysupportRetake = history?.findLast(
        elements => elements.name === 'SUPPORT_RETAKE',
      );
      if (elementFilteredBysupport) {
        return formatDate(elementFilteredBysupport.timestamp);
      }
      if (elementFilteredBysupportProgress) {
        return formatDate(elementFilteredBysupportProgress.timestamp);
      }
      if (elementFilteredBysupportInspector) {
        return formatDate(elementFilteredBysupportInspector.timestamp);
      }
      if (elementFilteredBysupportRetake) {
        return formatDate(elementFilteredBysupportRetake.timestamp);
      }
      return null;
    }
    return dateLastPhoto;
  };

  const renderInlineLoader = () => (
    <TableCell colSpan={13}>
      <div className="inspection-loader auto-left-right-margin">
        <InlineLoader isLoading />
        <Heading type="h5-light" className="auto-left-right-margin">
          {INSPECTION_LOADING_LABEL}
        </Heading>
      </div>
    </TableCell>
  );

  const renderTable = () => (
    <>
      {getCountryCode() === 'cl' && (
        <TableCell
          className="inspection-row-first-cell"
          type="data"
          dataHeader={INSPECTIONS_TABLE_HEADER_BUTTON}
        >
          <div className="inspection-row-first-cell-content">
            {renderPhotoAlertIcon()}

            <div className="inspection-row-content">
              <Assignee
                assignInspection={handleAssignInspection}
                assignDisabled={isAssignButtonDisabled()}
              />
            </div>
          </div>
        </TableCell>
      )}

      {getCountryCode() === 'cl' ? (
        <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NUMBER}>
          {submissionId === NOT_APPLICABLE
            ? id.toUpperCase()
            : submissionId.toUpperCase()}
        </TableCell>
      ) : (
        <TableCell
          className="inspection-row-first-cell"
          type="data"
          dataHeader={INSPECTIONS_TABLE_HEADER_NUMBER}
        >
          <div className="inspection-row-first-cell-content">
            {renderPhotoAlertIcon()}
            <div className="inspection-row-content">
              {submissionId === NOT_APPLICABLE
                ? id.toUpperCase()
                : submissionId.toUpperCase()}
            </div>
          </div>
        </TableCell>
      )}
      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NUMBER}>
        {createdBy}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NUMBER}>
        {createdOn}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NAME}>
        {dateDependsSubtitle()}
      </TableCell>

      {getCountryCode() === 'vn' && (
        <TableCell
          type="data"
          dataHeader={INSPECTIONS_TABLE_HEADER_COMPLETION_DATE}
        >
          {completionDateValue}
        </TableCell>
      )}

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_PLATE}>
        {licensePlateDisplay.toUpperCase()}
      </TableCell>

      <TableCell
        type="data"
        dataHeader={
          getCountryCode() !== 'vn'
            ? INSPECTIONS_TABLE_HEADER_IDENTIFICATION
            : INSPECTIONS_TABLE_HEADER_CHASSIS_NUMBER
        }
      >
        {getCountryCode() !== 'vn'
          ? idNumber || ''
          : vehicle?.chassisNumber || ''}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NAME}>
        {customerDisplayName}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_ASSIGNEE}>
        {getAssigneeDisplayName(assignee)}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_BROKER_NAME}>
        {broker?.brokerName}
      </TableCell>

      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_BROKER_NAME}>
        {broker?.brokerCode}
      </TableCell>
    </>
  );

  const renderFinishedTable = () => (
    <>
      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NUMBER}>
        {submissionId === NOT_APPLICABLE
          ? id.toUpperCase()
          : submissionId.toUpperCase()}
      </TableCell>
      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_PLATE}>
        {licensePlateDisplay.toUpperCase()}
      </TableCell>
      <TableCell
        type="data"
        dataHeader={INSPECTIONS_TABLE_HEADER_IDENTIFICATION}
      >
        {idNumber}
      </TableCell>
      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NAME}>
        {customerDisplayName}
      </TableCell>
      <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_NUMBER}>
        {createdOn}
      </TableCell>
      <TableCell
        type="data"
        dataHeader={INSPECTIONS_TABLE_HEADER_COMPLETION_DATE}
      >
        {completionDateValue}
      </TableCell>
    </>
  );

  return (
    <TableRow
      key={id}
      className={classNames({
        'adil-table-row-grey': isGrey,
      })}
    >
      {showInliner ? (
        renderInlineLoader()
      ) : (
        <>
          {activeInspections ? renderTable() : renderFinishedTable()}
          <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_ASSIGNEE}>
            <Chip
              color={getStatusColor(status)}
              text={getInspectionStatusDisplayName(status)}
            />
          </TableCell>

          <TableCell type="data" dataHeader={INSPECTIONS_TABLE_HEADER_ACTION}>
            <div onClick={onClick} role="button">
              <Image
                className="action-button"
                alt={INSPECTIONS_TABLE_ACTION_BUTTON}
                src={Review}
              />
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
const mapStateToProps = ({ user: { name, isCreator, isViewer } }) => ({
  name,
  isCreator,
  isViewer,
});
InspectionTableRow.propTypes = {
  inspection: inspectionPropType.isRequired,
  isGrey: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  inspectionActions: InspectionActions.PropertyTypes.isRequired,
  name: PropTypes.string.isRequired,
  isCreator: PropTypes.bool.isRequired,
  isViewer: PropTypes.bool.isRequired,
  onUpdateInspections: PropTypes.func,
};
InspectionTableRow.defaultProps = {
  isGrey: false,
  onUpdateInspections: () => {
    // Empty function
  },
};
const mapDispatchToProps = dispatch => ({
  inspectionActions: bindActionCreators(InspectionActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(InspectionTableRow);
