import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Panel, MenuFilter, StatusFilterBar } from '..';
import localizedStrings from '../../utils/localizedStrings';
import InputSearch from './InputSearch';
import './styles.scss';
import './toggle-switch.scss';
import InputSearchFinished from './InputSearchFinished';

const {
  SEARCH_BAR_SEARCH_ACTIVE_NOTIFICATION,
  SEARCH_BAR_SEARCH_COMPLETED_NOTIFICATION,
} = localizedStrings;

const SearchBar = ({ disabled, onSubmitFinishedForm }) => {
  const { filterSelected, activeInspections, countInspections } = useSelector(
    store => store.searchFilter,
  );

  const getSearchBarPanelClassName = () =>
    `adil-search-bar-panel ${
      filterSelected?.id === 5 ? 'adil-search__panel' : ''
    }`;

  const enableSubStatusFilter = () => {
    const subStatusAvailable = filterSelected?.statuses.some(
      status =>
        filterSelected.statuses.length > 1 && countInspections[status] > 0,
    );

    return subStatusAvailable;
  };

  return (
    <>
      <MenuFilter disabled={disabled} />

      <Panel className={getSearchBarPanelClassName()}>
        {filterSelected?.id !== 5 ? (
          <InputSearch />
        ) : (
          <InputSearchFinished onSubmitFinishedForm={onSubmitFinishedForm} />
        )}
      </Panel>
      <div className="adil-search-bar-notification">
        <li>
          {activeInspections
            ? SEARCH_BAR_SEARCH_ACTIVE_NOTIFICATION
            : SEARCH_BAR_SEARCH_COMPLETED_NOTIFICATION}
        </li>
      </div>
      {enableSubStatusFilter() && <StatusFilterBar />}
    </>
  );
};

SearchBar.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmitFinishedForm: PropTypes.func.isRequired,
};

export default SearchBar;
