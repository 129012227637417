import constants from '@lmig-latam/adil-api-common-lib/constants';

const {
  LANGUAGES: { TH },
} = constants;

const code = TH;

const strings = {
  BROWSER_TAB_TITLE: 'การตรวจสภาพ - ลิเบอร์ตี้ ประกันภัย',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'ลิเบอร์ตี้ ประกันภัย',
  VIGILADO_IMAGE_ALT_TEXT: 'VIGILADO SUPERINTENDENCIA BANCARIA DE COLOMBIA',

  APP_HEADER_TITLE: 'โมดูลการตรวจสภาพแบบบูรณาการ',
  APP_HEADER_USER_SECTION_LOGOUT: 'ล็อคเอาท์',
  OPERATING_COUNTRY_IMAGE_ALT_TEXT: 'ธงชาติอังกฤษ',

  SIDEBAR_ITEM_1: 'การตรวจสภาพ',
  SIDEBAR_ITEM_2: 'จัดการบัญชี',
  SIDEBAR_ITEM_3: 'เปลี่ยนรหัสผ่าน',
  SIDEBAR_ITEM_4: 'สร้างคำร้องขอตรวจสอบ',
  SIDEBAR_ITEM_5: 'รายงานการตรวจสภาพ',
  SIDEBAR_ITEM_6: 'ผู้ดูแลระบบ',

  FORM_INFORM_ANALYSIS_CORREDOR_NAME: 'ชื่อนายหน้า',
  FORM_INFORM_ANALYSIS_CORREDOR_CODE: 'รหัสทางเดิน',
  FORM_INFORM_ANALYSIS_INSPECTOR_USER: 'ผู้ใช้ตรวจสอบ',
  FORM_INFORM_ANALYSIS_INSPECTOR_NAME: 'ชื่อสารวัตร',
  FORM_INFORM_ANALYSIS_INSPECTOR_SUPPLIER: 'ผู้จัดหา',
  FORM_INFORM_ANALYSIS_INSPECTION_NAME_CUSTOMER: 'ชื่อลูกค้า',
  FORM_INFORM_ANALYSIS_INSPECTION_NUMBER: 'หมายเลขการตรวจสอบ',
  FORM_INFORM_ANALYSIS_INSPECTION_EMAIL_CUSTOMER: 'อีเมล์ลูกค้า',
  FORM_INFORM_ANALYSIS_INSPECTION_PLATE: 'สิทธิบัตร',
  FORM_INFORM_ANALYSIS_INSPECTION_VEHICULE_TYPE: 'ประเภทยานพาหนะ',
  FORM_INFORM_ANALYSIS_INSPECTION_MODULE: 'แบบจำลองการตรวจสอบ',
  FORM_INFORM_ANALYSIS_INSPECTION_PLACE: 'สถานที่ตรวจ',
  FORM_INFORM_ANALYSIS_INSPECTION_DATE: 'วันที่ตรวจสอบ',
  FORM_INFORM_ANALYSIS_INSPECTION_CITY_OR_REGION: 'เมืองหรือภูมิภาค',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS:
    'ไม่มีการแสดงอักขระพิเศษหรือตัวเลข',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY:
    'ไม่มีการแสดงอักขระพิเศษ',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY:
    'ยอมรับเฉพาะอักขระตัวเลขเท่านั้น',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED: 'ต้องระบุข้อมูลในช่องนี้',

  SUPPORT_TITTLE_PAGE: 'ผู้ดูแลระบบ',
  SUPPORT_SUBTITTLE_PAGE: 'เลือกตัวเลือกที่คุณต้องการจัดการ',
  ADMIN_SUPPORT_PAGE_TROUBLES: 'ตัวจัดการคำขอการสนับสนุน - ปัญหา',
  ADMIN_SUPPORT_PAGE_SOLUTIONS: 'ตัวจัดการคำขอการสนับสนุน - โซลูชั่น',
  ADMIN_PIECES_MANTEINER: '',
  ADMIN_PARTS_MANTEINER: '',
  ADMIN_SUPPORT_ADD: 'เพิ่ม',
  ADMIN_SUPPORT_MODIFY: 'ปรับเปลี่ยน',
  ADMIN_SUPPORT_BUTTON_VISUALIZE: 'เห็นภาพ',
  ADMIN_SUPPORT_ADD_TROUBLE: 'เพิ่มปัญหา',
  ADMIN_SUPPORT_ADD_PIECE: '',
  ADMIN_SUPPORT_ADD_PART: '',
  ADMIN_SUPPORT_SELECT_PART: '',
  ADMIN_SUPPORT_ADD_TROUBLE_SORT: 'เพิ่มคำสั่งซื้อ',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE: 'สถานะ',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION: 'เปิดใช้งาน',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION: 'ปิดการใช้งาน',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT: 'ใช้งานอยู่',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT: 'ไม่ทำงาน',
  ADMIN_SUPPORT_ADD_SOLUTION: 'เพิ่มโซลูชัน',
  ADMIN_SUPPORT_TABLE_HEAD_1: 'ปัญหา',
  ADMIN_SUPPORT_TABLE_HEAD_1_SOLUTIONS: 'โซลูชั่น',
  ADMIN_SUPPORT_TABLE_HEAD_2: 'คำสั่งซื้อ',
  ADMIN_SUPPORT_TABLE_HEAD_3: 'สถานะ',
  ADMIN_SUPPORT_TABLE_HEAD_4: 'ผู้ดูแลระบบ',
  ADMIN_SUPPORT_TABLE_HEAD_5: 'วันที่สร้าง',
  ADMIN_SUPPORT_TABLE_HEAD_6: 'วันที่แก้ไขล่าสุด',
  ADMIN_SUPPORT_TABLE_DELETE: 'ลบ',
  ADMIN_SUPPORT_TABLE_UPDATE: 'อัปเดต',
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: 'มีบางอย่างเกิดขึ้น!',
  ADMIN_SUPPORT_ERROR_GENERAL: 'ไม่สามารถดำเนินการนี้ให้เสร็จสิ้น',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION: 'คุณแน่ใจหรือไม่ว่าต้องการลบ',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT: 'ตัวเลือกที่ถูกลบ',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE:
    'คุณลบตัวเลือกสำเร็จแล้ว',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE:
    'ไม่สามารถลบตัวเลือกได้',
  ADMIN_SUPPORT_DELETE_YES: 'ยอมรับ',
  ADMIN_SUPPORT_DELETE_NO: 'ยกเลิก',
  COUNTER_PAGES: 'หน้า:',
  ADMIN_SUPPORT_TROUBLE_PREVISUALIZATE: 'ดูตัวอย่างปัญหา:',
  ADMIN_SUPPORT_TROUBLE_5_PROBLEMS:
    'สามารถเพิ่มระเบียนที่เกี่ยวข้องกับปัญหาได้ 5 รายการเท่านั้น',

  ADMIN_PIECES_MANTEINER_TABLE_HEAD_1: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_2: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_3: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_4: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_5: '',

  LOADING_TEXT: 'กำลังโหลด',

  ERROR_MESSAGE_NUMBER_ONLY: 'ช่องต้องเป็นตัวเลข',
  SUPPORT_INSPECTION_TROUBLES: 'ปัญหาที่รายงาน:',
  SUPPORT_INSPECTION_TROUBLES_ADITIONAL: 'ข้อมูลเพิ่มเติม',
  SUPPORT_INSPECTION_SOLUTIONS: 'วิธีแก้ไข:',
  SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL:
    'ความคิดเห็นเพิ่มเติมเกี่ยวกับโซลูชัน',
  SUPPORT_INSPECTION_SOLUTIONS_USER: 'ความคิดเห็นของผู้ใช้เพิ่มเติม',
  SUPPORT_INSPECTION_BINNACLE: 'ดูบันทึก',
  SUPPORT_INSPECTION_SOLUTIONS_RESOLUTOR: 'รีโซลเวอร์',
  SUPPORT_INSPECTION_BINNACLE_MODAL: 'บันทึกการสนับสนุน',
  SUPPORT_INSPECTION_BINNACLE_SELECT: 'เลือกคำขอ:',
  SUPPORT_INSPECTION_BINNACLE_SELECT_OPTION: 'คำขอการสนับสนุน - ',
  SUPPORT_INSPECTION_MODAL_TITLE: 'ขอรับการสนับสนุน',

  ERROR_MESSAGE_TITLE: 'ขออภัย ขั้นตอนได้เริ่มต้นไปแล้ว หรือเกิดข้อผิดพลาด',
  ERROR_MESSAGE_SUBTITLE:
    'หากคุณต้องการความช่วยเหลือ กรุณาติดต่อเราผ่านช่องทางให้บริการ',
  ERROR_MESSAGE_ACCESS_DENIED_TITLE: 'การเข้าใช้งานถูกปฏิเสธ',
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE:
    'คุณไม่สามารถเข้าใช้งานแบบคำร้องขอตรวจสภาพนี้ได้ คุณเข้าใช้งานถูกบัญชีหรือไม่',

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404:  ไม่พบหน้าดังกล่าว!',

  GPS_PERMISSION_DENIED: '',
  GPS_PERMISSION_ENABLED: '',

  MODAL_PRIMARY_BUTTON_LABEL: 'ดำเนินการต่อ',

  UTILS_AGE_SINCE_YEARS_AGO: '{interval} ปีที่แล้ว',
  UTILS_AGE_SINCE_MONTHS_AGO: '{interval} เดือนที่แล้ว',
  UTILS_AGE_SINCE_DAYS_AGO: '{interval} วันที่แล้ว',
  UTILS_AGE_SINCE_HOURS_AGO: '{interval} ชั่วโมงที่แล้ว',
  UTILS_AGE_SINCE_MINUTES_AGO: '{interval} นาทีที่แล้ว',
  UTILS_AGE_SINCE_SECONDS_AGO: '{interval} วินาทีที่แล้ว',

  LOGIN_TITLE: 'ลงชื่อเข้าใช้งาน',
  LOGIN_SUBTITLE: 'คลิกที่ปุ่มเพื่อลงชื่อเข้าใช้งาน ADIL',

  INSPECTIONS_TABLE_HEADER_NUMBER: 'หมายเลขคำร้องขอตรวจสภาพ',
  INSPECTIONS_TABLE_HEADER_PLATE: 'หมายเลขทะเบียนรถยนต์',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION_TYPE: 'ประเภทเอกสารยืนยันตัวตน',
  INSPECTIONS_TABLE_HEADER_CHASSIS_NUMBER: 'หมายเลขตัวถังรถ',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION: 'การยืนยันตัวตน',
  INSPECTIONS_TABLE_HEADER_NAME: 'ชื่อ',
  INSPECTIONS_TABLE_HEADER_ACTION: 'ดำเนินการ',
  INSPECTIONS_TABLE_HEADER_STATUS: 'สถานะ',
  INSPECTIONS_TABLE_ACTION_BUTTON: 'ตรวจสอบ',
  INSPECTIONS_TABLE_HEADER_CREATED_BY: 'สร้างโดย',
  INSPECTIONS_TABLE_HEADER_CREATED_ON: 'สร้างที่',
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE: 'Ngày tạo đơn',
  INSPECTIONS_TABLE_HEADER_ASSIGNEE: 'ผู้ได้รับมอบหมาย',
  INSPECTIONS_TABLE_HEADER_BROKER_NAME: 'BROKER NAME',
  INSPECTIONS_TABLE_HEADER_BROKER_CODE: 'BROKER CODE',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO: 'วันถ่ายรูป',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE: 'วันที่ส่งคืน',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW: 'วันที่ขอรับการสนับสนุน',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_TOOLTIP: 'การตรวจสภาพพร้อมรูปถ่ายแนบมาด้วย',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT: 'แจ้งเตือนรูปถ่าย',
  INSPECTIONS_TABLE_ROW_SUPPORT_REQUEST_ALERT_TOOLTIP:
    'การตรวจสอบพร้อมคำขอการสนับสนุน',

  INSPECTIONS_TABLE_REPORT_ADIL_TICKET: 'ตั๋ว ADIL',
  INSPECTIONS_TABLE_REPORT_STATUS: 'สถานะ',
  INSPECTIONS_TABLE_REPORT_PLATE_NUMBER: 'หมายเลขทะเบียน',
  INSPECTIONS_TABLE_REPORT_CREATOR_NAME: 'ชื่อผู้สร้าง',
  INSPECTIONS_TABLE_REPORT_CREATED_DATE: 'วันที่สร้าง',
  INSPECTIONS_TABLE_REPORT_CUSTOMER_ADDRESS: 'ที่อยู่ลูกค้า',
  INSPECTIONS_TABLE_REPORT_INSPECTION_USER: 'ผู้ใช้ตรวจสอบ',
  INSPECTIONS_TABLE_REPORT_INSPECTION_DATE: 'วันที่ตรวจสอบ',
  INSPECTIONS_TABLE_REPORT_APPROVED_USER: 'ผู้ใช้ที่ได้รับอนุมัติ',
  INSPECTIONS_TABLE_REPORT_APPROVED_DATE: 'วันที่อนุมัติ',
  REPORT_TABLE_ASIAN_NO_RESULTS: 'ไม่พบผลลัพธ์',

  INSPECTION_SUMMARY_SUBMISSION_ID: 'หมายเลขคำร้องขอตรวจสภาพ',
  INSPECTION_SUMMARY_LICENCE_PLATE: 'เลขทะเบียนรถยนต์',
  INSPECTION_SUMMARY_ID_TYPE: 'ประเภทเอกสารที่ใช้ยืนยันตัวตน',
  INSPECTION_SUMMARY_ID_NUMBER: 'หมายเลขบัตรประจำตัวประชาชน',
  INSPECTION_SUMMARY_USER_NAME: 'ชื่อของผู้ดูแลคำร้อง',
  INSPECTION_SUMMARY_CREATED_BY: 'สร้างโดย',
  INSPECTION_SUMMARY_CREATION_DATE: 'สร้างที่',
  INSPECTION_SUMMARY_STATUS: 'สถานะ',
  INSPECTION_SUMMARY_CUSTOMER_COMPLETION_DATE: 'วันถ่ายรูป',
  INSPECTION_SUMMARY_COMPLETION_DATE: 'วันที่อนุมัติใบสมัคร',
  INSPECTION_SUMMARY_POLICY_TYPE: 'ประเภทกรมธรรม์',
  INSPECTION_SUMMARY_LOCATION: 'ที่ตั้ง',
  INSPECTION_RESEND_USERNAME: '',

  FINISHED_SELECT_FIELD: 'Búsqueda por:',
  FINISHED_SELECT_INPUT_VALUE: 'Ingrese valor a buscar:',
  SEARCH_FINISHED: 'ค้นหา',
  SEARCH_TITLE: 'การตรวจสภาพ',
  SEARCH_SUBTITLE:
    'จัดการ อัพเดท และดำเนินการตรวจสภาพโดยรูปถ่ายเพื่อรอกรมธรรม์ของคุณ',
  SEARCH_LOADING_LABEL: 'กรุณารอสักครู่...',
  SEARCH_DUPLICATED_SUBMISSIONID_LABEL:
    'การตรวจสอบหมายเลขกรมธรรม์ที่ซ้ำกัน โปรดรอ...',
  SEARCH_CUSTOMER_INFO_EIS_LABEL: 'ค้นหาข้อมูลใน EIS โปรดรอ...',

  SEARCH_BAR_TABS_PENDING_REVIEW: 'รอการตรวจสอบ',
  SEARCH_BAR_TABS_PENDING_APPROVAL: 'รอการอนุมัติ',
  SEARCH_BAR_TABS_FINISHED: 'เสร็จสิ้น',
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER: 'รอลูกค้าดำเนินการ',
  SEARCH_BAR_TABS_PENDING_PER_SUPPORT: 'รอดำเนินการโดยผู้ขาย',
  SEARCH_BAR_TABS_SUPPORT_REQUEST: 'Solicitud de Soporte',
  SEARCH_BAR_INPUT_PLACEHOLDER: 'ค้นหาโดยใช้เลขทะเบียนหรือเลขบัตรประชาชน...',
  SEARCH_BAR_SEARCH_ACTIVE_NOTIFICATION:
    'แถบค้นหาจะกรองเฉพาะสถานะอื่นที่ไม่ใช่เสร็จสมบูรณ์ หากต้องการค้นหาการตรวจสอบที่เสร็จสิ้น โปรดเลือกแท็บที่เกี่ยวข้อง',
  SEARCH_BAR_SEARCH_COMPLETED_NOTIFICATION:
    'แถบค้นหาจะกรองเฉพาะสถานะที่เท่ากับ "เสร็จสิ้น" หากต้องการค้นหาการตรวจสอบที่มีสถานะรอดำเนินการ โปรดเลือกแท็บอื่นที่ไม่ใช่ "เสร็จสิ้น"',

  SEARCH_NO_RESULTS_TITLE: 'อุ๊ย ขออภัย เราไม่พบสิ่งที่คุณกำลังมองหา',
  SEARCH_NO_RESULTS_SUBTITLE: 'ตรวจสอบความถูกต้องและลองใหม่อีกครั้ง',
  FINISHED_SEARCH_NO_RESULTS_TITLE: 'No records found with these values',
  FINISHED_SEARCH_NO_RESULTS_SUBTITLE: 'Please, try again.',

  INSPECTION_TITLE: 'การตรวจสภาพ',
  INSPECTION_SWITCH_ENABLE_ENGLISH_EMAILS: 'ประกาศเป็นภาษาอังกฤษ',
  INSPECTION_SUBTITLE:
    'ทำการตรวจสภาพรถยนต์โดยใช้รูปถ่ายที่ลูกค้าส่งมาให้ อย่าลืมแสดงความคิดเห็นและให้คำอธิบายเพิ่มเติม',
  INSPECTION_LOADING_LABEL: 'กรุณารอสักครู่...',
  INSPECTION_APPROVE_BUTTON: 'อนุมัติ',
  INSPECTION_CONTINUE_ADIL_BUTTON: 'ดำเนินการต่อในadil',
  INSPECTION_GET_INFORM_ANALYTICS: 'รายงานและการวิเคราะห์',
  INSPECTION_CHANGE_PRESENTIAL_BUTTON: 'ไปเผชิญหน้ากัน',
  INSPECTION_REJECT_BUTTON: 'ปฏิเสธ',
  INSPECTION_RETURN_BUTTON: 'ย้อนกลับ',
  INSPECTION_CUSTOMER_RETAKE_BUTTON: 'ขอถ่ายรูปใหม่',
  INSPECTION_SAVE_BUTTON: 'บันทึก',
  INSPECTION_SAVE_AND_EXIT_BUTTON: 'บันทึกและออก',
  INSPECTION_DELETE_BUTTON: 'ลบ',
  INSPECTION_CANCEL_BUTTON: 'ยกเลิก',
  INSPECTION_PHOTO_UPLOAD_IMAGES: 'อัปโหลดรูปภาพ',
  INSPECTION_PHOTO_UPLOAD_DOCUMENTS: 'อัปโหลดไฟล์บีบอัด',
  INSPECTION_PHOTO_UPLOADING_DOCUMENTS: 'กำลังอัปโหลดไฟล์บีบอัด...',
  INSPECTION_PHOTO_UPLOAD_IMAGES_PER_ITEM: 'อัพโหลดภาพ',
  INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP: 'อัปโหลดไฟล์ Zip',
  INSPECTION_PHOTO_UPLOAD_IMAGES_FILE: 'เลือกภาพ',
  INSPECTION_PHOTO_UPLOAD_IMAGES_SAVE_BUTTON: 'ที่อัพโหลด',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG:
    'ไฟล์ไม่มีรูปแบบที่ถูกต้อง ใช้: (.jpg,.jpeg,.png)',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP:
    'ไฟล์ไม่มีรูปแบบที่ถูกต้อง ใช้: (.zip)',

  INSPECTION_DELETE_MODAL_TITLE: 'คุณแน่ใจหรือไม่?',
  INSPECTION_DELETE_MODAL_SUBTITLE:
    'เมื่อตัดสินใจแล้วจะย้อนกลับมาแก้ไขไม่ได้อีก',
  INSPECTION_DELETE_MODAL_CANCEL_BUTTON_LABEL: 'ยกเลิก',
  INSPECTION_DELETE_MODAL_CONFIRM_BUTTON_LABEL: 'ลบ',
  INSPECTION_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER:
    'ทำไมคำร้องขอตรวจสอบนี้จึงถูกลบทิ้ง (ตัวอักษรมากสุดไม่เกิน 1000 ตัว)',
  INSPECTION_DELETE_MODAL_TEXTAREA_TITLE:
    'กรุณาเขียนความคิดเห็นของคุณเกี่ยวกับกรณีนี้',

  INSPECTION_CANCEL_MODAL_TITLE: 'คุณแน่ใจไหม',
  INSPECTION_CANCEL_MODAL_SUBTITLE: 'การดำเนินการนี้ไม่สามารถยกเลิกได้',
  INSPECTION_CANCEL_MODAL_CANCEL_BUTTON_LABEL: 'ยกเลิก',
  INSPECTION_CANCEL_MODAL_CONFIRM_BUTTON_LABEL: 'ยืนยัน',
  INSPECTION_CANCEL_MODAL_MESSAGE_INPUT_PLACEHOLDER:
    'เหตุใดการตรวจสอบนี้จึงถูกยกเลิก (สูงสุด 1,000 ตัวอักษร)',
  INSPECTION_CANCEL_MODAL_TEXTAREA_TITLE:
    'กรุณาเขียนความคิดเห็นของคุณเกี่ยวกับกรณีนี้',

  INSPECTION_OVERRIDE_MODAL_TITLE: 'ปฏิเสธการตรวจสภาพเสร็จสิ้น',
  INSPECTION_OVERRIDE_MODAL_SUBTITLE:
    'เมื่อตัดสินใจแล้วจะย้อนกลับมาแก้ไขไม่ได้อีก',
  INSPECTION_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL: 'ยกเลิก',
  INSPECTION_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER:
    'เหตุใดจึงปฏิเสธคำร้องขอตรวจสภาพนี้ กรุณาให้รายละเอียดเพิ่มเติมให้มากที่สุดเท่าที่จะทำได้ เช่น รูปถ่ายได้มาจากแหล่งอื่น เป็นต้น (เขียนไม่เกิน 1000 ตัวอักษร)',
  INSPECTION_OVERRIDE_MODAL_TEXTAREA_TITLE:
    'กรุณาเขียนความคิดเห็นของคุณเกี่ยวกับกรณีนี้',

  INSPECTION_PHOTO_HISTORY_MODAL_FOTO: '',
  INSPECTION_PHOTO_HISTORY_MODAL_FOTO_DATE: '',
  INSPECTION_PHOTO_HISTORY_MODAL_PHOTO_COMMENTS: '',
  INSPECTION_PHOTO_HISTORY_MODAL_HEADER: '',

  INSPECTION_OVERRIDE_COMMENT_TITLE: 'ตรวจสภาพเสร็จสิ้นผ่านการตัวแทน',
  INSPECTION_OVERRIDE_AGENT_NAME: 'ชื่อเจ้าหน้าที่ตัวแทน',
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_TITLE: 'ไม่พบการตรวจสอบ',
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_SUBTITLE:
    'การตรวจสอบไม่ได้ถูกสร้างขึ้นเป็นการร้องขอ',
  INSPECTION_DUPLICATE_MODAL_ERROR_TITLE: 'ข้อผิดพลาดในการบริการ',
  INSPECTION_DUPLICATE_MODAL_ERROR_SUBTITLE:
    'ไม่สามารถให้บริการได้. กรุณาเพิ่มข้อมูล.',

  INSPECTION_SUMMARY_HEADER_1: 'หมายเลขคำร้องขอตรวจสภาพ ',
  INSPECTION_SUMMARY_HEADER_2: 'เลขทะเบียนรถยนต์',
  INSPECTION_SUMMARY_HEADER_3: 'ประเภทเอกสารที่ใช้ยืนยันตัวตน',
  INSPECTION_SUMMARY_HEADER_4: 'หมายเลขบัตรประจำตัวประชาชน ',
  INSPECTION_SUMMARY_HEADER_5: 'ชื่อของผู้ดูแลคำร้อง',
  INSPECTION_SUMMARY_HEADER_6: 'สร้างโดย',
  INSPECTION_SUMMARY_HEADER_7: 'สร้างที่',
  INSPECTION_SUMMARY_HEADER_8: 'สถานะ',

  INSPECTION_VEHICLE_VIDEO_TITLE: 'วิดีโอ 360°',
  INSPECTION_VEHICLE_VIDEO_HINT_VIEW: 'ลากเพื่อดู',
  INSPECTION_VEHICLE_VIDEO_BUTTON_CLOSE: 'ปิด',
  INSPECTION_VEHICLE_VIDEO_BUTTON_SAVE_CHANGES: 'บันทึกการเปลี่ยนแปลง',

  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_SUCCESS: 'เปลี่ยนกล้องแล้ว',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_FAILED: 'พบปัญหาในการเปลี่ยนกล้อง',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_BUTTON: 'เปลี่ยนกล้อง',

  INSPECTION_MESSAGE_IS_REQUIRED_VIDEO: 'ต้องมีวิดีโอ 360 องศา',
  INSPECTION_MESSAGE_IS_OPTIONAL_VIDEO: 'วิดีโอ 360 เป็นตัวเลือก',

  INSPECTION_VEHICLE_DETAILS_IMAGE_ALT_TEXT: 'พาหนะ',
  INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT: ' - กรุณาเลือก - ',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY: 'ข้อมูลที่ต้องกรอก',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MAX:
    'ต้องกรอกตัวอักษรไม่เกิน {maxLength} ตัว',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SUBMISSIONID:
    'เลขที่ใบเสนอราคา/เลขที่สัญญาไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MIN:
    'เลขที่ใบเสนอราคา/เลขที่สัญญาไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY:
    'กรุณาเลือกตัวเลือกจากรายการด้านล่าง',
  INSPECTION_VEHICLE_SUBMISSIONID_EXIST: 'เลขที่กรมธรรม์นี้มีอยู่แล้วในระบบ',
  INSPECTION_VEHICLE_LICENCEPLATE_EXIST: 'มีป้ายทะเบียนรถอยู่ในระบบแล้ว',

  INSPECTION_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE: 'ไม่มีรูปภาพ',
  INSPECTION_VEHICLE_PHOTOS_TITLE: 'การอนุมัติรูปภาพ',
  INSPECTION_VEHICLE_PHOTOS_SUBTITLE:
    'เริ่มขั้นตอนการตรวจสภาพ ตรวจสอบว่ารูปถ่ายถูกต้องตามมาตรฐานที่กำนดก่อนดำเนินการต่อ',
  INSPECTION_VEHICLE_PHOTOS_HEADER_2: 'คำอธิบายรูปภาพ',
  INSPECTION_VEHICLE_PHOTOS_HEADER_3: 'วันที่อัพโหลด',
  INSPECTION_VEHICLE_PHOTOS_HEADER_4: 'ดำเนินการ',
  INSPECTION_VEHICLE_PHOTOS_AI_BANNER:
    'หมายเหตุ: คุณอาจเป็นสัญลักษณ์อยู่ข้างรูปถ่ายแต่ละรูป เรากำลังทดสอบระบบตรวจจับรูปถ่ายอัตโนมัติ เพื่อช่วยคุณในขั้นตอนตรวจสอบ ข้อมูลดังกล่าวเป็นเพียงแนวทางเท่านั้น และรูปถ่ายยังคงต้องถูกตรวจสอบโดยบุคคล',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP: 'เราตรวจพบรถยนต์ในรูปนี้',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP:
    'เราตรวจพบว่าอาจมีบุคคลอยู่ในรูปนี้',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT: 'ยืนยัน',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_TEXT_TOOLTIP:
    'Hemos detectado que el número de chasis puede aparecer en esta imagen',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT: 'ถ่ายใหม่อีกครั้ง',
  INSPECTION_VEHICLE_CHASSIS_NUMBER_DETECTED_LABEL: '# chassis detectado:',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_ACCEPT: 'Correcto',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_REJECT: 'Incorrecto',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_ACCEPT:
    'เปิดใช้งานคู่มือภาพถ่าย',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_REJECT:
    'ปิดการใช้งานคู่มือภาพถ่าย',
  INSPECTION_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY:
    'ส่งรูปถ่ายพร้อมความคิดเห็นกลับเรียบร้อยแล้ว',
  INSPECTION_VEHICLE_PHOTOS_VIEW_COMMENTS: 'ดูความคิดเห็น',
  INSPECTION_VEHICLE_PHOTOS_INCORRECT_PHOTOS:
    'อุ๊ย ดูเหมือนว่าจะมีรูปถ่ายที่ไม่ถูกต้อง',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE: 'ดาวน์โหลดรูปภาพ',
  INSPECTION_VEHICLE_DOCUMENTS_DOWNLOAD: 'ดาวน์โหลด',
  INSPECTION_VEHICLE_PHOTOS_APPROVE_ALL_PHOTOS: '',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE: 'ดาวน์โหลดรูปภาพทุกรูป',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE:
    'กำลังดาวน์โหลด...กรุณารอสักครู่',
  INSPECTION_VEHICLE_DURATION_VIDEO: 'ความยาววิดีโอ',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES: 'ความถี่ของภาพ (วินาที)',
  INSPECTION_VEHICLE_DURATION_VIDEO_FIELD: 'ความยาววิดีโอ',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES_FIELD: 'ความถี่ของภาพ',
  INSPECTION_VEHICLE_IMAGE_COMPRESS: 'บีบอัดภาพ',
  INSPECTION_VEHICLE_IMAGE_COMPRESS_DISABLE: 'ไม่มีการบีบอัดภาพ',
  INSPECTION_CUSTOMER_MESSAGES_TITLE: 'ความคิดเห็นของฉัน',
  INSPECTION_CUSTOMER_MESSAGES_BUTTON_LABEL: 'ปิด',

  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE: 'สำเร็จ',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_GENERIC: 'ล้มเหลว',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE: 'โปรดทราบ',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE:
    'อีเมล์แจ้งเตือนได้ถูกนำส่งซ้ำให้ลูกค้าแล้ว',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_GENERIC:
    'การส่งข้อความแจ้งเตือนล้มเหลว',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE:
    'แน่ใจหรือไม่ว่าต้องการส่ง หากกดยอมรับจะบันทึกข้อมูลการตรวจสภาพด้วย',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE: 'ปิด',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_EMAIL:
    'เกิดเหตุขัดข้องในการส่งอีเมลแจ้งเตือน',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_EMAIL:
    'ส่ง SMS สำเร็จ แต่อีเมลติดต่อถูกต้อง',

  INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND: 'ส่งใหม่อีกครั้ง',
  INSPECTION_CUSTOMER_DETAILS_BUTTON_SENDING: 'กำลังส่ง...',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_PRECESSED_STATUS: 'ฟ้อง',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DELIVERED_STATUS: 'ส่ง',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BOUNCED_STATUS: 'เด้งกลับ',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DROPPED_STATUS: 'ล้มลง',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DIFERRED_STATUS: 'เลื่อนออกไป',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BLOCKED_STATUS: 'ล็อค',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_UNKNOWN_STATUS: 'คนแปลกหน้า',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_STATUS_LABEL: 'สถานะอีเมล',

  INSPECTION_NEW_CUSTOMER_MESSAGE_TITLE: 'ความคิดเห็นเกี่ยวกับรูปถ่าย',
  INSPECTION_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER:
    'เขียนข้อสังเกตของคุณเกี่ยวกับรูปถ่าย (ตัวอักษรไม่เกิน 1000 ตัว)',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS: 'ส่งข้อความไปหาลูกค้า',
  INSPECTION_NEW_CUSTOMER_MESSAGE_LOADING: 'กรุณารอสักครู่...',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE: 'ข้อความถูกส่งไปหาลูกค้าแล้ว',
  INSPECTION_NEW_CUSTOMER_MESSAGE_CLOSE: 'ปิด',

  INSPECTION_SUPPORTING_DOCUMENTS_TITLE: 'ข้อมูลสนับสนุน',
  INSPECTION_SUPPORTING_DOCUMENTS_SUBTITLE:
    'แนบหรือส่งข้อมูลสนับสนุนที่คุณคิดว่าจำเป็นในการตรวจสภาพ โดยอาจเป็นหน้าจอสกรีนช็อท เอกสาร และอื่น ๆ สกุลไฟล์ที่รับรองได้แก่ (PDF, jpg, jpeg, png, bmp, doc, docx)',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER: 'ลากไฟล์เพื่อแนบ หรือ',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK: 'ค้นหา',

  ENABLE_VENDOR_LABEL: 'เฉพาะการตรวจสอบผู้ขาย',

  INSPECTION_COMMENTS_TITLE: 'ความคิดเห็นของคุณ',
  INSPECTION_COMMENTS_SUBTITLE:
    'อย่าลืมเขียนความคิดเห็นของคุณเกี่ยวกับการตรวจสอบ',
  INSPECTION_COMMENTS_INPUT_PLACEHOLDER:
    'เขียนข้อสังเกตของคุณ (ตัวอักษรไม่เกิน 1000 ตัว)',

  INSPECTION_STATUS_AGENT_REVIEW_DISPLAY_VALUE: 'ต้องการให้เจ้าหน้าที่ตรวจสอบ',
  INSPECTION_STATUS_SUPPORT_AGENT_REVIEW_DISPLAY_VALUE:
    'ต้องการให้เจ้าหน้าที่ตรวจสอบ',
  INSPECTION_STATUS_AGENT_IN_PROGRESS_DISPLAY_VALUE: 'เจ้าหน้าที่กำลังตรวจสอบ',
  INSPECTION_STATUS_AGENT_APPROVED_DISPLAY_VALUE: 'อนุมัติแล้ว',
  INSPECTION_STATUS_AGENT_REJECTED_DISPLAY_VALUE: 'ถูกปฏิเสธ',
  INSPECTION_STATUS_CUSTOMER_RETAKE_DISPLAY_VALUE: 'ลูกค้าต้องถ่ายรูปใหม่',
  INSPECTION_STATUS_CUSTOMER_NEW_DISPLAY_VALUE: 'ขอตรวจสภาพใหม่',
  INSPECTION_STATUS_CUSTOMER_IN_PROGRESS_DISPLAY_VALUE: 'ลูกค้ากำลังดำเนินการ',
  INSPECTION_STATUS_SUPPORT_RETAKE_DISPLAY_VALUE: 'ซัพพลายเออร์ที่รอการตอบกลับ',
  INSPECTION_STATUS_SUPPORT_NEW_DISPLAY_VALUE: 'การตรวจสอบใหม่',
  INSPECTION_STATUS_SUPPORT_INSPECTOR_DISPLAY_VALUE: 'สารวัตรร้องขอ',
  INSPECTION_STATUS_SUPPORT_IN_PROGRESS_DISPLAY_VALUE:
    'ซัพพลายเออร์ในความคืบหน้า',
  INSPECTION_STATUS_EXPIRED_DISPLAY_VALUE: 'หมดอายุแล้ว',
  INSPECTION_STATUS_DELETED_DISPLAY_VALUE: 'ลบแล้ว',
  INSPECTION_STATUS_CANCELLED_DISPLAY_VALUE: 'ยกเลิก',
  INSPECTION_STATUS_CHANGED_TO_PRESENTIAL_DISPLAY_VALUE:
    'เปลี่ยนไปใช้ด้วยตนเอง',

  SUPPORT_INSPECTION_NO_COMMENTS: 'ไม่มีความคิดเห็น.',

  INSPECTION_ASSIGNEE_TITLE: 'ผู้ได้รับมอบหมาย',
  INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON: 'มอบหมายให้ฉัน',
  INSPECTION_DEFAULT_ASSIGNEE_DISPLAY_VALUE: 'ยังไม่ได้มอบหมาย',
  INSPECTION_PHOTO_VEHICLE_VIDEO_DISPLAY_STRING: 'วิดีโอ 360° ของตัวรถ',

  INSPECTION_COMPLETE_ACCEPTED_TITLE: 'ยอมรับการตรวจสภาพโดยใช้รูปถ่าย',
  INSPECTION_COMPLETE_ACCEPTED_SUBTITLE: 'รูปถ่ายเป็นไปตามข้อกำหนดการตรวจสภาพ',
  INSPECTION_COMPLETE_REJECTED_TITLE: 'การตรวจสภาพถูกปฏิเสธ',
  INSPECTION_COMPLETE_REJECTED_SUBTITLE:
    'รถยนต์ไม่เป็นไปตามข้อกำหนดขอรับกรมธรรม์',
  INSPECTION_COMPLETE_BUTTON_LABEL: 'ย้อนกลับ',

  INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING:
    'ด้านหน้าเอกสารรับรองการเป็นเจ้าของรถ',
  INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING:
    'ด้านหลังเอกสารรับรองการเป็นเจ้าของรถ',
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING: 'เลขทะเบียนรถยนต์',
  INSPECTION_PHOTO_VEHICLE_FRONT_DISPLAY_STRING: 'ด้านหน้ารถยนต์',
  INSPECTION_PHOTO_VEHICLE_REAR_DISPLAY_STRING: 'ด้านหลังรถยนต์',
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING: 'ด้านขวา',
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING: 'ด้านซ้าย',
  INSPECTION_PHOTO_VEHICLE_ENGINE_DISPLAY_STRING: 'ห้องเครื่อง',
  INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING:
    'เครื่องวัดความเร็วรถยนต์หรือแผงหน้าปัด',
  INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING: 'ระยะกิโลเมตร',
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING:
    'สติกเกอร์หรือแผ่นป้ายบอกรหัสซีเรียล',
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING: 'หมายเลขตัวถังรถ',
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE_DISPLAY_STRING: 'แผ่นพาโนรามิก',
  INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTIONE_DISPLAY_STRING:
    'ใบรับรองการตรวจสภาพรถ',
  INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD_DISPLAY_STRING: 'เล่มทะเบียนรถ',
  INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE_DISPLAY_STRING: 'มุมหน้ารถซ้าย',
  INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE_DISPLAY_STRING: 'มุมหน้ารถขวา',
  INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE_DISPLAY_STRING: 'มุมท้ายรถซ้าย',
  INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE_DISPLAY_STRING: 'มุมท้ายรถขวา',
  INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP_DISPLAY_STRING: 'ไฟหน้าซ้าย',
  INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP_DISPLAY_STRING: 'ไฟหน้าขวา',
  INSPECTION_PHOTO_VEHICLE_VN_ODOMETER_DISPLAY_STRING: 'หน้าปัด',
  INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT_DISPLAY_STRING:
    'บัตรประชาชน/ พาสปอร์ต',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1_DISPLAY_STRING:
    'ใบขับขี่/ IDP ของคุณ',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2_DISPLAY_STRING:
    'ใบขับขี่/ IDP ของคุณ',

  INSPECTION_PHOTO_ACCESSORY_RADIO_DISPLAY_STRING: 'วิทยุ',
  INSPECTION_PHOTO_ACCESSORY_DVD_DISPLAY_STRING: 'เครื่องเล่นดีวีดี',
  INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS_DISPLAY_STRING: 'เครื่องขยายเสียง',
  INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS_DISPLAY_STRING: 'ลำโพง',
  INSPECTION_PHOTO_ACCESSORY_EQUALIZERS_DISPLAY_STRING: 'อีควอไลเซอร์',
  INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA_DISPLAY_STRING: 'กล้องหลัง',
  INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS_DISPLAY_STRING:
    'พร็อกซิมิตี้เซนเซอร์',
  INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK_DISPLAY_STRING: 'เซ็นทรัล ล็อค',
  INSPECTION_PHOTO_ACCESSORY_ALARM_DISPLAY_STRING: 'สัญญาณกันขโมย',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM_DISPLAY_STRING: 'แผ่นฟิล์มนิรภัย',
  INSPECTION_PHOTO_ACCESSORY_SUN_ROOF_DISPLAY_STRING: 'ซันรูฟ',
  INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS_DISPLAY_STRING: 'หน้าต่างไฟฟ้า',
  INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING_DISPLAY_STRING:
    'เครื่องปรับอากาศ',
  INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY_DISPLAY_STRING:
    'ปิดเครื่องใช้ไฟฟ้า',
  INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING_DISPLAY_STRING:
    'รหัสประจำรถยนต์สลักบนกระจก',
  INSPECTION_PHOTO_ACCESSORY_FOOTRESTS_DISPLAY_STRING: 'ที่พักเท้า',
  INSPECTION_PHOTO_ACCESSORY_ROLL_BAR_DISPLAY_STRING: 'โรล บาร์ด้านใน/ด้านนอก',
  INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL_DISPLAY_STRING: 'หัวรถพ่วง',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL_DISPLAY_STRING: 'หมุดพ่วง',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG_DISPLAY_STRING: 'ปลั๊กต่อรถลาก',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS_DISPLAY_STRING: 'ล้อแม็กซ์',
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS_DISPLAY_STRING: 'กระบะ',
  INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION_DISPLAY_STRING:
    'อุปกรณ์ดัดแปลงเชื้อเพลิงรถยนต์',
  INSPECTION_PHOTO_ACCESSORY_DURALINER_DISPLAY_STRING: 'พื้นปูกระบะ',
  INSPECTION_PHOTO_ACCESSORY_HELMET_DISPLAY_STRING: 'หมวกกันน็อค',

  INSPECTION_PHOTO_ACCESSORY_ARMOR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CD_BOX_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FIXED_DVD_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WOOFER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_GLASSES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BULLBAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUNROOF_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_ACCESSORY_SPOILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TOW_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SKIRTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TIRES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROOF_RACK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRUCK_BED_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TWEETER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SLIDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WINCH_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_DRAWER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUSPENSION_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUMPER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BODY_KIT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MIRRORS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_SPARE_WHEEL_DISPLAY_STRING: 'ยางอะไหล่ ',

  INSPECTION_PHOTO_MOTORBIKE_FRONT_DISPLAY_STRING: 'Motorbiker front',
  INSPECTION_PHOTO_MOTORBIKE_REAR__DISPLAY_STRING: 'Pear of the vehicle',
  INSPECTION_PHOTO_MOTORBIKE_ENGINE_DISPLAY_STRING: 'Enginne',

  INSPECTION_PHOTO_DAMAGE_NAME: 'ความเสียหาย',

  CREATE_INSPECTION_NOTES_TITLE: '',
  CREATE_INSPECTION_CUSTOMER_TITLE: 'ลูกค้า ',
  CREATE_INSPECTION_CUSTOMER_SUBTITLE: 'ใส่ข้อมูลลูกค้า ',
  CREATE_INSPECTION_AGENT_TITLE: 'ตัวแทน',
  CREATE_INSPECTION_AGENT_SUBTITLE: 'ป้อนข้อมูลตัวแทน',
  CREATE_INSPECTION_VEHICLE_TITLE: 'รถยนต์ ',
  CREATE_INSPECTION_VEHICLE_SUBTITLE: 'ใส่ข้อมูลรถยนต์',
  CREATE_INSPECTION_BROKER_TITLE: 'โบรกเกอร์',
  CREATE_INSPECTION_BROKER_SUBTITLE: 'ใส่ข้อมูลโบรกเกอร์',
  CREATE_INSPECTION_NAMED_DRIVER_TITLE: 'ชื่อคนขับ',
  CREATE_INSPECTION_NAMED_DRIVER_SUBTITLE: 'ป้อนข้อมูลไดรเวอร์ที่มีชื่อ',
  CREATE_INSPECTION_SUBMIT: 'ส่ง',
  CREATE_INSPECTION_PREFILL: 'ใช้ข้อมูลทดสอบ',
  CREATE_INSPECTION_CLEAN_FIELDS: 'ทำความสะอาดทุ่งนา',
  CREATE_INSPECTION_CREATION_ERROR: 'เกิดความผิดพลาดขณะสร้างคำร้องขอตรวจสภาพ',
  CREATE_INSPECTION_CREATION_SUCCESS: 'สร้างการตรวจสภาพสำเร็จ',
  CREATE_INSPECTION_CREATION_CLOSE: 'ปิด',
  CREATE_INSPECTION_CREATION_CONTINUE: 'ดำเนินการต่อ',
  CREATE_INSPECTION_CREATION_COPY: 'คัดลอกลิงก์',
  CREATE_INSPECTION_INVALID_PHONE_NUMBER: 'ไม่มีหมายเลขดังกล่าว',
  CREATE_INSPECTION_CAMERA_TITLE: 'กล้อง',
  CREATE_INSPECTION_CAMERA_SUBTITLE: 'กรุณาเลือกกล้องที่จะใช้',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL: 'ประเภทกล้อง',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NATIVE: 'กล้องหลัก',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NEW: 'กล้องที่เชื่อมต่อ',
  CREATE_INSPECTION_VIDEO_TITLE: 'วิดีโอ 360°',
  CREATE_INSPECTION_VIDEO_SUBTITLE:
    'ในส่วนนี้ คุณสามารถเปิดใช้งานวิดีโอ 360° สำหรับลูกค้าได้',
  CREATE_INSPECTION_SELECT_VIDEO_LABEL: 'เลือกตัวเลือก',
  CREATE_INSPECTION_POLICY_TYPE_LABEL: 'ประเภทกรมธรรม์',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_1: 'ใช้งานวิดีโอ 360°',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_2: 'ห้ามใช้วิดีโอ 360°',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_1: 'ธุรกิจใหม่',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_2: 'การต่ออายุ',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_1: '1 ภาพ',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_2: '2 รูปภาพ',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_1: '1 ภาพ',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_2: '2 รูปภาพ',
  CREATE_INSPECTION_INSPECTION_NOT_FOUND: '',
  CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR: '',
  CREATE_INSPECTION_LOADING_INSPECTION_MESSAGE: '',

  REPORT_SUCCESS_MODAL_TITLE: 'รายงานของคุณได้รับการดาวน์โหลดเสร็จสิ้นแล้ว',
  REPORT_SUCCESS_MODAL_BUTTON: 'ปิด',
  REPORT_TITLE: 'รายงานการตรวจสภาพ',
  REPORT_SUBTITLE: 'สร้างรายงานที่ระบุข้อมูลการตรวจสภาพของคุณอย่างละเอียด',
  REPORT_SUBMIT_BUTTON: 'สร้างรายงาน',
  REPORT_FORM_SUBTITLE: 'เลือกช่วงวันที่ที่คุณอยากจะสร้างรายงานของตนเอง',
  REPORT_FORM_START_DATE: 'วันที่เริ่มต้น',
  REPORT_FORM_END_DATE: 'วันที่สิ้นสุด',
  REPORT_FORM_ERROR_MAX_RANGE: 'ช่วงระยะเวลาที่ร้องขอจะต้องไม่เกิน 90 วัน',
  REPORT_OPTION_ONE: 'เวิร์กโฟลว์ Adil',
  REPORT_OPTION_TWO: 'ภาพถ่ายกลับมา',
  REPORT_OPTION_THREE: 'วันที่',
  REPORT_OPTION_FOR: 'ความผิดพลาด',
  REPORT_OPTION_FIVE: 'คำขอการสนับสนุน',
  REPORT_OPTION_SIX: 'คุณสมบัติ',
  REPORT_OPTION_EIGHT: 'สถานะการส่งจดหมาย',
  REPORT_OPTION_NINE: '[NT] Inspections Deleted',
  REPORT_OPTION_TEN: '',

  REPORT_MESSAGE_TIMEOUT:
    'เกินขีดจำกัดของบันทึกที่จะให้คำปรึกษา โปรดลดช่วงเวลาที่เลือก',

  SEARCH_FILTER_BAR_WITH_PHOTOS: 'พร้อมรูปถ่าย',

  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_TRUNK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PORTER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PROTECTOR_KIT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_ALARM_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_EXPLORERS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_HELMET_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_JACKET_DISPLAY_STRING: '',

  INSPECTION_INFORM_TITLE: 'Inform Inspection',
  INSPECTION_INFORM_SUBTITLE:
    'Complete the follow steps for see the inform inspections result.',
  INSPECTION_INFORM_DATA_OPERATOR: 'Datos del corredor',
  INSPECTION_INFORM_DATA_INSPECTOR: 'Datos del inspector',
  INSPECTION_INFORM_DATA_INSPECTION: 'Datos de la inspección',
  INSPECTION_INFORM_DATA_VEHICLE: 'Datos del vehículo',
  INSPECTION_INFORM_SYSTEM_IDENTIFICATION: 'Sistemas de identificación',
  INSPECTION_INFORM_REVISION_FRONTAL: 'Revisión estructural: Frontal',
  INSPECTION_INFORM_LATERAL_LEFT: 'Revisión estructural: Lateral izquierdo',
  INSPECTION_INFORM_MAP_DAMAGE: 'Mapa de daños',
  INSPECTION_INFORM_PIECES_INSPECTION: 'Piezas a inspeccionar',
  INSPECTION_INFORM_CHARACTERS_LIMIT: '50 de 1000 caracteres',
  INSPECTION_INFORM_START_DAMAGE_INSPECTIONS: 'Ingresar inspección con daños',

  INSPECTION_INFORM_MODEL_YEAR: 'Año Modelo',
  INSPECTION_INFORM_CLASS: 'Clase',
  INSPECTION_INFORM_MANUFACTURER: 'Marca',
  INSPECTION_INFORM_TYPE_ID: 'Uso/Tipo de placa',
  INSPECTION_INFORM_KM_OR_AUTONOMIC: 'Km o autonomía',
  INSPECTION_INFORM_COLOR: 'Color',
  INSPECTION_INFORM_TYPE_ENGINE: 'Combustible',
  INSPECTION_INFORM_MODEL_VERSION: 'Modelo/Version',

  INSPECTION_INFORM_NUMBER_PATENT: 'Placa/Patente',
  INSPECTION_INFORM_STATE_PATENT: 'Estado Placa/Patente',
  INSPECTION_INFORM_NUMBER_CHASSIS: 'Número de chasis',
  INSPECTION_INFORM_STATE_CHASSIS: 'Estado Número de chasis',
  INSPECTION_INFORM_NUMBER_MOTOR: 'Serie motor',
  INSPECTION_INFORM_STATE_MOTOR: 'Estado Serie motor',
  INSPECTION_INFORM_CLOSE_FULLSCREEN_BUTTON: '',
  INSPECTION_INFORM_APPROVED_OPTION: '',
  INSPECTION_INFORM_DISAPPROVED_OPTION: '',

  STEPPER_VALIDATE_DATA: '[NT] Validation data',
  STEPPER_VALIDATE_CRIMINAL_RECORD: '[NT] Validation Criminal Record',
  STEPPER_SEND_RESULTS: '[NT] Send Results',
  STEPPER_LABEL_ONE: '1',
  STEPPER_LABEL_TWO: '2',
  STEPPER_LABEL_THREE: '3',
};

export default { code, strings };
