import constants from '@lmig-latam/adil-api-common-lib/constants';

const {
  LANGUAGES: { CL },
} = constants;

const code = CL;

const strings = {
  BROWSER_TAB_TITLE: 'Inspecciones – Liberty Seguros',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: 'Liberty Seguros',
  VIGILADO_IMAGE_ALT_TEXT: '',

  APP_HEADER_TITLE: 'Módulo de Inspecciones',
  APP_HEADER_USER_SECTION_LOGOUT: 'Salir',
  OPERATING_COUNTRY_IMAGE_ALT_TEXT: 'Bandera chilena',

  APP_FOOTER_TITLE: 'Liberty 2024. Todos los derechos reservados',

  SIDEBAR_ITEM_1: 'Inspecciones',
  SIDEBAR_ITEM_2: 'Administrar cuenta',
  SIDEBAR_ITEM_3: 'Cambia la contraseña',
  SIDEBAR_ITEM_4: 'Crear inspección digital',
  SIDEBAR_ITEM_5: 'Reporte de inspecciones',
  SIDEBAR_ITEM_6: 'Administradores',

  FORM_INFORM_ANALYSIS_CORREDOR_NAME: 'Nombre del corredor',
  FORM_INFORM_ANALYSIS_CORREDOR_CODE: 'Código del corredor',
  FORM_INFORM_ANALYSIS_INSPECTOR_USER: 'Usuario del inspector',
  FORM_INFORM_ANALYSIS_INSPECTOR_NAME: 'Nombre del inspector',
  FORM_INFORM_ANALYSIS_INSPECTOR_SUPPLIER: 'Proveedor',
  FORM_INFORM_ANALYSIS_INSPECTION_NAME_CUSTOMER: 'Nombre del cliente',
  FORM_INFORM_ANALYSIS_INSPECTION_NUMBER: 'Número de inspección',
  FORM_INFORM_ANALYSIS_INSPECTION_EMAIL_CUSTOMER: 'Correo del cliente',
  FORM_INFORM_ANALYSIS_INSPECTION_PLATE: 'Patente',
  FORM_INFORM_ANALYSIS_INSPECTION_VEHICULE_TYPE: 'Tipo de vehículo',
  FORM_INFORM_ANALYSIS_INSPECTION_MODULE: 'Modelo de inspección',
  FORM_INFORM_ANALYSIS_INSPECTION_PLACE: 'Lugar de inspección',
  FORM_INFORM_ANALYSIS_INSPECTION_DATE: 'Fecha de inspección',
  FORM_INFORM_ANALYSIS_INSPECTION_CITY_OR_REGION: 'Ciudad o región',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS: 'No se admiten caracteres especiales ni números',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY: 'No se admiten caracteres especiales',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY: 'Solo se acepta caracteres de tipo numérico',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED: 'Este campo es obligatorio',

  SUPPORT_TITTLE_PAGE: 'Administradores',
  SUPPORT_SUBTITTLE_PAGE: 'Elige la opción que deseas administrar.',
  ADMIN_SUPPORT_PAGE_TROUBLES: 'Administrador de solicitud de soporte - Problemas',
  ADMIN_SUPPORT_PAGE_SOLUTIONS: 'Administrador de solicitud de soporte - Soluciones',
  ADMIN_PIECES_MANTEINER: 'Administrador mantenedor de piezas',
  ADMIN_PARTS_MANTEINER: 'Administrador mantenedor de partes',
  ADMIN_SUPPORT_ADD: 'AGREGAR',
  ADMIN_SUPPORT_MODIFY: 'MODIFICAR',
  ADMIN_SUPPORT_BUTTON_VISUALIZE: 'VISUALIZAR',
  ADMIN_SUPPORT_ADD_TROUBLE: 'Agregar problema',
  ADMIN_SUPPORT_ADD_PIECE: 'Agregar pieza',
  ADMIN_SUPPORT_ADD_PART: 'Agregar parte',
  ADMIN_SUPPORT_SELECT_PART: 'Seleccionar parte',
  ADMIN_SUPPORT_ADD_TROUBLE_SORT: 'Agregar orden',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE: 'Estado',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION: 'Activar',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION: 'Inactivar',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT: 'Activo',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT: 'Inactivo',
  ADMIN_SUPPORT_ADD_SOLUTION: 'Agregar solución',
  ADMIN_SUPPORT_TABLE_HEAD_1: 'Problemas',
  ADMIN_SUPPORT_TABLE_HEAD_1_SOLUTIONS: 'Soluciones',
  ADMIN_SUPPORT_TABLE_HEAD_2: 'Orden',
  ADMIN_SUPPORT_TABLE_HEAD_3: 'Estado',
  ADMIN_SUPPORT_TABLE_HEAD_4: 'Administrador',
  ADMIN_SUPPORT_TABLE_HEAD_5: 'Fecha creación',
  ADMIN_SUPPORT_TABLE_HEAD_6: 'Fecha última modificación',
  ADMIN_SUPPORT_TABLE_DELETE: 'Eliminar',
  ADMIN_SUPPORT_TABLE_UPDATE: 'Modificar',
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: '¡Algo ha ocurrido!',
  ADMIN_SUPPORT_ERROR_GENERAL: 'No ha sido posible completar esta acción',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION: '¿Estás seguro que deseas eliminarlo?',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT: 'Opción eliminada',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE: 'Has eliminado la opción correctamente',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE: 'No ha sido posible eliminar la opción',
  ADMIN_SUPPORT_DELETE_YES: 'Aceptar',
  ADMIN_SUPPORT_DELETE_NO: 'Cancelar',
  COUNTER_PAGES: 'Páginas:',
  ADMIN_SUPPORT_TROUBLE_PREVISUALIZATE: 'Previsualización problemas:',
  ADMIN_SUPPORT_TROUBLE_5_PROBLEMS: 'Solo es posible añadir 5 registros relacionados con problemas.',

  ADMIN_PIECES_MANTEINER_TABLE_HEAD_1: 'ID',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_2: 'Pieza',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_3: 'Parte',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_4: 'Estado',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_5: 'Administrador',

  LOADING_TEXT: 'Cargando',

  ERROR_MESSAGE_NUMBER_ONLY: 'El campo debe ser numérico',
  SUPPORT_INSPECTION_TROUBLES: 'Problemas reportados:',
  SUPPORT_INSPECTION_TROUBLES_ADITIONAL: 'Información adicional',
  SUPPORT_INSPECTION_SOLUTIONS: 'Solución entregada:',
  SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL: 'Comentarios adicionales de la solución',
  SUPPORT_INSPECTION_SOLUTIONS_USER: 'Comentarios adicionales del usuario',
  SUPPORT_INSPECTION_SOLUTIONS_RESOLUTOR: 'Resolutor',
  SUPPORT_INSPECTION_BINNACLE: 'Ver bitácora',
  SUPPORT_INSPECTION_BINNACLE_MODAL: 'Bitácora de soporte',
  SUPPORT_INSPECTION_BINNACLE_SELECT: 'Seleccione una solicitud:',
  SUPPORT_INSPECTION_BINNACLE_SELECT_OPTION: 'Solicitud de soporte - ',

  SUPPORT_INSPECTION_MODAL_TITLE: 'Solicitud de soporte',

  ERROR_MESSAGE_TITLE: 'Lo sentimos, el proceso no ha funcionado correctamente o ya fue iniciado.',
  ERROR_MESSAGE_SUBTITLE: 'Si necesitas ayuda, puedes contáctarnos a través de nuestros canales de atención.',
  ERROR_MESSAGE_ACCESS_DENIED_TITLE: 'Acceso denegado',
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE: 'No tiene acceso a las inspecciones de esta aplicación. ¿Has iniciado sesión con la cuenta correcta?',

  PAGE_NOT_FOUND_ERROR_MESSAGE: '404: ¡Página no encontrada!',

  GPS_PERMISSION_DENIED: 'Permiso denegado',
  GPS_PERMISSION_ENABLED: 'Permiso habilitado',

  MODAL_PRIMARY_BUTTON_LABEL: 'Continuar',

  UTILS_AGE_SINCE_YEARS_AGO: 'Hace {interval} años',
  UTILS_AGE_SINCE_MONTHS_AGO: 'Hace {interval} meses',
  UTILS_AGE_SINCE_DAYS_AGO: 'Hace {interval} días',
  UTILS_AGE_SINCE_HOURS_AGO: 'Hace {interval} horas',
  UTILS_AGE_SINCE_MINUTES_AGO: 'Hace {interval} minutos',
  UTILS_AGE_SINCE_SECONDS_AGO: 'Hace {interval} segundos',

  LOGIN_TITLE: 'Iniciar sesión',
  LOGIN_SUBTITLE: 'Haga clic en el botón de abajo para iniciar sesión en ADIL',

  INSPECTIONS_TABLE_HEADER_NUMBER: 'N° INSPECCIÓN',
  INSPECTIONS_TABLE_HEADER_PLATE: 'PATENTE',
  INSPECTIONS_TABLE_HEADER_CHASSIS_NUMBER: 'N° DE CHASIS',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION_TYPE: 'TIPO DE ID',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION: 'NÚMERO DE RUT',
  INSPECTIONS_TABLE_HEADER_NAME: 'TOMADOR',
  INSPECTIONS_TABLE_HEADER_ACTION: 'ACCIÓN',
  INSPECTIONS_TABLE_HEADER_STATUS: 'ESTADO',
  INSPECTIONS_TABLE_ACTION_BUTTON: 'REVISAR',
  INSPECTIONS_TABLE_HEADER_CREATED_BY: 'CREADO POR',
  INSPECTIONS_TABLE_HEADER_CREATED_ON: 'FECHA DE CREACIÓN',
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE: 'FECHA DE APROBACIÓN',
  INSPECTIONS_TABLE_HEADER_ASSIGNEE: 'ASIGNADO',
  INSPECTIONS_TABLE_HEADER_BROKER_NAME: 'NOMBRE BROKER',
  INSPECTIONS_TABLE_HEADER_BROKER_CODE: 'CÓDIGO BROKER',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO: 'FECHA DE FOTOGRAFÍA',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE: 'FECHA DEVOLUCIÓN',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW: 'FECHA SOLICITUD SOPORTE',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_TOOLTIP: 'Inspección con fotografías adjuntas',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT: 'Alerta de foto',
  INSPECTIONS_TABLE_ROW_SUPPORT_REQUEST_ALERT_TOOLTIP: 'Inspección con solicitudes de soporte',

  INSPECTIONS_TABLE_REPORT_ADIL_TICKET: 'ADIL ticket',
  INSPECTIONS_TABLE_REPORT_STATUS: 'Estado',
  INSPECTIONS_TABLE_REPORT_PLATE_NUMBER: 'Patente',
  INSPECTIONS_TABLE_REPORT_CREATOR_NAME: 'Nombre del creador',
  INSPECTIONS_TABLE_REPORT_CREATED_DATE: 'Fecha de creación',
  INSPECTIONS_TABLE_REPORT_CUSTOMER_ADDRESS: 'Dirección del cliente',
  INSPECTIONS_TABLE_REPORT_INSPECTION_USER: 'Usuario de la inspección',
  INSPECTIONS_TABLE_REPORT_INSPECTION_DATE: 'Fecha de inspección',
  INSPECTIONS_TABLE_REPORT_APPROVED_USER: 'Usuario aprobado',
  INSPECTIONS_TABLE_REPORT_APPROVED_DATE: 'Fecha aprobada',
  REPORT_TABLE_ASIAN_NO_RESULTS: 'No se encontraron resultados',

  INSPECTION_SUMMARY_SUBMISSION_ID: 'N° Inspección',
  INSPECTION_SUMMARY_LICENCE_PLATE: 'Patente',
  INSPECTION_SUMMARY_ID_TYPE: 'Tipo de ID',
  INSPECTION_SUMMARY_ID_NUMBER: 'Número de Rut',
  INSPECTION_SUMMARY_USER_NAME: 'Nombre del Tomador',
  INSPECTION_SUMMARY_CREATED_BY: 'Creado por',
  INSPECTION_SUMMARY_CREATION_DATE: 'Fecha de creación',
  INSPECTION_SUMMARY_STATUS: 'Estado',
  INSPECTION_SUMMARY_CUSTOMER_COMPLETION_DATE: 'Fecha de fotografía',
  INSPECTION_SUMMARY_COMPLETION_DATE: 'Fecha de aprobación',
  INSPECTION_SUMMARY_POLICY_TYPE: 'Tipo de Póliza',
  INSPECTION_SUMMARY_LOCATION: 'Localidad',
  INSPECTION_RESEND_USERNAME: '',

  FINISHED_SELECT_FIELD: 'Búsqueda por:',
  FINISHED_SELECT_INPUT_VALUE: 'Ingrese valor a buscar:',
  SEARCH_FINISHED: 'Buscar',
  SEARCH_TITLE: 'Inspecciones',
  SEARCH_SUBTITLE: 'Gestiona, actualiza y realiza las inspecciones fotográficas de vehículos que esperan por su póliza',
  SEARCH_BAR_TABS_PENDING_REVIEW: 'Pendientes por revisión',
  SEARCH_BAR_TABS_PENDING_APPROVAL: 'Pendientes de finalización',
  SEARCH_BAR_TABS_FINISHED: 'Finalizadas',
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER: 'Pendiente por cliente',
  SEARCH_BAR_TABS_PENDING_PER_SUPPORT: 'Pendiente por vendor',
  SEARCH_BAR_TABS_SUPPORT_REQUEST: 'Solicitud de Soporte',
  SEARCH_BAR_INPUT_PLACEHOLDER: 'Busca por placa o identificación...',
  SEARCH_BAR_SEARCH_ACTIVE_NOTIFICATION: 'La barra de búsqueda filtrará solamente los estados distintos a "Finalizadas". Para buscar inspecciones finalizadas, por favor seleccionar la pestaña respectiva.',
  SEARCH_BAR_SEARCH_COMPLETED_NOTIFICATION: 'La barra de búsqueda filtrará solamente los estados iguales a "Finalizadas". Para buscar inspecciones con estados pendientes, por favor seleccionar una pestaña distinta a "Finalizadas".',

  SEARCH_NO_RESULTS_TITLE: 'Ups, lo sentimos no hemos encontrado lo que buscas',
  SEARCH_NO_RESULTS_SUBTITLE: 'Verifica e intenta nuevamente',
  FINISHED_SEARCH_NO_RESULTS_TITLE: 'No se encontraron registros con estos valores',
  FINISHED_SEARCH_NO_RESULTS_SUBTITLE: 'Favor vuelve a intentar',
  SEARCH_LOADING_LABEL: 'Por favor, espera...',
  SEARCH_DUPLICATED_SUBMISSIONID_LABEL: 'Verificando número de póliza duplicado. Por favor, espere...',
  SEARCH_CUSTOMER_INFO_EIS_LABEL: 'Buscando información en EIS. Por favor, espere...',

  INSPECTION_TITLE: 'Inspección',
  INSPECTION_SWITCH_ENABLE_ENGLISH_EMAILS: 'Anuncio en ingles',
  INSPECTION_SUBTITLE: 'Realiza la inspección del vehículo por medio de las fotografías enviadas por el cliente, no olvides los comentarios y adjuntar los soportes necesarios',
  INSPECTION_LOADING_LABEL: 'Por favor, espera...',
  INSPECTION_APPROVE_BUTTON: 'Aceptar fotos',
  INSPECTION_CONTINUE_ADIL_BUTTON: 'Continuar en adil',
  INSPECTION_GET_INFORM_ANALYTICS: 'Informe y análisis',
  INSPECTION_CHANGE_PRESENTIAL_BUTTON: 'Pasar a presencial',
  INSPECTION_REJECT_BUTTON: 'Rechazar',
  INSPECTION_RETURN_BUTTON: 'Volver',
  INSPECTION_CUSTOMER_RETAKE_BUTTON: 'Solicitar nueva toma de fotos',
  INSPECTION_SAVE_BUTTON: 'Guardar',
  INSPECTION_SAVE_AND_EXIT_BUTTON: 'Guardar y salir',
  INSPECTION_DELETE_BUTTON: 'Eliminar',
  INSPECTION_CANCEL_BUTTON: 'Cancelar',
  INSPECTION_PHOTO_UPLOAD_IMAGES: 'Cargar imágenes',
  INSPECTION_PHOTO_UPLOAD_DOCUMENTS: 'Cargar archivo comprimido',
  INSPECTION_PHOTO_UPLOADING_DOCUMENTS: 'Añadiendo archivo comprimido...',
  INSPECTION_PHOTO_UPLOAD_IMAGES_PER_ITEM: 'Cargar imágen',
  INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP: 'Cargar archivo comprimido',
  INSPECTION_PHOTO_UPLOAD_IMAGES_FILE: 'Seleccionar imagen',
  INSPECTION_PHOTO_UPLOAD_IMAGES_SAVE_BUTTON: 'Cargar',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG: 'El archivo no contiene el formato correcto. usar: (.jpg,.jpeg,.png)',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP: 'El archivo no contiene el formato correcto. usar: (.zip)',

  INSPECTION_DELETE_MODAL_TITLE: '¿Estás seguro que quieres eliminarlo?',
  INSPECTION_DELETE_MODAL_SUBTITLE: 'Esta acción no se puede deshacer.',
  INSPECTION_DELETE_MODAL_CANCEL_BUTTON_LABEL: 'Cancelar',
  INSPECTION_DELETE_MODAL_CONFIRM_BUTTON_LABEL: 'Eliminar',
  INSPECTION_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER: '¿Por qué se elimina esta inspección? (Máximo 1000 caracteres)',
  INSPECTION_DELETE_MODAL_TEXTAREA_TITLE: 'Por favor escribe tus comentarios respecto a este caso:',

  INSPECTION_CANCEL_MODAL_TITLE: '¿Estás seguro que quieres cancelarlo?',
  INSPECTION_CANCEL_MODAL_SUBTITLE: 'Esta acción no se puede deshacer.',
  INSPECTION_CANCEL_MODAL_CANCEL_BUTTON_LABEL: 'Cancelar',
  INSPECTION_CANCEL_MODAL_CONFIRM_BUTTON_LABEL: 'Confirmar',
  INSPECTION_CANCEL_MODAL_MESSAGE_INPUT_PLACEHOLDER: '¿Por qué se cancela esta inspección? (Máximo 1000 caracteres)',
  INSPECTION_CANCEL_MODAL_TEXTAREA_TITLE: 'Por favor escribe tus comentarios respecto a este caso:',

  INSPECTION_OVERRIDE_MODAL_TITLE: 'Esta inspección tiene fotografías pendientes',
  INSPECTION_OVERRIDE_MODAL_SUBTITLE: 'Esta acción no se puede deshacer.',
  INSPECTION_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL: 'Cancelar',
  INSPECTION_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Por favor escribe tus comentarios (Máximo 1000 caracteres)',
  INSPECTION_OVERRIDE_MODAL_TEXTAREA_TITLE: '¿Por qué se está aprobando esta inspección?',

  INSPECTION_PHOTO_HISTORY_MODAL_FOTO: 'Foto',
  INSPECTION_PHOTO_HISTORY_MODAL_FOTO_DATE: 'Fecha de carga',
  INSPECTION_PHOTO_HISTORY_MODAL_PHOTO_COMMENTS: 'Comentarios de devolucion',
  INSPECTION_PHOTO_HISTORY_MODAL_HEADER: 'Histórico de fotos repetidas',

  INSPECTION_OVERRIDE_COMMENT_TITLE: 'La inspección se finalizó con fotografías faltantes',
  INSPECTION_OVERRIDE_AGENT_NAME: 'Nombre de usuario que aprueba:',

  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_TITLE: 'No existe inspección',
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_SUBTITLE: 'La inspección no se encuentra creada como solicitud.',
  INSPECTION_DUPLICATE_MODAL_ERROR_TITLE: 'Error en servicio',
  INSPECTION_DUPLICATE_MODAL_ERROR_SUBTITLE: 'Error en disponibilidad del servicio. Favor digite la información.',

  INSPECTION_SUMMARY_HEADER_1: 'N° Inspección',
  INSPECTION_SUMMARY_HEADER_2: 'Patente',
  INSPECTION_SUMMARY_HEADER_3: 'Tipo de ID',
  INSPECTION_SUMMARY_HEADER_4: 'Número de Rut',
  INSPECTION_SUMMARY_HEADER_5: 'Nombre del Tomador',
  INSPECTION_SUMMARY_HEADER_6: 'Creado por',
  INSPECTION_SUMMARY_HEADER_7: 'Fecha de creación',
  INSPECTION_SUMMARY_HEADER_8: 'Estado',

  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_SUCCESS: 'Se ha cambiado la cámara',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_FAILED: 'Ocurrio un problema al cambiar la cámara',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_BUTTON: 'Cambiar cámara',

  INSPECTION_MESSAGE_IS_REQUIRED_VIDEO: 'El video 360 es obligatorio',
  INSPECTION_MESSAGE_IS_OPTIONAL_VIDEO: 'El video 360 es opcional',

  INSPECTION_VEHICLE_DETAILS_IMAGE_ALT_TEXT: 'Vehículo',
  INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT: '- Seleccione -',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY: 'el campo no debe estar vacío ',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MAX: 'este campo no debe superar {maxLength} caracteres',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SUBMISSIONID: 'Número de cotización/Número de contrato no válido. Por favor revise de nuevo.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MIN: 'Número de cotización/Número de contrato no válido. Por favor revise de nuevo.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY: 'por favor selecciona una opción válida de la lista desplegable',
  INSPECTION_VEHICLE_SUBMISSIONID_EXIST: 'el número de inspección ya existe en el sistema.',
  INSPECTION_VEHICLE_LICENCEPLATE_EXIST: 'la patente del vehículo ya existe en el sistema.',

  INSPECTION_VEHICLE_VIDEO_TITLE: 'Video 360°',
  INSPECTION_VEHICLE_VIDEO_HINT_VIEW: 'Arrastra para ver',
  INSPECTION_VEHICLE_VIDEO_BUTTON_CLOSE: 'Cerrar',
  INSPECTION_VEHICLE_VIDEO_BUTTON_SAVE_CHANGES: 'Guardar cambios',

  INSPECTION_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE: 'No hay fotos disponibles',
  INSPECTION_VEHICLE_PHOTOS_TITLE: 'Aprobación de fotos',
  INSPECTION_VEHICLE_PHOTOS_SUBTITLE: 'Comienza el proceso de inspección, verifica que las fotografías cumplan con los estandares permisos para continuar con el proceso',
  INSPECTION_VEHICLE_PHOTOS_HEADER_2: 'Descripción de la imagen',
  INSPECTION_VEHICLE_PHOTOS_HEADER_3: 'Fecha de carga',
  INSPECTION_VEHICLE_PHOTOS_HEADER_4: 'Acciones',
  INSPECTION_VEHICLE_PHOTOS_AI_BANNER: 'Nota: es posible que observes íconos al costado de cada imagen, estamos probando la detección automática de imágenes para ayudarte con el proceso de revisión. Esta información es solo para orientación y las imágenes aún deben revisarse manualmente.',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP: 'Hemos detectado un vehículo presente en esta imagen',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP: 'Hemos detectado que una persona puede aparecer en esta imagen',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_TEXT_TOOLTIP: 'Hemos detectado que el número de chasis puede aparecer en esta imagen',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT: 'Aceptar',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT: 'Solicitar de nuevo',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_ACCEPT: 'Activar Overlay',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_REJECT: 'Desactivar Overlay',
  INSPECTION_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY: 'Fotos fueron devueltas y los comentarios fueron enviados exitosamente',
  INSPECTION_VEHICLE_PHOTOS_VIEW_COMMENTS: 'Ver comentarios',
  INSPECTION_VEHICLE_PHOTOS_INCORRECT_PHOTOS: 'Ups, al parecer hay algunas fotografías incorrectas',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE: 'Descargar imagen',
  INSPECTION_VEHICLE_DOCUMENTS_DOWNLOAD: 'Descargar',
  INSPECTION_VEHICLE_PHOTOS_APPROVE_ALL_PHOTOS: 'Aceptar fotos',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE: 'Descargar imágenes',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE: 'Descargando... por favor espera',
  INSPECTION_VEHICLE_DURATION_VIDEO: 'Duración del video',
  INSPECTION_VEHICLE_DURATION_VIDEO_FIELD: 'Duración del video',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES_FIELD: 'Frecuencia de imágenes',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES: 'Frecuencia de imágenes (Segundos)',
  INSPECTION_VEHICLE_IMAGE_COMPRESS: 'Comprimir imágenes',
  INSPECTION_VEHICLE_IMAGE_COMPRESS_DISABLE: 'NO comprimir imágenes',

  INSPECTION_VEHICLE_CHASSIS_NUMBER_DETECTED_LABEL: '# chasis detectado:',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_ACCEPT: 'Correcto',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_REJECT: 'Incorrecto',

  INSPECTION_CUSTOMER_MESSAGES_TITLE: 'Mis comentarios',
  INSPECTION_CUSTOMER_MESSAGES_BUTTON_LABEL: 'Cerrar',

  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE: 'Envíado con éxito',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_GENERIC: 'Error en el envío',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE: 'Atención',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE: 'El Email y SMS fueron enviados al usuario',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_GENERIC: 'El envío al usuario no pudo realizarse, por favor intenta de nuevo',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE: '¿Estás seguro que deseas reenviar? Al aceptar, se guardará la información de inspección',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE: 'Cerrar',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_EMAIL: 'Error envío de mail recordatorio',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_EMAIL: 'Envió Exitoso de SMS, pero el mail de contacto no es correcto',

  INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND: 'Re-enviar',
  INSPECTION_CUSTOMER_DETAILS_BUTTON_SENDING: 'Enviando...',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_PRECESSED_STATUS: 'Procesado',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DELIVERED_STATUS: 'Entregado',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BOUNCED_STATUS: 'Rebotado',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DROPPED_STATUS: 'Caido',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DIFERRED_STATUS: 'Diferido',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BLOCKED_STATUS: 'Bloqueado',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_UNKNOWN_STATUS: 'Desconocido',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_STATUS_LABEL: 'Estado del email',

  INSPECTION_NEW_CUSTOMER_MESSAGE_TITLE: 'Comentarios de las fotos',
  INSPECTION_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER: 'Escribe tus observaciones sobre las fotos (máximo 1000 caracteres)',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS: 'Enviar mensaje al cliente',
  INSPECTION_NEW_CUSTOMER_MESSAGE_LOADING: 'Por favor, espera...',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE: 'Mensaje enviado al cliente',
  INSPECTION_NEW_CUSTOMER_MESSAGE_CLOSE: 'Cerrar',

  INSPECTION_SUPPORTING_DOCUMENTS_TITLE: 'Soportes',
  INSPECTION_SUPPORTING_DOCUMENTS_SUBTITLE: 'Adjunta y envía los soportes que consideres necesarios para la inspección, pueden ser pantallazos, documentos, entre otros.\nFormatos soportados (PDF, jpg, jpeg, png, bmp, doc, docx).',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER: 'Arrastre archivos para adjuntar, o ',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK: 'buscar',

  INSPECTION_COMMENTS_TITLE: 'Tus comentarios',
  INSPECTION_COMMENTS_SUBTITLE: 'No olvides ingresar los comentarios que tienes sobre la inspección',
  INSPECTION_COMMENTS_INPUT_PLACEHOLDER: 'Escribe tus observaciones (máximo 1000 caracteres)',

  INSPECTION_STATUS_AGENT_REVIEW_DISPLAY_VALUE: 'Pendiente por revisión',
  INSPECTION_STATUS_SUPPORT_AGENT_REVIEW_DISPLAY_VALUE: 'Pendiente por revisión',
  INSPECTION_STATUS_AGENT_IN_PROGRESS_DISPLAY_VALUE: 'Pendiente por aprobación',
  INSPECTION_STATUS_AGENT_APPROVED_DISPLAY_VALUE: 'Finalizada Aprobada',
  INSPECTION_STATUS_AGENT_REJECTED_DISPLAY_VALUE: 'Finalizada rechazada',
  INSPECTION_STATUS_CUSTOMER_RETAKE_DISPLAY_VALUE: 'Pendiente respuesta cliente',
  INSPECTION_STATUS_CUSTOMER_NEW_DISPLAY_VALUE: 'No iniciada',
  INSPECTION_STATUS_CUSTOMER_IN_PROGRESS_DISPLAY_VALUE: 'En proceso',
  INSPECTION_STATUS_SUPPORT_RETAKE_DISPLAY_VALUE: 'Pendiente respuesta cliente',
  INSPECTION_STATUS_SUPPORT_NEW_DISPLAY_VALUE: 'No iniciada',
  INSPECTION_STATUS_SUPPORT_IN_PROGRESS_DISPLAY_VALUE: 'En proceso',
  INSPECTION_STATUS_SUPPORT_INSPECTOR_DISPLAY_VALUE: 'Solicita inspector',
  INSPECTION_STATUS_EXPIRED_DISPLAY_VALUE: 'Finalizada vencida',
  INSPECTION_STATUS_DELETED_DISPLAY_VALUE: 'Finalizada eliminada',
  INSPECTION_STATUS_CANCELLED_DISPLAY_VALUE: 'Finalizada cancelada',
  INSPECTION_STATUS_CHANGED_TO_PRESENTIAL_DISPLAY_VALUE: 'Cambia a presencial',

  SUPPORT_INSPECTION_NO_COMMENTS: 'Sin Comentarios.',

  ENABLE_VENDOR_LABEL: 'Solo inspecciones de soporte',

  INSPECTION_ASSIGNEE_TITLE: 'Asignado:',
  INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON: 'Asignarme',
  INSPECTION_DEFAULT_ASSIGNEE_DISPLAY_VALUE: 'SIN ASIGNAR',

  INSPECTION_COMPLETE_ACCEPTED_TITLE: 'Inspección Fotográfica Aceptada',
  INSPECTION_COMPLETE_ACCEPTED_SUBTITLE: 'Las fotos cumplen con los requisitos de inspección',
  INSPECTION_COMPLETE_REJECTED_TITLE: 'Inspección rechazada',
  INSPECTION_COMPLETE_REJECTED_SUBTITLE: 'El vehículo no cumple con los requisitos básicos para obtener una póliza',
  INSPECTION_COMPLETE_BUTTON_LABEL: 'Volver a la bandeja',

  INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING: 'Frente documento propiedad del vehículo',
  INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING: 'Reverso documento propiedad del vehículo',
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING: 'Patente',
  INSPECTION_PHOTO_VEHICLE_FRONT_DISPLAY_STRING: 'Foto delantera del vehículo',
  INSPECTION_PHOTO_VEHICLE_REAR_DISPLAY_STRING: 'Foto trasera del vehículo',
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING: 'Foto Lateral derecho',
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING: 'Foto Lateral izquierda',
  INSPECTION_PHOTO_VEHICLE_ENGINE_DISPLAY_STRING: 'Habitáculo del motor',
  INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING: 'Tablero de instrumentos',
  INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING: 'Kilometraje',
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING: 'VIN o Chasis',
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING: 'Número de chasis',
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE_DISPLAY_STRING: 'N° parabrisas',
  INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTIONE_DISPLAY_STRING: 'Certificado de Inspección',
  INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD_DISPLAY_STRING: 'Documento/Tarjeta de Registro',
  INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE_DISPLAY_STRING: 'Ángulo Frontal Izquierdo',
  INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE_DISPLAY_STRING: 'Ángulo Frontal Derecho',
  INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE_DISPLAY_STRING: 'Ángulo Trasero Derecho',
  INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE_DISPLAY_STRING: 'Ángulo Trasero Izquierdo',
  INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP_DISPLAY_STRING: 'Faro Izquierdo',
  INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP_DISPLAY_STRING: 'Faro Derecho',
  INSPECTION_PHOTO_VEHICLE_VN_ODOMETER_DISPLAY_STRING: 'Kilometraje',
  INSPECTION_PHOTO_VEHICLE_VIDEO_DISPLAY_STRING: 'Video 360° del vehiculo',
  INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT_DISPLAY_STRING: 'Rut o Pasaporte',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1_DISPLAY_STRING: 'Licencia del primer conductor',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2_DISPLAY_STRING: 'Licencia del segundo conductor',

  INSPECTION_PHOTO_ACCESSORY_RADIO_DISPLAY_STRING: 'Radio',
  INSPECTION_PHOTO_ACCESSORY_DVD_DISPLAY_STRING: 'Pantallas DVD',
  INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS_DISPLAY_STRING: 'Amplificadores',
  INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS_DISPLAY_STRING: 'Parlantes fijos',
  INSPECTION_PHOTO_ACCESSORY_EQUALIZERS_DISPLAY_STRING: 'Ecualizadores',
  INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA_DISPLAY_STRING: 'Cámara de reversa',
  INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS_DISPLAY_STRING: 'Sensores de proximidad',
  INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK_DISPLAY_STRING: 'Bloqueo central',
  INSPECTION_PHOTO_ACCESSORY_ALARM_DISPLAY_STRING: 'Alarma',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM_DISPLAY_STRING: 'Lámina de seguridad',
  INSPECTION_PHOTO_ACCESSORY_SUN_ROOF_DISPLAY_STRING: 'Sun roof',
  INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS_DISPLAY_STRING: 'Vidrios eléctricos',
  INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING_DISPLAY_STRING: 'Aire acondicionado',
  INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY_DISPLAY_STRING: 'Corta corriente',
  INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING_DISPLAY_STRING: 'Grabado de patente en vidrios',
  INSPECTION_PHOTO_ACCESSORY_FOOTRESTS_DISPLAY_STRING: 'Pisaderas',
  INSPECTION_PHOTO_ACCESSORY_ROLL_BAR_DISPLAY_STRING: 'Barra antivuelco interior/exterior',
  INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL_DISPLAY_STRING: 'Muela de enganche',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL_DISPLAY_STRING: 'Coco de arrastre',
  INSPECTION_PHOTO_ACCESSORY_SPOILER_EXTRA: 'Spoiler', // Extra spoiler
  INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG_DISPLAY_STRING: 'Enchufe remolque',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS_DISPLAY_STRING: 'Llantas de aleación',
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS_DISPLAY_STRING: 'Neblineras',
  INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION_DISPLAY_STRING: 'Equipos conversión a gas',
  INSPECTION_PHOTO_ACCESSORY_DURALINER_DISPLAY_STRING: 'Cubre pick up',
  INSPECTION_PHOTO_ACCESSORY_HELMET_DISPLAY_STRING: 'Cúpula',

  INSPECTION_PHOTO_ACCESSORY_ARMOR_DISPLAY_STRING: 'Blindaje',
  INSPECTION_PHOTO_ACCESSORY_CD_BOX_DISPLAY_STRING: 'Caja de CDs',
  INSPECTION_PHOTO_ACCESSORY_FIXED_DVD_DISPLAY_STRING: 'DVD fijo',
  INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT_DISPLAY_STRING: 'Planta de sonido',
  INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE_DISPLAY_STRING: 'Radio cassette',
  INSPECTION_PHOTO_ACCESSORY_WOOFER_DISPLAY_STRING: 'Woofer',
  INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS_DISPLAY_STRING: 'Sensores de parqueo',
  INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS_DISPLAY_STRING: 'Sensores de reversa',

  INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_GLASSES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BULLBAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUNROOF_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING_DISPLAY_STRING: '',

  INSPECTION_PHOTO_ACCESSORY_SPOILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TOW_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SKIRTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TIRES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROOF_RACK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRUCK_BED_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TWEETER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SLIDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WINCH_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_DRAWER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUSPENSION_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUMPER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BODY_KIT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MIRRORS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_SPARE_WHEEL_DISPLAY_STRING: 'Rueda de repuesto',

  INSPECTION_PHOTO_MOTORBIKE_FRONT_DISPLAY_STRING: 'Parte delantera de la moto',
  INSPECTION_PHOTO_MOTORBIKE_REAR__DISPLAY_STRING: 'Parte trasera de la moto',
  INSPECTION_PHOTO_MOTORBIKE_ENGINE_DISPLAY_STRING: 'Motor',

  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_TRUNK_DISPLAY_STRING: 'Maleteros',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PORTER_DISPLAY_STRING: 'Cajuela',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PROTECTOR_KIT_DISPLAY_STRING: 'Kit de protectores',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_ALARM_DISPLAY_STRING: 'Alarma',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_EXPLORERS_DISPLAY_STRING: 'Exploradoras',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_HELMET_DISPLAY_STRING: 'Casco',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_JACKET_DISPLAY_STRING: 'Chaqueta/ Chaleco',

  INSPECTION_PHOTO_DAMAGE_NAME: 'Daño',

  CREATE_INSPECTION_NOTES_TITLE: '',
  CREATE_INSPECTION_CUSTOMER_TITLE: 'Cliente',
  CREATE_INSPECTION_CUSTOMER_SUBTITLE: 'Ingresar información del cliente',
  CREATE_INSPECTION_AGENT_TITLE: 'Agente',
  CREATE_INSPECTION_AGENT_SUBTITLE: 'Ingresar información del agente',
  CREATE_INSPECTION_VEHICLE_TITLE: 'Vehículo',
  CREATE_INSPECTION_VEHICLE_SUBTITLE: 'Ingresa aquí la información',
  CREATE_INSPECTION_BROKER_TITLE: 'Corredor',
  CREATE_INSPECTION_BROKER_SUBTITLE: 'Ingresa aquí la información del intermediario',
  CREATE_INSPECTION_NAMED_DRIVER_TITLE: 'Conductor asignado',
  CREATE_INSPECTION_NAMED_DRIVER_SUBTITLE: 'Ingresa aquí la información del conductor',
  CREATE_INSPECTION_SUBMIT: 'Crear',
  CREATE_INSPECTION_PREFILL: 'Rellenar',
  CREATE_INSPECTION_CLEAN_FIELDS: 'Limpiar Campos',
  CREATE_INSPECTION_CREATION_ERROR: 'Error al crear la inspección',
  CREATE_INSPECTION_CREATION_SUCCESS: 'Inspección creada con éxito',
  CREATE_INSPECTION_CREATION_CLOSE: 'Volver',
  CREATE_INSPECTION_CREATION_CONTINUE: 'Continuar',
  CREATE_INSPECTION_CREATION_COPY: 'Copiar link',
  CREATE_INSPECTION_INVALID_PHONE_NUMBER: 'el número de teléfono celular inválido',
  CREATE_INSPECTION_CAMERA_TITLE: 'Cámara',
  CREATE_INSPECTION_CAMERA_SUBTITLE: 'Selecciona la cámara que va a utilizar el cliente',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL: 'Tipo de cámara',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NATIVE: 'Cámara nativa',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NEW: 'Módulo de cámara Integrado',
  CREATE_INSPECTION_VIDEO_TITLE: 'Video 360°',
  CREATE_INSPECTION_VIDEO_SUBTITLE: 'En esta seccion puedes habilitar el video 360° para el cliente',
  CREATE_INSPECTION_SELECT_VIDEO_LABEL: 'Seleccione una opcion',
  CREATE_INSPECTION_POLICY_TYPE_LABEL: 'Tipo de política',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_1: 'Implementar video 360°',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_2: 'No implementar video 360°',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_1: 'Nuevos negocios',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_2: 'Renovar',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_1: '1 Imagen',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_2: '2 Imágenes',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_1: '1 Minuto',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_2: '2 Minutos',
  CREATE_INSPECTION_INSPECTION_NOT_FOUND: 'La inspección no se encuentra creada como solicitud',
  CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR: 'Error en disponibilidad del servicio. Favor digite la información',
  CREATE_INSPECTION_LOADING_INSPECTION_MESSAGE: 'Cargando datos de inspección',

  REPORT_SUCCESS_MODAL_TITLE: 'Tu reporte se ha descargado exitosamente',
  REPORT_SUCCESS_MODAL_BUTTON: 'Cerrar',
  REPORT_TITLE: 'Reporte de inspecciones',
  REPORT_SUBTITLE: 'Generar un reporte detallado con toda información de tus inspecciones',
  REPORT_SUBMIT_BUTTON: 'Generar reporte',
  REPORT_FORM_SUBTITLE: 'Selecciona el rango de fechas sobre las que deseas generar tu reporte',
  REPORT_FORM_START_DATE: 'Fecha inicial',
  REPORT_FORM_END_DATE: 'Fecha final',
  REPORT_FORM_ERROR_MAX_RANGE: 'Las fechas de consulta deben ser igual o menor a 90 días',
  REPORT_OPTION_ONE: 'Adil Workflow',
  REPORT_OPTION_TWO: 'Fotos devueltas',
  REPORT_OPTION_THREE: 'Fechas',
  REPORT_OPTION_FOR: 'Errores',
  REPORT_OPTION_FIVE: 'Solicitudes de soporte',
  REPORT_OPTION_SIX: 'Features',
  REPORT_OPTION_EIGHT: 'Estado envío de correos',
  REPORT_OPTION_NINE: 'Inspecciones eliminadas',
  REPORT_OPTION_TEN: 'Geolocalizacion de fotos',
  REPORT_MESSAGE_TIMEOUT: 'Límite de registros a consultar superado, favor reduce el rango de tiempo seleccionado',

  SEARCH_FILTER_BAR_WITH_PHOTOS: 'Con fotos',

  INSPECTION_INFORM_TITLE: 'Informe de inspección',
  INSPECTION_INFORM_SUBTITLE: 'Completa los siguientes pasos para el resultado del informe de inspección.',
  INSPECTION_INFORM_DATA_OPERATOR: 'Datos del corredor',
  INSPECTION_INFORM_DATA_INSPECTOR: 'Datos del inspector',
  INSPECTION_INFORM_DATA_INSPECTION: 'Datos de la inspección',
  INSPECTION_INFORM_DATA_VEHICLE: 'Datos del vehículo',
  INSPECTION_INFORM_SYSTEM_IDENTIFICATION: 'Sistemas de identificación',
  INSPECTION_INFORM_REVISION_FRONTAL: 'Revisión estructural: Frontal',
  INSPECTION_INFORM_LATERAL_LEFT: 'Revisión estructural: Lateral izquierdo',
  INSPECTION_INFORM_MAP_DAMAGE: 'Mapa de daños',
  INSPECTION_INFORM_PIECES_INSPECTION: 'Piezas a inspeccionar',
  INSPECTION_INFORM_CHARACTERS_LIMIT: '50 de 1000 caracteres',
  INSPECTION_INFORM_START_DAMAGE_INSPECTIONS: 'Ingresar inspección con daños',

  INSPECTION_INFORM_MODEL_YEAR: 'Año Modelo',
  INSPECTION_INFORM_CLASS: 'Clase',
  INSPECTION_INFORM_MANUFACTURER: 'Marca',
  INSPECTION_INFORM_TYPE_ID: 'Uso/Tipo de placa',
  INSPECTION_INFORM_KM_OR_AUTONOMIC: 'Km o autonomía',
  INSPECTION_INFORM_COLOR: 'Color',
  INSPECTION_INFORM_TYPE_ENGINE: 'Combustible',
  INSPECTION_INFORM_MODEL_VERSION: 'Modelo/Version',

  INSPECTION_INFORM_NUMBER_PATENT: 'Placa/Patente',
  INSPECTION_INFORM_STATE_PATENT: 'Estado Placa/Patente',
  INSPECTION_INFORM_NUMBER_CHASSIS: 'Número de chasis',
  INSPECTION_INFORM_STATE_CHASSIS: 'Estado Número de chasis',
  INSPECTION_INFORM_NUMBER_MOTOR: 'Serie motor',
  INSPECTION_INFORM_STATE_MOTOR: 'Estado Serie motor',
  INSPECTION_INFORM_CLOSE_FULLSCREEN_BUTTON: 'Salir de pantalla completa',
  INSPECTION_INFORM_APPROVED_OPTION: 'Aprobado',
  INSPECTION_INFORM_DISAPPROVED_OPTION: 'Rechazado',

  STEPPER_VALIDATE_DATA: 'Validación de datos',
  STEPPER_VALIDATE_CRIMINAL_RECORD: 'Validación de antecedentes',
  STEPPER_SEND_RESULTS: 'Entrega de resultado',
  STEPPER_LABEL_ONE: '1',
  STEPPER_LABEL_TWO: '2',
  STEPPER_LABEL_THREE: '3',
};

export default { code, strings };
