import React, { useEffect, useState } from 'react';
import { Content } from '@lmig/lmds-react';
import { useDispatch } from 'react-redux';
import { AppFooter, AppHeader, SideBar } from '../../components';
import TableIndividualPartsMantainer from '../../components/TableIndividualPartsMantainer';
import TableGeneralPartsManteiner from '../../components/TableGeneralPartsManteiner/TableGeneralPartsManteiner';
import { partsAndPiecesActions } from '../../redux/parts-and-pieces-admin';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const AdminPartsManteiner = () => {
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setInlineLoaderDisplayed(true);
    dispatch(
      partsAndPiecesActions.getPiecesAndPartsDB(
        getCountryCode()
          .toLowerCase()
          .toString(),
      ),
    ).finally(() => setInlineLoaderDisplayed(false));
  }, [dispatch]);

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <div className="adil-content">
          <Content>
            <TableGeneralPartsManteiner onLoader={inlineLoaderDisplayed} />
            <TableIndividualPartsMantainer onLoader={inlineLoaderDisplayed} />
            {renderFooterSection()}
          </Content>
        </div>
      </SideBar>
    </div>
  );
};
export default AdminPartsManteiner;
