import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function AddingRow({ onAddItem }) {
  const [newRegister, setNewRegister] = useState({});
  const [addingRow, setAddingRow] = useState(false);

  const addColumnValueToRegister = (column, value) => {
    setNewRegister({
      ...newRegister,
      [column]: value,
    });
  };

  const isValidRegister = () => {
    let isValid = true;

    // If not exist the column or the value is the default is invalid
    if (!newRegister.Piece || newRegister.Piece === '-1') {
      isValid = false;
    }

    // If not exist the column or the value is the default is invalid
    if (!newRegister.Evaluation || newRegister.Evaluation === '-1') {
      isValid = false;
    }

    return isValid;
  };

  const getContextButton = () => {
    if (addingRow) {
      return (
        <button
          className="py:0.5rem bg:#99E5EA bg:#99E5EA99:hover bg:#99E5EA66:disabled color:#111 r:0.3rem px:2rem border:1px|solid|#CCC"
          type="button"
          disabled={!isValidRegister()}
          onClick={() => {
            onAddItem(newRegister);
            setAddingRow(false);
          }}
        >
          Confirmar
        </button>
      );
    }
    return (
      <button
        className="py:0.5rem bg:#99E5EA bg:#99E5EA99:hover color:#111 r:0.3rem px:2rem border:1px|solid|#CCC"
        type="button"
        onClick={() => setAddingRow(true)}
      >
        Añadir
      </button>
    );
  };

  return (
    <>
      <div
        className={`flex:row bg:green-90 py:0.5rem ${
          addingRow ? 'flex' : 'hidden'
        }`}
      >
        <span className="flex flex:1 justify-content:center">
          <select
            className="border:none bg:transparent font-size:1rem"
            name="app-table-inspection-row"
            id="app-table-inspection-row"
            value={newRegister?.Piece ?? '-1'}
            onChange={({ target }) => {
              addColumnValueToRegister('Piece', target.value);
            }}
          >
            <option label="Seleccione" value="-1" />
            <option label="Capo" value="Capo" />
            <option label="Retrovisor" value="Retrovisor" />
            <option label="Foco Izquierdo" value="Foco Izquierdo" />
            <option label="Foco Derecho" value="Foco Derecho" />
          </select>
        </span>

        <span className="flex flex:1 justify-content:center">
          <select
            className="border:none bg:transparent font-size:1rem"
            name="app-table-inspection-row"
            id="app-table-inspection-row"
            value={newRegister?.Evaluation ?? '-1'}
            onChange={({ target }) => {
              addColumnValueToRegister('Evaluation', target.value);
            }}
          >
            <option label="Seleccione" value="-1" />
            <option label="Malo" value="Malo" />
            <option label="Regular" value="Regular" />
          </select>
        </span>
      </div>

      <div className="flex flex:row bg:white py:0.5rem">
        <div className="flex flex:1" />
        <div className="flex flex:1" />

        <div className="flex flex:1 justify-content:center">
          {getContextButton()}
        </div>
      </div>
    </>
  );
}

AddingRow.propTypes = {
  onAddItem: PropTypes.func.isRequired,
};
