import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { collapsableActions } from '../../redux/collapsables';
import { animationCollapsable, removeAllCollapsable } from '../../utils';

const Collapsable = ({ idCollapsable, children, forceOpen }) => {
  const [isOpenCollapsable, setIsOpenCollapsable] = useState(false);
  const dispatch = useDispatch();

  const {
    collapsable: { openCollapsable },
  } = useSelector(state => state);

  const handleCollapsable = () => {
    setIsOpenCollapsable(!isOpenCollapsable);
    animationCollapsable(idCollapsable);
  };

  useEffect(() => {
    if (openCollapsable === false) {
      setIsOpenCollapsable(openCollapsable);
      removeAllCollapsable();
      dispatch(collapsableActions.closeAllCollapsables(null));
    } else if (forceOpen && !isOpenCollapsable) {
      animationCollapsable(idCollapsable);
      if (!isOpenCollapsable) setIsOpenCollapsable(!isOpenCollapsable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openCollapsable, idCollapsable, forceOpen]);

  return (
    <div className="collapasableAdil">
      <div className="collapasableAdil__buttons">
        {isOpenCollapsable && (
          <span role="button" onClick={handleCollapsable}>
            -
          </span>
        )}
        {!isOpenCollapsable && (
          <span role="button" onClick={handleCollapsable}>
            +
          </span>
        )}
      </div>
      <div id={idCollapsable} className="collapasableAdil__content">
        <div className={`collapasableAdil__content__grown--${idCollapsable}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

Collapsable.defaultProps = {
  forceOpen: false,
};

Collapsable.propTypes = {
  idCollapsable: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  forceOpen: PropTypes.bool,
};

export default Collapsable;
