/* eslint-disable consistent-return */
import {
  createSupportRequest,
  deleteSupportRequest,
  getSupportRequest,
} from '../../api';
import { GET_REGISTERS_SUPPORT_REQUEST } from './constants';

const createAndUpdatesupportRequest = supportRequest => async () =>
  createSupportRequest(supportRequest);

const deletesSupportRequest = objectDelete => async () =>
  deleteSupportRequest(objectDelete);

const allDataSupportRequest = troublesAndSolutions => ({
  type: GET_REGISTERS_SUPPORT_REQUEST,
  payload: {
    troubles: troublesAndSolutions.troubles,
    solutions: troublesAndSolutions.solutions,
    troublesRequired: troublesAndSolutions.troublesRequired,
  },
});
const getSupportRequestDb = countryCode => async dispatch => {
  let troublesAndSolutions;
  try {
    troublesAndSolutions = await getSupportRequest(countryCode);
    dispatch(allDataSupportRequest(troublesAndSolutions));
  } catch (error) {
    return error;
  }
};

export default {
  createAndUpdatesupportRequest,
  getSupportRequestDb,
  deletesSupportRequest,
};
