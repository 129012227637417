import { GET_REGISTERS_SUPPORT_REQUEST } from './constants';

export const initialState = {
  supportTroubleAndSolutions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGISTERS_SUPPORT_REQUEST:
      return {
        ...state,
        supportTroubleAndSolutions: action.payload,
      };

    default:
      return state;
  }
};
