import { FIELD_NAMES } from '@lmig-latam/adil-api-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const {
  // Vehicle fields
  VEHICLE,
  LICENCE_PLATE_TYPE,
  YEAR,
  MAKE,
  MODEL,
  COLOR,
  BODY_STYLE,
  USE,
  FASECOLDA_CODE,
  FASECOLDA_VALUE,
  COUNTRY_CAR_ID,
  ORIGIN,
  VERSION,
  CHASSIS_NUMBER,
  SERIAL_NUMBER,
  FUEL_TYPE,
  TRANSMISSION_TYPE,
  PAINT_TYPE,
  VIN,
  ENGINE_NUMBER,
  CAPACITY,
  CLASS,
  KILOMETERS,
  VALUE,
} = FIELD_NAMES;

const FIELD_CONFIG = getCountryConfig(getCountryCode()).getFieldConfig();

export const fieldConfig = {
  topLeftSection: [FIELD_CONFIG[VEHICLE][FASECOLDA_CODE]],

  topRightSectionFirstRow: [
    FIELD_CONFIG[VEHICLE][MAKE],
    FIELD_CONFIG[VEHICLE][MODEL],
    FIELD_CONFIG[VEHICLE][LICENCE_PLATE_TYPE],
    FIELD_CONFIG[VEHICLE][CAPACITY],
    FIELD_CONFIG[VEHICLE][VERSION],
  ],
  topRightSectionSecondRow: [
    FIELD_CONFIG[VEHICLE][FASECOLDA_VALUE],
    FIELD_CONFIG[VEHICLE][CLASS],
    FIELD_CONFIG[VEHICLE][FUEL_TYPE],
    FIELD_CONFIG[VEHICLE][ORIGIN],
    FIELD_CONFIG[VEHICLE][COUNTRY_CAR_ID],
  ],

  bottomSectionFirstRow: [
    FIELD_CONFIG[VEHICLE][USE],
    FIELD_CONFIG[VEHICLE][CHASSIS_NUMBER],
    FIELD_CONFIG[VEHICLE][SERIAL_NUMBER],
    FIELD_CONFIG[VEHICLE][ENGINE_NUMBER],
    FIELD_CONFIG[VEHICLE][YEAR],
    FIELD_CONFIG[VEHICLE][VIN],
  ],
  bottomSectionSecondRow: [
    FIELD_CONFIG[VEHICLE][COLOR],
    FIELD_CONFIG[VEHICLE][BODY_STYLE],
    FIELD_CONFIG[VEHICLE][KILOMETERS],
    FIELD_CONFIG[VEHICLE][PAINT_TYPE],
    FIELD_CONFIG[VEHICLE][TRANSMISSION_TYPE],
    FIELD_CONFIG[VEHICLE][VALUE],
  ],
};

Object.keys(fieldConfig).forEach(fieldSection => {
  fieldConfig[fieldSection] = fieldConfig[fieldSection].filter(
    field => typeof field !== 'undefined',
  );
});

export const config = fieldConfig;

export default config;
