import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CardGlobalHeader = ({ indexCard, titleCard }) => (
  <div className="content__box__header">
    {indexCard > 0 && (
      <span className="content__box__header__number">{indexCard}</span>
    )}
    <h4 className="content__box__header__titleCard">{titleCard}</h4>
  </div>
);

export default CardGlobalHeader;

CardGlobalHeader.propTypes = {
  indexCard: PropTypes.number,
  titleCard: PropTypes.string,
};

CardGlobalHeader.defaultProps = {
  indexCard: 0,
  titleCard: '',
};
