import React from 'react';
import PropTypes from 'prop-types';

export const DownloadIcon = ({
  width,
  height,
  background,
  href,
  hidden,
  ...props
}) => (
  <a
    download
    href={href}
    style={{ width, height, display: hidden && 'none' }}
    {...props}
  >
    <svg width={width} height={height} viewBox="0 0 32 32">
      <g id="Grupo_19268" data-name="Grupo 19268">
        <circle cx="16" cy="16" r="16" fill={background} />
      </g>
      <g transform="translate(8 9)">
        <g transform="translate(0 0)">
          <path
            d="M15.739,6.581l.009,0a3.15,3.15,0,0,0-.974-1.238l-.016-.012a3.057,3.057,0,0,0-.465-.291l-.031-.016c-.08-.039-.162-.077-.245-.109l0,0a3.161,3.161,0,0,0-.554-.159l-.021,0c-.093-.017-.185-.031-.281-.04l-.17-.016L12.97,4.52a4.987,4.987,0,0,0-9.931,0l-.015.171-.172.015a3.144,3.144,0,0,0,.292,6.275H6.413v-.927H3.145A2.217,2.217,0,0,1,2.938,5.63l.938-.086.087-.938a4.06,4.06,0,0,1,8.084,0l.087.938.927.085h0c.022,0,.044.006.065.009.063.007.126.018.187.03.039.008.078.017.116.028s.092.026.137.041.094.032.139.051.113.049.168.077c.038.02.077.042.114.064l.1.061c.039.026.078.052.115.08l.025.02a2.205,2.205,0,0,1,.251.231l.033.035a2.221,2.221,0,0,1-1.655,3.7H9.8v.927h3.054a3.146,3.146,0,0,0,2.884-4.4"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M9.267,13.5V8.159H8.34v5.4l-.888-.853-.642.669L8.817,15.3l1.867-1.942-.669-.642Z"
            transform="translate(-0.74 -0.886)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </a>
);

DownloadIcon.defaultProps = {
  width: 40,
  height: 40,
  href: null,
  hidden: false,
  background: '#2a2a2a3d',
};

DownloadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  href: PropTypes.string,
  background: PropTypes.string,
  hidden: PropTypes.bool,
};
