import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Heading, Table, TableCell, TableRow } from '@lmig/lmds-react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { formatISOString } from '../../utils';
import SuportRequestActions from '../../redux/support-request/actions';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import InlineLoader from '../InlineLoader';

const {
  ADMIN_SUPPORT_PAGE_TROUBLES,
  ADMIN_SUPPORT_ADD,
  ADMIN_SUPPORT_MODIFY,
  ADMIN_SUPPORT_ADD_TROUBLE,
  ADMIN_SUPPORT_ADD_TROUBLE_SORT,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION,
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT,
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT,
  ADMIN_SUPPORT_BUTTON_VISUALIZE,
  ADMIN_SUPPORT_TABLE_HEAD_1,
  ADMIN_SUPPORT_TABLE_HEAD_2,
  ADMIN_SUPPORT_TABLE_HEAD_3,
  ADMIN_SUPPORT_TABLE_HEAD_4,
  ADMIN_SUPPORT_TABLE_HEAD_5,
  ADMIN_SUPPORT_TABLE_HEAD_6,
  ADMIN_SUPPORT_TABLE_DELETE,
  ADMIN_SUPPORT_TABLE_UPDATE,
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY,
  ERROR_MESSAGE_NUMBER_ONLY,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
  ADMIN_SUPPORT_DELETE_YES,
  ADMIN_SUPPORT_DELETE_NO,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
  ADMIN_SUPPORT_TROUBLE_PREVISUALIZATE,
  ADMIN_SUPPORT_TROUBLE_5_PROBLEMS,
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
  SEARCH_LOADING_LABEL,
} = localizedStrings;

const MySwal = withReactContent(Swal);

const TableSupportTrobles = ({ troubles, troublesRequired, onLoader }) => {
  const [troublesArray, setTroublesArray] = useState(troubles || []);
  const [troublesArrayRequired, setTroublesArrayRequired] = useState(
    troublesRequired || [],
  );
  const [isformModifying, setIsformModifying] = useState(false);
  const [idModify, setidModify] = useState(null);

  const dispatch = useDispatch();

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  useEffect(() => {
    setTroublesArray(troubles);
    return () => {
      setTroublesArray({});
    };
  }, [troubles]);

  useEffect(() => {
    setTroublesArrayRequired(troublesArrayRequired);
    return () => {
      setTroublesArrayRequired({});
    };
  }, [troublesArrayRequired, troublesRequired]);

  useEffect(() => {
    troubles.sort((a, b) => a.sort - b.sort);
    troublesArray.sort((a, b) => a.sort - b.sort);
  }, [troubles, troublesArray]);

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });

  const onSubmit = data => {
    if (isValid && !isformModifying && troublesArray.length >= 5) {
      MySwal.fire({
        title: ADMIN_SUPPORT_TABLE_HEAD_1,
        text: ADMIN_SUPPORT_TROUBLE_5_PROBLEMS,
        allowOutsideClick: false,
        customClass: {
          htmlContainer: 'sweetAdil__container',
          title: 'sweetAdil__title',
          confirmButton: 'sweetAdil__acceptButton',
        },
      });
      reset();
      return;
    }

    if (isValid && !isformModifying) {
      const troublesArrayTemp = [...troublesArray];
      const createDate = formatISOString(new Date()).split(' ')[0];
      const troubleToSend = {
        ...data,
        id: uuidv4(),
        createDate,
        countryCode: getCountryCode().toLocaleLowerCase(),
      };
      dispatch(
        SuportRequestActions.createAndUpdatesupportRequest(troubleToSend),
      )
        .then(() => {
          troublesArrayTemp.push(troubleToSend);
          setTroublesArray(troublesArrayTemp);
          reset();
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        });
    }

    if (isValid && isformModifying) {
      const dateModify = formatISOString(new Date()).split(' ')[0];
      const troublesArrayTemp = [...troublesArray];
      const indexSolution = troublesArray.findIndex(
        trouble => trouble.id === idModify,
      );
      troublesArrayTemp[indexSolution] = {
        id: idModify,
        ...data,
        createDate: troublesArrayTemp[indexSolution].createDate,
        updateDate: dateModify,
      };
      dispatch(
        SuportRequestActions.createAndUpdatesupportRequest(
          troublesArrayTemp[indexSolution],
        ),
      )
        .then(() => {
          setTroublesArray(troublesArrayTemp);
          reset();
          setIsformModifying(false);
        })
        .catch(() => {
          MySwal.fire({
            title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
            text: ADMIN_SUPPORT_ERROR_GENERAL,
            allowOutsideClick: false,
            customClass: {
              htmlContainer: 'sweetAdil__container',
              title: 'sweetAdil__title',
              confirmButton: 'sweetAdil__acceptButton',
            },
          });
        });
    }
  };

  const setValueForm = formValues => {
    const { trouble, description, sort, activeOption, id } = formValues;
    setIsformModifying(true);
    setidModify(id);
    setValue('trouble', trouble || description, { shouldValidate: true });
    setValue('sort', sort, { shouldValidate: true });
    setValue('activeOption', activeOption, { shouldValidate: true });
  };

  const deleteTroubleForId = idTrouble => {
    MySwal.fire({
      title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION,
      showDenyButton: true,
      confirmButtonText: ADMIN_SUPPORT_DELETE_YES,
      denyButtonText: ADMIN_SUPPORT_DELETE_NO,
      allowOutsideClick: false,
      customClass: {
        title: 'sweetAdil__title',
        denyButton: 'sweetAdil__cancelButton',
        confirmButton: 'sweetAdil__acceptButton',
      },
    }).then(result => {
      if (result.isConfirmed) {
        const troubleArrayDeleteId = troublesArray.filter(
          item => item.id !== idTrouble,
        );
        dispatch(
          SuportRequestActions.deletesSupportRequest({
            idItem: idTrouble,
            isTrouble: true,
            countryCode: getCountryCode().toLocaleLowerCase(),
          }),
        )
          .then(() => {
            setTroublesArray(troubleArrayDeleteId);
            MySwal.fire({
              title: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            });
          })
          .catch(() =>
            MySwal.fire({
              title: ADMIN_SUPPORT_TABLE_HEAD_1,
              text: ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE,
              allowOutsideClick: false,
              customClass: {
                htmlContainer: 'sweetAdil__container',
                title: 'sweetAdil__title',
                confirmButton: 'sweetAdil__acceptButton',
              },
            }),
          );
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  };

  const openPrevisualizatePopup = () => {
    const listItems = troublesArray.map(
      ({ description, trouble }) =>
        `<li class="listItems__item">${description || trouble}</li>`,
    );
    MySwal.fire({
      title: ADMIN_SUPPORT_TROUBLE_PREVISUALIZATE,
      html: `<ul class="listItems">${listItems.join(',')}</ul>`,
      confirmButtonText: ADMIN_SUPPORT_DELETE_YES,
      customClass: {
        title: 'sweetAdil__title',
        confirmButton: 'sweetAdil__acceptButton',
      },
    });
  };

  return (
    <div className="supportBox">
      <h4>{ADMIN_SUPPORT_PAGE_TROUBLES}</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="supportBox__form">
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">{ADMIN_SUPPORT_ADD_TROUBLE}</label>
          <input
            placeholder={ADMIN_SUPPORT_ADD_TROUBLE}
            {...register('trouble', {
              required: true,
            })}
            className={`inputs_rhf_adil ${
              errors.trouble ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">
            {ADMIN_SUPPORT_ADD_TROUBLE_SORT}
          </label>
          <input
            placeholder={ADMIN_SUPPORT_ADD_TROUBLE_SORT}
            {...register('sort', {
              required: true,
              pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
              },
            })}
            className={`inputs_rhf_adil ${
              errors.sort ? 'validation-input-field-error' : ''
            }`}
          />
        </div>
        <div className="supportBox__inputs">
          <label className="label_rhf_adil">
            {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE}
          </label>
          <select
            className={`inputs_rhf_adil ${
              errors.activeOption ? 'validation-input-field-error' : ''
            }`}
            defaultValue="active"
            {...register('activeOption')}
          >
            <option value="active">
              {ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION}
            </option>
            <option value="inactive">
              {ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION}
            </option>
          </select>
        </div>
        <div className="supportBox__buttons">
          <Button type="submit" className="supportBox__button">
            {isformModifying && ADMIN_SUPPORT_MODIFY}
            {!isformModifying && ADMIN_SUPPORT_ADD}
          </Button>
          <Button
            type="button"
            className="supportBox__button"
            onClick={openPrevisualizatePopup}
          >
            {ADMIN_SUPPORT_BUTTON_VISUALIZE}
          </Button>
        </div>
        <div className="supportBox__errorMessages">
          {errors.trouble && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_TROUBLE}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
          {errors.sort && errors.sort.type === 'required' && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_TROUBLE_SORT}: ${INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY}`}
            </span>
          )}
          {errors.sort && errors.sort.type === 'pattern' && (
            <span className="error-message">
              {`${ADMIN_SUPPORT_ADD_TROUBLE_SORT}: ${ERROR_MESSAGE_NUMBER_ONLY}`}
            </span>
          )}
        </div>
      </form>
      <div className="supportBox__table">
        <Table>
          <tbody>
            <TableRow className="supportBox__table__headers">
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_1}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_2}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_3}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_4}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_5}</TableCell>
              <TableCell type="colHead">{ADMIN_SUPPORT_TABLE_HEAD_6}</TableCell>
            </TableRow>
            {!onLoader &&
              troublesArray.length > 0 &&
              troublesArray.map((troubleSupport, index) => (
                <TableRow
                  className={`supportBox__table__content ${
                    index % 2 === 0 ? 'adil-table-row-grey' : ''
                  }`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={troubleSupport.id}
                >
                  <td>
                    {troubleSupport.description || troubleSupport.trouble}
                  </td>
                  <td>{troubleSupport.sort}</td>
                  <td>
                    {troubleSupport.activeOption === 'active'
                      ? ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT
                      : ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT}
                  </td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        className="supportBox__chipButtons supportBox__chipButtons__update"
                        role="button"
                        onClick={() => setValueForm(troubleSupport)}
                      >
                        <span>{ADMIN_SUPPORT_TABLE_UPDATE}</span>
                      </div>
                      <div
                        className="supportBox__chipButtons supportBox__chipButtons__eliminate"
                        role="button"
                        data-testid="supportBox__chipButtons__eliminate"
                        onClick={() => deleteTroubleForId(troubleSupport.id)}
                      >
                        <span>{ADMIN_SUPPORT_TABLE_DELETE}</span>
                      </div>
                    </div>
                  </td>
                  <td>{troubleSupport.createDate}</td>
                  <td>{troubleSupport.updateDate}</td>
                </TableRow>
              ))}
            {!onLoader &&
              troublesRequired.length > 0 &&
              troublesRequired.map((troubleSupport, index) => (
                <TableRow
                  className={`supportBox__table__content ${
                    index % 2 === 0 ? 'adil-table-row-grey' : ''
                  }`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={troubleSupport.id}
                >
                  <td>
                    {troubleSupport.description || troubleSupport.trouble}
                  </td>
                  <td>{troubleSupport.sort}</td>
                  <td>
                    {troubleSupport.activeOption === 'active'
                      ? ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT
                      : ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT}
                  </td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td style={{ display: 'flex', justifyContent: 'center' }} />
                  <td>{troubleSupport.createDate}</td>
                  <td>{troubleSupport.updateDate}</td>
                </TableRow>
              ))}
          </tbody>
        </Table>
        {onLoader && renderInlineLoader()}
      </div>
    </div>
  );
};

TableSupportTrobles.propTypes = {
  troubles: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onLoader: PropTypes.bool.isRequired,
  troublesRequired: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default TableSupportTrobles;
