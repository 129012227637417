import constants from '@lmig-latam/adil-api-common-lib/constants';
import { getEnvironmentConfig } from '../config/environments';
import { isLocalhost } from '../config/environments/environmentsHelpers';
import languages from './languages';
import { logcodes, logger } from './logger';

const {
  LANGUAGES: { CL },
} = constants;

export const getLanguageForCountryCode = countryCode => {
  // Grab the specific language
  const languageConfig = languages[countryCode];

  // Return the strings object
  return languageConfig.strings;
};

export const getCurrentLanguage = () => {
  const possibleLanguageCodes = [];

  // Try using window.location
  if (
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.host &&
    !isLocalhost()
  ) {
    const domainCountryCode = window.location.host.split('.').pop();
    if (domainCountryCode) {
      possibleLanguageCodes.push(domainCountryCode);
    }
  }

  // Try using environment variable
  if (
    typeof process !== 'undefined' &&
    process &&
    process.env &&
    process.env.COUNTRY_CODE
  ) {
    possibleLanguageCodes.push(process.env.COUNTRY_CODE);
  }

  // Get the default language from the environment configuration
  const { language } = getEnvironmentConfig();

  possibleLanguageCodes.push(language);

  // Grab the first code from the list
  let languageCode = possibleLanguageCodes[0];

  // Record error if code not present
  if (!languageCode) {
    logger.log(logcodes.CALAN110);
    languageCode = CL; // Default to CL to make sure the app loads
  }

  return getLanguageForCountryCode(languageCode);
};

export default getCurrentLanguage();
