import { LANGUAGES } from '@lmig-latam/adil-api-common-lib/constants';

export class InspectionBuilder {
  constructor() {
    this.customer = {};
    this.vehicle = {};
    this.broker = {};
    this.countryCode = null;
  }

  setFullName() {
    this.customer.fullName = `${this.customer.firstName || ''} ${this.customer
      .lastName || ''} ${this.customer.secondLastName || ''}`;
  }

  formatPhoneNumber() {
    const { cellPhoneNumber } = this.customer;
    switch (this.countryCode) {
      case LANGUAGES.EC: {
        const existPrefixNumber = cellPhoneNumber.substring(0, 2) === '59';
        if (!existPrefixNumber)
          this.customer.cellPhoneNumber = `59-${cellPhoneNumber}`;
        break;
      }
      default:
    }
    return null;
  }

  setCountryCode(country) {
    this.countryCode = country;
    return this;
  }

  setCustomer(customer) {
    this.customer = customer;
    this.formatPhoneNumber();
    this.setFullName();
    return this;
  }

  setVehicle(vehicle) {
    const result = {
      licencePlate: String(vehicle.licencePlate),
      year: String(vehicle.year),
      make: String(vehicle.make),
      use: String(vehicle.use),
      model: String(vehicle.model),
      vin: String(vehicle.vin),
      category: String(vehicle.category),
      value: String(vehicle.value),
      chassisNumber: String(vehicle.chassisNumber),
    };
    this.vehicle = result;
    return this;
  }

  setBroker(broker) {
    this.broker = {
      brokerCode: String(broker.brokerCode),
      brokerEmail: broker.brokerEmail,
      brokerName: broker.brokerName,
    };
    return this;
  }

  build() {
    return {
      broker: this.broker,
      customer: this.customer,
      vehicle: this.vehicle,
    };
  }
}
