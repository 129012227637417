/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import React, { useEffect, useState } from 'react';
import { IconSearch } from '@lmig/lmds-react/icons';
import { Form, Text } from 'informed';
import { useDispatch, useSelector } from 'react-redux';
import { SearchActions } from '../../redux/search-inspection';
import localizedStrings from '../../utils/localizedStrings';
import {
  getCountryCode,
  isAsianDomain,
} from '../../config/environments/environmentsHelpers';
import { supportRequestEnabled } from '../../utils/constants';
import { TAB_SECTION_NUMBER_LATAM } from '../../constants/menu';
import Input from '../Input/Input';

const { SEARCH_BAR_INPUT_PLACEHOLDER } = localizedStrings;

function InputSearch() {
  const [formApi, setFormApi] = useState();
  const { filterText, quarterSelected, filterSelected } = useSelector(
    store => store.searchFilter,
  );
  const [changeVietnamFinished, setChangeVietnamFinished] = useState({
    vn: {
      equal: 'filterSelected?.id === 5',
      notEqual: 'filterSelected?.id !== 5',
    },
    all: {
      equal: 'filterSelected?.id === 4',
      notEqual: 'filterSelected?.id !== 4',
    },
  });
  const dispatch = useDispatch();

  const submitSearch = () => {
    dispatch(SearchActions.resetFilterSteps());
    const { filter } = formApi.getState().values;
    dispatch(SearchActions.onChangeSearchTextInspections(filter));
  };

  const filterTextOnChange = e => {
    dispatch(SearchActions.onChangeSearchText(e.target.value));
  };

  useEffect(() => {
    if (supportRequestEnabled.includes(getCountryCode())) {
      setChangeVietnamFinished(prevState => {
        const { vn } = prevState;
        return vn;
      });
    } else {
      setChangeVietnamFinished(prevState => {
        const { all } = prevState;
        return all;
      });
    }
  }, []);

  const isFinalizedSection = () =>
    filterSelected?.id === TAB_SECTION_NUMBER_LATAM.FINISHED;

  const isDisabledSearchIcon = () => isFinalizedSection() && !isAsianDomain();

  const getInputClassNameBySection = () =>
    isFinalizedSection() ? '' : 'adil-input-search-container';

  return (
    <Form getApi={setFormApi}>
      <div className="adil-search-bar-form">
        <div
          className={`adil-search-bar-form ${eval(
            changeVietnamFinished.equal,
          ) && 'adil-search__submit'}`}
        >
          {eval(changeVietnamFinished.equal) && (
            <label className="label_rhf_adil">
              {SEARCH_BAR_INPUT_PLACEHOLDER}
            </label>
          )}

          {!isAsianDomain() ? (
            <Input
              type="text"
              value={filterText}
              onChange={filterTextOnChange}
              className={getInputClassNameBySection()}
              placeholder={SEARCH_BAR_INPUT_PLACEHOLDER}
              disabled={
                eval(changeVietnamFinished.equal) && quarterSelected === ''
              }
            />
          ) : (
            <>
              <Text
                type="text"
                field="filter"
                autoComplete="off"
                disabled={
                  eval(changeVietnamFinished.equal) && quarterSelected === ''
                }
                value={filterText}
                initialValue={filterText}
                onChange={filterTextOnChange}
                className="adil-search-bar-input"
                placeholder={SEARCH_BAR_INPUT_PLACEHOLDER}
              />
              <button
                type="submit"
                className="adil-search-bar-search-icon"
                onClick={submitSearch}
                disabled={isDisabledSearchIcon()}
              >
                <IconSearch size="16" />
              </button>
            </>
          )}
        </div>
      </div>
    </Form>
  );
}

export default InputSearch;
