import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { restMethods } from './http-methods';

const { GET, DELETE } = restMethods;

const buildQueryStringFromObject = (queryObject = {}) => {
  const queryStrings = [];
  let queryString = '';

  Object.keys(queryObject).forEach(key => {
    if (queryObject[key]) {
      queryStrings.push(`${key}=${queryObject[key]}`);
    }
  });

  if (queryStrings.length > 0) {
    queryString += `?${queryStrings.join('&')}`;
  }

  return queryString;
};

const buildBody = (body = {}, method = null) => {
  if ([GET, DELETE].includes(method)) {
    return null;
  }

  return {
    ...body,
    countryCode: body.countryCode
      ? body.countryCode.toLowerCase()
      : getCountryCode(),
  };
};

export { buildQueryStringFromObject, buildBody };
