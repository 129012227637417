import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export function SupportRequestIcon({ width, height, count, onClick }) {
  return (
    <>
      <b
        role="button"
        className="support-request-icon"
        onClick={() => onClick()}
      >
        {count || ''}
      </b>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 120 120"
        style={{ cursor: 'pointer' }}
        onClick={() => onClick()}
      >
        <g>
          <path
            style={{ fill: '#037b86' }}
            d="M86.1,88.6H63.4l-26.6,15.7c-1.1,0.6-2.3-0.5-1.9-1.6l5.7-14.1h-6.7c-7.6,0-13.7-6.1-13.7-13.7V29.2   c0-7.6,6.1-13.7,13.7-13.7h52.2c7.6,0,13.7,6.1,13.7,13.7v45.7C99.8,82.4,93.6,88.6,86.1,88.6z"
          />

          <path
            style={{ fill: '#FFFFFF' }}
            d="M83.3,55.9v-7c0-0.8-0.6-1.4-1.4-1.4H79c-0.4-1.8-1.2-3.5-2.1-5l2-2c0.6-0.6,0.6-1.5,0-2L74,33.5   c-0.6-0.6-1.5-0.6-2,0l-2,2c-1.6-1-3.3-1.7-5.1-2.2v-2.9c0-0.8-0.6-1.4-1.4-1.4h-7c-0.8,0-1.4,0.6-1.4,1.4v2.9   c-1.8,0.4-3.5,1.2-5,2.2l-2-2c-0.6-0.6-1.5-0.6-2,0l-4.9,4.9c-0.6,0.6-0.6,1.5,0,2l2,2c-0.9,1.6-1.6,3.3-2.1,5h-2.9   c-0.8,0-1.4,0.6-1.4,1.4v7c0,0.8,0.6,1.4,1.4,1.4H41c0.4,1.8,1.2,3.5,2.1,5l-2,2c-0.6,0.6-0.6,1.5,0,2l4.9,4.9c0.6,0.6,1.5,0.6,2,0   l2-2c1.6,1,3.3,1.6,5,2.1v2.9c0,0.8,0.6,1.4,1.4,1.4h7c0.8,0,1.4-0.6,1.4-1.4v-2.9c1.8-0.4,3.5-1.2,5-2.1l2,2c0.6,0.6,1.5,0.6,2,0   l4.9-4.9c0.6-0.6,0.6-1.5,0-2l-2-2c0.9-1.6,1.6-3.3,2.1-5h3C82.6,57.3,83.3,56.7,83.3,55.9z M60.7,63.2   c-6.6,0.4-11.9-4.9-11.5-11.5c0.3-5.4,4.7-9.8,10.1-10.1c6.6-0.4,11.9,4.9,11.5,11.5C70.4,58.5,66,62.9,60.7,63.2z"
          />
        </g>
      </svg>
    </>
  );
}

SupportRequestIcon.defaultProps = {
  count: null,
  width: 40,
  height: 40,
};

SupportRequestIcon.propTypes = {
  count: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};
