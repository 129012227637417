import {
  Button,
  GridCol,
  GridRow,
  Heading,
  IconDocument,
  Table,
  TableCell,
  TableRow,
} from '@lmig/lmds-react';
import { Form, Text } from 'informed';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import ErrorsList from '../ErrorsList';
import localizedStrings from '../../utils/localizedStrings';
import { getReportTableFields } from '../../constants/menu';
import './styles.scss';
import Panel from '../Panel';
import { InspectionActions, SettingsActions } from '../../actions';
import { getMainStatus } from '../../screens/Search/Search';
import InlineLoader from '../InlineLoader';
import {
  downloadFileFromUrl,
  filterByValue,
  flattenObjectsIntoArray,
  formatDate,
} from '../../utils';
import SearchInputGeneral from '../SearchInputGeneral/SearchInputGeneral';
import CircleImage from '../CircleImage';

const ReportAsian = () => {
  const {
    REPORT_FORM_START_DATE,
    REPORT_FORM_END_DATE,
    REPORT_SUBMIT_BUTTON,
    COUNTER_PAGES,
    SEARCH_LOADING_LABEL,
    REPORT_SUCCESS_MODAL_TITLE,
    REPORT_TABLE_ASIAN_NO_RESULTS,
    REPORT_SUCCESS_MODAL_BUTTON,
    SEARCH_FINISHED,
  } = localizedStrings;

  const [formApi, setFormApi] = useState({});
  const [maxEndDate, setMaxEndDate] = useState();
  const [tableHeaders, setTableHeaders] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [noInspections, setNoInspections] = useState(false);
  const [inspectionsToShow, setInspectionsToShow] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [onLoader, setOnLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wordToSearch, setWordToSearch] = useState(null);
  const [page, setPage] = useState(1);
  const dataPerPage = 10;
  const lastPost = page * dataPerPage;
  const firstPost = lastPost - dataPerPage;
  const [currentPage, setcurrentPage] = useState(
    inspections.slice(firstPost, lastPost) || [],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const arrayInspectionsCopy = [...inspections];
    if (!wordToSearch || wordToSearch === '')
      setInspections(arrayInspectionsCopy);
    const arrayFiltered = filterByValue(inspections, wordToSearch);
    if (arrayFiltered.length === 0) setNoInspections(true);
    setInspectionsToShow(arrayFiltered || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordToSearch, setWordToSearch]);

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  useEffect(() => {
    const indexPagination = [];
    for (
      let index = 1;
      index <= Math.ceil(inspections.length / dataPerPage);
      // eslint-disable-next-line no-plusplus
      index++
    ) {
      indexPagination.push(index);
    }
    setPagination(indexPagination);
  }, [setcurrentPage, inspections, currentPage]);

  const changePagination = paginationNumber => {
    setPage(paginationNumber);
  };

  useEffect(() => {
    if (inspections.slice(firstPost, lastPost).length === 0) {
      setPage(1);
      changePagination(1);
    }
  }, [firstPost, lastPost, inspections]);

  const downloadReport = url => {
    const fileNamePrefix = 'Reports/';
    const fileNameSuffix = '.xlsx';
    const fileName = url.substring(
      url.indexOf(fileNamePrefix) + fileNamePrefix.length,
      url.indexOf(fileNameSuffix) + fileNameSuffix.length,
    );

    setIsModalOpen(true);

    downloadFileFromUrl(url, fileName);
    formApi.reset();
    setWordToSearch('');
    setInspections([]);
    setNoInspections(false);
    setOnLoader(false);

    dispatch(SettingsActions.hideAppLoader());
  };

  const submitReportTable = () => {
    setOnLoader(true);
    setNoInspections(false);
    const {
      values: { startDate, endDate },
    } = formApi?.getState();
    const totalDays =
      (new Date(endDate) - new Date(startDate)) / (1000 * 3600 * 24);

    if (totalDays > 90) {
      dispatch(
        InspectionActions.getReport({
          startDate,
          endDate,
        }),
      ).then(response => {
        const { reportUrl } = response;
        downloadReport(reportUrl);
      });
    } else {
      dispatch(
        InspectionActions.getReportInspectionAsian({
          startDate,
          endDate,
          status: getMainStatus(),
        }),
      ).then(result => {
        setOnLoader(false);
        if (result.Items.length === 0) setNoInspections(true);
        setInspections(
          result?.Items.map(item => flattenObjectsIntoArray(item)),
        );
      });
    }
  };

  const formatDateIfExist = date => {
    if (date) {
      return formatDate(date);
    }

    return null;
  };

  useEffect(() => {
    setInspectionsToShow(inspections.slice(firstPost, lastPost));
    return () => {
      setInspectionsToShow([]);
    };
  }, [firstPost, lastPost, setInspectionsToShow, inspections, setInspections]);

  useEffect(() => {
    setTableHeaders(getReportTableFields());
  }, []);

  useEffect(() => {
    setNoInspections(false);
  }, []);

  const setInformedApi = informedApi => {
    setFormApi(informedApi);
  };

  const validateStartDate = (startDate, { endDate }) => {
    if (!startDate) {
      return 'no value';
    }

    if (startDate && !endDate) {
      formApi.setValue('endDate', startDate);
    }

    if (startDate > endDate) {
      formApi.setValue('endDate', startDate);
    }

    const startDateType = new Date(startDate);
    const maxEndDate = new Date(
      startDateType.setMonth(startDateType.getMonth() + 12),
    );
    setMaxEndDate(
      maxEndDate > new Date()
        ? new Date().toISOString().split('T')[0]
        : maxEndDate.toISOString().split('T')[0],
    );

    return undefined;
  };

  const validateEndDate = (endDate, { startDate }) => {
    if (!endDate) {
      return 'no value';
    }

    if (endDate < startDate) {
      formApi.setValue('startDate', endDate);
    }

    return undefined;
  };

  const renderReportSuccessModal = () => (
    <ReactModal
      isOpen={isModalOpen}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      <div className="modal-content">
        <CircleImage>
          <IconDocument size="64" />
        </CircleImage>
        <Heading type="h3-light">{REPORT_SUCCESS_MODAL_TITLE}</Heading>
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          {REPORT_SUCCESS_MODAL_BUTTON}
        </Button>
      </div>
    </ReactModal>
  );

  const submitReportRequest = async () => {
    dispatch(SettingsActions.showAppLoader());

    const {
      values: { startDate, endDate },
    } = formApi.getState();

    const { reportUrl } = await dispatch(
      InspectionActions.getReport({
        startDate,
        endDate,
      }),
    );

    downloadReport(reportUrl);
  };

  return (
    <>
      {renderReportSuccessModal()}
      <Form getApi={setInformedApi}>
        {({
          formState: {
            errors: { startDate: startDateErrors, endDate: endDateErrors },
            error,
          },
        }) => (
          <>
            <GridRow gutters justify="center">
              <GridCol base={6}>
                <label>
                  {REPORT_FORM_START_DATE}
                  <Text
                    field="startDate"
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    className={classNames([
                      'adil-report-input',
                      {
                        'validation-input-field-error':
                          startDateErrors || error,
                      },
                    ])}
                    validate={validateStartDate}
                    validateOnChange
                  />
                </label>
              </GridCol>
              <GridCol base={6}>
                <label>
                  {REPORT_FORM_END_DATE}
                  <Text
                    field="endDate"
                    type="date"
                    max={maxEndDate}
                    className={classNames([
                      'adil-report-input',
                      {
                        'validation-input-field-error': endDateErrors || error,
                      },
                    ])}
                    validate={validateEndDate}
                    validateOnChange
                  />
                </label>
              </GridCol>
              {error && (
                <GridCol base={12}>
                  <ErrorsList errors={[error]} />
                </GridCol>
              )}
              <GridCol base={12}>
                <Button type="button" onClick={submitReportTable}>
                  {SEARCH_FINISHED}
                </Button>
              </GridCol>
            </GridRow>
            {((!onLoader && inspectionsToShow.length !== 0) ||
              noInspections) && (
              <GridRow>
                <GridCol base={12}>
                  <SearchInputGeneral setWordToSearch={setWordToSearch} />
                </GridCol>
              </GridRow>
            )}
          </>
        )}
      </Form>
      {onLoader && renderInlineLoader()}
      {noInspections && inspectionsToShow.length === 0 && (
        <h4>{REPORT_TABLE_ASIAN_NO_RESULTS}</h4>
      )}
      {!onLoader && inspectionsToShow.length !== 0 && (
        <>
          <Panel style={{ marginBottom: '10px' }}>
            <Table>
              <tbody>
                <TableRow>
                  {tableHeaders.length !== 0 &&
                    tableHeaders.map((header, index) => (
                      <TableCell
                        key={header.label || index}
                        type="colHead"
                        dataHeader={header.label}
                      >
                        <div className="header-style">
                          <div className="div-arrow-style">{header.label}</div>
                        </div>
                      </TableCell>
                    ))}
                </TableRow>
                {inspectionsToShow.length !== 0 &&
                  inspectionsToShow.map((item, index) => (
                    <TableRow
                      className={`supportBox__table__content ${
                        index % 2 === 0 ? 'adil-table-row-grey' : ''
                      }`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <TableCell type="data">
                        {item.submissionId || ''}
                      </TableCell>
                      <TableCell type="data">{item.status || ''}</TableCell>
                      <TableCell type="data">
                        {item.licencePlate || ''}
                      </TableCell>
                      <TableCell type="data">
                        {item.nameIntermediaryInsurance || ''}
                      </TableCell>
                      <TableCell type="data">
                        {formatDateIfExist(item.emailTokenCreationDate)}
                      </TableCell>
                      <TableCell type="data">{item.location || ''}</TableCell>
                      <TableCell type="data">{item.assignee || ''}</TableCell>
                      <TableCell type="data">
                        {formatDateIfExist(item.customerCompletionDate)}
                      </TableCell>
                      <TableCell type="data">{item.assignee || ''}</TableCell>
                      <TableCell type="data">
                        {formatDateIfExist(item.completionDate)}
                      </TableCell>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
          </Panel>
          <div className="supportBox__pagination">
            <div className="supportBox__pagination__item">
              <label>{COUNTER_PAGES}</label>
              <select
                onChange={e => changePagination(e.target.value)}
                className="inputs_rhf_adil"
              >
                {pagination.length > 0 &&
                  pagination.map(pageData => (
                    <option key={pageData} value={pageData}>
                      {pageData}
                    </option>
                  ))}
              </select>
            </div>

            <div className="supportBox__pagination__item">
              <Button onClick={submitReportRequest} type="button">
                {REPORT_SUBMIT_BUTTON}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReportAsian;
