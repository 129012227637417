import constants from '@lmig-latam/adil-api-common-lib/constants';
import ApprovalPending from '../styles/images/svg/steps/pending-approval.svg';
import ClientPending from '../styles/images/svg/steps/pending-client.svg';
import Finish from '../styles/images/svg/steps/finish.svg';
import ReviewPending from '../styles/images/svg/steps/peding-review.svg';
import localizedStrings from '../utils/localizedStrings';
import { getCountryCode } from '../config/environments/environmentsHelpers';

const {
  SEARCH_BAR_TABS_PENDING_REVIEW,
  SEARCH_BAR_TABS_PENDING_APPROVAL,
  SEARCH_BAR_TABS_FINISHED,
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER,
  SEARCH_BAR_TABS_PENDING_PER_SUPPORT,
  SEARCH_BAR_TABS_SUPPORT_REQUEST,
  INSPECTIONS_TABLE_HEADER_NUMBER,
  INSPECTIONS_TABLE_HEADER_PLATE,
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION,
  INSPECTIONS_TABLE_HEADER_NAME,
  INSPECTIONS_TABLE_HEADER_ASSIGNEE,
  INSPECTIONS_TABLE_HEADER_BROKER_NAME,
  INSPECTIONS_TABLE_HEADER_BROKER_CODE,
  INSPECTIONS_TABLE_HEADER_ACTION,
  INSPECTIONS_TABLE_HEADER_STATUS,
  INSPECTIONS_TABLE_HEADER_CREATED_BY,
  INSPECTIONS_TABLE_HEADER_CREATED_ON,
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
  INSPECTION_SUMMARY_SUBMISSION_ID,
  INSPECTION_SUMMARY_CREATED_BY,
  INSPECTION_SUMMARY_CREATION_DATE,
  INSPECTION_SUMMARY_CUSTOMER_COMPLETION_DATE,
  INSPECTION_SUMMARY_LICENCE_PLATE,
  INSPECTION_SUMMARY_USER_NAME,
  INSPECTION_SUMMARY_POLICY_TYPE,
  INSPECTION_SUMMARY_STATUS,
  INSPECTION_SUMMARY_ID_NUMBER,
  INSPECTION_SUMMARY_ID_TYPE,
  INSPECTION_SUMMARY_COMPLETION_DATE,
  INSPECTIONS_TABLE_HEADER_BUTTON,
  INSPECTIONS_TABLE_REPORT_ADIL_TICKET,
  INSPECTIONS_TABLE_REPORT_STATUS,
  INSPECTIONS_TABLE_REPORT_PLATE_NUMBER,
  INSPECTIONS_TABLE_REPORT_CREATOR_NAME,
  INSPECTIONS_TABLE_REPORT_CREATED_DATE,
  INSPECTIONS_TABLE_REPORT_CUSTOMER_ADDRESS,
  INSPECTIONS_TABLE_REPORT_INSPECTION_USER,
  INSPECTIONS_TABLE_REPORT_INSPECTION_DATE,
  INSPECTIONS_TABLE_REPORT_APPROVED_USER,
  INSPECTIONS_TABLE_REPORT_APPROVED_DATE,
} = localizedStrings;

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_APPROVED,
    STATUS_CUSTOMER_RETAKE,
    STATUS_AGENT_REJECTED,
    STATUS_CUSTOMER_NEW,
    STATUS_CUSTOMER_IN_PROGRESS,
    STATUS_SUPPORT_NEW,
    STATUS_SUPPORT_IN_PROGRESS,
    STATUS_EXPIRED,
    STATUS_SUPPORT_RETAKE,
    STATUS_SUPPORT_AGENT_REVIEW,
    STATUS_SUPPORT_INSPECTOR,
    STATUS_CHANGED_TO_PRESENTIAL,
    STATUS_CANCELLED,
  },
} = constants;

export const TAB_SECTION_NUMBER_LATAM = {
  PENDING_PER_CUSTOMER: 1,
  PENDING_REVIEW: 2,
  PENDING_APPROVAL: 3,
  SUPPORT_REQUEST: 4,
  FINISHED: 5,
};

const arrayLatamTabs = [
  {
    id: 1,
    title: SEARCH_BAR_TABS_PENDING_PER_CUSTOMER,
    statuses: [STATUS_CUSTOMER_NEW, STATUS_CUSTOMER_IN_PROGRESS],
    icon: ClientPending,
    count: 0,
    active: false,
  },
  {
    id: 2,
    title: SEARCH_BAR_TABS_PENDING_REVIEW,
    statuses: [STATUS_AGENT_REVIEW],
    icon: ReviewPending,
    count: 0,
    active: false,
  },
  {
    id: 3,
    title: SEARCH_BAR_TABS_PENDING_APPROVAL,
    statuses: [STATUS_CUSTOMER_RETAKE],
    icon: ApprovalPending,
    count: 0,
    active: false,
  },
  {
    id: 4,
    title: SEARCH_BAR_TABS_SUPPORT_REQUEST,
    statuses: [
      STATUS_SUPPORT_NEW,
      STATUS_SUPPORT_IN_PROGRESS,
      STATUS_SUPPORT_RETAKE,
      STATUS_SUPPORT_INSPECTOR,
    ],
    icon: ClientPending,
    count: 0,
    active: false,
  },
  {
    id: 5,
    title: SEARCH_BAR_TABS_FINISHED,
    statuses: [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_EXPIRED,
      STATUS_CHANGED_TO_PRESENTIAL,
    ],
    icon: Finish,
    count: 0,
    active: false,
  },
];

const arrayAllCountriesTabs = [
  {
    id: 1,
    title: SEARCH_BAR_TABS_PENDING_PER_CUSTOMER,
    statuses: [STATUS_CUSTOMER_NEW, STATUS_CUSTOMER_IN_PROGRESS],
    icon: ClientPending,
    count: 0,
    active: false,
  },
  {
    id: 2,
    title: SEARCH_BAR_TABS_PENDING_REVIEW,
    statuses: [STATUS_AGENT_REVIEW],
    icon: ReviewPending,
    count: 0,
    active: false,
  },
  {
    id: 3,
    title: SEARCH_BAR_TABS_PENDING_APPROVAL,
    statuses: [STATUS_CUSTOMER_RETAKE],
    icon: ApprovalPending,
    count: 0,
    active: false,
  },
  {
    id: 4,
    title: SEARCH_BAR_TABS_FINISHED,
    statuses: [STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED, STATUS_EXPIRED],
    icon: Finish,
    count: 0,
    active: false,
  },
];

const arrayVietnamTabs = [
  {
    id: 1,
    title: SEARCH_BAR_TABS_PENDING_PER_CUSTOMER,
    statuses: [STATUS_CUSTOMER_NEW, STATUS_CUSTOMER_IN_PROGRESS],
    icon: ClientPending,
    count: 0,
    active: false,
  },
  {
    id: 2,
    title: SEARCH_BAR_TABS_PENDING_REVIEW,
    statuses: [STATUS_AGENT_REVIEW],
    icon: ReviewPending,
    count: 0,
    active: false,
  },
  {
    id: 3,
    title: SEARCH_BAR_TABS_PENDING_APPROVAL,
    statuses: [STATUS_CUSTOMER_RETAKE],
    icon: ApprovalPending,
    count: 0,
    active: false,
  },
  {
    id: 4,
    title: SEARCH_BAR_TABS_PENDING_PER_SUPPORT,
    statuses: [
      STATUS_SUPPORT_NEW,
      STATUS_SUPPORT_IN_PROGRESS,
      STATUS_SUPPORT_RETAKE,
      STATUS_SUPPORT_AGENT_REVIEW,
    ],
    icon: ClientPending,
    count: 0,
    active: false,
  },
  {
    id: 5,
    title: SEARCH_BAR_TABS_FINISHED,
    statuses: [
      STATUS_AGENT_APPROVED,
      STATUS_AGENT_REJECTED,
      STATUS_EXPIRED,
      STATUS_CANCELLED,
    ],
    icon: Finish,
    count: 0,
    active: false,
  },
];

const tableHeaders = [
  INSPECTIONS_TABLE_HEADER_NUMBER,
  INSPECTIONS_TABLE_HEADER_CREATED_BY,
  INSPECTIONS_TABLE_HEADER_CREATED_ON,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
  INSPECTIONS_TABLE_HEADER_PLATE,
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION,
  INSPECTIONS_TABLE_HEADER_NAME,
  INSPECTIONS_TABLE_HEADER_ASSIGNEE,
  INSPECTIONS_TABLE_HEADER_BROKER_NAME,
  INSPECTIONS_TABLE_HEADER_BROKER_CODE,
  INSPECTIONS_TABLE_HEADER_STATUS,
  INSPECTIONS_TABLE_HEADER_ACTION,
];

const clTableHeaders = [
  INSPECTIONS_TABLE_HEADER_BUTTON,
  INSPECTIONS_TABLE_HEADER_NUMBER,
  INSPECTIONS_TABLE_HEADER_CREATED_BY,
  INSPECTIONS_TABLE_HEADER_CREATED_ON,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
  INSPECTIONS_TABLE_HEADER_PLATE,
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION,
  INSPECTIONS_TABLE_HEADER_NAME,
  INSPECTIONS_TABLE_HEADER_ASSIGNEE,
  INSPECTIONS_TABLE_HEADER_BROKER_NAME,
  INSPECTIONS_TABLE_HEADER_BROKER_CODE,
  INSPECTIONS_TABLE_HEADER_STATUS,
  INSPECTIONS_TABLE_HEADER_ACTION,
];

const finishedTableHeaders = [
  INSPECTIONS_TABLE_HEADER_NUMBER,
  INSPECTIONS_TABLE_HEADER_PLATE,
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION,
  INSPECTIONS_TABLE_HEADER_NAME,
  INSPECTIONS_TABLE_HEADER_CREATED_ON,
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE,
  INSPECTIONS_TABLE_HEADER_STATUS,
  INSPECTIONS_TABLE_HEADER_ACTION,
];

const vnSummaryTable = [
  {
    label: INSPECTION_SUMMARY_SUBMISSION_ID,
    value: 'submissionId',
  },
  {
    label: INSPECTION_SUMMARY_CREATED_BY,
    value: 'createdBy',
  },
  {
    label: INSPECTION_SUMMARY_CREATION_DATE,
    value: 'creationDate',
  },
  {
    label: INSPECTION_SUMMARY_CUSTOMER_COMPLETION_DATE,
    value: 'customerCompletionDate',
  },
  {
    label: INSPECTION_SUMMARY_COMPLETION_DATE,
    value: 'completionDate',
  },
  {
    label: INSPECTION_SUMMARY_LICENCE_PLATE,
    value: 'licencePlate',
  },
  {
    label: INSPECTION_SUMMARY_USER_NAME,
    value: 'userName',
  },
  {
    label: INSPECTION_SUMMARY_POLICY_TYPE,
    value: 'policyType',
  },
  {
    label: INSPECTIONS_TABLE_HEADER_ASSIGNEE,
    value: 'assignee',
  },
  {
    label: INSPECTION_SUMMARY_STATUS,
    value: 'status',
  },
];

const vnReportTable = [
  {
    label: INSPECTIONS_TABLE_REPORT_ADIL_TICKET,
    value: 'submissionId',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_STATUS,
    value: 'status',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_PLATE_NUMBER,
    value: 'vehicle.licencePlate',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_CREATOR_NAME,
    value: 'infoAgent.nameIntermediaryInsurance',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_CREATED_DATE,
    value: 'emailTokenCreationDate',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_CUSTOMER_ADDRESS,
    value: 'customer.location',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_INSPECTION_USER,
    value: 'assignee',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_INSPECTION_DATE,
    value: 'customerCompletionDate',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_APPROVED_USER,
    value: 'assignee',
  },
  {
    label: INSPECTIONS_TABLE_REPORT_APPROVED_DATE,
    value: 'completionDate',
  },
];

const summaryTable = [
  {
    label: INSPECTION_SUMMARY_SUBMISSION_ID,
    value: 'submissionId',
  },
  {
    label: INSPECTION_SUMMARY_CREATED_BY,
    value: 'createdBy',
  },
  {
    label: INSPECTION_SUMMARY_CREATION_DATE,
    value: 'creationDate',
  },
  {
    label: INSPECTION_SUMMARY_LICENCE_PLATE,
    value: 'licencePlate',
  },
  {
    label: INSPECTION_SUMMARY_ID_TYPE,
    value: 'idType',
  },
  {
    label: INSPECTION_SUMMARY_ID_NUMBER,
    value: 'idNumber',
  },
  {
    label: INSPECTION_SUMMARY_USER_NAME,
    value: 'userName',
  },
  {
    label: INSPECTION_SUMMARY_STATUS,
    value: 'status',
  },
];

export const arrayFiltersConfig = () => {
  switch (getCountryCode()) {
    case 'vn':
      return arrayVietnamTabs;
    case 'th':
      return arrayAllCountriesTabs;
    default:
      return arrayLatamTabs;
  }
};

export const getSearchTableFields = () => {
  switch (getCountryCode()) {
    case 'cl':
      return clTableHeaders;
    default:
      return tableHeaders;
  }
};

export const getFinishedTableFields = () => finishedTableHeaders;

export const getSummaryTableFields = () => {
  switch (getCountryCode()) {
    case 'vn':
      return vnSummaryTable;
    default:
      return summaryTable;
  }
};

export const getReportTableFields = () => {
  switch (getCountryCode()) {
    default:
      return vnReportTable;
  }
};
