import localizedStrings from '../utils/localizedStrings';

const localizedStringsCommonlib = require('@lmig-latam/adil-api-common-lib/utils/localizedStrings');

const {
  VEHICLE_CLASS_MOTORBIKE,
} = localizedStringsCommonlib.getCurrentLanguage();

const {
  INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_REAR_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_ENGINE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTIONE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_VN_ODOMETER_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_VIDEO_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2_DISPLAY_STRING,

  INSPECTION_PHOTO_ACCESSORY_RADIO_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_DVD_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EQUALIZERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ALARM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SUN_ROOF_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FOOTRESTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ROLL_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_DURALINER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HELMET_DISPLAY_STRING,
  INSPECTION_PHOTO_SPARE_WHEEL_DISPLAY_STRING,

  INSPECTION_PHOTO_ACCESSORY_SPOILER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TOW_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SKIRTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FENDER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TIRES_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ROOF_RACK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRUCK_BED_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TWEETER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SLIDER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_WINCH_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_DRAWER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SUSPENSION_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BUMPER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BODY_KIT_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRAILER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MIRRORS_DISPLAY_STRING,

  INSPECTION_PHOTO_MOTORBIKE_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_REAR__DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_ENGINE_DISPLAY_STRING,

  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_TRUNK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PORTER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PROTECTOR_KIT_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_ALARM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_EXPLORERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_HELMET_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_JACKET_DISPLAY_STRING,

  INSPECTION_PHOTO_ACCESSORY_ARMOR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_CD_BOX_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FIXED_DVD_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_WOOFER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_GLASSES_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BULLBAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SUNROOF_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING_DISPLAY_STRING,
} = localizedStrings;

export const photoDisplayStringMapping = {
  // Required photos
  INSPECTION_PHOTO_PROPERTY_CARD_FRONT: INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_PROPERTY_CARD_BACK: INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE: INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_FRONT: INSPECTION_PHOTO_VEHICLE_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_REAR: INSPECTION_PHOTO_VEHICLE_REAR_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE: INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE: INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_ENGINE: INSPECTION_PHOTO_VEHICLE_ENGINE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_DASH: INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_MILEAGE: INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER: INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER: INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE: INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTION: INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTIONE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD: INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE: INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE: INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE: INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE: INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP: INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP: INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_VN_ODOMETER: INSPECTION_PHOTO_VEHICLE_VN_ODOMETER_DISPLAY_STRING,
  INSPECTION_VIDEO_PHOTOS_VEHICLE: INSPECTION_PHOTO_VEHICLE_VIDEO_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT: INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1: INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1_DISPLAY_STRING,
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2: INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2_DISPLAY_STRING,

  // Optional accessory photos
  INSPECTION_PHOTO_ACCESSORY_RADIO: INSPECTION_PHOTO_ACCESSORY_RADIO_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_DVD: INSPECTION_PHOTO_ACCESSORY_DVD_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS: INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS: INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EQUALIZERS: INSPECTION_PHOTO_ACCESSORY_EQUALIZERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA: INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS: INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK: INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ALARM: INSPECTION_PHOTO_ACCESSORY_ALARM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM: INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SUN_ROOF: INSPECTION_PHOTO_ACCESSORY_SUN_ROOF_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS: INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING: INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY: INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING: INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FOOTRESTS: INSPECTION_PHOTO_ACCESSORY_FOOTRESTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ROLL_BAR: INSPECTION_PHOTO_ACCESSORY_ROLL_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL: INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL: INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG: INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS: INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS: INSPECTION_PHOTO_ACCESSORY_EXPLORERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION: INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_DURALINER: INSPECTION_PHOTO_ACCESSORY_DURALINER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HELMET: INSPECTION_PHOTO_ACCESSORY_HELMET_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SPOILER: INSPECTION_PHOTO_ACCESSORY_SPOILER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TOW_BAR: INSPECTION_PHOTO_ACCESSORY_TOW_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR: INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SKIRTS: INSPECTION_PHOTO_ACCESSORY_SKIRTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER: INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FENDER: INSPECTION_PHOTO_ACCESSORY_FENDER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS: INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TIRES: INSPECTION_PHOTO_ACCESSORY_TIRES_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING: INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ROOF_RACK: INSPECTION_PHOTO_ACCESSORY_ROOF_RACK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRUCK_BED: INSPECTION_PHOTO_ACCESSORY_TRUCK_BED_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TWEETER: INSPECTION_PHOTO_ACCESSORY_TWEETER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SLIDER: INSPECTION_PHOTO_ACCESSORY_SLIDER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS: INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR: INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE: INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK: INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_WINCH: INSPECTION_PHOTO_ACCESSORY_WINCH_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER: INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_DRAWER: INSPECTION_PHOTO_ACCESSORY_DRAWER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SUSPENSION: INSPECTION_PHOTO_ACCESSORY_SUSPENSION_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BUMPER: INSPECTION_PHOTO_ACCESSORY_BUMPER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE: INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN: INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL: INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BODY_KIT: INSPECTION_PHOTO_ACCESSORY_BODY_KIT_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY: INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_TRAILER: INSPECTION_PHOTO_ACCESSORY_TRAILER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_MIRRORS: INSPECTION_PHOTO_ACCESSORY_MIRRORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ARMOR: INSPECTION_PHOTO_ACCESSORY_ARMOR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_CD_BOX: INSPECTION_PHOTO_ACCESSORY_CD_BOX_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_FIXED_DVD: INSPECTION_PHOTO_ACCESSORY_FIXED_DVD_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT: INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE: INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_WOOFER: INSPECTION_PHOTO_ACCESSORY_WOOFER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS: INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS: INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS: INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY: INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM: INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_GLASSES: INSPECTION_PHOTO_ACCESSORY_GLASSES_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BULLBAR: INSPECTION_PHOTO_ACCESSORY_BULLBAR_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_SUNROOF: INSPECTION_PHOTO_ACCESSORY_SUNROOF_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING: INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING_DISPLAY_STRING,

  // Conditional Required photos
  INSPECTION_PHOTO_SPARE_WHEEL: INSPECTION_PHOTO_SPARE_WHEEL_DISPLAY_STRING,
};

export const photoDisplayMotorbikeStringMapping = {
  INSPECTION_PHOTO_PROPERTY_CARD_FRONT: INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_PROPERTY_CARD_BACK: INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_NUMBER_PLATE: INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_FRONT: INSPECTION_PHOTO_MOTORBIKE_FRONT_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_REAR: INSPECTION_PHOTO_MOTORBIKE_REAR__DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_RIGHT_SIDE: INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_LEFT_SIDE: INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_ENGINE: INSPECTION_PHOTO_MOTORBIKE_ENGINE_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_DASH: INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_MILEAGE: INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_SERIAL_NUMBER: INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING,
  INSPECTION_PHOTO_MOTORBIKE_CHASSIS_NUMBER: INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING,

  INSPECTION_PHOTO_ACCESSORY_TRUNK: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_TRUNK_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PORTER: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PORTER_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_PROTECTOR_KIT: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PROTECTOR_KIT_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_ALARM: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_ALARM_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_EXPLORERS_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_HELMET: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_HELMET_DISPLAY_STRING,
  INSPECTION_PHOTO_ACCESSORY_JACKET: INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_JACKET_DISPLAY_STRING,
};

export const getPhotoDisplayStringFromId = (vehicleClass, id) => {
  let displayString = photoDisplayStringMapping[id];
  if (vehicleClass === VEHICLE_CLASS_MOTORBIKE) {
    displayString = photoDisplayMotorbikeStringMapping[id];
  }

  if (!displayString) {
    return '???';
  }

  return displayString;
};

export default getPhotoDisplayStringFromId;
