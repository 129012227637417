import { GALLERY_PHOTO_TYPE } from '../../constants/inspectionInform';
import {
  ADD_INSPECTION_DATA_ORIGINAL,
  ADD_INSPECTION_INFORM_STEP_1,
  ADD_INSPECTION_INFORM_STEP_2_VEHICLE_STEP,
  ADD_VIDEO_CAPTURE_TO_GALLERY,
  ON_LOADING_GALLERY,
  SET_ALL_PHOTOS_TO_GALLERY,
} from './constants';

export const initialState = {
  inspectionOriginal: {},
  steeper: {},
  loadingGallery: false,
  photos: [],
};

const addVideoCaptureToGalleryHandler = (state, action) => {
  const { photo } = action.payload;
  const allCaptures = state?.photos.filter(
    photo => photo.type === GALLERY_PHOTO_TYPE.VIDEO_CAPTURE,
  );
  const photoName = `${photo.name}.jpg`;

  if (allCaptures.length > 0) {
    const isExist = allCaptures.find(item => photoName.includes(item.name));
    if (isExist) return state.photos;
  }

  const newPhoto = {
    ...photo,
    label: `Foto ${allCaptures.length + 1} Video`,
    type: GALLERY_PHOTO_TYPE.VIDEO_CAPTURE,
  };

  const video = state.photos.find(
    item => item.type === GALLERY_PHOTO_TYPE.VIDEO,
  );

  const videoRecordsUpdated = video.photos.map(record =>
    photo.name.includes(record.name) ? { ...record, isAdded: true } : record,
  );

  video.photos = videoRecordsUpdated;
  const photosUpdated = state.photos.map(item =>
    item.type === GALLERY_PHOTO_TYPE.VIDEO
      ? { ...item, photos: videoRecordsUpdated }
      : item,
  );
  return [...photosUpdated, newPhoto];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INSPECTION_DATA_ORIGINAL:
      return {
        ...state,
        inspectionOriginal: action.payload,
      };

    case ADD_INSPECTION_INFORM_STEP_1:
      return {
        ...state,
        steeper: {
          ...state.steeper,
          step1: action.payload,
        },
      };

    case ADD_INSPECTION_INFORM_STEP_2_VEHICLE_STEP:
      return {
        ...state,
        steeper: {
          ...state.steeper,
          step2: {
            ...state.steeper.step2,
            step2Vehicle: action.payload,
          },
        },
      };

    case SET_ALL_PHOTOS_TO_GALLERY:
      return { ...state, photos: action.payload.photos };

    case ADD_VIDEO_CAPTURE_TO_GALLERY: {
      const allPhotos = addVideoCaptureToGalleryHandler(state, action);
      return { ...state, photos: allPhotos };
    }

    case ON_LOADING_GALLERY:
      return { ...state, loadingGallery: action.payload.isLoading };
    default:
      return state;
  }
};
