import React from 'react';
import Loader from './MainLoaderCircle';
import { LibertyIcon } from '../Icons';
import './styles.scss';

export const MainLoader = () => (
  <div className="overlay-main-loader">
    <Loader className="position-absolute" />
    <LibertyIcon />
  </div>
);
