import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '../Icons/ArrowIcon/ArrowIcon';
import IconCheck from '../../widgets/InspecionInform/icon/IconCheck';
import './styles.scss';
/**
 * @param {string} title
 * @param {number}  number
 * @param {boolean} isValid
 * @param {React.ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */
export default function CollapsableItem({ title, number, isValid, children }) {
  /**
   * @param {boolean}
   */
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="position:relative collapsable-item">
      <div
        className={`position:absolute top:1.7rem left:0.45rem z-index:9 h:115% w:0.5rem ${
          isValid ? 'bg:#99E5EA' : 'bg:#C5C5C5'
        }`}
      />

      <div className="flex flex:row gap:0.5rem align-items:center">
        <IconCheck isCheck={isValid} />

        <div
          role="button"
          className="flex flex:1 align-items:center justify-content:center bg:#99E5EA"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="px:1rem">
            <div className="flex align-items:center justify-content:center bg:white w:2rem h:2rem r:50% color:black">
              <span className="font-weight:normal font-size:1rem">
                {number}
              </span>
            </div>
          </div>
          <h2 className="flex flex:1 flex:wrap px:1rem title-section">
            {title}
          </h2>
          <div className="pr:1rem pt:0.5rem">
            <ArrowIcon
              direction={isOpen ? 'top' : 'bottom'}
              width={28}
              height={24}
              className=""
              color="gray"
              circle={false}
            />
          </div>
        </div>
      </div>

      <div className={isOpen ? 'flex flex:1 pl:2rem py:1rem' : 'display:none'}>
        {children}
      </div>
    </div>
  );
}

CollapsableItem.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
