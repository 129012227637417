import { BodyText, Content, Heading } from '@lmig/lmds-react';
import { IconErrorAlert } from '@lmig/lmds-react/icons';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SettingsActions } from '../../actions';
import { AppFooter, AppHeader, CircleImage, SideBar } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const { ERROR_MESSAGE_TITLE, ERROR_MESSAGE_SUBTITLE } = localizedStrings;

const GenericError = ({ settingsActions: { hideAppLoader } }) => {
  const {
    state: {
      title = ERROR_MESSAGE_TITLE,
      subtitle = ERROR_MESSAGE_SUBTITLE,
    } = {},
  } = useLocation();

  // Hide App Loader
  // (In case it was displayed prior to navigating here)
  hideAppLoader();

  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <Content className="adil-content generic-error-content">
          <CircleImage>
            <IconErrorAlert size="64" />
          </CircleImage>
          <Heading type="h4-light" className="auto-left-right-margin">
            {title}
          </Heading>
          <BodyText className="gray-text">{subtitle}</BodyText>
          {renderFooterSection()}
        </Content>
      </SideBar>
    </div>
  );
};

GenericError.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(GenericError);
