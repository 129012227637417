import { Link } from '@lmig/lmds-react';
import { IconMyAccount, IconPowerOn } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CircleImage } from '..';
import { userPropType } from '../../prop-types';
import localizedStrings from '../../utils/localizedStrings';
import {
  getCountryCode,
  PropertyTypes as EnvironmentPropertyTypes,
} from '../../config/environments/environmentsHelpers';

import './styles.scss';

const {
  APP_HEADER_USER_SECTION_LOGOUT,
  OPERATING_COUNTRY_IMAGE_ALT_TEXT,
} = localizedStrings;

const AppHeaderUserSection = ({ className, user, onLogout, environment }) => {
  const { name } = user;
  const { countryFlag } = environment[getCountryCode()];

  return (
    <div className={className}>
      {countryFlag && (
        <div className="country-code-image">
          <img
            src={countryFlag}
            className="app-header-image, flag-border"
            alt={OPERATING_COUNTRY_IMAGE_ALT_TEXT}
          />
        </div>
      )}

      <CircleImage size="extra-small">
        <IconMyAccount size="16" />
      </CircleImage>

      <span>{name}</span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        caretPosition="none"
        onClick={onLogout}
        variant="standalone"
        onBackground="yellow"
      >
        <IconPowerOn
          size="24"
          style={{
            marginRight: '0.5rem',
          }}
        />
        {APP_HEADER_USER_SECTION_LOGOUT}
      </Link>
    </div>
  );
};

AppHeaderUserSection.propTypes = {
  className: PropTypes.string,
  user: userPropType.isRequired,
  onLogout: PropTypes.func.isRequired,
  environment: EnvironmentPropertyTypes.isRequired,
};

AppHeaderUserSection.defaultProps = {
  className: 'app-header-user-section',
};

const mapStateToProps = ({ settings: { environment } }) => ({
  environment,
});

export default connect(mapStateToProps, null)(AppHeaderUserSection);
