import { Button, Heading, IconButton } from '@lmig/lmds-react';
import { IconCheckmark, IconClose } from '@lmig/lmds-react/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { CircleImage } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  INSPECTION_NEW_CUSTOMER_MESSAGE_TITLE,
  INSPECTION_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE,
  INSPECTION_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER,
  INSPECTION_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS,
  INSPECTION_NEW_CUSTOMER_MESSAGE_LOADING,
  INSPECTION_NEW_CUSTOMER_MESSAGE_CLOSE,
} = localizedStrings;

const CustomerRetakeModal = ({
  handleSendComments,
  addNewCustomerMessage,
  closeButtonHandler,
  showModalInlineLoader,
  isOpen,
  showMessageSuccess,
}) => {
  const [currentMessage, setCurrentMessage] = useState('');

  const updateCurrentMessage = event => {
    setCurrentMessage(event.target.value);
  };

  const addCurrentMessage = () => {
    addNewCustomerMessage(currentMessage);
    handleSendComments(currentMessage);
    setCurrentMessage('');
  };

  const renderMessageSend = () => (
    <div className="modal-content">
      <div className="customer-retake-modal-image-close-button">
        <IconButton onClick={closeButtonHandler}>
          <IconClose size="16" />
        </IconButton>
      </div>
      <Heading type="h3-light" className="auto-left-right-margin">
        {INSPECTION_NEW_CUSTOMER_MESSAGE_TITLE}
      </Heading>
      <textarea
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        className="new-customer-message-text-area"
        onChange={updateCurrentMessage}
        placeholder={INSPECTION_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER}
        value={currentMessage}
        maxLength="1000"
      />
      <Button onClick={addCurrentMessage} disabled={currentMessage.length < 1}>
        {showModalInlineLoader
          ? INSPECTION_NEW_CUSTOMER_MESSAGE_LOADING
          : INSPECTION_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS}
      </Button>
    </div>
  );

  const renderMessageSuccess = () => (
    <div className="modal-content">
      <div className="customer-retake-modal-image-close-button">
        <IconButton onClick={closeButtonHandler}>
          <IconClose size="16" />
        </IconButton>
      </div>
      <CircleImage>
        <IconCheckmark size="64" />
      </CircleImage>
      <Heading type="h3-light" className="auto-left-right-margin">
        {INSPECTION_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE}
      </Heading>
      <Button onClick={closeButtonHandler}>
        {INSPECTION_NEW_CUSTOMER_MESSAGE_CLOSE}
      </Button>
    </div>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeButtonHandler}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      {showMessageSuccess ? renderMessageSuccess() : renderMessageSend()}
    </ReactModal>
  );
};

CustomerRetakeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeButtonHandler: PropTypes.func.isRequired,
  addNewCustomerMessage: PropTypes.func.isRequired,
  handleSendComments: PropTypes.func.isRequired,
  showModalInlineLoader: PropTypes.bool.isRequired,
  showMessageSuccess: PropTypes.bool.isRequired,
};

export default CustomerRetakeModal;
