import { GridRow, Button, Caption } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { getAssigneeDisplayName } from '../../utils';
import { Panel } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON,
  INSPECTION_ASSIGNEE_TITLE,
} = localizedStrings;

const InspectionAssignee = ({ assignee, assignInspection, assignDisabled }) => (
  <Panel className="assignee-section">
    <GridRow vAlign="middle">
      <Caption className="assignee-text assignee-text-title">
        {INSPECTION_ASSIGNEE_TITLE}
      </Caption>
      <Caption className="assignee-text">
        {getAssigneeDisplayName(assignee)}
      </Caption>
      <Button
        type="button"
        level="primary"
        onClick={assignInspection}
        className={`assignee-button ${assignDisabled ? 'button-disabled' : ''}`}
        disabled={assignDisabled}
      >
        <span>{INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON}</span>
      </Button>
    </GridRow>
  </Panel>
);

InspectionAssignee.propTypes = {
  assignDisabled: PropTypes.bool.isRequired,
  assignInspection: PropTypes.func.isRequired,
  assignee: PropTypes.string,
};

InspectionAssignee.defaultProps = {
  assignee: null,
};

export default InspectionAssignee;
