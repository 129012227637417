import React from 'react';
import './styles.scss';

const SaveAndExitIcon = () => (
  <svg className="inspection-buttons-save-and-exit-icon">
    <g stroke="none" strokeWidth="1">
      <g transform="translate(-476.000000, -2118.000000)">
        <g transform="translate(214.000000, 2094.000000)">
          <g transform="translate(16.000000, 15.000000)">
            <g transform="translate(238.000000, 0.000000)">
              <g transform="translate(8.000000, 9.000000)">
                <g transform="translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) ">
                  <path d="M11.624989,13.5 C11.8320941,13.5 11.9999648,13.6679062 12,13.8749766 L12,16.4999883 C12,17.3284453 11.3284115,18 10.4999912,18 L1.50000879,18 C0.671588532,18 5.32907052e-15,17.3284453 5.32907052e-15,16.5000234 L5.32907052e-15,1.50001172 C5.32907052e-15,0.671589844 0.671588532,0 1.50000879,0 L10.4999912,0 C11.3284115,0 11.9999648,0.671589844 12,1.50001172 L12,4.12502344 C12,4.33212891 11.8320941,4.50003516 11.624989,4.50003516 L10.8750022,4.50003516 C10.6678971,4.50003516 10.4999912,4.33212891 10.4999912,4.12502344 L10.4999912,1.50001172 L1.50000879,1.50001172 L1.50000879,16.5000234 L10.4999912,16.5000234 L10.4999912,13.8750117 C10.4999912,13.6679062 10.6678971,13.5 10.8750022,13.5 L11.624989,13.5 Z M17.8739271,9.04671273 C17.9544271,9.12039317 18,9.22444265 18,9.33345033 C18,9.44245802 17.9544271,9.54650749 17.8739271,9.62018793 L13.2072604,13.8978463 C13.1339792,13.9646728 13.0398073,14 12.9444688,14 C12.8916771,14 12.8381198,13.9893909 12.7879896,13.9669696 C12.6467135,13.9051013 12.5555677,13.7653236 12.5555677,13.6111452 L12.5555677,12.833399 C12.5555677,12.7232612 12.6022708,12.6180815 12.6843021,12.5444011 L15.387724,10.1112329 L4.38890104,10.1112329 C4.17394271,10.1112329 4,9.93729457 4,9.72234162 L4,8.9445955 C4,8.72964255 4.17394271,8.55570421 4.38890104,8.55570421 L15.387724,8.55570421 L12.6843021,6.12253604 C12.6022708,6.0488556 12.5555677,5.94367594 12.5555677,5.83353808 L12.5555677,5.0557555 C12.5555677,4.90157707 12.6467135,4.76183578 12.7879896,4.69993109 C12.9281354,4.63689621 13.0933281,4.66536945 13.2072604,4.76905435 L17.8739271,9.04671273 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SaveAndExitIcon;
