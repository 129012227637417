import React from 'react';
import PropTypes from 'prop-types';

export const TrashIcon = ({ width, height, background, hidden, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    cursor="pointer"
    style={{ display: hidden && 'none' }}
    {...props}
  >
    <circle cx="16" cy="16" r="16" fill={background} />
    <g transform="translate(10.25 8.002)">
      <g transform="translate(-0.25 -0.002)">
        <path
          d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
          fill="none"
        />
        <path
          d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
          fill="none"
        />
        <path
          d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
          fill="none"
        />
        <path
          d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
          fill="none"
        />
        <path
          d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
          fill="none"
        />
        <path
          d="M2.917,16a1.856,1.856,0,0,1-1.854-1.854v-9.4h1v9.4A.854.854,0,0,0,2.917,15H9.582a.854.854,0,0,0,.855-.854v-9.4h1v9.4A1.857,1.857,0,0,1,9.582,16Zm5.275-2.858v-8.4h1v8.4Zm-2.442,0v-8.4h1v8.4Zm-2.441,0v-8.4h1v8.4ZM0,3.361v-1H12.5v1ZM7.313,1.884V.951L5.139,1l.049.884h-1V.951A.952.952,0,0,1,5.139,0H7.361a.952.952,0,0,1,.951.951v.932Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

TrashIcon.defaultProps = {
  width: 40,
  height: 40,
  background: '#2a2a2a3d',
};

TrashIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  hidden: PropTypes.bool.isRequired,
};
