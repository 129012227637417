import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useControls } from 'react-zoom-pan-pinch';
import { GALLERY_OPTIONS } from '../../Stepper/Stepper';
import { useBrigthness } from '../../../hooks/useBrigthness';
import { Brigthness } from '../Brigthness/Brigthness';
import { MenuGalleryIcon } from './MenuGalleryIcon';
import {
  FullscreenIcon,
  TrashIcon,
  DownloadIcon,
  RetryIcon,
  RotationArrowIcon,
} from '../../Icons';
import { ZoomInIcon } from '../../Icons/ZoomIn';
import { ZoomOutIcon } from '../../Icons/ZoomOutIcon';
import ZoomResetIcon from '../../Icons/ZoomResetIcon';
import './styles.scss';

export const GalleryOptions = ({
  onManageGalleryOption,
  currentPhoto,
  hasPhotoHistory,
  setBrigthnessValue,
  brigthnessRef,
  resetBrigthness,
  handleFullscreen,
  galleryClassName,
  enabledOptions,
}) => {
  const [isOpenMenuOptions, setIsOpenMenuOptions] = useState(false);

  const { zoomIn, zoomOut, resetTransform } = useControls();

  const {
    brigthnessOpen,
    changeValueBrigthness,
    handleShowBrightnessBar,
    valueBrigthness,
    resetBrigthnessValue,
  } = useBrigthness(brigthnessRef);

  const onZoomHandler = event => {
    event.preventDefault();

    const { id } = event.target;

    switch (id) {
      case 'zoomIn':
        zoomIn();
        break;
      case 'zoomOut':
        zoomOut();
        break;
      case 'zoomReset':
        resetTransform();
        break;
      default:
    }
  };

  const isEnableOption = option => enabledOptions.some(op => op === option);

  const renderMenuIcons = () => (
    <>
      <TrashIcon hidden={!isEnableOption('trash')} />

      <RotationArrowIcon
        direction="left"
        hidden={!isEnableOption('rotation')}
        onClick={() => {
          onManageGalleryOption(GALLERY_OPTIONS.RIGHT_ROTATION);
        }}
      />

      <RotationArrowIcon
        direction="right"
        hidden={!isEnableOption('rotation')}
        onClick={() => {
          onManageGalleryOption(GALLERY_OPTIONS.LEFT_ROTATION);
        }}
      />

      <FullscreenIcon
        onClick={() => handleFullscreen?.enter()}
        hidden={!isEnableOption('fullscreen')}
      />

      <DownloadIcon
        href={currentPhoto.url}
        hidden={!isEnableOption('download')}
      />

      <Brigthness
        hidden={!isEnableOption('brigthness')}
        brigthnessRef={brigthnessRef}
        brigthnessOpen={brigthnessOpen}
        valueBrigthness={valueBrigthness}
        handleShowBrightnessBar={handleShowBrightnessBar}
        changeValueBrigthness={changeValueBrigthness}
      />

      <ZoomInIcon
        id="zoomIn"
        onClick={onZoomHandler}
        hidden={!isEnableOption('zoom')}
      />

      <ZoomOutIcon
        id="zoomOut"
        onClick={onZoomHandler}
        hidden={!isEnableOption('zoom')}
      />

      <ZoomResetIcon
        id="zoomReset"
        hidden={!isEnableOption('zoom')}
        onClick={onZoomHandler}
      />

      {hasPhotoHistory && (
        <RetryIcon
          onClick={() => {
            onManageGalleryOption(GALLERY_OPTIONS.PHOTO_HISTORY);
          }}
        />
      )}
    </>
  );

  useEffect(() => {
    setBrigthnessValue(valueBrigthness);
  }, [valueBrigthness, setBrigthnessValue]);

  useEffect(() => {
    if (resetBrigthness) resetBrigthnessValue();
  }, [resetBrigthness, resetBrigthnessValue]);

  return (
    <div className={['gallery-options', galleryClassName].join(' ')}>
      <div className="gallery-options__container">
        <MenuGalleryIcon
          isOpen={isOpenMenuOptions}
          onPress={setIsOpenMenuOptions}
        />

        {isOpenMenuOptions && renderMenuIcons()}
      </div>
    </div>
  );
};

GalleryOptions.defaultProps = {
  enabledOptions: [],
  galleryClassName: undefined,
};

GalleryOptions.propTypes = {
  galleryClassName: PropTypes.string,
  hasPhotoHistory: PropTypes.bool.isRequired,
  handleFullscreen: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    enter: PropTypes.func.isRequired,
  }).isRequired,
  currentPhoto: PropTypes.shape({ url: PropTypes.string.isRequired })
    .isRequired,
  onManageGalleryOption: PropTypes.func.isRequired,
  setBrigthnessValue: PropTypes.func.isRequired,
  brigthnessRef: PropTypes.shape({}).isRequired,
  resetBrigthness: PropTypes.bool.isRequired,
  enabledOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
};
