import React from 'react';
import PropTypes from 'prop-types';

export const PauseIcon = ({ width, height, ...props }) => (
  <svg
    fill="#FFF"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 1920 1920"
    {...props}
  >
    <path
      d="M754.571 0v1920H206V0h548.571Zm960 0v1920H1166V0h548.571Z"
      fillRule="evenodd"
    />
  </svg>
);

PauseIcon.defaultProps = {
  width: 80,
  height: 80,
};

PauseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
