import React, { useEffect } from 'react';
import * as propTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CardGlobalUse } from '../CardGlobal';
import { CorredorBox } from './CorredorBox';
import './styles.scss';
import { InspectorBox } from './InspectorBox';
import { InspectionBox } from './InspectionBox';
import { prefillDataIntoInform } from '../../utils';
import { Panel } from '..';
import localizedStrings from '../../utils/localizedStrings';
import { inspectionInformActions } from '../../redux/inspection-inform';

const {
  MODAL_PRIMARY_BUTTON_LABEL,
  INSPECTION_RETURN_BUTTON,
  INSPECTION_INFORM_DATA_OPERATOR,
  INSPECTION_INFORM_DATA_INSPECTOR,
  INSPECTION_INFORM_DATA_INSPECTION,
} = localizedStrings;

/** @returns {[boolean, string | null]} */
const verifyInspectionLocal = () => {
  // @type {string | null} Return null if the key doesn't exist in the Storage
  const verifyDataLocalstorage = localStorage.getItem('inspectionInformData');
  const dataInLocal = verifyDataLocalstorage !== null;

  return [dataInLocal, verifyDataLocalstorage];
};

const getStorageFormOrBuildPrefill = inspectionData => {
  const [dataInLocal, verifyDataLocalstorage] = verifyInspectionLocal();
  // Load the information of saved form
  if (dataInLocal) {
    const prefilledData = JSON.parse(verifyDataLocalstorage);
    // Verify if the information of the inspection has been changed
    if (prefilledData.id !== inspectionData.id) {
      // @type {InspectionFormInput} Overwrite the form with the new inspection data
      const prefillData = prefillDataIntoInform(inspectionData || {});
      localStorage.setItem('inspectionInformData', JSON.stringify(prefillData));
    }

    return prefilledData;
  }

  // @type {InspectionFormInput} If the data doesn't exist in the Storage, create the form with the information of inspection
  const prefillData = prefillDataIntoInform(inspectionData || {});
  localStorage.setItem('inspectionInformData', JSON.stringify(prefillData));
  return prefillData;
};

/**
 *
 * @param {import('../../types/InspectionsInform/InspectionData').InspectionData} inspectionData
 * @param {(inspectionForm: InspectionFormInput) => void} onContinue Call when the form of this component is valid
 * @param {() => void} onCancel Call when the user press the cancel button of this form. This property is optional.
 * @return {JSX.Element}
 * @constructor
 */
const SteeperScreen1 = ({ inspectionData, onContinue, onCancel }) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: getStorageFormOrBuildPrefill(inspectionData),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      inspectionInformActions.addInspectionDataOriginalRedux(inspectionData),
    );
  }, [inspectionData, dispatch]);

  const submit = () => {
    if (
      methods.formState.isValid ||
      // The default value not trigger the validation of form until
      // interaction of user.
      Object.keys(methods.formState.errors).length === 0
    ) {
      // @type {InspectionFormInput}
      const formData = methods.getValues();
      formData.id = inspectionData.id;
      localStorage.setItem('inspectionInformData', JSON.stringify(formData));
      dispatch(inspectionInformActions.addStep1Redux(formData));
      // The responsibility of review if the part 1 of form is correct
      // is of this component.
      onContinue(formData);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <div className="content">
          <CardGlobalUse
            indexCard={1}
            titleCard={INSPECTION_INFORM_DATA_OPERATOR}
          >
            <CorredorBox />
          </CardGlobalUse>
          <CardGlobalUse
            indexCard={2}
            titleCard={INSPECTION_INFORM_DATA_INSPECTOR}
          >
            <InspectorBox />
          </CardGlobalUse>
          <CardGlobalUse
            indexCard={3}
            titleCard={INSPECTION_INFORM_DATA_INSPECTION}
          >
            <InspectionBox />
          </CardGlobalUse>
        </div>

        <Panel className="adil-report-panel">
          <Button onClick={onCancel} level="secondary">
            {INSPECTION_RETURN_BUTTON}
          </Button>
          <Button type="submit" level="secondary">
            {MODAL_PRIMARY_BUTTON_LABEL}
          </Button>
        </Panel>
      </form>
    </FormProvider>
  );
};
export default SteeperScreen1;

SteeperScreen1.propTypes = {
  onCancel: propTypes.func,
  onContinue: propTypes.func,
  inspectionData: propTypes.shape({
    id: PropTypes.string,
  }),
};
SteeperScreen1.defaultProps = {
  inspectionData: {
    id: 'ad-12212s-',
    corredorName: 'Juan Diego',
    corredorCode: '1542115',
    userInspector: 'Inspector info',
    inspectorName: 'name inspector',
    supplierInspector: 'supplier',
    nameCustomer: 'Client custom',
    inspectionNumber: '154241',
    emailCustomer: 'fandino@gmail.com',
    plateInspection: '124sdff',
    vehicleType: 'vehicletpype',
    inspectionModule: 'asdasd',
    inspectionPlace: 'cali',
    inspectionDate: 'asdasd',
    inspectionCityRegion: 'cali',
  },
  onCancel: () => {
    // Empty function
  },
  onContinue: () => {
    // Empty function
  },
};
