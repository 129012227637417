import { combineReducers } from 'redux';
import settingsReducer from '../../reducers/settingsReducer';
import userReducer from '../../reducers/userReducer';
import { currentInspectionReducer } from '../../redux/current-inspection';
import { collapsableReducer } from '../../redux/collapsables';
import { SearchReducer } from '../../redux/search-inspection';
import { supportRequestReducer } from '../../redux/support-request';
import { inspectionInformReducer } from '../../redux/inspection-inform';
import { partsAndPiecesReducer } from '../../redux/parts-and-pieces-admin';

export default combineReducers({
  settings: settingsReducer,
  user: userReducer,
  searchFilter: SearchReducer,
  supportRequestAdmin: supportRequestReducer,
  currentInspection: currentInspectionReducer,
  collapsable: collapsableReducer,
  inspectionInform: inspectionInformReducer,
  partsAndPiecesAdmin: partsAndPiecesReducer,
});
