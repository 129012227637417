import {
  ADD_INSPECTION_DATA_ORIGINAL,
  ADD_INSPECTION_INFORM_STEP_1,
  ADD_INSPECTION_INFORM_STEP_2_VEHICLE_STEP,
  ADD_VIDEO_CAPTURE_TO_GALLERY,
  ON_LOADING_GALLERY,
  SET_ALL_PHOTOS_TO_GALLERY,
} from './constants';

const addInspectionDataOriginalRedux = inspectionData => ({
  type: ADD_INSPECTION_DATA_ORIGINAL,
  payload: inspectionData,
});

const addStep1Redux = Step1 => ({
  type: ADD_INSPECTION_INFORM_STEP_1,
  payload: Step1,
});

const addStep2VehicleRedux = step2Vehicle => ({
  type: ADD_INSPECTION_INFORM_STEP_2_VEHICLE_STEP,
  payload: step2Vehicle,
});

const setAllPhotosToGallery = photos => ({
  type: SET_ALL_PHOTOS_TO_GALLERY,
  payload: { photos },
});

const addVideoCaptureToGallery = photo => ({
  type: ADD_VIDEO_CAPTURE_TO_GALLERY,
  payload: { photo },
});

const onLoadingGallery = isLoading => ({
  type: ON_LOADING_GALLERY,
  payload: { isLoading },
});

export default {
  addInspectionDataOriginalRedux,
  addStep1Redux,
  addStep2VehicleRedux,
  setAllPhotosToGallery,
  onLoadingGallery,
  addVideoCaptureToGallery,
};
