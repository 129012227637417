import constants from '@lmig-latam/adil-api-common-lib/constants';

const {
  LANGUAGES: { VN },
} = constants;

const code = VN;

const strings = {
  BROWSER_TAB_TITLE: 'Giám định - Bảo hiểm Liberty',
  LIBERTY_LOGO_IMAGE_ALT_TEXT: '',
  VIGILADO_IMAGE_ALT_TEXT: '',

  APP_HEADER_TITLE: 'Hạng mục Giám định Tích hợp',
  APP_HEADER_USER_SECTION_LOGOUT: 'Đăng xuất',
  OPERATING_COUNTRY_IMAGE_ALT_TEXT: 'Tiếng Anh',

  SIDEBAR_ITEM_1: 'Giám định',
  SIDEBAR_ITEM_2: 'Tài khoản Quản lý',
  SIDEBAR_ITEM_3: 'Đổi Mật khẩu',
  SIDEBAR_ITEM_4: 'Thiết lập Giám định',
  SIDEBAR_ITEM_5: 'Báo cáo Giám định',
  SIDEBAR_ITEM_6: 'quản trị viên',

  FORM_INFORM_ANALYSIS_CORREDOR_NAME: 'Tên người môi giới',
  FORM_INFORM_ANALYSIS_CORREDOR_CODE: 'mã hành lang',
  FORM_INFORM_ANALYSIS_INSPECTOR_USER: 'người dùng thanh trar',
  FORM_INFORM_ANALYSIS_INSPECTOR_NAME: 'tên thanh tra',
  FORM_INFORM_ANALYSIS_INSPECTOR_SUPPLIER: 'nhà cung cấp',
  FORM_INFORM_ANALYSIS_INSPECTION_NAME_CUSTOMER: 'Tên khách hàng',
  FORM_INFORM_ANALYSIS_INSPECTION_NUMBER: 'số kiểm tra',
  FORM_INFORM_ANALYSIS_INSPECTION_EMAIL_CUSTOMER: 'Email của khách hàng',
  FORM_INFORM_ANALYSIS_INSPECTION_PLATE: 'Bằng sáng chế',
  FORM_INFORM_ANALYSIS_INSPECTION_VEHICULE_TYPE: 'Loại phương tiệne',
  FORM_INFORM_ANALYSIS_INSPECTION_MODULE: 'Mô hình kiểm tra',
  FORM_INFORM_ANALYSIS_INSPECTION_PLACE: 'Nơi kiểm tra',
  FORM_INFORM_ANALYSIS_INSPECTION_DATE: 'Ngày kiểm tra',
  FORM_INFORM_ANALYSIS_INSPECTION_CITY_OR_REGION: 'Thành phố hoặc khu vực',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS: 'Không có ký tự đặc biệt hoặc số được hiển thị',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS_ONLY: 'Không có ký tự đặc biệt nào được hiển thị',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_NUMBER_ONLY: 'Chỉ chấp nhận ký tự số',
  FORM_INFORM_ANALYSIS_ERROR_MESSAGE_REQUIRED: 'Trường này là bắt buộc',

  SUPPORT_TITTLE_PAGE: 'quản trị viên',
  SUPPORT_SUBTITTLE_PAGE: 'Chọn tùy chọn bạn muốn quản lý.',
  ADMIN_SUPPORT_PAGE_TROUBLES: 'Trình quản lý yêu cầu hỗ trợ - Sự cố',
  ADMIN_SUPPORT_PAGE_SOLUTIONS: 'Trình quản lý yêu cầu hỗ trợ - Giải pháp',
  ADMIN_PIECES_MANTEINER: '',
  ADMIN_PARTS_MANTEINER: '',
  ADMIN_SUPPORT_ADD: 'CỘNG',
  ADMIN_SUPPORT_MODIFY: 'SỬA ĐỔI',
  ADMIN_SUPPORT_BUTTON_VISUALIZE: 'ĐÁNH GIÁ',
  ADMIN_SUPPORT_ADD_TROUBLE: 'Thêm sự cố',
  ADMIN_SUPPORT_ADD_PIECE: '',
  ADMIN_SUPPORT_ADD_PART: '',
  ADMIN_SUPPORT_SELECT_PART: '',
  ADMIN_SUPPORT_ADD_TROUBLE_SORT: 'Thêm đơn hàng',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE: 'Trạng thái',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_OPTION: 'Bật',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_OPTION: 'Tắt',
  ADMIN_SUPPORT_ADD_TROUBLE_ACTIVE_RESULT: 'Hoạt động',
  ADMIN_SUPPORT_ADD_TROUBLE_INACTIVE_RESULT: 'Không hoạt động',
  ADMIN_SUPPORT_ADD_SOLUTION: 'Thêm giải pháp',
  ADMIN_SUPPORT_TABLE_HEAD_1: 'Vấn đề',
  ADMIN_SUPPORT_TABLE_HEAD_1_SOLUTIONS: 'Các giải pháp',
  ADMIN_SUPPORT_TABLE_HEAD_2: 'Đặt hàng',
  ADMIN_SUPPORT_TABLE_HEAD_3: 'Trạng thái',
  ADMIN_SUPPORT_TABLE_HEAD_4: 'Quản trị viên',
  ADMIN_SUPPORT_TABLE_HEAD_5: 'Ngày tạo',
  ADMIN_SUPPORT_TABLE_HEAD_6: 'Ngày sửa đổi lần cuối',
  ADMIN_SUPPORT_TABLE_DELETE: 'Xóa',
  ADMIN_SUPPORT_TABLE_UPDATE: 'Cập nhật',
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE: 'Có điều gì đó đã xảy ra!',
  ADMIN_SUPPORT_ERROR_GENERAL: 'Không thể hoàn thành hành động này',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION: 'Bạn có chắc chắn muốn xóa nó không?',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT: 'Tùy chọn đã xóa',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ACCEPT_SUBTITLE: 'Bạn đã xóa thành công tùy chọn',
  ADMIN_SUPPORT_DELETE_ITEM_INFORMATION_ERROR_SUBTITLE: 'Không thể xóa tùy chọn',
  ADMIN_SUPPORT_DELETE_YES: 'Chấp nhận',
  ADMIN_SUPPORT_DELETE_NO: 'Hủy',
  COUNTER_PAGES: 'Trang:',
  ADMIN_SUPPORT_TROUBLE_PREVISUALIZATE: 'Sự cố xem trước:',
  ADMIN_SUPPORT_TROUBLE_5_PROBLEMS: 'Chỉ có thể thêm 5 bản ghi liên quan đến các vấn đề.',

  ADMIN_PIECES_MANTEINER_TABLE_HEAD_1: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_2: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_3: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_4: '',
  ADMIN_PIECES_MANTEINER_TABLE_HEAD_5: '',

  LOADING_TEXT: 'Đang tải',

  ERROR_MESSAGE_NUMBER_ONLY: 'Trường phải là số',
  SUPPORT_INSPECTION_TROUBLES: 'Sự cố được báo cáo:',
  SUPPORT_INSPECTION_TROUBLES_ADITIONAL: 'Thông tin bổ sung',
  SUPPORT_INSPECTION_SOLUTIONS: 'Giải pháp:',
  SUPPORT_INSPECTION_SOLUTIONS_ADITIONAL: 'Nhận xét Giải pháp Bổ sung',
  SUPPORT_INSPECTION_SOLUTIONS_USER: 'Nhận xét của người dùng bổ sung',
  SUPPORT_INSPECTION_SOLUTIONS_RESOLUTOR: 'Người phân giải',
  SUPPORT_INSPECTION_BINNACLE: 'Xem nhật ký',
  SUPPORT_INSPECTION_BINNACLE_MODAL: 'Nhật ký hỗ trợ',
  SUPPORT_INSPECTION_BINNACLE_SELECT: 'Chọn một yêu cầu:',
  SUPPORT_INSPECTION_BINNACLE_SELECT_OPTION: 'Yêu cầu hỗ trợ - ',
  SUPPORT_INSPECTION_MODAL_TITLE: 'Yêu cầu hỗ trợ',

  ERROR_MESSAGE_TITLE: 'Xin lỗi, quy trình đã bắt đầu hoặc không hoạt động chính xác',
  ERROR_MESSAGE_SUBTITLE: 'Nếu bạn cần trợ giúp, bạn có thể liên hệ với chúng tôi qua các kênh dịch vụ của chúng tôi.',
  ERROR_MESSAGE_ACCESS_DENIED_TITLE: 'Truy cập bị từ chối',
  ERROR_MESSAGE_ACCESS_DENIED_SUBTITLE: 'Bạn không có quyền truy cập vào các mục giám định của ứng dụng này. Bạn đã đăng nhập bằng đúng tài khoản chưa?',

  PAGE_NOT_FOUND_ERROR_MESSAGE: 'Lỗi 404: Không tìm thấy trang!',

  GPS_PERMISSION_DENIED: '',
  GPS_PERMISSION_ENABLED: '',

  MODAL_PRIMARY_BUTTON_LABEL: 'Tiếp tục',

  UTILS_AGE_SINCE_YEARS_AGO: '{interval} năm trước',
  UTILS_AGE_SINCE_MONTHS_AGO: '{interval} tháng trước',
  UTILS_AGE_SINCE_DAYS_AGO: '{interval} ngày trước',
  UTILS_AGE_SINCE_HOURS_AGO: '{interval} giờ trước',
  UTILS_AGE_SINCE_MINUTES_AGO: '{interval} phút trước',
  UTILS_AGE_SINCE_SECONDS_AGO: '{interval} giây trước',

  LOGIN_TITLE: 'Đăng nhập',
  LOGIN_SUBTITLE: 'Nhấp vào nút bên dưới để đăng nhập vào ADIL',

  INSPECTIONS_TABLE_HEADER_NUMBER: 'Đơn Giám định số',
  INSPECTIONS_TABLE_HEADER_PLATE: 'Biển số xe',
  INSPECTIONS_TABLE_HEADER_CHASSIS_NUMBER: 'Số Khung',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION_TYPE: 'LOẠI BÁO GIÁ',
  INSPECTIONS_TABLE_HEADER_STATUS: 'Trạng thái',
  INSPECTIONS_TABLE_HEADER_IDENTIFICATION: 'SỐ BÁO GIÁ',
  INSPECTIONS_TABLE_HEADER_NAME: 'Tên Khách Hàng',
  INSPECTIONS_TABLE_HEADER_ACTION: 'Thao tác',
  INSPECTIONS_TABLE_ACTION_BUTTON: 'Xem lại',
  INSPECTIONS_TABLE_HEADER_CREATED_BY: 'Người tạo đơn',
  INSPECTIONS_TABLE_HEADER_CREATED_ON: 'Ngày tạo đơn',
  INSPECTIONS_TABLE_HEADER_COMPLETION_DATE: 'Ngày duyệt đơn',
  INSPECTIONS_TABLE_HEADER_ASSIGNEE: 'Người duyệt giám định',
  INSPECTIONS_TABLE_HEADER_BROKER_NAME: 'BROKER NAME',
  INSPECTIONS_TABLE_HEADER_BROKER_CODE: 'BROKER CODE',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO: 'Ngày chụp ảnh',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE: 'NGÀY TRỞ LẠI',
  INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW: 'YÊU CẦU HỖ TRỢ NGÀY',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_TOOLTIP: 'Giám định với hình ảnh đính kèm',
  INSPECTIONS_TABLE_ROW_PHOTO_ALERT_ICON_ALT_TEXT: 'Cảnh báo hình ảnh',
  INSPECTIONS_TABLE_ROW_SUPPORT_REQUEST_ALERT_TOOLTIP: 'Kiểm tra với các yêu cầu hỗ trợ',

  INSPECTIONS_TABLE_REPORT_ADIL_TICKET: 'Số adil',
  INSPECTIONS_TABLE_REPORT_STATUS: 'Tình trạng',
  INSPECTIONS_TABLE_REPORT_PLATE_NUMBER: 'Biển số xe',
  INSPECTIONS_TABLE_REPORT_CREATOR_NAME: 'Người tạo đơn',
  INSPECTIONS_TABLE_REPORT_CREATED_DATE: 'Ngày tạo adil',
  INSPECTIONS_TABLE_REPORT_CUSTOMER_ADDRESS: 'Địa chỉ khách hàng',
  INSPECTIONS_TABLE_REPORT_INSPECTION_USER: 'Người chụp hình',
  INSPECTIONS_TABLE_REPORT_INSPECTION_DATE: 'Ngày chụp hình',
  INSPECTIONS_TABLE_REPORT_APPROVED_USER: 'Người duyệt adil',
  INSPECTIONS_TABLE_REPORT_APPROVED_DATE: 'Ngày duyệt',
  REPORT_TABLE_ASIAN_NO_RESULTS: 'không có kết quả nào được tìm thấy',

  INSPECTION_SUMMARY_SUBMISSION_ID: 'Đơn Giám định số',
  INSPECTION_SUMMARY_LICENCE_PLATE: 'Biển số xe',
  INSPECTION_SUMMARY_ID_TYPE: 'Loại CMND/CCCD',
  INSPECTION_SUMMARY_ID_NUMBER: 'Số CMND/CCCD',
  INSPECTION_SUMMARY_USER_NAME: 'Người chụp hình',
  INSPECTION_SUMMARY_CREATED_BY: 'Người tạo đơn',
  INSPECTION_SUMMARY_CREATION_DATE: 'Ngày tạo đơn',
  INSPECTION_SUMMARY_STATUS: 'Trạng thái',
  INSPECTION_SUMMARY_CUSTOMER_COMPLETION_DATE: 'Ngày chụp ảnh',
  INSPECTION_SUMMARY_COMPLETION_DATE: 'Ngày duyệt đơn',
  INSPECTION_SUMMARY_POLICY_TYPE: 'Loại hình Hợp đồng',
  INSPECTION_SUMMARY_LOCATION: 'Địa điểm',
  INSPECTION_RESEND_USERNAME: 'Tên Khách Hàng',

  FINISHED_SELECT_FIELD: 'Búsqueda por:',
  FINISHED_SELECT_INPUT_VALUE: 'Ingrese valor a buscar:',
  SEARCH_FINISHED: 'Tìm kiếm',
  SEARCH_TITLE: 'Các mục giám định',
  SEARCH_SUBTITLE: 'Quản lý, cập nhật và thực hiện kiểm tra hình ảnh của xe đang chờ bảo hiểm',
  SEARCH_BAR_TABS_PENDING_REVIEW: 'Đang chờ kiểm tra',
  SEARCH_BAR_TABS_PENDING_APPROVAL: 'đang chờ hoàn thành',
  SEARCH_BAR_TABS_FINISHED: 'Đã hoàn tất',
  SEARCH_BAR_TABS_PENDING_PER_CUSTOMER: 'Đang chờ khách hàng tiến hành',
  SEARCH_BAR_TABS_PENDING_PER_SUPPORT: 'Nhà cung cấp',
  SEARCH_BAR_TABS_SUPPORT_REQUEST: 'Solicitud de Soporte',
  SEARCH_BAR_INPUT_PLACEHOLDER: 'Tìm kiếm bằng biển số xe hoặc thông tin số báo giá…',
  SEARCH_BAR_SEARCH_ACTIVE_NOTIFICATION: 'Thanh tìm kiếm sẽ chỉ lọc các trạng thái không phải là Đã hoàn thành. Để tìm kiếm các kiểm tra đã hoàn thành, vui lòng chọn tab tương ứng.',
  SEARCH_BAR_SEARCH_COMPLETED_NOTIFICATION: 'Thanh tìm kiếm sẽ chỉ lọc các trạng thái bằng "Đã hoàn thành". Để tìm kiếm các kiểm tra có trạng thái đang chờ xử lý, vui lòng chọn một tab khác ngoài "Đã hoàn thành".',

  SEARCH_NO_RESULTS_TITLE: 'Rất xin lỗi, chúng tôi không tìm thấy mục bạn tìm kiếm',
  SEARCH_NO_RESULTS_SUBTITLE: 'Kiểm tra và thử lại',
  FINISHED_SEARCH_NO_RESULTS_TITLE: 'No records found with these values',
  FINISHED_SEARCH_NO_RESULTS_SUBTITLE: 'Please, try again.',
  SEARCH_LOADING_LABEL: 'Vui lòng chờ…',
  SEARCH_DUPLICATED_SUBMISSIONID_LABEL: 'Đang xác minh số chính sách trùng lặp. Vui lòng chờ...',
  SEARCH_CUSTOMER_INFO_EIS_LABEL: 'Tìm kiếm thông tin trong EIS. Vui lòng chờ...',

  INSPECTION_TITLE: 'Kiểm định',
  INSPECTION_SWITCH_ENABLE_ENGLISH_EMAILS: 'Thông báo bằng tiếng anh',
  INSPECTION_SUBTITLE: 'Tiến hành giám định xe bằng hình ảnh khách hàng gửi, không quên bình luận và cung cấp các hỗ trợ cần thiết',
  INSPECTION_LOADING_LABEL: 'Vui lòng chờ…',
  INSPECTION_APPROVE_BUTTON: 'Đã được duyệt',
  INSPECTION_CONTINUE_ADIL_BUTTON: 'Tiếp tục trong adil',
  INSPECTION_GET_INFORM_ANALYTICS: 'Báo cáo và phân tích',
  INSPECTION_CHANGE_PRESENTIAL_BUTTON: 'Gặp mặt trực tiếp',
  INSPECTION_REJECT_BUTTON: 'Từ chối',
  INSPECTION_RETURN_BUTTON: 'Quay lại',
  INSPECTION_CUSTOMER_RETAKE_BUTTON: 'Yêu cầu một lượt chụp ảnh mới',
  INSPECTION_SAVE_BUTTON: 'Lưu',
  INSPECTION_SAVE_AND_EXIT_BUTTON: 'Lưu và thoát',
  INSPECTION_DELETE_BUTTON: 'Xóa',
  INSPECTION_CANCEL_BUTTON: 'Hủy',
  INSPECTION_PHOTO_UPLOAD_IMAGES: 'Tải lên hình ảnh',
  INSPECTION_PHOTO_UPLOAD_DOCUMENTS: 'Tải lên tệp nén',
  INSPECTION_PHOTO_UPLOADING_DOCUMENTS: 'Tải lên tệp nén...',
  INSPECTION_PHOTO_UPLOAD_IMAGES_PER_ITEM: 'Tải lên hình ảnh',
  INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP: 'Tải lên tệp Zip',
  INSPECTION_PHOTO_UPLOAD_IMAGES_FILE: 'Chọn hình ảnh',
  INSPECTION_PHOTO_UPLOAD_IMAGES_SAVE_BUTTON: 'TẢI LÊN',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG: 'Tệp không chứa đúng định dạng. sử dụng: (.jpg,.jpeg,.png)',
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP: 'Tệp không chứa đúng định dạng. sử dụng: (.zip)',

  INSPECTION_DELETE_MODAL_TITLE: 'Bạn chắc chắn chứ?',
  INSPECTION_DELETE_MODAL_SUBTITLE: 'Không thể hoàn tác bước này.',
  INSPECTION_DELETE_MODAL_CANCEL_BUTTON_LABEL: 'Hủy',
  INSPECTION_DELETE_MODAL_CONFIRM_BUTTON_LABEL: 'Xóa',
  INSPECTION_DELETE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Tại sao lượt giám định này lại bị xóa? (Tối đa 1000 ký tự)',
  INSPECTION_DELETE_MODAL_TEXTAREA_TITLE: 'Vui lòng viết bình luận của bạn về trường hợp này',

  INSPECTION_CANCEL_MODAL_TITLE: 'Bạn chắc chắn chứ?',
  INSPECTION_CANCEL_MODAL_SUBTITLE: 'Không thể hoàn tác bước này.',
  INSPECTION_CANCEL_MODAL_CANCEL_BUTTON_LABEL: 'Hủy',
  INSPECTION_CANCEL_MODAL_CONFIRM_BUTTON_LABEL: 'Xác nhận',
  INSPECTION_CANCEL_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Tại sao lượt giám định này lại bị hủy? (Tối đa 1000 ký tự)',
  INSPECTION_CANCEL_MODAL_TEXTAREA_TITLE: 'Vui lòng viết bình luận của bạn về trường hợp này',

  INSPECTION_OVERRIDE_MODAL_TITLE: 'Hủy bỏ trạng thái Hoàn tất giám định',
  INSPECTION_OVERRIDE_MODAL_SUBTITLE: 'Không thể hoàn tất bước này.',
  INSPECTION_OVERRRIDE_MODAL_CANCEL_BUTTON_LABEL: 'Hủy',
  INSPECTION_OVERRIDE_MODAL_MESSAGE_INPUT_PLACEHOLDER: 'Tại sao lượt giám định này lại bị hủy bỏ? Vui lòng cung cấp càng nhiều chi tiết càng tốt. Ví dụ, ảnh được lấy từ các nguồn lưu trữ khác, v.v. (Tối đa 1000 ký tự) ',
  INSPECTION_OVERRIDE_MODAL_TEXTAREA_TITLE: 'Vui lòng viết bình luận của bạn về trường hợp này',

  INSPECTION_PHOTO_HISTORY_MODAL_FOTO: '',
  INSPECTION_PHOTO_HISTORY_MODAL_FOTO_DATE: '',
  INSPECTION_PHOTO_HISTORY_MODAL_PHOTO_COMMENTS: '',
  INSPECTION_PHOTO_HISTORY_MODAL_HEADER: '',

  INSPECTION_OVERRIDE_COMMENT_TITLE: '',
  INSPECTION_OVERRIDE_AGENT_NAME: 'Được ghi nhận bởi:',

  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_TITLE: 'Kiểm tra không tìm thấy.',
  INSPECTION_DUPLICATE_MODAL_NOT_FOUND_SUBTITLE: 'Việc kiểm tra không được tạo ra như một yêu cầu.',
  INSPECTION_DUPLICATE_MODAL_ERROR_TITLE: 'Lỗi dịch vụ',
  INSPECTION_DUPLICATE_MODAL_ERROR_SUBTITLE: 'Dịch vụ Không sẵn có. Vui lòng thêm thông tin.',

  INSPECTION_SUMMARY_HEADER_1: 'Đơn Giám định số',
  INSPECTION_SUMMARY_HEADER_2: 'Biển số xe',
  INSPECTION_SUMMARY_HEADER_3: 'Loại CMND/CCCD',
  INSPECTION_SUMMARY_HEADER_4: 'Số CMND/CCCD',
  INSPECTION_SUMMARY_HEADER_5: 'Người chụp hình',
  INSPECTION_SUMMARY_HEADER_6: 'Người tạo đơn',
  INSPECTION_SUMMARY_HEADER_7: 'Ngày tạo đơn',
  INSPECTION_SUMMARY_HEADER_8: 'Trạng thái',

  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_SUCCESS: 'Máy ảnh đã được thay đổi',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_FAILED: 'Đã xảy ra sự cố khi thay đổi máy ảnh',
  INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_BUTTON: 'đổi máy quay',

  INSPECTION_MESSAGE_IS_REQUIRED_VIDEO: 'Video 360 là bắt buộc',
  INSPECTION_MESSAGE_IS_OPTIONAL_VIDEO: 'Video 360 là tùy chọn',

  INSPECTION_VEHICLE_DETAILS_IMAGE_ALT_TEXT: 'Xe',
  INSPECTION_VEHICLE_DETAILS_SELECT_DEFAULT: '- Vui lòng chọn -',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_EMPTY: 'không thể để trống mục này',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MAX: 'không thể điền quá {maxLength} ký tự cho mục này',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_FIELD_MIN: 'Số báo giá/ Số hợp đồng không hợp lệ. Vui lòng kiểm tra lại.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SUBMISSIONID: 'Số báo giá/ Số hợp đồng không hợp lệ. Vui lòng kiểm tra lại.',
  INSPECTION_VEHICLE_DETAILS_VALIDATION_SELECT_EMPTY: 'vui lòng chọn một lựa chọn hợp lệ từ danh sách dưới đây',
  INSPECTION_VEHICLE_SUBMISSIONID_EXIST: 'Số báo giá/Số hợp đồng đã được sử dụng trong Số giám định đang được xử lý.',
  INSPECTION_VEHICLE_LICENCEPLATE_EXIST: 'biển số xe đã tồn tại trong hệ thống',

  INSPECTION_VEHICLE_VIDEO_TITLE: 'วิดีโอ 360°',
  INSPECTION_VEHICLE_VIDEO_HINT_VIEW: 'Kéo để xem',
  INSPECTION_VEHICLE_VIDEO_BUTTON_CLOSE: 'Thoát',
  INSPECTION_VEHICLE_VIDEO_BUTTON_SAVE_CHANGES: 'Lưu thay đổi',

  INSPECTION_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE: 'Không có ảnh nào',
  INSPECTION_VEHICLE_PHOTOS_TITLE: 'Duyệt Ảnh',
  INSPECTION_VEHICLE_PHOTOS_SUBTITLE: 'Bắt đầu quy trình giám định, kiểm tra xem hình ảnh đã tuân thủ các tiêu chuẩn cho phép hay chưa để tiếp tục quy trình',
  INSPECTION_VEHICLE_PHOTOS_HEADER_2: 'Mô tả hình ảnh',
  INSPECTION_VEHICLE_PHOTOS_HEADER_3: 'Ngày đăng',
  INSPECTION_VEHICLE_PHOTOS_HEADER_4: 'Hành động',
  INSPECTION_VEHICLE_PHOTOS_AI_BANNER: 'Ghi chú: Bạn sẽ thấy các biểu tượng bên cạnh mỗi ảnh, thể hiện chúng tôi đang chạy chức năng phát hiện ảnh tự động để hỗ trợ bạn trong quá trình kiểm tra. Thông tin này chỉ mang tính hướng dẫn, và hình ảnh vẫn phải được kiểm tra thủ công.',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_VEHICLE_TOOLTIP: 'Chúng tôi tìm thấy một chiếc xe trong ảnh này',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_TEXT_TOOLTIP: 'Hemos detectado que el número de chasis puede aparecer en esta imagen',
  INSPECTION_VEHICLE_PHOTOS_IMAGE_PERSON_TOOLTIP: 'Chúng tôi tìm thấy một người xuất hiện trong ảnh này',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_ACCEPT: 'Đồng ý',
  INSPECTION_VEHICLE_PHOTOS_RADIO_BUTTON_REJECT: 'Chụp lại',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_ACCEPT: ' bật hướng dẫn ảnh',
  INSPECTION_VEHICLE_OVERLAY_ENABLER_RADIO_BUTTON_REJECT: 'tắt hướng dẫn bằng hình ảnh',
  INSPECTION_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY: 'Hình ảnh đã được hoàn lại, bình luận đã được gửi thành công',
  INSPECTION_VEHICLE_PHOTOS_VIEW_COMMENTS: 'Xem bình luận',
  INSPECTION_VEHICLE_PHOTOS_INCORRECT_PHOTOS: 'Xin lỗi, rõ ràng có một số hình ảnh không chính xác',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_IMAGE: 'Tải ảnh',
  INSPECTION_VEHICLE_DOCUMENTS_DOWNLOAD: 'Tải xuống',
  INSPECTION_VEHICLE_PHOTOS_APPROVE_ALL_PHOTOS: '',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE: 'Tải tất cả ảnh',
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE: 'Đang tải… Vui lòng chờ',
  INSPECTION_VEHICLE_DURATION_VIDEO: 'Thời lượng video',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES: 'Tần số hình ảnh (Giây)',
  INSPECTION_VEHICLE_DURATION_VIDEO_FIELD: 'Thời lượng video',
  INSPECTION_VEHICLE_FREQUENCY_IMAGES_FIELD: 'Tần số hình ảnh',
  INSPECTION_VEHICLE_IMAGE_COMPRESS: 'Nén hình ảnh',
  INSPECTION_VEHICLE_IMAGE_COMPRESS_DISABLE: 'KHÔNG Nén hình ảnh',

  INSPECTION_VEHICLE_CHASSIS_NUMBER_DETECTED_LABEL: '# chassis detectado:',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_ACCEPT: 'Correcto',
  INSPECTION_VEHICLE_AI_RESULT_RADIO_BUTTON_REJECT: 'Incorrecto',

  INSPECTION_CUSTOMER_MESSAGES_TITLE: 'Bình luận của tôi',
  INSPECTION_CUSTOMER_MESSAGES_BUTTON_LABEL: 'Đóng',

  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_TITLE: 'Thành công',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_GENERIC: 'Thất bại',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_TITLE: 'Chú ý',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_SUCCESS_SUBTITLE: 'Bạn có chắc chắn muốn gửi? Chọn đồng ý cũng sẽ khiến thông tin giám định được lưu lại',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_GENERIC: 'Không thể gửi thông báo',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CONFIRMATION_SUBTITLE: 'Bạn có chắc chắn muốn gửi? Chọn đồng ý cũng sẽ khiến thông tin kiểm định được lưu lại',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_CLOSE: 'Đóng',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_TITLE_EMAIL: 'Có lỗi khi gửi email nhắc lại',
  INSPECTION_CUSTOMER_RESEND_NOTIFICATION_FAIL_SUBTITLE_EMAIL: 'Gửi tin nhắn thành công, nhưng email liên lạc là chính xác',

  INSPECTION_CUSTOMER_DETAILS_BUTTON_RESEND: 'Gửi lại',
  INSPECTION_CUSTOMER_DETAILS_BUTTON_SENDING: 'Đang gửi…',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_PRECESSED_STATUS: 'truy tố',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DELIVERED_STATUS: 'Đã giao hàng',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BOUNCED_STATUS: 'bị trả lại',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DROPPED_STATUS: 'Rơi',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_DIFERRED_STATUS: 'Hoãn lại',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_BLOCKED_STATUS: 'bị khóa',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_UNKNOWN_STATUS: 'Một người lạ',
  INSPECTION_CUSTOMER_DETAILS_EMAIL_STATUS_LABEL: 'trạng thái thư',

  INSPECTION_NEW_CUSTOMER_MESSAGE_TITLE: 'Bình luận ảnh',
  INSPECTION_NEW_CUSTOMER_MESSAGE_INPUT_PLACEHOLDER: 'Ghi các quan sát của bạn về các bức ảnh (tối đa 1000 ký tự)',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SEND_COMMENTS: 'Gửi tin nhắn cho khách hàng',
  INSPECTION_NEW_CUSTOMER_MESSAGE_LOADING: 'Vui lòng chờ…',
  INSPECTION_NEW_CUSTOMER_MESSAGE_SUCCESS_TITLE: 'Tin nhắn đã được gửi cho khách hàng',
  INSPECTION_NEW_CUSTOMER_MESSAGE_CLOSE: 'Đóng',

  INSPECTION_SUPPORTING_DOCUMENTS_TITLE: 'Các hỗ trợ',
  INSPECTION_SUPPORTING_DOCUMENTS_SUBTITLE: 'Đính kèm và gửi các tệp tin hỗ trợ mà bạn cho là cần thiết cho việc giám định, có thể là ảnh chụp màn hình, tài liệu, v.v. \nĐịnh dạng được hỗ trợ (PDF, jpg, jpeg, png, bmp, doc, docx).',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER: 'Kéo tệp tin vào để đính kèm, hoặc',
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_BROWSE_LINK: 'tìm kiếm',

  INSPECTION_COMMENTS_TITLE: 'Bình luận của bạn',
  INSPECTION_COMMENTS_SUBTITLE: 'Đừng quên nhập bình luận của bạn về việc kiểm định',
  INSPECTION_COMMENTS_INPUT_PLACEHOLDER: 'Ghi các quan sát của bạn (tối đã 1000 ký tự)',

  INSPECTION_STATUS_AGENT_REVIEW_DISPLAY_VALUE: 'Cần chuyên viên kiểm tra',
  INSPECTION_STATUS_SUPPORT_AGENT_REVIEW_DISPLAY_VALUE: 'Cần chuyên viên kiểm tra',
  INSPECTION_STATUS_AGENT_IN_PROGRESS_DISPLAY_VALUE: 'Chuyên viên đang kiểm tra',
  INSPECTION_STATUS_AGENT_APPROVED_DISPLAY_VALUE: 'Đã duyệt',
  INSPECTION_STATUS_AGENT_REJECTED_DISPLAY_VALUE: 'Đã từ chối',
  INSPECTION_STATUS_CUSTOMER_RETAKE_DISPLAY_VALUE: 'Khách hàng cần chụp lại ảnh',
  INSPECTION_STATUS_CUSTOMER_NEW_DISPLAY_VALUE: 'Lượt kiểm định mới',
  INSPECTION_STATUS_CUSTOMER_IN_PROGRESS_DISPLAY_VALUE: 'Khách hàng đang tiến hành',
  INSPECTION_STATUS_SUPPORT_RETAKE_DISPLAY_VALUE: 'Đợi chụp lại',
  INSPECTION_STATUS_SUPPORT_NEW_DISPLAY_VALUE: 'Giám định mới',
  INSPECTION_STATUS_SUPPORT_INSPECTOR_DISPLAY_VALUE: 'thanh tra yêu cầu',
  INSPECTION_STATUS_SUPPORT_IN_PROGRESS_DISPLAY_VALUE: 'Nhà cung cấp đang tiến hành',
  INSPECTION_STATUS_EXPIRED_DISPLAY_VALUE: 'Đã hết hạn',
  INSPECTION_STATUS_DELETED_DISPLAY_VALUE: 'Đã xóa',
  INSPECTION_STATUS_CANCELLED_DISPLAY_VALUE: 'Đã hủy',
  INSPECTION_STATUS_CHANGED_TO_PRESENTIAL_DISPLAY_VALUE: 'chuyển sang trực tiếp',

  SUPPORT_INSPECTION_NO_COMMENTS: 'Miễn bình luận.',

  ENABLE_VENDOR_LABEL: 'chỉ kiểm tra nhà cung cấp',

  INSPECTION_ASSIGNEE_TITLE: 'Đã phân bổ: ',
  INSPECTION_ASSIGNEE_ASSIGN_TO_ME_BUTTON: 'Phân bổ cho tôi',
  INSPECTION_DEFAULT_ASSIGNEE_DISPLAY_VALUE: 'CHƯA PHÂN BỔ',

  INSPECTION_COMPLETE_ACCEPTED_TITLE: 'Đã chấp nhận giám định hình ảnh',
  INSPECTION_COMPLETE_ACCEPTED_SUBTITLE: 'Hình ảnh đáp ứng các yêu cầu giám định',
  INSPECTION_COMPLETE_REJECTED_TITLE: 'Đã từ chối giám định',
  INSPECTION_COMPLETE_REJECTED_SUBTITLE: 'Xe không đáp ứng các yêu cầu cơ bản để đăng ký bảo hiểm',
  INSPECTION_COMPLETE_BUTTON_LABEL: 'Quay lại',

  INSPECTION_PHOTO_PROPERTY_CARD_FRONT_DISPLAY_STRING: 'Mặt trước Giấy chứng nhận đăng ký xe',
  INSPECTION_PHOTO_PROPERTY_CARD_BACK_DISPLAY_STRING: 'Mặt sau Giấy chứng nhận đăng ký xe',
  INSPECTION_PHOTO_VEHICLE_NUMBER_PLATE_DISPLAY_STRING: 'Biển số xe',
  INSPECTION_PHOTO_VEHICLE_FRONT_DISPLAY_STRING: 'Phía trước của xe',
  INSPECTION_PHOTO_VEHICLE_REAR_DISPLAY_STRING: 'Phía sau của xe',
  INSPECTION_PHOTO_VEHICLE_RIGHT_SIDE_DISPLAY_STRING: 'Phía bên phải',
  INSPECTION_PHOTO_VEHICLE_LEFT_SIDE_DISPLAY_STRING: 'Phía bên trái',
  INSPECTION_PHOTO_VEHICLE_ENGINE_DISPLAY_STRING: 'Khoang động cơ',
  INSPECTION_PHOTO_VEHICLE_DASH_DISPLAY_STRING: 'Đồng hồ đo số km hoặc bảng điều khiển',
  INSPECTION_PHOTO_VEHICLE_MILEAGE_DISPLAY_STRING: 'Quãng đường đã đi',
  INSPECTION_PHOTO_VEHICLE_SERIAL_NUMBER_DISPLAY_STRING: 'Nhãn dán hoặc bảng mã nhận diện xe',
  INSPECTION_PHOTO_VEHICLE_CHASSIS_NUMBER_DISPLAY_STRING: 'Số khung',
  INSPECTION_PHOTO_VEHICLE_WINDSHIELD_PLATE_DISPLAY_STRING: 'Biển số nhận diện',
  INSPECTION_PHOTO_VEHICLE_CERTIFICATION_INSPECTIONE_DISPLAY_STRING: 'Chứng nhận đăng kiểm xe',
  INSPECTION_PHOTO_VEHICLE_REGISTRATION_CARD_DISPLAY_STRING: 'Chứng nhận đăng ký xe',
  INSPECTION_PHOTO_VEHICLE_LEFT_FRONT_ANGLE_DISPLAY_STRING: 'Hình chụp góc trước trái',
  INSPECTION_PHOTO_VEHICLE_RIGHT_FRONT_ANGLE_DISPLAY_STRING: 'Hình chụp góc trước phải',
  INSPECTION_PHOTO_VEHICLE_RIGHT_BACK_ANGLE_DISPLAY_STRING: 'Hình chụp góc sau phải',
  INSPECTION_PHOTO_VEHICLE_LEFT_BACK_ANGLE_DISPLAY_STRING: 'Hình chụp góc sau trái',
  INSPECTION_PHOTO_VEHICLE_LEFT_HEADLAMP_DISPLAY_STRING: 'Hình chụp đèn pha trái',
  INSPECTION_PHOTO_VEHICLE_RIGHT_HEADLAMP_DISPLAY_STRING: 'Hình chụp đèn pha phải',
  INSPECTION_PHOTO_VEHICLE_VN_ODOMETER_DISPLAY_STRING: 'Hình chụp đồng hồ km',
  INSPECTION_PHOTO_VEHICLE_VIDEO_DISPLAY_STRING: 'Video 360 ° về chiếc xe',
  INSPECTION_PHOTO_VEHICLE_ID_CARD_OR_PASSPORT_DISPLAY_STRING: 'Chứng minh thư / Hộ chiếu',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_1_DISPLAY_STRING: 'Giấy phép lái xe/IDP cho người lái xe được đặt tên đầu tiên',
  INSPECTION_PHOTO_VEHICLE_DRIVER_LICENSE_2_DISPLAY_STRING: 'Giấy phép lái xe/ IDP cho người lái xe có tên thứ 2',

  INSPECTION_PHOTO_ACCESSORY_RADIO_DISPLAY_STRING: 'Đài radio',
  INSPECTION_PHOTO_ACCESSORY_DVD_DISPLAY_STRING: 'Đầu đĩa DVD',
  INSPECTION_PHOTO_ACCESSORY_AMPLIFIERS_DISPLAY_STRING: 'Loa',
  INSPECTION_PHOTO_ACCESSORY_FIXED_SPEAKERS_DISPLAY_STRING: 'Loa cố định',
  INSPECTION_PHOTO_ACCESSORY_EQUALIZERS_DISPLAY_STRING: 'Bộ cân bằng hiệu ứng',
  INSPECTION_PHOTO_ACCESSORY_REAR_CAMERA_DISPLAY_STRING: 'Camera phía sau',
  INSPECTION_PHOTO_ACCESSORY_PROXY_SENSORS_DISPLAY_STRING: 'Cảm biến tiệm cận',
  INSPECTION_PHOTO_ACCESSORY_CENTRAL_LOCK_DISPLAY_STRING: 'Khóa trung tâm',
  INSPECTION_PHOTO_ACCESSORY_ALARM_DISPLAY_STRING: 'Bộ phận báo động',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_FILM_DISPLAY_STRING: 'Phim an toàn',
  INSPECTION_PHOTO_ACCESSORY_SUN_ROOF_DISPLAY_STRING: 'Mái che nắng',
  INSPECTION_PHOTO_ACCESSORY_ELECTRIC_WINDOWS_DISPLAY_STRING: 'Cửa sổ điện tử',
  INSPECTION_PHOTO_ACCESSORY_AIR_CONDITIONING_DISPLAY_STRING: 'Điều hòa không khí',
  INSPECTION_PHOTO_ACCESSORY_TURN_OFF_ELECTRICITY_DISPLAY_STRING: 'Tắt điện',
  INSPECTION_PHOTO_ACCESSORY_PATENT_ENGRAVING_DISPLAY_STRING: 'Chứng nhận sáng chế khắc trên kính',
  INSPECTION_PHOTO_ACCESSORY_FOOTRESTS_DISPLAY_STRING: 'Chỗ để chân',
  INSPECTION_PHOTO_ACCESSORY_ROLL_BAR_DISPLAY_STRING: 'Thanh cuộn bên trong / bên ngoài',
  INSPECTION_PHOTO_ACCESSORY_HITCH_WHEEL_DISPLAY_STRING: 'Bánh xe xoay',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_HITCH_BALL_DISPLAY_STRING: 'Cần móc xe kéo',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_PLUG_DISPLAY_STRING: 'Chốt vặn móc xe kéo',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_WHEELS_DISPLAY_STRING: 'Bánh xe Luxury',
  INSPECTION_PHOTO_ACCESSORY_EXPLORERS_DISPLAY_STRING: 'Xe Explorers',
  INSPECTION_PHOTO_ACCESSORY_GAS_CONVERSION_DISPLAY_STRING: 'Thiết bị Chuyển đổi Xăng',
  INSPECTION_PHOTO_ACCESSORY_DURALINER_DISPLAY_STRING: 'Xe Duraliner',
  INSPECTION_PHOTO_ACCESSORY_HELMET_DISPLAY_STRING: 'Mũ bảo hiểm',

  INSPECTION_PHOTO_ACCESSORY_ARMOR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CD_BOX_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FIXED_DVD_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SOUND_PLANT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_RADIO_CASSETTE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WOOFER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_PARKING_SENSORS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REVERSE_SENSORS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BLUETOOTH_SYSTEM_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_GLASSES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BULLBAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUNROOF_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LEATHER_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUCKET_COATING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SAFETY_SHEETS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_ACCESSORY_SPOILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TOW_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SIDE_PROTECTION_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SKIRTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_COVER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HALOGEN_HEADLIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TIRES_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_CAR_MOULDING_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROOF_RACK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRUCK_BED_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TWEETER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SLIDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FOG_LIGHTS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_STABILIZER_BAR_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_EMERGENCY_TIRE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_HITCH_LOCK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_WINCH_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_FRONT_FENDER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_DRAWER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_SUSPENSION_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BUMPER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_ROLL_CAGE_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_REFRIGERATED_VAN_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_LUXURY_STEERING_WHEEL_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_BODY_KIT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_UPHOLSTERY_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_TRAILER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MIRRORS_DISPLAY_STRING: '',

  INSPECTION_PHOTO_SPARE_WHEEL_DISPLAY_STRING: 'Bánh xe dự phòng',

  INSPECTION_PHOTO_MOTORBIKE_FRONT_DISPLAY_STRING: 'Mặt trước của xe đạp',
  INSPECTION_PHOTO_MOTORBIKE_REAR__DISPLAY_STRING: 'Phía sau xe máy',
  INSPECTION_PHOTO_MOTORBIKE_ENGINE_DISPLAY_STRING: 'Động cơ',

  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_TRUNK_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PORTER_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_PROTECTOR_KIT_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_ALARM_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_EXPLORERS_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_HELMET_DISPLAY_STRING: '',
  INSPECTION_PHOTO_ACCESSORY_MOTORBIKE_JACKET_DISPLAY_STRING: '',

  INSPECTION_PHOTO_DAMAGE_NAME: 'Hư hại',

  CREATE_INSPECTION_NOTES_TITLE: 'Ghi chú của Người tạo Giám định xe',
  CREATE_INSPECTION_CUSTOMER_TITLE: 'Thông tin Khách Hàng',
  CREATE_INSPECTION_CUSTOMER_SUBTITLE: 'Nhập thông tin khách hàng',
  CREATE_INSPECTION_AGENT_TITLE: 'Thông tin Trung Gian Bảo Hiểm',
  CREATE_INSPECTION_AGENT_SUBTITLE: 'Nhập thông tin đại lý',
  CREATE_INSPECTION_VEHICLE_TITLE: 'Thông tin Xe được bảo hiểm',
  CREATE_INSPECTION_VEHICLE_SUBTITLE: 'Nhập thông tin xe',
  CREATE_INSPECTION_BROKER_TITLE: 'Người môi giới',
  CREATE_INSPECTION_BROKER_SUBTITLE: 'Nhập thông tin người môi giới',
  CREATE_INSPECTION_NAMED_DRIVER_TITLE: 'Người lái xe có tên',
  CREATE_INSPECTION_NAMED_DRIVER_SUBTITLE: 'Nhập thông tin trình điều khiển được đặt tên',
  CREATE_INSPECTION_SUBMIT: 'Nộp',
  CREATE_INSPECTION_PREFILL: 'Nhập lại',
  CREATE_INSPECTION_CLEAN_FIELDS: 'Cánh đồng sạch',
  CREATE_INSPECTION_CREATION_ERROR: 'Lỗi khi tạo lượt giám định',
  CREATE_INSPECTION_CREATION_SUCCESS: 'Lượt giám định được tạo thành công',
  CREATE_INSPECTION_CREATION_CLOSE: 'Đóng',
  CREATE_INSPECTION_CREATION_CONTINUE: 'Tiếp tục',
  CREATE_INSPECTION_CREATION_COPY: 'Sao chép đường dẫn',
  CREATE_INSPECTION_INVALID_PHONE_NUMBER: 'Số điện thoại không hợp lệ',
  CREATE_INSPECTION_CAMERA_TITLE: 'Máy ảnh',
  CREATE_INSPECTION_CAMERA_SUBTITLE: 'Chọn máy ảnh để khách hàng sử dụng',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL: 'Loại máy ảnh',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NATIVE: 'Máy ảnh gốc',
  CREATE_INSPECTION_SELECT_CAMERA_TYPE_NEW: 'mô-đun máy ảnh tích hợp',
  CREATE_INSPECTION_VIDEO_TITLE: 'Video 360°',
  CREATE_INSPECTION_VIDEO_SUBTITLE: 'Trong phần này, bạn có thể bật video 360 ° cho ứng dụng khách',
  CREATE_INSPECTION_SELECT_VIDEO_LABEL: 'Chọn một tùy chọn',
  CREATE_INSPECTION_POLICY_TYPE_LABEL: 'loại chính sách',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_1: 'Triển khai video 360 °',
  CREATE_INSPECTION_SELECT_VIDEO_OPTION_2: 'Không triển khai video 360 °',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_1: 'New Business',
  CREATE_INSPECTION_SELECT_POLICY_TYPE_OPTION_2: 'Renewal',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_1: '1 Hình ảnh',
  CREATE_INSPECTION_SELECT_VIDEO_FREQUENCY_OPTION_2: '2 Hình ảnh',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_1: '1 Hình ảnh',
  CREATE_INSPECTION_SELECT_VIDEO_VIDEO_LENGTH_OPTION_2: '2 Hình ảnh',
  CREATE_INSPECTION_INSPECTION_NOT_FOUND: '',
  CREATE_INSPECTION_UNAVAILABLE_SERVICE_ERROR: '',
  CREATE_INSPECTION_LOADING_INSPECTION_MESSAGE: '',

  REPORT_SUCCESS_MODAL_TITLE: 'Báo cáo của bạn đã được tải thành công',
  REPORT_SUCCESS_MODAL_BUTTON: 'Đóng',
  REPORT_TITLE: '',
  REPORT_SUBTITLE: '',
  REPORT_SUBMIT_BUTTON: 'Tạo báo cáo',
  REPORT_FORM_SUBTITLE: 'Chọn phạm vi ngày bạn muốn tạo báo cáo',
  REPORT_FORM_START_DATE: 'Ngày bắt đầu',
  REPORT_FORM_END_DATE: 'Ngày kết thúc',
  REPORT_FORM_ERROR_MAX_RANGE: 'Khung thời gian yêu cầu phải từ 90 ngày trở xuống',
  REPORT_OPTION_ONE: 'Quy trình làm việc Adil',
  REPORT_OPTION_TWO: 'ảnh trả về',
  REPORT_OPTION_THREE: 'ngày',
  REPORT_OPTION_FOR: 'sai lầm',
  REPORT_OPTION_FIVE: 'yêu cầu hỗ trợ',
  REPORT_OPTION_SIX: 'Đặc trưng',
  REPORT_OPTION_EIGHT: 'Tình trạng gửi thư',
  REPORT_OPTION_NINE: '[NT] Inspections deleted',
  REPORT_OPTION_TEN: '',

  REPORT_MESSAGE_TIMEOUT: 'Đã vượt quá giới hạn bản ghi để tham khảo, vui lòng giảm phạm vi thời gian đã chọn',

  SEARCH_FILTER_BAR_WITH_PHOTOS: 'Kèm ảnh',

  INSPECTION_INFORM_TITLE: 'Inform Inspection',
  INSPECTION_INFORM_SUBTITLE: 'Complete the follow steps for see the inform inspections result.',
  INSPECTION_INFORM_DATA_OPERATOR: 'Datos del corredor',
  INSPECTION_INFORM_DATA_INSPECTOR: 'Datos del inspector',
  INSPECTION_INFORM_DATA_INSPECTION: 'Datos de la inspección',
  INSPECTION_INFORM_DATA_VEHICLE: 'Datos del vehículo',
  INSPECTION_INFORM_SYSTEM_IDENTIFICATION: 'Sistemas de identificación',
  INSPECTION_INFORM_REVISION_FRONTAL: 'Revisión estructural: Frontal',
  INSPECTION_INFORM_LATERAL_LEFT: 'Revisión estructural: Lateral izquierdo',
  INSPECTION_INFORM_MAP_DAMAGE: 'Mapa de daños',
  INSPECTION_INFORM_PIECES_INSPECTION: 'Piezas a inspeccionar',
  INSPECTION_INFORM_CHARACTERS_LIMIT: '50 de 1000 caracteres',
  INSPECTION_INFORM_START_DAMAGE_INSPECTIONS: 'Ingresar inspección con daños',

  INSPECTION_INFORM_MODEL_YEAR: 'Año Modelo',
  INSPECTION_INFORM_CLASS: 'Clase',
  INSPECTION_INFORM_MANUFACTURER: 'Marca',
  INSPECTION_INFORM_TYPE_ID: 'Uso/Tipo de placa',
  INSPECTION_INFORM_KM_OR_AUTONOMIC: 'Km o autonomía',
  INSPECTION_INFORM_COLOR: 'Color',
  INSPECTION_INFORM_TYPE_ENGINE: 'Combustible',
  INSPECTION_INFORM_MODEL_VERSION: 'Modelo/Version',

  INSPECTION_INFORM_NUMBER_PATENT: 'Placa/Patente',
  INSPECTION_INFORM_STATE_PATENT: 'Estado Placa/Patente',
  INSPECTION_INFORM_NUMBER_CHASSIS: 'Número de chasis',
  INSPECTION_INFORM_STATE_CHASSIS: 'Estado Número de chasis',
  INSPECTION_INFORM_NUMBER_MOTOR: 'Serie motor',
  INSPECTION_INFORM_STATE_MOTOR: 'Estado Serie motor',
  INSPECTION_INFORM_CLOSE_FULLSCREEN_BUTTON: '',
  INSPECTION_INFORM_APPROVED_OPTION: '',
  INSPECTION_INFORM_DISAPPROVED_OPTION: '',

  STEPPER_VALIDATE_DATA: '[NT] Validation data',
  STEPPER_VALIDATE_CRIMINAL_RECORD: '[NT] Validation Criminal Record',
  STEPPER_SEND_RESULTS: '[NT] Send Results',
  STEPPER_LABEL_ONE: '1',
  STEPPER_LABEL_TWO: '2',
  STEPPER_LABEL_THREE: '3',
};

export default { code, strings };
