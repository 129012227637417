import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BodyText, GridCol, GridRow, Button } from '@lmig/lmds-react';
import {
  IconCamera,
  IconErrorAlert,
  IconSuccessAlert,
} from '@lmig/lmds-react/icons';
import { CAMERA_TYPE } from '@lmig-latam/adil-api-common-lib/constants';
import InformedSelect from '../InformedSelect';
import localizedStrings from '../../utils/localizedStrings';
import { getCameraTypes } from '../../constants/camera';
import RequiredVideoSwitch from '../RequiredVideoSwitch';
import ReloadIcon from './reload.svg';
import Panel from '../Panel';
import { isValidVideoModule } from '../../utils/constants';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import './styles.scss';

const CAMERA_TYPES = getCameraTypes();

export default function ChangeParametersCamera({
  onChange,
  success,
  initialValue,
  readOnly,
  updateCamera,
  cameraType,
  isRequiredVideo,
  onChangeRequiredVideo,
}) {
  const {
    CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL,
    INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_SUCCESS,
    INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_FAILED,
    INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_BUTTON,
  } = localizedStrings;

  const [cameraSelected, setCameraSelected] = useState(cameraType);

  const handlerSelectCamera = e => {
    const { value } = e.target;
    if (CAMERA_TYPES.NATIVE.value.trim() === value) {
      onChange(CAMERA_TYPES.NATIVE.id);
      setCameraSelected(CAMERA_TYPES.NATIVE.id);
    } else {
      onChange(CAMERA_TYPES.FRAME.id);
      setCameraSelected(CAMERA_TYPES.FRAME.id);
    }
  };

  const getMessageFailed = () => (
    <>
      <BodyText className="change-camera__failed-text scale-in-center">
        {INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_FAILED}
      </BodyText>
      <IconErrorAlert color="error" className="change-camera__icon" />
    </>
  );

  const getMessageSuccess = () => (
    <>
      <BodyText className="change-camera__success-text scale-in-center">
        {INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_SUCCESS}
      </BodyText>
      <IconSuccessAlert color="success" className="change-camera__icon" />
    </>
  );

  const isValidSwitchVideoRequired = () =>
    isValidVideoModule.includes(getCountryCode()) &&
    cameraSelected === CAMERA_TYPE.FRAME;
  return (
    <Panel className="customer-details-section">
      <GridRow>
        <GridCol base={1}>
          <div className="change-camera__img-container">
            <IconCamera size="32" className="change-camera__img" />
          </div>
        </GridCol>
        <GridCol base={5}>
          <InformedSelect
            id="camera"
            labelVisual={CREATE_INSPECTION_SELECT_CAMERA_TYPE_LABEL}
            initialValue={initialValue}
            onChange={e => handlerSelectCamera(e)}
            selectValues={[CAMERA_TYPES.FRAME.value, CAMERA_TYPES.NATIVE.value]}
            disabled={readOnly}
          />
        </GridCol>
        <GridCol base={2}>
          <GridRow>
            <Button
              type="button"
              level="primary"
              onClick={updateCamera}
              className="change-button"
              disabled={readOnly}
            >
              <span>{INSPECTION_MESSAGE_CHANGE_CAMERA_TYPE_BUTTON}</span>
              <img src={ReloadIcon} alt="" width="18" />
            </Button>
          </GridRow>
        </GridCol>

        {isValidSwitchVideoRequired() && (
          <GridCol base={4}>
            <RequiredVideoSwitch
              required={isRequiredVideo}
              onChange={onChangeRequiredVideo}
            />
          </GridCol>
        )}
      </GridRow>
      <GridRow>
        <GridCol base={1} />
        <GridCol base={5}>
          <GridRow>
            {success && getMessageSuccess()}
            {success === false && getMessageFailed()}
          </GridRow>
        </GridCol>
      </GridRow>
    </Panel>
  );
}

ChangeParametersCamera.defaultProps = {
  success: null,
  isRequiredVideo: false,
};

ChangeParametersCamera.propTypes = {
  onChange: PropTypes.func.isRequired,
  success: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  updateCamera: PropTypes.func.isRequired,
  isRequiredVideo: PropTypes.bool,
  cameraType: PropTypes.string.isRequired,
  onChangeRequiredVideo: PropTypes.func.isRequired,
};
