import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CardGlobalBody = ({ children }) => (
  <div className="content__box__body">{children}</div>
);

export default CardGlobalBody;

CardGlobalBody.propTypes = {
  children: PropTypes.node.isRequired,
};
