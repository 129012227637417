import * as React from 'react';
import './styles.scss';
import { Button } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Panel } from '../index';
import localizedStrings from '../../utils/localizedStrings';

const {
  MODAL_PRIMARY_BUTTON_LABEL,
  INSPECTION_RETURN_BUTTON,
} = localizedStrings;

export default function SteeperScreen3({ onContinue, onCancel }) {
  const methods = useForm({
    mode: 'all',
  });

  // eslint-disable-next-line no-console
  const submit = data => onContinue(data);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <div className="content">
          <Panel className="adil-report-panel">
            <Button onClick={onCancel} level="secondary">
              {INSPECTION_RETURN_BUTTON}
            </Button>
            <Button type="submit" level="secondary">
              {MODAL_PRIMARY_BUTTON_LABEL}
            </Button>
          </Panel>
        </div>
      </form>
    </FormProvider>
  );
}

SteeperScreen3.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
