import React from 'react';
import PropTypes from 'prop-types';
import CardGlobalHeader from './CardGlobalHeader';
import CardGlobalBody from './CardGlobalBody';

const CardGlobalUse = ({ indexCard = 0, titleCard, children }) => (
  <div className="content__box">
    <CardGlobalHeader indexCard={indexCard} titleCard={titleCard} />
    <CardGlobalBody>{children}</CardGlobalBody>
  </div>
);

CardGlobalUse.propTypes = {
  indexCard: PropTypes.number,
  titleCard: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardGlobalUse.defaultProps = {
  indexCard: 0,
  titleCard: '',
};

export default CardGlobalUse;
