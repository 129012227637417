import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SearchActions } from '../../redux/search-inspection';
import localizedStrings from '../../utils/localizedStrings';

const {
  FINISHED_SELECT_FIELD,
  SEARCH_FINISHED,
  FINISHED_SELECT_INPUT_VALUE,
  INSPECTION_SUMMARY_SUBMISSION_ID,
  INSPECTION_SUMMARY_LICENCE_PLATE,
  INSPECTION_SUMMARY_ID_NUMBER,
} = localizedStrings;

function InputSearchFinished({ onSubmitFinishedForm }) {
  const dispatch = useDispatch();
  const { finishedInspectionsQuery } = useSelector(store => store.searchFilter);
  const searchingFields = [
    {
      label: INSPECTION_SUMMARY_LICENCE_PLATE,
      id: 'licencePlate',
    },
    {
      label: INSPECTION_SUMMARY_SUBMISSION_ID,
      id: 'submissionId',
    },
    {
      label: INSPECTION_SUMMARY_ID_NUMBER,
      id: 'idNumber',
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm();

  const [disableButton, setDisableButton] = useState(true);

  const onSubmit = data => {
    if (isValid) {
      const { searchingField, searchingValue } = data;
      const finishedInspectionsQuery = `${searchingField}=${searchingValue}`;
      dispatch(
        SearchActions.setFinishedInspectionsQuery(finishedInspectionsQuery),
      );
      setDisableButton(true);
      onSubmitFinishedForm({ searchingField, searchingValue });
    }
  };

  const isDisableButton = () => {
    const value = getValues('searchingValue');
    if (
      value.length < 1 ||
      `${getValues('searchingField')}=${value}` === finishedInspectionsQuery
    ) {
      setDisableButton(true);
    } else setDisableButton(false);
  };

  return (
    <form className="formSearch" onSubmit={handleSubmit(onSubmit)}>
      <div className="formSearch__fields">
        <label className="label_rhf_adil">{FINISHED_SELECT_FIELD}</label>
        <select
          className="inputs_rhf_adil"
          defaultValue={searchingFields[0]}
          {...register('searchingField', {
            required: true,
            onChange: isDisableButton,
          })}
        >
          {searchingFields.map(({ id, label }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="formSearch__fields">
        <label className="label_rhf_adil">{FINISHED_SELECT_INPUT_VALUE}</label>
        <input
          className="adil-input"
          {...register('searchingValue', {
            required: true,
            minLength: 1,
            onChange: isDisableButton,
          })}
        />
      </div>

      <button
        type="submit"
        className={
          !disableButton
            ? 'adil-search__button'
            : 'adil-search__button disabledButtonSearch'
        }
        disabled={disableButton}
      >
        {SEARCH_FINISHED}
      </button>
    </form>
  );
}

InputSearchFinished.propTypes = {
  onSubmitFinishedForm: PropTypes.func.isRequired,
};

export default InputSearchFinished;
