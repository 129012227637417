import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableRow } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InformedSelect from '../InformedSelect';
import { InspectionTableRow, Panel } from '..';
import { inspectionPropType } from '../../prop-types';
import arrowUp from './arrow-up.svg';
import arrowDown from './arrow-down.svg';
import { SearchActions } from '../../redux/search-inspection';
import localizedStrings from '../../utils/localizedStrings';
import { INSPECTION } from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import './styles.scss';
import {
  getFinishedTableFields,
  getSearchTableFields,
} from '../../constants/menu';
import { subItemRetakeDates, subItemSupportDates } from '../../utils/constants';
import { isAsianDomain } from '../../config/environments/environmentsHelpers';

const {
  INSPECTIONS_TABLE_HEADER_CREATED_ON,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE,
  INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW,
} = localizedStrings;

const sortableHeaders = {
  [INSPECTIONS_TABLE_HEADER_CREATED_ON]: 'emailTokenCreationDate',
  [INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO]: 'customerCompletionDate',
  [INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE]: 'history',
  [INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW]: 'history',
};

const PAGINATION_DISPLAY_LIMIT_DEFAULT = 10;

const InspectionTable = ({
  inspections,
  count,
  currentPageIndex,
  requestSort,
  sortConfig,
  onUpdateInspections,
}) => {
  const dispatch = useDispatch();
  const { currentLocation, activeInspections } = useSelector(
    store => store.searchFilter,
  );
  const tableHeaders = activeInspections
    ? getSearchTableFields()
    : getFinishedTableFields();
  const navigateToInspectionScreen = inspectionId => {
    navigate(`${INSPECTION}/${inspectionId}`);
  };
  const getSortDirection = header => {
    if (header in sortableHeaders && header === sortConfig.key) {
      return !sortConfig.direction ? (
        <img src={arrowUp} className="arrow-style" alt="arrow-up" />
      ) : (
        <img src={arrowDown} className="arrow-style" alt="arrow-down" />
      );
    }
    return null;
  };

  const [tableHeaderUpdate, setTableHeaderUpdate] = useState(
    tableHeaders.slice(0),
  );

  const selectHeaderStyle = header =>
    header in sortableHeaders ? 'header-pointer' : null;

  const renderPagination = () => {
    const numberOfPages = Math.ceil(count / PAGINATION_DISPLAY_LIMIT_DEFAULT);

    if (numberOfPages < 2) {
      return null;
    }

    const paginationDisplayValues = [];

    for (let i = 1; i <= numberOfPages; i += 1) {
      paginationDisplayValues.push(i.toString());
    }

    const currentPageIndexDisplayValue = (currentPageIndex + 1).toString();

    const onChangeLocationPage = newtLocation => {
      if (newtLocation.currentPageIndex !== currentLocation.currentPageIndex)
        dispatch(SearchActions.onChangeCurrentLocationPage(newtLocation));
    };

    return (
      <div className="pagination-container">
        <InformedSelect
          id="page-select"
          labelVisual=""
          initialValue={currentPageIndexDisplayValue}
          value={currentPageIndexDisplayValue}
          selectValues={paginationDisplayValues}
          onChange={event => {
            onChangeLocationPage({
              currentPageIndex: parseInt(event.target.value, 10) - 1,
            });
          }}
        />
      </div>
    );
  };

  const renderTableRows = () =>
    inspections.map((inspection, index) => (
      <InspectionTableRow
        onUpdateInspections={onUpdateInspections}
        key={inspection.id}
        inspection={inspection}
        isGrey={index % 2 === 0}
        onClick={() => {
          navigateToInspectionScreen(inspection.id);
        }}
      />
    ));

  const selectTableHeaders = () => {
    if (isAsianDomain()) {
      return tableHeaders;
    }
    return tableHeaderUpdate;
  };
  const renderTableHeaders = () => (
    <TableRow>
      {selectTableHeaders().map((header, index) => (
        <TableCell
          key={header || index}
          type="colHead"
          dataHeader={header}
          onClick={() => requestSort(header)}
          className={selectHeaderStyle(header)}
        >
          <div className="header-style">
            <div className="div-arrow-style">{header}</div>
            <div>{getSortDirection(header)}</div>
          </div>
        </TableCell>
      ))}
    </TableRow>
  );

  const titleDependsDates = () => {
    let indexToChange = 0;
    if (
      currentLocation?.subStatusSelected &&
      subItemRetakeDates.includes(currentLocation.subStatusSelected)
    ) {
      if (
        tableHeaderUpdate.indexOf(INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO) !==
        -1
      ) {
        indexToChange = tableHeaderUpdate.indexOf(
          INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
        );
        setTableHeaderUpdate(
          tableHeaderUpdate.map((value, index) =>
            index === indexToChange
              ? INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE
              : value,
          ),
        );
      }

      if (
        tableHeaderUpdate.indexOf(
          INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW,
        ) !== -1
      ) {
        indexToChange = tableHeaderUpdate.indexOf(
          INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW,
        );
        setTableHeaderUpdate(
          tableHeaderUpdate.map((value, index) =>
            index === indexToChange
              ? INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE
              : value,
          ),
        );
      }
    } else if (
      currentLocation?.subStatusSelected &&
      subItemSupportDates.includes(currentLocation.subStatusSelected)
    ) {
      if (
        tableHeaderUpdate.indexOf(INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO) !==
        -1
      ) {
        indexToChange = tableHeaderUpdate.indexOf(
          INSPECTIONS_TABLE_HEADER_DATE_LAST_PHOTO,
        );
        setTableHeaderUpdate(
          tableHeaderUpdate.map((value, index) =>
            index === indexToChange
              ? INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW
              : value,
          ),
        );
      }
      if (
        tableHeaderUpdate.indexOf(
          INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE,
        ) !== -1
      ) {
        indexToChange = tableHeaderUpdate.indexOf(
          INSPECTIONS_TABLE_HEADER_DATE_LAST_CUSTOMER_RETAKE,
        );
        setTableHeaderUpdate(
          tableHeaderUpdate.map((value, index) =>
            index === indexToChange
              ? INSPECTIONS_TABLE_HEADER_DATE_LAST_SUPPORT_NEW
              : value,
          ),
        );
      }
    } else {
      setTableHeaderUpdate(tableHeaders);
    }
  };

  useEffect(() => {
    titleDependsDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation?.subStatusSelected]);

  return (
    <>
      <Panel>
        <Table>
          <tbody>
            {renderTableHeaders()}
            {renderTableRows()}
          </tbody>
        </Table>
      </Panel>
      {renderPagination()}
    </>
  );
};

InspectionTable.propTypes = {
  inspections: PropTypes.arrayOf(inspectionPropType).isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  count: PropTypes.number,
  requestSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    direction: PropTypes.bool.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  onUpdateInspections: PropTypes.func,
};

InspectionTable.defaultProps = {
  count: 0,
  onUpdateInspections: () => {},
};

export default InspectionTable;
