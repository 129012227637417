import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, IconButton } from '@lmig/lmds-react';
import { IconClose } from '@lmig/lmds-react/icons';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { fileUtils } from '@lmig-latam/adlib-ui';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryConfig } from '@lmig-latam/adil-api-common-lib/countries';
// eslint-disable-next-line import/no-named-as-default
import getPhotoDisplayStringFromId from '../../../config/photoDisplayStringMapping';
import localizedStrings from '../../../utils/localizedStrings';
import Arrow from './Arrow.svg';
import './styles.scss';
import { InspectionActions } from '../../../actions';
import { getCountryCode } from '../../../config/environments/environmentsHelpers';
import { currentInspectionActions } from '../../../redux/current-inspection';
import InlineLoader from '../../InlineLoader';

const {
  INSPECTION_PHOTO_UPLOAD_IMAGES_PER_ITEM,
  INSPECTION_PHOTO_UPLOAD_IMAGES,
  INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP,
  INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER,
  INSPECTION_PHOTO_UPLOAD_IMAGES_FILE,
  INSPECTION_PHOTO_UPLOAD_IMAGES_SAVE_BUTTON,
  CREATE_INSPECTION_SELECT_VIDEO_LABEL,
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG,
  INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP,
  ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
  ADMIN_SUPPORT_ERROR_GENERAL,
  SEARCH_LOADING_LABEL,
} = localizedStrings;

const MySwal = withReactContent(Swal);

const { countryRequiredPhotoIds } = getCountryConfig(getCountryCode());

const VehiclePhotosModal = ({
  isOpen,
  onCloseModal,
  inspectionId,
  vehicleClass,
}) => {
  const [file, setFile] = useState(null);
  const [idPhoto, setIdPhoto] = useState(null);
  const [idInspecion] = useState(inspectionId);
  const [nameFile, setNameFile] = useState(INSPECTION_PHOTO_UPLOAD_IMAGES_FILE);
  const [nameFileChoosed, setNameFileChoosed] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [arrayPhotosConverted, setArrayPhotosConverted] = useState([]);
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(false);

  const fileRef = useRef();
  const dispatch = useDispatch();

  const { currentInspection } = useSelector(store => store);

  const convertArrayPhotosToUse = () => {
    const arrayUpdated = [];
    countryRequiredPhotoIds.forEach(requiredId => {
      arrayUpdated.push({
        id: requiredId,
        label: getPhotoDisplayStringFromId(vehicleClass, requiredId),
      });
    });
    setArrayPhotosConverted(arrayUpdated);
  };

  useEffect(() => {
    convertArrayPhotosToUse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInlineLoader = () => (
    <div className="search-loader auto-left-right-margin">
      <InlineLoader isLoading />
      <Heading type="h4-light" className="auto-left-right-margin">
        {SEARCH_LOADING_LABEL}
      </Heading>
    </div>
  );

  const onChangeFilePickerHandler = event => {
    const { files } = event.target;
    if (
      files &&
      nameFile === INSPECTION_PHOTO_UPLOAD_IMAGES_FILE &&
      !['jpg', 'jpeg', 'png'].includes(files[0].type.split('/').pop())
    ) {
      MySwal.fire({
        icon: 'error',
        title: INSPECTION_PHOTO_UPLOAD_TITLE_WRONG,
        customClass: {
          title: 'sweetAdil__title',
          container: 'sweetAdil__container',
          confirmButton: 'sweetAdil__acceptButton',
        },
      });
      setFile(null);
      setNameFileChoosed('');
      return;
    }
    if (
      files &&
      nameFile !== INSPECTION_PHOTO_UPLOAD_IMAGES_FILE &&
      !files[0].type
        .split('/')
        .pop()
        .includes('zip')
    ) {
      MySwal.fire({
        icon: 'error',
        title: INSPECTION_PHOTO_UPLOAD_TITLE_WRONG_ZIP,
        customClass: {
          title: 'sweetAdil__title',
          container: 'sweetAdil__container',
          confirmButton: 'sweetAdil__acceptButton',
        },
      });
      setFile(null);
      setNameFileChoosed('');
      return;
    }
    setFile(files[0]);
    setNameFileChoosed(files[0].name);
  };

  const changeNameUpload = event => {
    const { value: zipName } = event.target;
    setNameFile(
      zipName === 'notZipFiles'
        ? INSPECTION_PHOTO_UPLOAD_IMAGES_FILE
        : INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP,
    );
    setFile(null);
    setIdPhoto();
    setNameFileChoosed('');
  };

  useEffect(() => {
    setDisabledButton(true);
    if (
      nameFile === INSPECTION_PHOTO_UPLOAD_IMAGES_FILE &&
      idPhoto !== undefined &&
      file !== null
    ) {
      setDisabledButton(false);
    }
    if (nameFile !== INSPECTION_PHOTO_UPLOAD_IMAGES_FILE && file !== null) {
      setDisabledButton(false);
    }
  }, [idPhoto, file, nameFile]);

  const resetPhotoModal = () => {
    setFile(null);
    setNameFile(INSPECTION_PHOTO_UPLOAD_IMAGES_FILE);
    setNameFileChoosed('');
  };

  const sendImages = async () => {
    setInlineLoaderDisplayed(true);

    let imageToSendAWS = {};
    if (idPhoto !== undefined) {
      imageToSendAWS = {
        name: idPhoto,
        data: await fileUtils.getBase64StringFromBlobFile(file),
      };
    } else {
      imageToSendAWS = {
        data: await fileUtils.getBlobUrlFromBlobFile(file),
      };
    }
    dispatch(
      InspectionActions.updateInspectionPhotosOrZip(
        idInspecion,
        imageToSendAWS,
      ),
    )
      .then(response => {
        if (currentInspection?.currentStatus !== response.inspectionStatus) {
          dispatch(
            currentInspectionActions.changeStatusInspectionAfterPhotos(
              response.inspectionStatus,
            ),
          );
        }
        dispatch(currentInspectionActions.changeGetPhotosVariable(true));
        setInlineLoaderDisplayed(false);
        onCloseModal(!isOpen);
        resetPhotoModal();
      })
      .catch(() => {
        MySwal.fire({
          icon: 'error',
          title: ADMIN_SUPPORT_ERROR_GENERAL_TITLE,
          text: ADMIN_SUPPORT_ERROR_GENERAL,
          customClass: {
            title: 'sweetAdil__title',
            container: 'sweetAdil__container',
            confirmButton: 'sweetAdil__acceptButton',
          },
        });
        setInlineLoaderDisplayed(false);
        onCloseModal(!isOpen);
        resetPhotoModal();
      });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onCloseModal(!isOpen);
        resetPhotoModal();
      }}
      className="modal-container"
      overlayClassName="modal-container-overlay"
    >
      <div className="modal-content">
        <div className="confirmation-modal-image-close-button">
          <IconButton
            onClick={() => {
              onCloseModal(!isOpen);
              resetPhotoModal();
            }}
          >
            <IconClose size="16" />
          </IconButton>
        </div>

        <Heading type="h4-bold" className="auto-left-right-margin">
          {INSPECTION_PHOTO_UPLOAD_IMAGES}
        </Heading>

        <div className="modal-content__chooseOption">
          <select
            className="inputs_rhf_adil"
            defaultValue={nameFile}
            onChange={event => changeNameUpload(event)}
          >
            <option value="notZipFiles">
              {INSPECTION_PHOTO_UPLOAD_IMAGES_PER_ITEM}
            </option>
            <option value="ZipFiles">
              {INSPECTION_PHOTO_UPLOAD_IMAGES_ZIP}
            </option>
          </select>
        </div>

        <div className="dividerAdil" />
        <div className="modal-content__body">
          {nameFile === INSPECTION_PHOTO_UPLOAD_IMAGES_FILE && (
            <select
              className="inputs_rhf_adil"
              defaultValue={0}
              onChange={event => setIdPhoto(event.target.value)}
            >
              <option value={0} disabled>
                {CREATE_INSPECTION_SELECT_VIDEO_LABEL}
              </option>
              {arrayPhotosConverted.map(photo => (
                <option key={photo.id} value={photo.id}>
                  {photo.label}
                </option>
              ))}
            </select>
          )}
          <div
            className={classNames(
              'supporting-documents-upload-box',
              'auto-left-right-margin',
              {
                'supporting-documents-upload-box--noSpace':
                  nameFile !== INSPECTION_PHOTO_UPLOAD_IMAGES_FILE,
              },
            )}
          >
            <img
              src={Arrow}
              alt={INSPECTION_SUPPORTING_DOCUMENTS_INPUT_PLACEHOLDER}
            />

            <label className="modal-content__body__nameFile">
              <span className="supporting-documents-upload-box-browse-link">
                {nameFileChoosed || nameFile}
              </span>
              <input
                type="file"
                name="filePhoto"
                id="fileElem"
                accept=".jpg,.jpeg,.png,.zip"
                className="supporting-documents-upload-box-file-picker"
                onChange={onChangeFilePickerHandler}
                ref={fileRef}
              />
            </label>
          </div>
          <button
            type="button"
            onClick={sendImages}
            className={classNames('modal-content__body__sendButton', {
              'button-disabled': disabledButton,
            })}
            disabled={disabledButton}
          >
            {INSPECTION_PHOTO_UPLOAD_IMAGES_SAVE_BUTTON}
          </button>
          {inlineLoaderDisplayed && renderInlineLoader()}
        </div>
      </div>
    </ReactModal>
  );
};

VehiclePhotosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  inspectionId: PropTypes.string.isRequired,
  vehicleClass: PropTypes.string.isRequired,
};
export default VehiclePhotosModal;
