import PropTypes from 'prop-types';
import React from 'react';
import { LoadingAnimation } from '..';
import './styles.scss';

const InlineLoader = ({ isLoading }) =>
  isLoading && (
    <div className="inline-loader">
      <LoadingAnimation />
    </div>
  );

InlineLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default InlineLoader;
