import { GridRow, GridCol } from '@lmig/lmds-react';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';
import { driversPropType } from '../../prop-types';
import { InformedAlphanumericInput, Panel, ErrorsList } from '..';
import { config } from './config';
import './styles.scss';

const NamedDriverDetails = ({
  namedDrivers,
  updateFieldValidation,
  readOnly,
}) => {
  const formatValue = (fieldName, driver) => {
    if (fieldName.includes('driverName')) {
      return driver.firstName;
    }
    if (fieldName.includes('driverLast')) {
      return driver.lastName;
    }
    return driver.licenseNumber;
  };

  const getInformedInput = (field, driver) => {
    const { fieldName, labelVisual } = field;

    const fieldProps = {
      field: fieldName,
      id: fieldName,
      className: 'namedDriver-details-input',
      labelVisual,
      initialValue: formatValue(fieldName, driver),
      value: formatValue(fieldName, driver),
      disabled: readOnly,
    };

    return (
      <GridCol key={`vehicle-details-${fieldName}`}>
        <InformedAlphanumericInput {...fieldProps} />
      </GridCol>
    );
  };

  const handleFieldValidation = formState => {
    updateFieldValidation(Object.values(formState.errors));
  };

  return (
    <Form autoComplete="off" onChange={handleFieldValidation}>
      {({ formState }) => {
        const errors = Object.values(formState.errors);
        return (
          <Panel className="namedDriver-details-section">
            {namedDrivers.drivers.map((driver, index) => (
              <GridRow justify="start" key={driver.fieldName}>
                <GridCol base={12}>
                  <GridRow gutterSize="small">
                    {config[index + 1].map(field =>
                      getInformedInput(field, driver),
                    )}
                  </GridRow>
                </GridCol>
              </GridRow>
            ))}

            <ErrorsList errors={errors} />
          </Panel>
        );
      }}
    </Form>
  );
};

NamedDriverDetails.propTypes = {
  namedDrivers: PropTypes.shape({
    drivers: PropTypes.arrayOf(driversPropType).isRequired,
  }).isRequired,
  updateFieldValidation: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

NamedDriverDetails.defaultProps = {
  readOnly: false,
};

export default NamedDriverDetails;
