import {
  GET_PHOTOS_FROM_UPLOAD_PHOTOS,
  GET_INSPECTION_DATA,
  UPDATE_STATUS_INSPECTION_AFTER_PHOTOS,
  SET_RETAKE_PHOTO_HISTORY,
  ON_DISPLAY_PHOTO_HISTORY_MODAL,
  SET_CURRENT_PHOTO_PREVIEW_MODAL,
  RESET_PHOTO_HISTORY,
} from './constants';

export const initialState = {
  idInspection: null,
  arrayRequiredPhotos: [],
  arrayPhotosAtMoment: [],
  getPhotosAgain: false,
  currentStatus: null,
  currentPhotoPreview: null,
  rejectedPhotoHistory: {
    photoHistory: null,
    photoId: null,
    vehicleClass: null,
    displayPhotoHistoryModal: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PHOTOS_FROM_UPLOAD_PHOTOS:
      return {
        ...state,
        getPhotosAgain: action.payload,
      };

    case UPDATE_STATUS_INSPECTION_AFTER_PHOTOS:
      return {
        ...state,
        currentStatus: action.payload,
      };

    case GET_INSPECTION_DATA:
      return {
        ...state,
        idInspection: action.payload.idInspection
          ? action.payload.idInspection
          : state.idInspection,
        arrayRequiredPhotos: action.payload.arrayRequiredPhotos
          ? action.payload.arrayRequiredPhotos
          : state.arrayRequiredPhotos,
        arrayPhotosAtMoment: action.payload.arrayPhotosAtMoment
          ? action.payload.arrayPhotosAtMoment
          : state.arrayPhotosAtMoment,
        currentStatus: action.payload.currentStatus
          ? action.payload.currentStatus
          : state.currentStatus,
      };
    case ON_DISPLAY_PHOTO_HISTORY_MODAL:
      return {
        ...state,
        rejectedPhotoHistory: {
          ...state.rejectedPhotoHistory,
          displayPhotoHistoryModal: action.payload.isOpen,
          vehicleClass: action.payload.vehicleClass,
          photoId: action.payload.photoId,
        },
      };
    case RESET_PHOTO_HISTORY:
      return {
        ...state,
        rejectedPhotoHistory: {
          photoHistory: null,
          photoId: null,
          vehicleClass: null,
          displayPhotoHistoryModal: false,
        },
      };
    case SET_RETAKE_PHOTO_HISTORY:
      return {
        ...state,
        rejectedPhotoHistory: {
          ...state.rejectedPhotoHistory,
          photoHistory: action.payload.photos,
        },
      };

    case SET_CURRENT_PHOTO_PREVIEW_MODAL:
      return {
        ...state,
        currentPhotoPreview: action.payload.photoPreview,
      };
    default:
      return state;
  }
};
