import React from 'react';
import PropTypes from 'prop-types';

export function CameraIcon({ width, height }) {
  return (
    <svg
      id="_x31__x2C_5"
      viewBox="0 0 36 36"
      width={`${width}px`}
      height={`${height}px`}
    >
      <path
        d="m4.167 27.623c0-6.917-3.583-11.667-3.583-15s1.916-10.333 17.249-10.333 17.583 9.417 17.583 13.083c.001 17.167-31.249 24.5-31.249 12.25z"
        fill="#efefef"
      />
      <g fill="#a4afc1">
        <path
          d="m5.345 4.091h1v2h-1z"
          transform="matrix(.707 -.707 .707 .707 -1.888 5.624)"
        />
        <path
          d="m9.229 7.974h1v2h-1z"
          transform="matrix(.707 -.707 .707 .707 -3.496 9.505)"
        />
        <path
          d="m4.668 8.303h2v1h-2z"
          transform="matrix(.707 -.707 .707 .707 -4.565 6.586)"
        />
        <path
          d="m8.558 4.414h2v1h-2z"
          transform="matrix(.707 -.707 .707 .707 -.675 8.198)"
        />
      </g>
      <path
        d="m27.208 14.75h-4l-.95-1.89c-.34-.68-1.03-1.11-1.79-1.11h-1.76-.708-.042-.708-1.76c-.76 0-1.45.43-1.79 1.11l-.95 1.89h-4c-1.1 0-2 .9-2 2v10.5c0 1.1.9 2 2 2h9.208.042 9.208c1.1 0 2-.9 2-2v-10.5c0-1.1-.9-2-2-2z"
        fill="#f3f3f1"
      />
      <circle cx="18" cy="21.5" fill="#2fdf84" r="4.75" />
      <path
        d="m15.7 12.86c.34-.68 1.03-1.11 1.79-1.11h-.24-1.76c-.76 0-1.45.43-1.79 1.11l-.95 1.89h2z"
        fill="#d5dbe1"
      />
      <path
        d="m8.75 27.25v-10.5c0-1.1.9-2 2-2h-2c-1.1 0-2 .9-2 2v10.5c0 1.1.9 2 2 2h2c-1.1 0-2-.9-2-2z"
        fill="#d5dbe1"
      />
      <path
        d="m15.25 21.5c0-2.28 1.608-4.18 3.75-4.64-.323-.069-.656-.11-1-.11-2.623 0-4.75 2.127-4.75 4.75s2.127 4.75 4.75 4.75c.344 0 .677-.04 1-.11-2.142-.46-3.75-2.36-3.75-4.64z"
        fill="#00b871"
      />
      <path d="m18 27c-3.032 0-5.5-2.467-5.5-5.5s2.468-5.5 5.5-5.5 5.5 2.467 5.5 5.5-2.468 5.5-5.5 5.5zm0-9.5c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z" />
      <path d="m27.25 30h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-10.5c0-1.517 1.233-2.75 2.75-2.75h3.538l.742-1.477c.47-.939 1.412-1.523 2.46-1.523h2.51v1.5h-2.51c-.476 0-.904.267-1.119.695l-.951 1.892c-.127.253-.387.413-.67.413h-4c-.689 0-1.25.561-1.25 1.25v10.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-9.25h1.5v9.25c0 1.517-1.233 2.75-2.75 2.75z" />
      <circle cx="26" cy="26" r="1" />
      <path d="m30 16h-1.5v-.75c0-4.273-3.477-7.75-7.75-7.75h-.75v-1.5h.75c5.101 0 9.25 4.149 9.25 9.25z" />
      <path d="m27.167 16h-1.5v-.75c0-2.711-2.206-4.917-4.917-4.917h-.75v-1.5h.75c3.538 0 6.417 2.878 6.417 6.417z" />
      <path d="m24.333 16h-1.5v-.75c0-1.149-.935-2.083-2.083-2.083h-.75v-1.5h.75c1.976 0 3.583 1.607 3.583 3.583z" />
    </svg>
  );
}

CameraIcon.defaultProps = {
  width: 40,
  height: 40,
};

CameraIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
