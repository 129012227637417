import constants from '@lmig-latam/adil-api-common-lib/constants';
import { Button, Caption, Content, Heading } from '@lmig/lmds-react';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppFooter, AppHeader, CircleImage, SideBar } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import { DEFAULT } from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import inspectionAcceptedImage from './inspection-accepted.svg';
import inspectionRejectedImage from './inspection-rejected.svg';
import './styles.scss';
import { getCountryCode } from '../../config/environments/environmentsHelpers';

const {
  STATUS_TYPES: { STATUS_AGENT_APPROVED, STATUS_AGENT_REJECTED },
} = constants;

const {
  INSPECTION_COMPLETE_ACCEPTED_TITLE,
  INSPECTION_COMPLETE_ACCEPTED_SUBTITLE,
  INSPECTION_COMPLETE_REJECTED_TITLE,
  INSPECTION_COMPLETE_REJECTED_SUBTITLE,
  INSPECTION_COMPLETE_BUTTON_LABEL,
} = localizedStrings;

const InspectionComplete = () => {
  const { state: { status } = {} } = useLocation();

  const navigateToSearchScreen = () => {
    navigate(DEFAULT);
  };

  const getScreenProps = () => {
    switch (status) {
      case STATUS_AGENT_REJECTED:
        return {
          title: INSPECTION_COMPLETE_REJECTED_TITLE,
          subtitle: INSPECTION_COMPLETE_REJECTED_SUBTITLE,
          imageSource: inspectionRejectedImage,
        };
      case STATUS_AGENT_APPROVED:
      default:
        return {
          title: INSPECTION_COMPLETE_ACCEPTED_TITLE,
          subtitle: INSPECTION_COMPLETE_ACCEPTED_SUBTITLE,
          imageSource: inspectionAcceptedImage,
        };
    }
  };

  const { title, subtitle, imageSource } = getScreenProps();
  const renderFooterSection = () =>
    getCountryCode() === 'co' ? <AppFooter /> : null;

  return (
    <div className="adil-screen">
      <AppHeader />
      <SideBar>
        <Content className="adil-content">
          <div className="inspection-complete-content">
            <Heading type="h2-light" className="auto-left-right-margin">
              {title}
            </Heading>
            <Caption className="gray-text">{subtitle}</Caption>
            <CircleImage>
              <img src={imageSource} alt="" />
            </CircleImage>
            <Button onClick={navigateToSearchScreen}>
              {INSPECTION_COMPLETE_BUTTON_LABEL}
            </Button>
          </div>
          {renderFooterSection()}
        </Content>
      </SideBar>
    </div>
  );
};

export default connect(null, null)(InspectionComplete);
