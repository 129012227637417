import { FIELD_NAMES } from '@lmig-latam/adil-api-common-lib/constants';
import { Caption, GridCol, GridRow } from '@lmig/lmds-react';
import classNames from 'classnames';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CircleImage,
  ErrorsList,
  InformedAlphanumericInput,
  InformedSelect,
  Panel,
} from '..';
import { vehiclePropType } from '../../prop-types';
import {
  verifyFieldValidation,
  verifySelectValidation,
  YEAR_SELECT_OPTIONS,
} from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import car from './car.svg';
import { config } from './config';
import './styles.scss';

const { YEAR } = FIELD_NAMES;

const { INSPECTION_VEHICLE_DETAILS_IMAGE_ALT_TEXT } = localizedStrings;

const VehicleDetails = ({
  vehicle,
  updateVehicle,
  updateFieldValidation,
  readOnly,
}) => {
  const getInformedInput = field => {
    const { fieldName, labelVisual, disabled } = field;
    let inputComponent;

    // Try to find content for an InformedSelect field
    let fieldContent = field.options;

    if (fieldName === YEAR) {
      fieldContent = YEAR_SELECT_OPTIONS;
    }

    const useAlphanumericInput = !fieldContent;

    const validate = useAlphanumericInput
      ? value => verifyFieldValidation(value, field)
      : value => verifySelectValidation(value, field);

    const fieldProps = {
      field: fieldName,
      id: fieldName,
      className: classNames({
        'vehicle-details-input': true,
        'vehicle-details-input-gray': disabled,
      }),
      labelVisual,
      onChange: event => {
        updateVehicle({ ...vehicle, [fieldName]: event.target.value });
      },
      initialValue: vehicle[fieldName],
      value: vehicle[fieldName],
      validate,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      disabled: readOnly,
    };

    if (useAlphanumericInput) {
      inputComponent = <InformedAlphanumericInput {...fieldProps} />;
    } else {
      inputComponent = (
        <InformedSelect {...fieldProps} selectValues={fieldContent} />
      );
    }

    return (
      <GridCol key={`vehicle-details-${fieldName}`}>{inputComponent}</GridCol>
    );
  };

  const getInformedInputRow = fields =>
    fields.map(field => getInformedInput(field));

  const renderTopLeftSection = () => {
    const { licencePlate } = vehicle;

    return (
      <GridCol base={3} className="vehicle-details-top-left-section">
        <GridRow>
          <GridCol className="vehicle-details-top-left-section-first-row">
            <CircleImage size="small">
              <img
                src={car}
                alt={INSPECTION_VEHICLE_DETAILS_IMAGE_ALT_TEXT}
                className="vehicle-details-car-icon"
              />
            </CircleImage>
            <Caption className="vehicle-details-license-plate">
              {licencePlate}
            </Caption>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol>
            <div className="vehicle-details-fasecolda-code-wrapper">
              {getInformedInputRow(config.topLeftSection)}
            </div>
          </GridCol>
        </GridRow>
      </GridCol>
    );
  };

  const renderTopRightSection = () => (
    <GridCol className="vehicle-details-top-right-section">
      <GridRow>{getInformedInputRow(config.topRightSectionFirstRow)}</GridRow>
      <GridRow className="vehicle-details-top-right-second-row">
        {getInformedInputRow(config.topRightSectionSecondRow)}
      </GridRow>
    </GridCol>
  );

  const renderBottomSection = () => (
    <GridCol>
      <GridRow>{getInformedInputRow(config.bottomSectionFirstRow)}</GridRow>
      <GridRow>{getInformedInputRow(config.bottomSectionSecondRow)}</GridRow>
    </GridCol>
  );

  const handleFieldValidation = formState =>
    updateFieldValidation(Object.values(formState.errors));

  return (
    <Form autoComplete="off" onChange={handleFieldValidation}>
      {({ formState }) => {
        const errors = Object.values(formState.errors);

        return (
          <Panel className="vehicle-details">
            <GridRow>
              {renderTopLeftSection()}
              {renderTopRightSection()}
            </GridRow>
            <GridRow className="vehicle-details-bottom-section">
              {renderBottomSection()}
            </GridRow>
            <ErrorsList errors={errors} />
          </Panel>
        );
      }}
    </Form>
  );
};

VehicleDetails.propTypes = {
  vehicle: vehiclePropType.isRequired,
  updateVehicle: PropTypes.func.isRequired,
  updateFieldValidation: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

VehicleDetails.defaultProps = {
  readOnly: false,
};

export default VehicleDetails;
