import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import localizedStrings from '../../utils/localizedStrings';
import { identificationSystemStatuses } from '../../utils/constants';

const {
  INSPECTION_INFORM_NUMBER_PATENT,
  INSPECTION_INFORM_STATE_PATENT,
  INSPECTION_INFORM_NUMBER_CHASSIS,
  INSPECTION_INFORM_STATE_CHASSIS,
  INSPECTION_INFORM_NUMBER_MOTOR,
  INSPECTION_INFORM_STATE_MOTOR,
  INSPECTION_INFORM_APPROVED_OPTION,
  INSPECTION_INFORM_DISAPPROVED_OPTION,
} = localizedStrings;

const statusesInspectionSystem = {
  // eslint-disable-next-line prettier/prettier
  'INSPECTION_INFORM_APPROVED_OPTION': INSPECTION_INFORM_APPROVED_OPTION,
  // eslint-disable-next-line prettier/prettier
  'INSPECTION_INFORM_DISAPPROVED_OPTION': INSPECTION_INFORM_DISAPPROVED_OPTION,
};

export default function PanelInspectionSystem({ onValidSubmit }) {
  const {
    FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
  } = localizedStrings;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const statePatentRef = useRef(null);
  const motorStateRef = useRef(null);
  const chassisStateRef = useRef(null);

  useEffect(() => {
    onValidSubmit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnNameOf = nameOption => {
    const wordSearch = `INSPECTION_INFORM_${nameOption}_OPTION`;
    const indexOfNameOption = Object.keys(statusesInspectionSystem).findIndex(
      e => e === wordSearch,
    );
    return Object.values(statusesInspectionSystem)[indexOfNameOption];
  };

  const changeColorOption = e => {
    const { name, value } = e.target;
    const referenceByEvent = `${name}Ref`;
    const indexIdentificationSystem = Object.keys(
      identificationSystemStatuses,
    ).findIndex(element => element === value);
    const classForColorChange = Object.values(identificationSystemStatuses)[
      indexIdentificationSystem
    ];
    let classNameRefSelected = null;
    switch (referenceByEvent) {
      case 'statePatentRef':
        classNameRefSelected = statePatentRef.current;
        break;
      case 'motorStateRef':
        classNameRefSelected = motorStateRef.current;
        break;
      case 'chassisStateRef':
        classNameRefSelected = chassisStateRef.current;
        break;
      default:
        return;
    }
    classNameRefSelected.className = 'inputs_rhf_adil';
    classNameRefSelected.classList.add(classForColorChange.class);
  };

  return (
    <div className="flex flex:1 flex:col gap:0.8rem">
      <div>
        <input
          placeholder={INSPECTION_INFORM_NUMBER_PATENT}
          {...register('numberPatent', {
            pattern: {
              value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
              message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
            },
          })}
          className={`inputs_rhf_adil ${
            errors.numberPatent && errors.numberPatent.type === 'pattern'
              ? 'validation-input-field-error'
              : ''
          }`}
        />

        {errors.numberPatent && errors.numberPatent.type === 'pattern' && (
          <p className="error-message--forms">
            {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
          </p>
        )}
      </div>

      <div>
        <select
          name="statePatent"
          defaultValue="default"
          {...register('statePatent')}
          ref={statePatentRef}
          className={`inputs_rhf_adil ${
            errors.statePatent ? 'validation-input-field-error' : ''
          }`}
          onChange={e => changeColorOption(e)}
        >
          <option value="default" disabled="disabled">
            {INSPECTION_INFORM_STATE_PATENT}
          </option>
          {Object.entries(identificationSystemStatuses).map(([key, value]) => (
            <option className={value.class} key={key} value={key}>
              {returnNameOf(key)}
            </option>
          ))}
        </select>
      </div>

      <div>
        <input
          placeholder={INSPECTION_INFORM_NUMBER_CHASSIS}
          {...register('chassisNumber', {
            pattern: {
              value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
              message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
            },
          })}
          className={`inputs_rhf_adil ${
            errors.chassisNumber && errors.chassisNumber.type === 'pattern'
              ? 'validation-input-field-error'
              : ''
          }`}
        />

        {errors.chassisNumber && errors.chassisNumber.type === 'pattern' && (
          <p className="error-message--forms">
            {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
          </p>
        )}
      </div>

      <div>
        <select
          name="chassisState"
          defaultValue="default"
          {...register('chassisState')}
          ref={chassisStateRef}
          className={`inputs_rhf_adil ${
            errors.chassisState ? 'validation-input-field-error' : ''
          }`}
          onChange={e => changeColorOption(e)}
        >
          <option value="default" disabled="disabled">
            {INSPECTION_INFORM_STATE_CHASSIS}
          </option>
          {Object.entries(identificationSystemStatuses).map(([key, value]) => (
            <option className={value.class} key={key} value={key}>
              {returnNameOf(key)}
            </option>
          ))}
        </select>
      </div>

      <div>
        <input
          placeholder={INSPECTION_INFORM_NUMBER_MOTOR}
          {...register('motorNumber', {
            pattern: {
              value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
              message: FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS,
            },
          })}
          className={`inputs_rhf_adil ${
            errors.motorNumber && errors.motorNumber.type === 'pattern'
              ? 'validation-input-field-error'
              : ''
          }`}
        />

        {errors.motorNumber && errors.motorNumber.type === 'pattern' && (
          <p className="error-message--forms">
            {FORM_INFORM_ANALYSIS_ERROR_MESSAGE_SPECIAL_CHARACTERS}
          </p>
        )}
      </div>

      <div>
        <select
          name="motorState"
          defaultValue="default"
          {...register('motorState')}
          ref={motorStateRef}
          className={`inputs_rhf_adil ${
            errors.motorState ? 'validation-input-field-error' : ''
          }`}
          onChange={e => changeColorOption(e)}
        >
          <option value="default" disabled="disabled">
            {INSPECTION_INFORM_STATE_MOTOR}
          </option>
          {Object.entries(identificationSystemStatuses).map(([key, value]) => (
            <option className={value.class} key={key} value={key}>
              {returnNameOf(key)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

PanelInspectionSystem.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
};
