import React from 'react';
import PropTypes from 'prop-types';

export const RotationArrowIcon = ({
  width,
  height,
  background,
  direction,
  hidden,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    cursor="pointer"
    style={{ display: hidden && 'none' }}
    {...props}
  >
    <circle cx="16" cy="16" r="16" fill={background} />
    {direction === 'left' ? (
      <path
        d="M6.669,18.568a9.159,9.159,0,0,1,14.613-1.752l-2.952-.405L18.168,17.6l5,.686.736-4.909L22.723,13.2l-.442,2.943A10.346,10.346,0,0,0,5.537,18.15a4.809,4.809,0,0,1,1.132.418"
        transform="translate(1.463 0.24)"
        fill="#fff"
      />
    ) : (
      <path
        d="M22.773,18.568A9.159,9.159,0,0,0,8.161,16.816l2.952-.405.163,1.185-5,.686-.736-4.909L6.72,13.2l.442,2.943A10.346,10.346,0,0,1,23.906,18.15a4.809,4.809,0,0,0-1.132.418"
        transform="translate(1.463 0.24)"
        fill="#fff"
      />
    )}
  </svg>
);

RotationArrowIcon.defaultProps = {
  width: 40,
  height: 40,
  hidden: false,
  background: '#2a2a2a3d',
};

RotationArrowIcon.propTypes = {
  hidden: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
};
