import {
  BodyText,
  Button,
  Caption,
  Disclaimer,
  Heading,
  IconUpload,
  Notification,
  Table,
  TableCell,
  TableRow,
} from '@lmig/lmds-react';
import { IconChat, IconDownload, IconInfo } from '@lmig/lmds-react/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { photoUtils } from '@lmig-latam/adlib-ui';
import { LANGUAGES } from '@lmig-latam/adil-api-common-lib/constants';
import {
  CircleImage,
  CustomerMessages,
  Panel,
  PhotoModal,
  VehiclePhotoRow,
} from '..';
import { customerMessagePropType, photoPropType } from '../../prop-types';
import { photoStatuses } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import VehiclePhotosModal from './VehiclePhotosModal/index';
import Collapsable from '../Collapsable';
import { getCountryCode } from '../../config/environments/environmentsHelpers';
import { isCountry } from '../../config/environments';
import { currentInspectionActions } from '../../redux/current-inspection';
import './styles.scss';

const {
  INSPECTION_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE,
  INSPECTION_VEHICLE_PHOTOS_TITLE,
  INSPECTION_VEHICLE_PHOTOS_SUBTITLE,
  INSPECTION_VEHICLE_PHOTOS_HEADER_2,
  INSPECTION_VEHICLE_PHOTOS_HEADER_3,
  INSPECTION_VEHICLE_PHOTOS_HEADER_4,
  INSPECTION_VEHICLE_PHOTOS_AI_BANNER,
  INSPECTION_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY,
  INSPECTION_VEHICLE_PHOTOS_VIEW_COMMENTS,
  INSPECTION_VEHICLE_PHOTOS_INCORRECT_PHOTOS,
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE,
  INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE,
  INSPECTION_VEHICLE_PHOTOS_APPROVE_ALL_PHOTOS,
  INSPECTION_PHOTO_UPLOAD_IMAGES,
} = localizedStrings;

const { APPROVED, REJECTED } = photoStatuses;

const VehiclePhotos = ({
  photos,
  damagePhotos,
  approveAllPhotos,
  inspectionId,
  downloadPhotosZip,
  photosZipDownloading,
  updatePhotos,
  customerMessages,
  newCustomerMessages,
  readOnly,
  displayImageAnalysis,
  vehicleClass,
  downloadDisabled,
  hideButtonUploadPhotos,
  rotateImage,
  updateVideo,
  videoData,
  drivers,
  photoModalDisplayed,
  setPhotoModalDisplayed,
}) => {
  const [
    customerMessagesModalDisplayed,
    setCustomerMessagesModalDisplayed,
  ] = useState(false);

  const [openUploadPhotos, setOpenUploadPhotos] = useState(false);
  const [rotation, setRotation] = useState(0);

  const { doesImageContainPerson, doesImageContainVehicle } = photoUtils;

  const {
    currentInspection: { currentPhotoPreview },
  } = useSelector(store => store);

  const dispatch = useDispatch();

  const showOrHideModalPhotos = condition => {
    setOpenUploadPhotos(condition);
  };

  const showCustomerMessagesModal = () => {
    setCustomerMessagesModalDisplayed(true);
  };

  const hideCustomerMessagesModal = () => {
    setCustomerMessagesModalDisplayed(false);
  };

  const [allPhotos, setAllPhotos] = useState([]);

  useEffect(() => {
    const mergedPhotos = [...photos, ...(damagePhotos || [])];
    setAllPhotos(mergedPhotos);
  }, [photos, damagePhotos]);

  const updatePhoto = updatedPhoto => {
    const updatedAllPhotos = allPhotos.map(photo =>
      photo.name === updatedPhoto.name ? updatedPhoto : photo,
    );
    setAllPhotos(updatedAllPhotos);

    updatePhotos(updatedAllPhotos);
  };

  const displayAllowActionRotateImage = isCountry('cl', 'ec', 'co');

  const showPhotoModal = photo => {
    dispatch(currentInspectionActions.setCurrentPhotoPreviewModal(photo));
    setPhotoModalDisplayed(true);
  };

  const hidePhotoModal = () => {
    setPhotoModalDisplayed(false);
    dispatch(currentInspectionActions.setCurrentPhotoPreviewModal(null));
    setRotation(0);
  };

  const displayImageContainsVehicleIcon =
    displayImageAnalysis &&
    currentPhotoPreview?.tags &&
    doesImageContainVehicle(currentPhotoPreview?.tags);

  const displayImageContainsPersonIcon =
    displayImageAnalysis &&
    currentPhotoPreview?.tags &&
    doesImageContainPerson(currentPhotoPreview?.tags);

  useEffect(() => {
    if (approveAllPhotos) {
      const photosUpdatedStatus = photos.map(photo => ({
        ...photo,
        status: APPROVED,
      }));
      updatePhotos(photosUpdatedStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveAllPhotos]);

  const tablePhotosRender = (
    hasExistingMessages,
    numberOfRejectedPhotos,
    anyRejectedPhotos,
  ) => (
    <>
      <Table className="vehicle-photos-table adil-box-shadow">
        <tbody>
          <TableRow className="vehicle-photos-headers">
            <TableCell type="colHead" />
            <TableCell type="colHead" />
            <TableCell
              type="colHead"
              dataHeader={INSPECTION_VEHICLE_PHOTOS_HEADER_2}
            >
              {INSPECTION_VEHICLE_PHOTOS_HEADER_2}
            </TableCell>
            <TableCell
              type="colHead"
              dataHeader={INSPECTION_VEHICLE_PHOTOS_HEADER_3}
            >
              {INSPECTION_VEHICLE_PHOTOS_HEADER_3}
            </TableCell>
            <TableCell
              type="colHead"
              dataHeader={INSPECTION_VEHICLE_PHOTOS_HEADER_4}
            >
              {INSPECTION_VEHICLE_PHOTOS_HEADER_4}
            </TableCell>
          </TableRow>

          {allPhotos.map((photo, index) => (
            <VehiclePhotoRow
              key={photo.url}
              approveAllPhotos={approveAllPhotos}
              photo={photo}
              updatePhoto={updatePhoto}
              videoData={videoData}
              isGrey={index % 2 === 0}
              updateVideo={updateVideo}
              readOnly={photo.readOnly || readOnly}
              displayImageAnalysis={displayImageAnalysis}
              vehicleClass={vehicleClass}
              downloadDisabled={downloadDisabled}
              rotateImage={rotateImage}
              drivers={drivers}
              photoPreview={currentPhotoPreview}
              showPhotoModal={showPhotoModal}
              photoModalDisplayed={photoModalDisplayed}
            />
          ))}
          {hasExistingMessages && (
            <TableRow
              className={classNames({
                'adil-table-row-grey': photos.length % 2 === 0,
              })}
            >
              <td
                colSpan="5"
                className="lm-Table-cell vehicle-photos-existing-customer-messages"
              >
                <div>
                  <BodyText>
                    {`\u2713 ${numberOfRejectedPhotos} ${INSPECTION_VEHICLE_PHOTOS_COMMENTS_SENT_SUCCESSFULLY}`}
                  </BodyText>
                  <div role="button" onClick={showCustomerMessagesModal}>
                    <BodyText>
                      {INSPECTION_VEHICLE_PHOTOS_VIEW_COMMENTS}
                    </BodyText>
                    <CircleImage
                      size="small"
                      className="vehicle-photos-existing-customer-messages-icon"
                    >
                      <IconChat size="32" />
                    </CircleImage>
                  </div>
                </div>
              </td>
            </TableRow>
          )}
        </tbody>
      </Table>
      {anyRejectedPhotos && (
        <div className="vehicle-photos-incorrect-photos-tooltip">
          <Caption>
            <IconInfo size="16" />
            {INSPECTION_VEHICLE_PHOTOS_INCORRECT_PHOTOS}
          </Caption>
          <i />
        </div>
      )}
    </>
  );

  const renderPhotosTable = anyRejectedPhotos => {
    const hasExistingMessages =
      customerMessages.length + newCustomerMessages.length > 0;
    const numberOfRejectedPhotos = photos.filter(
      photo => photo.status === REJECTED,
    ).length;
    return (
      <>
        {getCountryCode() === 'vn' && (
          <Collapsable idCollapsable="photosId">
            {tablePhotosRender(
              hasExistingMessages,
              numberOfRejectedPhotos,
              anyRejectedPhotos,
            )}
          </Collapsable>
        )}
        {getCountryCode() !== 'vn' &&
          tablePhotosRender(
            hasExistingMessages,
            numberOfRejectedPhotos,
            anyRejectedPhotos,
          )}
      </>
    );
  };

  const renderNoPhotosMessage = () => (
    <BodyText className="vehicle-photos-no-photos-message auto-left-right-margin">
      {INSPECTION_VEHICLE_PHOTOS_NO_PHOTOS_MESSAGE}
    </BodyText>
  );

  const onApproveAllPhotos = () => {
    const photosUpdatedStatus = photos.map(photo => ({
      ...photo,
      status: APPROVED,
    }));
    updatePhotos(photosUpdatedStatus);
  };

  const renderDownloadAll = () => {
    if (downloadDisabled) {
      return null;
    }
    return (
      <div
        onClick={photosZipDownloading ? null : downloadPhotosZip}
        className="download-all-link"
        role="button"
      >
        <BodyText>
          {photosZipDownloading
            ? INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOADING_ALL_IMAGE
            : INSPECTION_VEHICLE_PHOTOS_MODAL_DOWNLOAD_ALL_IMAGE}
        </BodyText>
        <CircleImage size="small">
          <IconDownload size="16" />
        </CircleImage>
      </div>
    );
  };

  const photosFilteredWithoutVideo = useMemo(
    () => photos?.filter(item => !item.name.includes('VIDEO')),
    [photos],
  );

  const anyRejectedPhotos = allPhotos.some(photo => photo.status === REJECTED);
  const photosNonZero = photos.length > 0;

  return (
    <Panel className="vehicle-photos">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {!hideButtonUploadPhotos && (
          <>
            <div
              onClick={() => showOrHideModalPhotos(!openUploadPhotos)}
              className="download-all-link"
              role="button"
            >
              <BodyText>{INSPECTION_PHOTO_UPLOAD_IMAGES}</BodyText>
              <CircleImage size="small">
                <IconUpload size="16" />
              </CircleImage>
            </div>

            <VehiclePhotosModal
              isOpen={openUploadPhotos}
              onCloseModal={closeModal => showOrHideModalPhotos(closeModal)}
              inspectionId={inspectionId}
              vehicleClass={vehicleClass}
            />
          </>
        )}

        {photos.length !== 0 && renderDownloadAll()}

        {getCountryCode() === LANGUAGES.CL && (
          <div className="approve-all-photos">
            <Button
              className="approve-all-photos-button"
              disabled={readOnly}
              level="secondary"
              onClick={onApproveAllPhotos}
            >
              {INSPECTION_VEHICLE_PHOTOS_APPROVE_ALL_PHOTOS}
            </Button>
          </div>
        )}
      </div>

      <Heading type="h3-light" className="auto-left-right-margin header">
        {INSPECTION_VEHICLE_PHOTOS_TITLE}
      </Heading>
      <Disclaimer>{INSPECTION_VEHICLE_PHOTOS_SUBTITLE}</Disclaimer>
      {displayImageAnalysis && photosNonZero && (
        <Notification className="vehicle-photos-ai-banner">
          {INSPECTION_VEHICLE_PHOTOS_AI_BANNER}
        </Notification>
      )}
      {photos.length > 0
        ? renderPhotosTable(anyRejectedPhotos)
        : renderNoPhotosMessage()}
      <CustomerMessages
        isOpen={customerMessagesModalDisplayed}
        closeButtonHandler={hideCustomerMessagesModal}
        customerMessages={customerMessages}
        newCustomerMessages={newCustomerMessages}
      />

      {currentPhotoPreview && (
        <PhotoModal
          isOpen={photoModalDisplayed}
          inspectionId={inspectionId}
          photos={photosFilteredWithoutVideo}
          closeButtonHandler={hidePhotoModal}
          rotateImage={rotateImage}
          rotation={rotation}
          currentPhotoPreview={currentPhotoPreview}
          geolocation={currentPhotoPreview.location}
          downloadDisabled={downloadDisabled}
          displayImageContainsVehicleIcon={displayImageContainsVehicleIcon}
          displayImageContainsPersonIcon={displayImageContainsPersonIcon}
          displayAllowActionRotateImage={displayAllowActionRotateImage}
          showPhotoModal={showPhotoModal}
        />
      )}
    </Panel>
  );
};

VehiclePhotos.propTypes = {
  photos: PropTypes.arrayOf(photoPropType),
  damagePhotos: PropTypes.arrayOf(photoPropType),
  approveAllPhotos: PropTypes.bool,
  inspectionId: PropTypes.string.isRequired,
  downloadPhotosZip: PropTypes.func.isRequired,
  photosZipDownloading: PropTypes.bool,
  updatePhotos: PropTypes.func.isRequired,
  customerMessages: PropTypes.arrayOf(customerMessagePropType),
  newCustomerMessages: PropTypes.arrayOf(customerMessagePropType),
  readOnly: PropTypes.bool,
  displayImageAnalysis: PropTypes.bool,
  vehicleClass: PropTypes.string,
  downloadDisabled: PropTypes.bool,
  hideButtonUploadPhotos: PropTypes.bool,
  rotateImage: PropTypes.func.isRequired,
  updateVideo: PropTypes.func.isRequired,
  photoModalDisplayed: PropTypes.bool.isRequired,
  setPhotoModalDisplayed: PropTypes.func.isRequired,
  videoData: PropTypes.shape({
    rotation: PropTypes.number,
  }),
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      licenseNumber: PropTypes.string,
    }),
  ),
};

VehiclePhotos.defaultProps = {
  photos: [],
  damagePhotos: [],
  approveAllPhotos: false,
  photosZipDownloading: false,
  customerMessages: [],
  newCustomerMessages: [],
  readOnly: false,
  displayImageAnalysis: false,
  vehicleClass: '',
  downloadDisabled: false,
  hideButtonUploadPhotos: false,
  videoData: {
    rotation: 0,
  },
  drivers: [],
};

const mapStateToProps = ({ user: { isAdmin, isReviewer } }) => ({
  isAdmin,
  isReviewer,
});

export default connect(mapStateToProps)(VehiclePhotos);
