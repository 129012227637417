import React from 'react';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { GalleryOptions } from '../InspectionInformGallery/GalleryOptions/GalleryOptions';

const ImagePreviewModal = ({
  downloadDisabled,
  handleFullscreen,
  currentPhoto,
  rotation,
  galleryOptions: {
    onManageGalleryOption,
    setBrigthnessValue,
    brigthnessRef,
    resetBrigthness,
  },
}) => {
  const classNameImage = [
    'img',
    handleFullscreen.active && 'fullscreen-img',
  ].join(' ');

  const ImageInPreview = () => (
    <img
      src={currentPhoto?.url}
      alt={currentPhoto?.name}
      className={classNameImage}
      style={{ transform: `rotate(${rotation}deg)` }}
    />
  );

  return (
    <TransformWrapper>
      <GalleryOptions
        galleryClassName="menu-photo-options"
        hasPhotoHistory={false}
        onManageGalleryOption={onManageGalleryOption}
        currentPhoto={{ url: currentPhoto?.url }}
        setBrigthnessValue={setBrigthnessValue}
        brigthnessRef={brigthnessRef}
        resetBrigthness={resetBrigthness}
        handleFullscreen={handleFullscreen}
        enabledOptions={[
          'rotation',
          'fullscreen',
          'zoom',
          !downloadDisabled && 'download',
        ]}
      />

      <TransformComponent>
        <ImageInPreview />
      </TransformComponent>
    </TransformWrapper>
  );
};

ImagePreviewModal.propTypes = {
  downloadDisabled: PropTypes.bool.isRequired,
  handleFullscreen: PropTypes.func.isRequired,

  currentPhoto: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  rotation: PropTypes.number.isRequired,

  galleryOptions: PropTypes.shape({
    onManageGalleryOption: PropTypes.func.isRequired,
    setBrigthnessValue: PropTypes.func.isRequired,
    brigthnessRef: PropTypes.node.isRequired,
    resetBrigthness: PropTypes.func.isRequired,
  }).isRequired,
};

export default ImagePreviewModal;
